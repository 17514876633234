import {ErrorBoundary, useMainStore} from "@haulmont/jmix-react-core";
import React from "react";
import {useIntl} from "react-intl";
import useTimeoutCallback from "../utils/useTimeoutCallback";
import {NotificationType} from "./AppNotificationContext";
import useAppNotification from "./useAppNotification";

export const AppErrorBoundary = function (props) {
  const intl = useIntl();
  const mainStore = useMainStore();
  const {addNotificationModal} = useAppNotification()
  const logoutOut = useTimeoutCallback(mainStore.logout)
  // useTimeout(mainStore.logout, 4)

  return (
    <ErrorBoundary
      message={intl.formatMessage({id: "common.unknownAppError"})}
      render={message => {
        //   console.log("ERROR: ", message)
        mainStore.authToken = null
        addNotificationModal({type: NotificationType.Error, title: message})
        //   logoutOut(4 * 1000)
        return (<></>)
      }}
    >
      {props.children}
    </ErrorBoundary>
  );
};
