import {useMainStore} from "@haulmont/jmix-react-core";
import Card from "@mui/material/Card";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppNotification from "app/common/error/AppNotification";
import TopNavbar from "app/components/navabar/TopNavbar";
import logoImage from "assets/app/logo/md-white-logo-full.png";
// Images
import bgImage from "assets/images/bg5.jpg";
import MDBox from "components/MDBox";
import {observer} from "mobx-react-lite";
// Routes
import routes from "page.routes";
import {useEffect, useRef} from "react";
// rellax
import Rellax from "rellax";
import ConditionsSection from "./components/ConditionSection";
import DistrictSection from "./components/DistrictSection";
import IconsPromo from "./components/IconsPromo";
import Information from "./components/Information";
import ProgramSection from "./components/ProgramSection";
import TeamSection from "./components/TeamSection";
import TourSection from "./components/ToursSection";
import useAuthenticated from "../../common/authentication/useAuthenticated";

const HomePage = observer(() => {
  const headerRef = useRef(null);
  const mainStore = useMainStore();
  var authenticated = useAuthenticated();

  // Setting up rellax
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });

    return () => parallax.destroy();
  }, []);


  const signInAction = {
    type: "internal",
    route: "/sign-in",
    label: "Вхід",
    color: "light",
  };

  const goToDashboard = {
    type: "internal",
    route: "/tours",
    label: "Account",
    color: "light",
  };

  return (
    <>
      <MDBox bgColor="white">
        <TopNavbar
          routes={routes}
          // action={authenticated() ? goToDashboard : signInAction}
          transparent
          relative
          light
        />
      </MDBox>
      <MDBox
        ref={headerRef}
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.8),
              rgba(gradients.dark.state, 0.8)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            alignContent={"center"}
            justifyContent="center"
            flexDirection="column"
          >
            <MDBox
              component="img"
              style={{
                maxWidth: "300px",
                borderRadius: "15px 15px 0px 0px",
                objectFit: "cover",
                objectPosition: "right",
                width: "100%",
                height: "auto",
              }}
              src={logoImage}
              alt={"alt"}
              // borderRadius="lg"
              // shadow="md"
              width="100%"
              height="auto"
              position="relative"
              zIndex={1}
            />
          </Grid>
        </Container>
      </MDBox>
      <Card
        sx={{
          // p: 2,
          mx: {xs: 2, lg: 3},
          mt: -8,
          mb: 4,
          boxShadow: ({boxShadows: {xxl}}) => xxl,
        }}
      >
        <Information mt={10}/>
        <IconsPromo mt={8}/>
        <TourSection mt={8}/>
        <DistrictSection mt={8}/>
        <ConditionsSection mt={8}/>
        <ProgramSection mt={8}/>
        <TeamSection mt={8}/>
        {/* <Information /> */}
        {/* <Steps /> */}
        {/* <OurEfforts /> */}
        {/* <Features /> */}
        {/* <Posts /> */}
        {/* <Support /> */}
      </Card>
      {/* <MDBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MDBox> */}
    </>
  );
});

export default HomePage;
