import {ApolloError} from "@apollo/client";
import CenteredLoader from "app/common/loader/CenteredLoader";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {observer} from "mobx-react";
import {useParams} from "react-router-dom";
import FondyPayment from "./components/FondyPayment";
import {
  AcquiringProvider,
  TokenPayload,
  useCreatOrderTourQuery,
  useOrderTourTokenQuery
} from "app/generated/generated-types";
import LiqPayPayment from "app/components/payment/components/LiqPayPayment";


type PaymentProps = {
  kidId: string,
  tourId: string,
  isBooking: boolean,
  friends: string[]
  onSuccess?: (status?: any) => void,
  onFailure?: (status?: any) => void

}

type RepaymentProps = {
  orderId: string,
  onSuccess?: (status?: any) => void,
  onFailure?: (status?: any) => void

}

const Payment = observer((props?: PaymentProps | RepaymentProps) => {
  const {id} = useParams();
  console.log("Id : ", id)

  var tokenPayload: TokenPayload | undefined;
  var error: ApolloError | undefined;

  var loading: boolean;
  if (id || "orderId" in props!) {
    props = props as RepaymentProps
    const orderId: string = id ? id : props.orderId
    var repayment = useOrderTourTokenQuery({
      variables: {
        orderId: orderId
      }
    })
    loading = repayment.loading
    error = repayment.error
    if (repayment.data) {
      tokenPayload = repayment.data.tokeByOrderId as TokenPayload
    }

  } else {
    props = props as PaymentProps
    const payment = useCreatOrderTourQuery({
      fetchPolicy: "no-cache",
      variables: {
        request: {
          kidId: props.kidId,
          tourId: props.tourId,
          booking: props.isBooking,
          friends: props.friends
        }


      }
    });
    loading = payment.loading
    error = payment.error
    if (payment.data) {
      tokenPayload = payment.data.createOrderTour as TokenPayload
    }
  }


  if (loading) {
    return <CenteredLoader/>
  }


  return (
    <DashboardLayout>
      <DashboardNavbar/>
      {
        (tokenPayload && tokenPayload?.provider == AcquiringProvider.Fondy) &&
        <FondyPayment onFailure={props.onFailure} onSuccess={props.onSuccess}
                      token={tokenPayload.token!}/>
      }
      {
        (tokenPayload && tokenPayload?.provider == AcquiringProvider.Liqpay) &&
        <LiqPayPayment onFailure={props.onFailure} onSuccess={props.onSuccess}
                       data={tokenPayload.data!} signature={tokenPayload.signature!}/>
      }

    </DashboardLayout>
  );
})


export default Payment;