import {Grid} from "@mui/material";
import AutocompleteField from "app/common/form/fields/AutocompleteField";
import getValueTranslation from "app/common/form/translations/getValueTranslation";
import getEnumValues from "app/common/form/translations/getEnumValues";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {ScoutvilleKid} from "jmix/entities/ScoutvilleKid";
// @mui material components
// Material Dashboard 2 PRO React components
// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
import PropTypes from "prop-types";
import {Relation} from "../../../../jmix/enums/enums";
import {ScoutvilleKidParentRelation} from "../../../../jmix/entities/ScoutvilleKidParentRelation";

function KidGeneralInfo({formData, disableFields}) {
  const {formFields, form} = formData;
  const {values, errors, touched, handleChange} = form;
  const {firstName, secondName, lastName, birthDate, gender, canSwim, relation, shortSize} = formFields;
  const {
    firstName: firstNameV,
    secondName: secondNameV,
    lastName: lastNameV,
    birthDate: birthDateV,
    canSwim: canSwimV,
    relation: relationV,
    shortSize: shortSizeV,
    gender: genderV,
  } = values;

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">About kid</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory information
        </MDTypography>
      </MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <FormField
              disabled={disableFields}
              onChange={handleChange}
              type={lastName.type}
              label={lastName.label}
              name={lastName.name}
              value={lastNameV}
              placeholder={lastName.placeholder}
              error={errors.lastName && touched.lastName}
              success={lastNameV.length > 0 && !errors.lastName}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormField
              disabled={disableFields}
              onChange={handleChange}
              type={firstName.type}
              label={firstName.label}
              name={firstName.name}
              value={firstNameV}
              placeholder={firstName.placeholder}
              error={errors.firstName && touched.firstName}
              success={firstNameV.length > 0 && !errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormField
              disabled={disableFields}
              onChange={handleChange}
              type={secondName.type}
              label={secondName.label}
              name={secondName.name}
              value={secondNameV}
              placeholder={secondName.placeholder}
              error={errors.secondName && touched.secondName}
              success={secondName.length > 0 && !errors.secondName}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormField
              disabled={disableFields}
              onChange={handleChange}
              type={birthDate.type}
              label={birthDate.label}
              name={birthDate.name}
              value={birthDateV}
              InputLabelProps={{shrink: true}}
              error={errors.birthDay && touched.birthDay}
              success={birthDate.length > 0 && !errors.birthDay}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AutocompleteField
              disabled={disableFields}
              onChange={handleChange}
              type={gender.type}
              label={gender.label}
              name={gender.name}
              value={genderV}
              getOptionLabel={(v) => getValueTranslation(ScoutvilleKid.NAME, gender.name, v)}
              options={getEnumValues(ScoutvilleKid.NAME, gender.name).map((v) => v.name)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AutocompleteField
              disabled={disableFields}
              onChange={handleChange}
              type={relation.type}
              label={relation.label}
              name={relation.name}
              value={relationV}
              getOptionDisabled={(option) => {
                return option !== Relation.MOTHER && option !== Relation.FATHER && option !== Relation.GUARDIAN
              }}
              getOptionLabel={(v) =>
                getValueTranslation(ScoutvilleKidParentRelation.NAME, relation.name, v)
              }
              options={getEnumValues(ScoutvilleKidParentRelation.NAME, relation.name).map(
                (v) => v.name
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AutocompleteField
              disabled={disableFields}
              onChange={handleChange}
              type={canSwim.type}
              label={canSwim.label}
              name={canSwim.name}
              value={canSwimV}
              getOptionLabel={(v) => getValueTranslation(ScoutvilleKid.NAME, canSwim.name, v)}
              options={getEnumValues(ScoutvilleKid.NAME, canSwim.name).map((v) => v.name)}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AutocompleteField
              onChange={handleChange}
              type={shortSize.type}
              label={shortSize.label}
              name={shortSize.name}
              value={shortSizeV}
              getOptionLabel={(v) => getValueTranslation(ScoutvilleKid.NAME, shortSize.name, v)}
              options={getEnumValues(ScoutvilleKid.NAME, shortSize.name).map((v) => v.name)}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for UserInfo
KidGeneralInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default KidGeneralInfo;
