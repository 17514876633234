import { ScoutvilleOrder } from "./ScoutvilleOrder";
import { ScoutvilleKid } from "./ScoutvilleKid";
import { ScoutvilleTour } from "./ScoutvilleTour";
export class ScoutvilleOrderTour extends ScoutvilleOrder {
  static NAME = "ScoutvilleOrderTour";
  isBooking?: boolean | null;
  kid?: ScoutvilleKid | null;
  tour?: ScoutvilleTour | null;
  friends?: string | null;
  additionalInfo?: string | null;
  basePrice?: any | null;
}
export type ScoutvilleOrderTourViewName = "_base" | "_instance_name" | "_local";
export type ScoutvilleOrderTourView<
  V extends ScoutvilleOrderTourViewName
> = V extends "_base"
  ? Pick<
      ScoutvilleOrderTour,
      | "id"
      | "customer"
      | "isBooking"
      | "friends"
      | "additionalInfo"
      | "version"
      | "createdBy"
      | "createdDate"
      | "deletedBy"
      | "deletedDate"
      | "lastModifiedBy"
      | "lastModifiedDate"
      | "discountPrice"
      | "confirmed"
      | "repayWith"
    >
  : V extends "_instance_name"
  ? Pick<ScoutvilleOrderTour, "id" | "customer">
  : V extends "_local"
  ? Pick<
      ScoutvilleOrderTour,
      | "id"
      | "isBooking"
      | "friends"
      | "additionalInfo"
      | "version"
      | "createdBy"
      | "createdDate"
      | "deletedBy"
      | "deletedDate"
      | "lastModifiedBy"
      | "lastModifiedDate"
      | "discountPrice"
      | "confirmed"
      | "repayWith"
    >
  : never;
