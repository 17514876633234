export function isImageFile(fileName: string): boolean {
  return !!fileName.match(".*(jpg|jpeg|gif|png)$");
}

export function isPdfFile(fileName: string): boolean {
  return !!fileName.match(".*(pdf)$");
}




