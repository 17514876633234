import {ReactNode, useEffect, useState} from "react";
import MDSnackbar from "app/common/snackbar/MDSnackbar";
import {NotificationColor} from "../AppNotification";
import useTimeoutCallback from "../../utils/useTimeoutCallback";


type SnackbarProps = {
  title: string,
  message: string,
  dismissible: boolean | null,
  timeout: number | null,
  icon: ReactNode,
  anchorOrigin?: Object,
  color?: NotificationColor,
  onClose?: () => void

}


const SnackbarNotification = (props: SnackbarProps) => {
  const [isOpen, setIsOpen] = useState(true);

  const close = () => {
    props.dismissible && setIsOpen(false);
    props.dismissible && props.onClose && props.onClose()
  }

  const closeCallback = useTimeoutCallback(close)

  useEffect(() => {
    if (typeof props.dismissible == "number") {
      closeCallback(props.dismissible)
    }
  }, [props.dismissible]);


  return (
    <MDSnackbar
      anchorOrigin={props.anchorOrigin}
      color={props.color ? props.color : NotificationColor.info}
      icon={props.icon}
      title={props.title}
      dismissible={props.dismissible}
      timeout={props.timeout}
      content={props.message}
      open={isOpen}
      close={close}
      bgWhite
    />
  )

}

export default SnackbarNotification;