/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
// @mui material components
import Card from "@mui/material/Card";
import MDAvatar from "components/MDAvatar";
// Material Kit 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

const DefaultProgramCard = ({ image, description, icon, name, raised }) => {
  const imageTemplate = (
    <>
      <MDBox
        component="img"
        src={image}
        alt={name}
        borderRadius="lg"
        shadow={raised ? "md" : "none"}
        width="100%"
        position="relative"
        zIndex={1}
      />
      <MDBox
        borderRadius="lg"
        shadow={raised ? "md" : "none"}
        width="100%"
        height="100%"
        position="absolute"
        left={0}
        top={0}
        sx={
          raised
            ? {
                backgroundImage: `url(${image})`,
                transform: "scale(0.94)",
                filter: "blur(12px)",
                backgroundSize: "cover",
              }
            : {}
        }
      />
    </>
  );

  return (
    <Card className="h-full">
      <MDBox position="relative" borderRadius="lg" mx={2} mt={raised ? -3 : 2}>
        {imageTemplate}
      </MDBox>
      <MDBox p={2}>
        <MDBox display="flex" alignItems="center" mt={3}>
          {icon && (
            <MDAvatar
              src={icon}
              alt={name ? name : ""}
              shadow="md"
              variant={raised ? "circular" : "rounded"}
            />
          )}
          {name ? (
            <MDBox pl={2} lineHeight={0}>
              <MDTypography component="h6" variant="button" fontWeight="medium" gutterBottom>
                {name}
              </MDTypography>
            </MDBox>
          ) : null}
        </MDBox>
      </MDBox>
      <MDBox px={3}>
        <MDTypography
          paragraph
          sx={{
            fontSize: "10pt",
          }}
          component="p"
        >
          {description}
        </MDTypography>
      </MDBox>
    </Card>
  );
};

// Setting default props for the DefaultBlogCard
DefaultProgramCard.defaultProps = {
  category: false,
  author: false,
  raised: true,
};

// Typechecking props for the DefaultBlogCard
DefaultProgramCard.propTypes = {
  image: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  raised: PropTypes.bool,
};

export default DefaultProgramCard;
