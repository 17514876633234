/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Settings page components
import Header from "./components";
import BasicInfo from "./components/BasicInfo/BasicInfo";
import TrustedInfo from "./components/TrustedInfo/TrustedInfo";
import useUserData from "../../common/user/useUserData";
import Card from "@mui/material/Card";
import CenteredLoader from "../../common/loader/CenteredLoader";


function ProfileInfo() {
  let {userData, refreshData} = useUserData();


  return (
    <MDBox mt={4}>
      <Grid container spacing={3}>
        {/* <Grid item xs={12} lg={3}>
            <Sidenav />
          </Grid> */}
        <Grid item xs={12} lg={12}>
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {userData ? <Header userData={userData}/> : <Card><CenteredLoader/></Card>}
              </Grid>
              <Grid item xs={12}>
                {userData ? <BasicInfo userData={userData} refreshUserData={refreshData}/> : <Card><CenteredLoader/></Card>}
              </Grid>
              <Grid item xs={12}>
                <TrustedInfo/>
              </Grid>
              {/*<Grid item xs={12}>*/}
              {/*  <ChangePassword/>*/}
              {/*</Grid>*/}
              {/*<Grid item xs={12}>*/}
              {/*  <Authentication />*/}
              {/*</Grid>*/}
              {/*<Grid item xs={12}>*/}
              {/*  <Accounts />*/}
              {/*</Grid>*/}
              {/*<Grid item xs={12}>*/}
              {/*  <Notifications />*/}
              {/*</Grid>*/}
              {/*<Grid item xs={12}>*/}
              {/*  <Sessions />*/}
              {/*</Grid>*/}
              {/*<Grid item xs={12}>*/}
              {/*  <DeleteAccount />*/}
              {/*</Grid>*/}
            </Grid>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default ProfileInfo;
