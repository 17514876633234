import ImageUpload from "app/common/files/ImageUpload";
import PictureUpload from "app/common/files/PictureUpload";
import TourCardGrid from "app/components/tour/TourCardGrid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

const TourPage = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <TourCardGrid />
    </DashboardLayout>
  );
};

export default TourPage;
