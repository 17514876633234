import SimpleTourCard from "app/common/cards/SimpleTourCard";
import useFileDownload from "app/common/files/useFileDownload";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDProgress from "components/MDProgress";
import MDTypography from "components/MDTypography";
import { observer } from "mobx-react";
import React, {useEffect, useState} from "react";

const ages = {
  opacity: 0.7,
  variant: "overline",
};

const TourCard = observer(({tour}) => {

  const [imageUrl , seImageUrl] = useState();
  const { isVisible, objectUrl } = useFileDownload(tour.image);


  useEffect(() => {
    if(objectUrl) {
      seImageUrl(objectUrl)
    }

  }, [objectUrl,  tour.image])



  return (
    <SimpleTourCard
      className="custom-class"
      image={isVisible && imageUrl ? imageUrl : null}
      title={tour.name}
      // description={tour.description ? tour.description : ""}
      other={
        <MDBox>
          <MDBox mb={2} mt={1}>
            <MDTypography opacity={0.7} variant="h5">
              {tour.price} ₴
            </MDTypography>
          </MDBox>
          <MDBox mt={1}>
            <MDTypography opacity={0.4} variant="h6">
              {tour.startDate} - {tour.endDate}
            </MDTypography>
          </MDBox>
          <MDTypography {...ages}>7-9 РОКІВ</MDTypography>
          <MDProgress value={tour.kidsTicketsSold} />
          <MDTypography {...ages}>10-12 РОКІВ </MDTypography>
          <MDProgress value={tour.juniorTicketsSold} />
          <MDTypography {...ages}>13-17 РОКІВ</MDTypography>
          <MDProgress value={tour.teensTicketsSold} />
        </MDBox>
      }
      badge={
        tour.badgeInfo ? (
          <MDBadge
            badgeContent={tour.badgeInfo}
            variant="contained"
            color="warning"
            container
            circular
          />
        ) : null
      }
      action={
        tour.active
          ? {
              type: "internal",
              route: "/checkout",
              color: "success",
              label: "Buy",
            }
          : null
      }
    />
  );
});

export default TourCard;
