import { Divider, Modal, Slide } from "@mui/material";
import MDAlertCloseIcon from "components/MDAlert/MDAlertCloseIcon";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useState } from "react";

// type SnackbarProps = {
//   title: string,
//   message: string,
//   icon: ReactNode,
//   anchorOrigin?: Object,
//   color?: NotificationColor,
//   onClose?: () => void,
// };

const ModalNotification = ({ title = "", message, icon, color, onClose }) => {
  const [isOpen, setIsOpen] = useState(true);

  const close = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  return (
    <Modal open={isOpen} onClose={close} sx={{ display: "grid", placeItems: "center" }}>
      <Slide direction="down" in={isOpen} timeout={500}>
        <MDBox
          position="relative"
          width="500px"
          display="flex"
          flexDirection="column"
          borderRadius="xl"
          bgColor="white"
          shadow="xl"
        >
          <MDBox display="flex" alginItems="center" justifyContent="space-between" p={2}>
            <MDTypography variant="h5">{title}</MDTypography>
            <MDAlertCloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={close} />
          </MDBox>
          <Divider sx={{ my: 0 }} />
          <MDBox p={2}>
            <MDTypography variant="body2" color="secondary" fontWeight="regular">
              {message}
            </MDTypography>
          </MDBox>
          <Divider sx={{ my: 0 }} />
          <MDBox display="flex" justifyContent="space-between" p={1.5}>
            <MDButton variant="gradient" color="dark" onClick={close}>
              close
            </MDButton>
          </MDBox>
        </MDBox>
      </Slide>
    </Modal>
  );
};

export default ModalNotification;
