/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDBox from "../../../../../../components/MDBox";
import {FaEye, MdPayment} from "react-icons/all";
import MDButton from "../../../../../../components/MDButton";
import {useNavigate} from "react-router-dom";

function ActionsCell({row}) {
  let navigate = useNavigate();
  const buildAction = (action, index) => {
    return (
      <span onClick={() => navigate(action.link)}   className={"hover-pointer"}>
        <MDBox mb={1} key={index} display="flex" alignItems="center">
          <MDBox mr={1}>
            <MDButton variant="outlined" color={action.color} size="small" iconOnly circular>
              {action.icon}
            </MDButton>
          </MDBox>
          <MDTypography variant="caption" fontWeight="medium" color="text" sx={{lineHeight: 0}}>
            {action.text}
          </MDTypography>
        </MDBox>
      </span>
    )
  }


  return (
    <MDBox display="flex" flexDirection="column">
      {!row.isPaid && buildAction({
        link: "/payment/" + row.id,
        text: "Оплатити",
        color: "success",
        icon: <MdPayment/>
      })}

      {buildAction({
        link: "/orders/detail/" + row.id,
        text: "Переглянути",
        color: "success",
        icon: <FaEye/>
      })}

      {/*{actions && actions.length > 0 && actions.map((action, index) => buildAction(action, index))}*/}
    </MDBox>
  );
}

//
// // Setting default values for the props of DefaultCell
// ActionsCell.defaultProps = {
//   suffix: "",
// };

// // Typechecking props for the DefaultCell
// ActionsCell.propTypes = {
//   actions: PropTypes.arrayOf(PropTypes.shape({
//     link: PropTypes.string,
//     text: PropTypes.string,
//     color: PropTypes.string,
//     icon: PropTypes.node,
//   }))
// };

export default ActionsCell;
