/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Images
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import useFileDownload from "app/common/files/useFileDownload";
import MDButton from "../../../../../../components/MDButton";
import {useIntl} from "react-intl";
import {Link} from "react-router-dom";
import {getOrderStatusName} from "../../../utils";

function OrderInfo({order}) {
  const image = useFileDownload(order?.tour?.image);
  const intl = useIntl();
  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={12} md={6}>
        <MDBox display="flex" alignItems="center">
          {(image && image?.objectUrl) && <MDBox mr={2}>
            <MDAvatar variant="rounded" size="xxl" src={image?.objectUrl}/>
          </MDBox>}
          <MDBox lineHeight={1}>
            <MDTypography variant="h6" fontWeight="medium">
              {order?.tour?.name}
            </MDTypography>
            <MDBox mb={2}>
              <MDTypography variant="button" color="text">
                {intl.formatDate(order?.tour?.startDate)} - {intl.formatDate(order?.tour?.endDate)}
              </MDTypography>
            </MDBox>
            {order?.currentStatus && <MDBadge
              variant="gradient"
              color={"info"}
              size="xs"
              badgeContent={getOrderStatusName(order.currentStatus)}
              container
            />}
          </MDBox>
        </MDBox>
      </Grid>
      <Grid item xs={12} md={6} sx={{textAlign: "right"}}>
        {!order.isPaid &&
          <Link to={"/payment/" + order.id}>
            <MDButton variant="gradient" color="success" size="small">
              Оплатити
            </MDButton>
          </Link>


        }
        <MDBox>
          <MDTypography variant="caption" color="text">
            {order.tour.description}
          </MDTypography>
        </MDBox>
      </Grid>
    </Grid>
  );
}

export default OrderInfo;
