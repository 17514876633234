import { FormLabel, Radio, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { ErrorMessage, Field } from "formik";

const RadioField = ({ label, values, name, ...rest }) => {
  // const [field, meta, helpers] = useField(name);
  return (
    <MDBox mb={1.5}>
      <FormLabel>
        <Typography variant="body2" color="textSecondary">
          {label}
        </Typography>
      </FormLabel>
      {values.map((value, index) => (
        <MDBox key={index}>
          <label style={{ display: "block" }} key={index}>
            <Field
              key={index}
              {...rest}
              type="radio"
              as={Radio}
              value={value}
              name={name}
              variant="standard"
              // fullWidth
            ></Field>
            {/* <FormControlLabel control={<Radio />} label={label} /> */}
            <MDTypography pl={1} display="inline" variant={"body2"}>
              {value}
            </MDTypography>
          </label>
        </MDBox>
      ))}

      <MDBox mt={0.75}>
        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
          <ErrorMessage name={name} />
        </MDTypography>
      </MDBox>
    </MDBox>

    // <Field
    //   as={FieldAutocompleate}
    //   // tShort
    //   form={form}
    //   type={canSwim.type}
    //   label={canSwim.label}
    //   name={canSwim.name}
    //   value={canSwimV}
    //   // error={errors.canSwim && touched.canSwim}
    //   // success={canSwim.length > 0 && !errors.canSwim}
    //   options={useEnumValues("ScoutvilleKid", canSwim.name)}
    // ></Field>

    // <Field>
    //   {/* <Field {...rest} name={name} as={MDInput} variant="standard" label={label} fullWidth /> */}
    //   <Autocomplete
    //     // {...props}
    //     // {...field}
    //     name={name}
    //     options={options}
    //     onChange={(_, value) => setFieldValue(name, value)}
    //     // onBlur={() => setTouched({ [name]: true })}
    //     // getOptionSelected={(item, current) => item.value === current.value}
    //     // isOptionEqualToValue={(option, value) => option.id === value.id}
    //     renderInput={(props) => (
    //       <MDInput
    //         variant="standard"
    //         {...props}
    //         {...textFieldProps}
    //         // helperText={helperText}
    //         // error={error}
    //       />
    //     )}
    //   />
    // </Field>

    // <Autocomplete
    //   // {...props}
    //   // {...field}
    //   name={name}
    //   options={options}
    //   onChange={(_, value) => setFieldValue(name, value)}
    //   // onBlur={() => setTouched({ [name]: true })}
    //   // getOptionSelected={(item, current) => item.value === current.value}
    //   // isOptionEqualToValue={(option, value) => option.id === value.id}
    //   renderInput={(props) => (
    //     <MDInput
    //       variant="standard"
    //       {...props}
    //       {...textFieldProps}
    //       // helperText={helperText}
    //       // error={error}
    //     />
    //   )}
    // />
  );
};

export default RadioField;
