import {Grid} from "@mui/material";
import useErrorNotification from "app/common/error/useErrorNotification";
import CenteredLoader from "app/common/loader/CenteredLoader";
import useTimeoutCallback from "app/common/utils/useTimeoutCallback";
import React from "react";
import KidCard from "./KidCard";
import {useDeleteKidByIdMutation, useKidListQuery, useUpdateKidMutation} from "../../generated/generated-types";
import useUserData from "../../common/user/useUserData";

const KidCardList = ({onSelect}) => {
  const onError = useErrorNotification();
  let {refreshData} = useUserData();
  const {data, error, loading, refetch} = useKidListQuery({
    fetchPolicy: "network-only",
  })
  const [deleteKid, deleteResp] = useDeleteKidByIdMutation()
  const refetchFn = useTimeoutCallback(() =>{
    refetch()
    refreshData()
  });
  const handleSelect = (kid) => {
    onSelect(kid);
  };


  const handleDelete = (kid) => {
    deleteKid({
      variables: {
        id: kid.id,
      },
    }).then(() => {
      refetchFn(1000);

    });
  };

  if (error || deleteResp.error) {
    onError(error || deleteResp.error);
  }

  if (loading) {
    return <CenteredLoader/>;
  }

  console.log("Kids: " , data)
  return (<Grid container columnSpacing={3} rowSpacing={6}>
    {data?.ScoutvilleKidList?.map((kid) => (<Grid
      item
      xs={24}
      sm={10}
      md={6}
      lg={4}
      flex={"auto"}
      key={kid.id + "-grid"}
      className="mb-24 gutter-row"
    >
      <KidCard key={kid.id +  "-card"}  onSelect={handleSelect} onDelete={handleDelete} kid={kid}/>
    </Grid>))}
  </Grid>);
};

export default KidCardList;
