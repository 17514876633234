import {extractName, useGetFile, useMainStore} from "@haulmont/jmix-react-core";
import {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {Notification, NotificationType} from "../error/AppNotificationContext";
import useAppNotification from "../error/useAppNotification";
import {isImageFile, isPdfFile} from "../utils/files";
import useSleep from "app/common/utils/useSleep";


const useFileDownload = (fileRef) => {
    const [permissionLoaded, setPermissionsLoaded] = useState(false);
    const getFile = useGetFile()
    const intl = useIntl();
    const {addNotificationSnackbar} = useAppNotification();
    const mainStore = useMainStore();
    const sleep = useSleep();
    const [fileData, setPreviewState] = useState({
      isVisible: false,
      isLoading: false,
      fileName: "",
      objectUrl: "",
    });

    useEffect(() => {
        if (!mainStore?.initialized || !mainStore?.security.permissions) {
          const a = sleep(300)
        }

        if (!mainStore?.initialized) {
          return;
        }

        if (mainStore?.security.permissions) {
          setPermissionsLoaded(true)
        }

        if (!mainStore?.security.permissions) {
          mainStore.security.loadPermissions().then(() =>
            setPermissionsLoaded(true)
          )
        }

        if (!permissionLoaded) {
          return;
        }

        if (!mainStore?.security?.canDownloadFiles()) {
          var message = intl.formatMessage({id: "file.download.notAllowed"})
          console.log("Message 1: ", message)
          addNotificationSnackbar({type: NotificationType.Error, title: message} as Notification)
          return;
        }


        if (!fileRef) {

          return;
        }

        if (fileRef instanceof Blob) {

          return;
        }

        if (!(typeof fileRef === "string")) {
          return;
        }


        const fileName = extractName(fileRef)


        const fileUrl = encodeURIComponent(fileRef)

        const isImage = isImageFile(fileName);
        const isPdf = isPdfFile(fileName);

        console.log("Is image:  ", isImage, fileName)
        console.log("Is pdf:  ", isPdf, fileName)
        if (isImage || isPdf) {
          // Open image in ImagePreview component
          setPreviewState((prev) => ({
            ...prev,
            isVisible: true,
            isLoading: true,
            fileName: fileName,
          }));
          getFile(fileUrl)
            .then((blob: Blob) => {
              setPreviewState((prev) => ({
                ...prev,
                objectUrl: URL.createObjectURL(blob),
                fileName: fileName,
              }));
            })
            .catch(() => {
              var message = intl.formatMessage({id: "jmix.file.downloadFailed"})
              console.log("Message 2: ", message)
              addNotificationSnackbar({type: NotificationType.Error, message: message} as Notification)
              setPreviewState((prev) => ({
                ...prev,
                isVisible: false,
                fileName: fileName,
              }));
            })
            .finally(() => {
              setPreviewState((prev) => ({
                ...prev,
                isLoading: false,
                fileName: fileName
              }));
            });
        } else {
          // Download file with correct filename
          const hideDownloadMessage = () => {
            var message = intl.formatMessage({id: "jmix.file.downloadFailed"})
            console.log("Message 3: ", message)
            addNotificationSnackbar({type: NotificationType.Error, title: message} as Notification)
          }

          getFile(fileUrl)
            .then((blob: Blob) => {
              const objectUrl: string = URL.createObjectURL(blob);
              // saveFile(objectUrl, fileName);
              URL.revokeObjectURL(objectUrl);
            })
            .catch(() => {
              var message = intl.formatMessage({id: "jmix.file.downloadFailed"})
              console.log("Message 4: ", message)
              addNotificationSnackbar({type: NotificationType.Error, message: message} as Notification)
            })
            .finally(() => {
              hideDownloadMessage();
            });
        }
      },
      [fileRef, permissionLoaded]
    );


    return {...fileData};
  }
;


export default useFileDownload;