/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import {
  Autocomplete,
  Card,
  Chip,
  Container,
  Divider,
  InputAdornment,
  List,
  ListItemText,
  Stack,
  Switch
} from "@mui/material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CenteredLoader from "app/common/loader/CenteredLoader";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import memoize from "fast-memoize";
import {Gender} from "jmix/enums/enums";
import {useCallback, useState} from "react";
import Payment from "../payment/Payment";
import {MdAddCircleOutline} from "react-icons/all";
import {useKidHasOrdersLazyQuery, useKidListQuery, useTourListQuery} from "../../generated/generated-types";
import {GenderBlock} from "../../../jmix/enums/enums";
import MDSnackbar from "../../common/snackbar/MDSnackbar";
import {MdOutlineWarning} from "react-icons/md";
import useUserData from "../../common/user/useUserData";

function Checkout() {
  const {userProfileCompleted, trustedCompleted} = useUserData();
  const tourData = useTourListQuery()
  const kidData = useKidListQuery()
  const [checkedAggr, setCheckedAggr] = useState(false);
  const [booking, setBooking] = useState(false);
  const [friends, setFriends] = useState([]);
  const [friendInput, setFriendInput] = useState("");
  const [kid, setKid] = useState();
  const [tour, setTour] = useState();
  const [paymentOpen, setPaymentOpen] = useState(false);


  const openPayment = () => {
    console.log("Open payment.. ");
    setPaymentOpen(true);
  };

  const onPaymentSuccess = (status) => {
    console.log("onPaymentSuccess.. :", status);
    // setPaymentOpen(false);
  };

  const onPaymentFailure = (status) => {
    console.log("onPaymentFailure.. :", status);
    // setPaymentOpen(false);
  };

  const changeTour = (tour) => {
    setTour(tour);
    setBooking(false);
  };

  const changeKid = (kid) => {
    setKid(kid);
  };

  const handleCheckedAggr = () => setCheckedAggr(!checkedAggr);
  const handleCheckedBooking = () => setBooking(!booking);
  const handleRemoveFriend = (idx) => {
    var fr = [...friends];
    if (fr && fr.length > 0) {
      fr.splice(idx, 1);
      setFriends(fr);
    }
  };
  const handleAddFriend = () => {
    if (friendInput && friendInput.length > 0) {
      setFriends((friendsArr) => {
        friendsArr.push(friendInput);
        return friendsArr;
      });
    }
    setFriendInput(() => "");
  };
  const changeFriend = useCallback((e) => setFriendInput(e.target.value), [setFriendInput]);

  const isValid = memoize((kid, tour) => {
    var rez = true;
    if (kid && tour && tour) {
      const bd = new Date(kid.birthDate);
      const minBd = new Date(tour.minDateOfBirth);
      const maxBd = new Date(tour.maxDateOfBirth);
      if (bd >= minBd && bd <= maxBd) {
        if (tour.blocks && tour.blocks.length > 0) {
          tour.blocks.some((block) => {
            if (block.blockedFor === GenderBlock.ALL) {
              if (block.blockedYear) {
                return bd.getFullYear() === block.blockedYear
              } else return true;
            } else if (block.blockedFor === GenderBlock.MALE) {
              if (block.blockedYear) {
                return bd.getFullYear() === block.blockedYear && kid.gender === Gender.MALE
              } else return kid.gender === Gender.MALE
            } else if (block.blockedFor === GenderBlock.FEMALE) {
              if (block.blockedYear) {
                return bd.getFullYear() === block.blockedYear && kid.gender === Gender.FEMALE
              } else return kid.gender === Gender.FEMALE
            }
            return false;
          });
        }
      } else {
        rez = false;
      }
    }
    return rez;
  });

  if (tourData.error || kidData.error) {
    var error = tourData.error ? tourData.error : kidData.error;
    return (
      <MDAlert dismissible={true} color="error">
        {error.message}
      </MDAlert>
    );
  }

  if (tourData.loading || kidData.loading) {
    return <CenteredLoader/>;
  }

  if (paymentOpen) {
    return (
      <Payment
        kidId={kid.id}
        tourId={tour.id}
        friends={friends}
        isBooking={booking}
        onSuccess={onPaymentSuccess}
        onFailure={onPaymentFailure}
      />
    );
  }



  return (
    <DashboardLayout>
      <DashboardNavbar/>
      <Card>
        <MDBox component="section" py={12}>
          <Container>
            <Grid
              container
              item
              justifyContent="center"
              xs={10}
              lg={7}
              mx="auto"
              textAlign="center"
            >
              <MDTypography variant="h3" mb={1}>
                Buy a tour
              </MDTypography>
            </Grid>
            <Grid container item xs={12} lg={7} sx={{mx: "auto"}}>
              <MDBox width="100%" component="form" method="post" autocomplete="off">
                <MDBox p={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        disabled={!userProfileCompleted || !trustedCompleted}
                        options={tourData?.data?.ScoutvilleTourList ? tourData?.data?.ScoutvilleTourList : []}
                        onChange={(ev, tour) => changeTour(tour)}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionDisabled={(option) => {
                          var tourVal = isValid(kid, option);
                          return !tourVal;
                        }}
                        getOptionLabel={(t) => t.name}
                        renderInput={(params) => (
                          <MDInput
                            variant="standard"
                            label="Tour"
                            fullWidth
                            InputLabelProps={{shrink: true}}
                            {...params}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        onChange={(ev, kid) => changeKid(kid)}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionDisabled={(option) => {
                          var kidVal = isValid(option, tour);
                          return !kidVal;
                        }}
                        getOptionLabel={(t) => `${t.firstName} ${t.secondName} ${t.lastName}`}
                        options={kidData?.data?.ScoutvilleKidList ? kidData?.data?.ScoutvilleKidList : []}
                        renderInput={(params) => (
                          <MDInput
                            variant="standard"
                            label="Kid"
                            fullWidth
                            InputLabelProps={{shrink: true}}
                            {...params}
                          />
                        )}
                      />
                    </Grid>
                    {tour && tour.availableForBooking ? (
                      <Grid item xs={12}>
                        <MDBox display="flex" alignItems="center">
                          <Switch checked={booking} onChange={handleCheckedBooking}/>
                          <MDBox ml={2} lineHeight={0.5}>
                            <MDTypography
                              onClick={handleCheckedBooking}
                              display="block"
                              variant="button"
                              fontWeight="bold"
                            >
                              Is a booking
                            </MDTypography>
                            <MDTypography variant="caption" color="text" fontWeight="regular">
                              To book a tour without paying all amount.
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </Grid>
                    ) : null}
                    <Grid item xs={11} md={6}>
                      <MDInput
                        value={friendInput}
                        onChange={changeFriend}
                        variant="standard"
                        label="Friends"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                color="default"
                                size="small"
                                onClick={() => handleAddFriend()}>
                                <MdAddCircleOutline/>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {friends && friends.length > 0 ? (
                        <MDBox pt={2}>
                          <Grid item xs={12} md={12}>
                            <Stack direction="row" spacing={1}>
                              {friends.map((fr, idx) => (
                                <Chip
                                  id={fr}
                                  onDelete={() => handleRemoveFriend(idx)}
                                  key={fr + idx}
                                  label={fr}
                                  variant="outlined"/>
                              ))}
                            </Stack>
                          </Grid>
                        </MDBox>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} md={12} alignItems="center" ml={-1}>
                      <Switch checked={checkedAggr} onChange={handleCheckedAggr}/>
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        ml={-1}
                        sx={{cursor: "pointer", userSelect: "none"}}
                        onClick={handleCheckedAggr}>
                        &nbsp;&nbsp;I agree the&nbsp;
                      </MDTypography>
                      <MDTypography
                        component="a"
                        href="#"
                        variant="button"
                        fontWeight="regular"
                        color="dark"
                      >
                        Terms and Conditions
                      </MDTypography>
                    </Grid>
                  </Grid>
                  <Grid item justifyContent="center" xs={12} my={2}>
                    <MDBox pt={3}>
                      <MDButton
                        disabled={!checkedAggr || !kid || !tour || !userProfileCompleted || !trustedCompleted}
                        onClick={() => openPayment()}
                        variant="gradient"
                        color="dark"
                        fullWidth>
                        Checkout
                      </MDButton>
                    </MDBox>
                  </Grid>
                </MDBox>
              </MDBox>
            </Grid>
          </Container>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default Checkout;
