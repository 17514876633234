/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMainStore } from "@haulmont/jmix-react-core";
import { loginMapJmixRestErrorToIntlId } from "@haulmont/jmix-react-web";
// @mui material components
import Card from "@mui/material/Card";
import CenteredLoader from "app/common/loader/CenteredLoader";
// Images
import bgImage from "assets/app/images/panoramic/photo1.jpg";
import MDAlert from "components/MDAlert";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
// react-router-dom components
import { Link, useNavigate } from "react-router-dom";
import useAuthenticated from "../useAuthenticated";

const SignIn = observer(() => {
  const intl = useIntl();
  const mainStore = useMainStore();
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState("");
  // const [loginCount, setLoginCount] = useState(0);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const isAuthenticated = useAuthenticated();
  const [performingLoginRequest, setPerformingLoginRequest] = useState(false);

  const changeLogin = useCallback((e) => setLogin(e.target.value), [setLogin]);
  const changePassword = useCallback((e) => setPassword(e.target.value), [setPassword]);
  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/dashboard", { replace: true });
    }
  });

  const doLogin = useCallback(() => {
    setPerformingLoginRequest(true);
    mainStore
      .login(login, password)
      .then(
        action(() => {
          setPerformingLoginRequest(false);
        })
      )
      .catch(
        action((error) => {
          const loginMessageErrorIntlId = loginMapJmixRestErrorToIntlId(error);
          setPerformingLoginRequest(false);
          setAlertMessage(intl.formatMessage({ id: loginMessageErrorIntlId }));
        })
      );
  }, [setPerformingLoginRequest, mainStore, intl, login, password]);

  if (performingLoginRequest) {
    return <CenteredLoader />;
  }
  return (
    <BasicLayout image={bgImage}>
      {alertMessage ? (
        <MDAlert on dismissible={true} color={"error"}>
          {alertMessage}
        </MDAlert>
      ) : null}
      <Card>
        <MDBox
          key={"login_box"}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography key={"t"} variant="h4" fontWeight="medium" color="white" mt={1}>
            Вхід
          </MDTypography>
          {/* <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid> */}
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput onChange={changeLogin} type="email" label="Email" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput onChange={changePassword} type="password" label="Password" fullWidth />
            </MDBox>

            <MDBox mt={4} mb={1}>
              <MDButton onClick={() => doLogin()} variant="gradient" color="info" fullWidth>
                Вхід
              </MDButton>
            </MDBox>
            <MDBox mt={2} textAlign="center">
              <MDTypography variant="button" color="text">
                Немає облікового запису?{" "}
                <MDTypography
                  component={Link}
                  to="/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >Реєстрація
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDBox mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Забули пароль?{" "}
                <MDTypography
                  component={Link}
                  to="/password-reset"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >Скидання пароля
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
});

export default SignIn;
