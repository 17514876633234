import { loginMapJmixRestErrorToIntlId } from '@haulmont/jmix-react-web';
import { useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import { AppNotificationContext, NotificationType } from './AppNotificationContext';


const useErrorNotification = () => {
  const { addNotificationAlert } = useContext(AppNotificationContext);
  const intl = useIntl();

  return useCallback((error) => new Promise(() => {
    const loginMessageErrorIntlId = loginMapJmixRestErrorToIntlId(error);
    const title = intl.formatMessage({ id: loginMessageErrorIntlId })
    addNotificationAlert({
      title: title,
      // message: message,
      type: NotificationType.Error,
    })
  }),[]);
};



export default useErrorNotification;