import {useContext} from 'react';
import {UserDataContext} from "./UserDataContext";

const useUserData = () => {
  const {
    userData,
    trustedCompleted,
    userProfileCompleted,
    kidsCount,
    loading,
    getUserData,
    refreshData
  } = useContext(UserDataContext);
  return {
    loading,
    userData,
    trustedCompleted,
    userProfileCompleted,
    kidsCount,
    getUserData,
    refreshData
  };
}

export default useUserData;