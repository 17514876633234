/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-router-dom components
// @mui material components
import {FormHelperText} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CenteredLoader from "app/common/loader/CenteredLoader";
// Images
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import {Form, FormikProvider, useFormik} from "formik";
// Authentication layout components
import {observer} from "mobx-react";
import {useState} from "react";
import {Link} from "react-router-dom";
import * as yup from "yup";


const SignUpForm = observer(({onFinish}) => {
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);



  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string("Enter your password")
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
    firstName: yup.string("Enter your first name").required("First name is required"),
    secondName: yup.string("Enter your second name").required("Second name is required"),
    lastName: yup.string("Enter your last name").required("Last name is required"),
    checkbox: yup.bool().oneOf([true], "Accept Terms & Conditions is required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      secondName: "",
      lastName: "",
      email: "",
      password: "",
      checkbox: false,
    },
    validationSchema: validationSchema,
    onSubmit: onFinish,
  });

  if (loading) {
    return <CenteredLoader />;
  }

  return (
      <>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <FormikProvider value={formik}>
            <Form>
              <MDBox>
                <MDBox mb={2}>
                  <MDInput
                    name="firstName"
                    id="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                    success={formik.touched.firstName && !Boolean(formik.errors.firstName)}
                    FormHelperTextProps={{ error: true }}
                    helperText={formik.touched.firstName && formik.errors.firstName}
                    type="text"
                    label="First name"
                    variant="standard"
                    fullWidth
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    name="secondName"
                    id="secondName"
                    value={formik.values.secondName}
                    onChange={formik.handleChange}
                    error={formik.touched.secondName && Boolean(formik.errors.secondName)}
                    success={formik.touched.secondName && !Boolean(formik.errors.secondName)}
                    FormHelperTextProps={{ error: true }}
                    helperText={formik.touched.secondName && formik.errors.secondName}
                    type="text"
                    label="Second name"
                    variant="standard"
                    fullWidth
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    name="lastName"
                    id="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                    success={formik.touched.lastName && !Boolean(formik.errors.lastName)}
                    FormHelperTextProps={{ error: true }}
                    helperText={formik.touched.lastName && formik.errors.lastName}
                    type="text"
                    label="Last name"
                    variant="standard"
                    fullWidth
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    name="email"
                    id="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    success={formik.touched.email && !Boolean(formik.errors.email)}
                    FormHelperTextProps={{ error: true }}
                    helperText={formik.touched.email && formik.errors.email}
                    type="email"
                    label="Email"
                    variant="standard"
                    fullWidth
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    name="password"
                    id="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    success={formik.touched.password && !Boolean(formik.errors.password)}
                    FormHelperTextProps={{
                      error: true,
                    }}
                    helperText={formik.touched.password && formik.errors.password}
                    type="password"
                    label="Password"
                    variant="standard"
                    fullWidth
                  />
                </MDBox>
                <MDBox display="flex" alignItems="center" ml={-1}>
                  <Checkbox
                    id="checkbox"
                    name="checkbox"
                    value={formik.values.checkbox}
                    onChange={formik.handleChange}
                  />
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    &nbsp;&nbsp;I agree the&nbsp;
                  </MDTypography>
                  <MDTypography
                    component="a"
                    href="#"
                    variant="button"
                    fontWeight="bold"
                    color="info"
                    textGradient
                  >
                    Terms and Conditions
                  </MDTypography>
                </MDBox>
                {formik.touched.checkbox && Boolean(formik.errors.checkbox) ? (
                  <MDBox xs={11} sm={11} md={11} lg={11} xl={11}>
                    <FormHelperText error>{formik.errors.checkbox}</FormHelperText>
                  </MDBox>
                ) : null}
                <MDBox mt={4} mb={1}>
                  <MDButton
                    onClick={formik.onSubmitForm}
                    type="submit"
                    value="submit"
                    variant="gradient"
                    color="info"
                    fullWidth
                  >
                    Вхід
                  </MDButton>
                </MDBox>
                <MDBox mt={3} mb={1} textAlign="center">
                  <MDTypography variant="button" color="text">
                    Вже є аккаунт?{" "}
                    <MDTypography
                      component={Link}
                      to="/sign-in"
                      variant="button"
                      color="info"
                      fontWeight="medium"
                      textGradient
                    >
                      Вхід
                    </MDTypography>
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Form>
          </FormikProvider>
        </MDBox>
      </>
  );
});

export default SignUpForm;
