import getValueTranslation from "../../common/form/translations/getValueTranslation";
import {ScoutvilleOrderStatus} from "../../../jmix/entities/ScoutvilleOrderStatus";
import {OrderStatusType} from "../../../jmix/enums/enums";
import {FaRegCheckCircle, FaRegTimesCircle, FaShoppingCart, MdUpdate} from "react-icons/all";
import {ScoutvilleOrder} from "../../../jmix/entities/ScoutvilleOrder";
import {ScoutvilleOrderTour} from "../../../jmix/entities/ScoutvilleOrderTour";


export const getOrderStatusName = (v) => getValueTranslation(ScoutvilleOrderStatus.NAME, "statusType", v)
export const orderStatusNameToIcon = (v) => {

  switch (v) {
    case OrderStatusType.NEW_ORDER:
      return <FaShoppingCart/>
    case OrderStatusType.PROCESSING:
      return <MdUpdate/>
    case OrderStatusType.COMPLETE:
      return <FaRegCheckCircle/>
    case OrderStatusType.REFUNDED:
      return <FaRegTimesCircle/>

  }
}