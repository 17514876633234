import { Container, Grid } from "@mui/material";
import DefaultProgramCard from "app/common/cards/DefaultProgrammCard";
import bowIcon from "assets/app/icons/custom/bow-and-arrow.svg";
import eagleIcon from "assets/app/icons/custom/eagle.svg";
import challengesImg from "assets/app/images/program/challenges.png";
import romanticImg from "assets/app/images/program/romantic.png";
import competitionImg from "assets/app/images/program/competition.png";
import creativityImg from "assets/app/images/program/creativity.png";
import doingImg from "assets/app/images/program/learn_by_doing.png";
import selfImg from "assets/app/images/program/self.png";
import walkImg from "assets/app/images/program/walk.png";
import waterExtImg from "assets/app/images/program/water_ext.png";
import fireIcon from "assets/app/icons/custom/fire.svg";
import handAndStickIcon from "assets/app/icons/custom/hand-and-stick.svg";
import kayakIcon from "assets/app/icons/custom/kayak.svg";
import mapIcon from "assets/app/icons/custom/map.svg";
import paintingIcon from "assets/app/icons/custom/painting.svg";
import paperPlainIcon from "assets/app/icons/custom/paper-plain.svg";
import background from "assets/app/images/backgrounds/bg1.jpg";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { FaAngleRight } from "react-icons/fa";

const programs = [
  {
    image: doingImg,
    icon: handAndStickIcon,
    title: "Навчання",
    name: "НАВЧАННЯ ЧЕРЕЗ СПРАВУ",
    description:
      "Людина запам’ятовує 10% з того, що прочитала, 20% - з того, що почула, 30% - з того, що побачила, і 90% - з того, що сама зробила. Саме тому ми надаємо кожній дитині можливість спробувати зробити все, що заманеться: поставити намет, розпалити багаття, приготувати борщ, змайструвати шалаш, стати кращим.",
  },

  {
    image: challengesImg,
    icon: eagleIcon,
    title: "ВИПРОБУВАННЯ",
    name: "ВИПРОБУВАННЯ",
    description:
      "Хто сказав, що діти з міста не вміють ховатися в хащах, як лисиці, ночувати на деревах, як пантери, долати довгі маршрути, як вовки? Ще вони можуть цілодобово мовчати або зробити щось таке, що їм раніше навіть наснитися не могло. В Скаутвіллі вони вміють все!",
  },
  {
    image: creativityImg,
    icon: paintingIcon,
    title: "МАЙСТЕРНІ",
    name: "ТВОРЧІ МАЙСТЕРНІ",
    description:
      "Всі вміють співати, танцювати, малювати, ліпити, плести, фотографувати, виступати на сцені. Просто комусь раніше не дозволяли це робити. Може навіть він сам собі не дозволяв. Але в Скаутвіллі можна спробувати все, і наші майстри в цьому залюбки допоможуть.",
  },
  {
    image: romanticImg,
    icon: fireIcon,
    title: "РОМАНТИКА",
    name: "ЛІСОВА РОМАНТИКА",
    description:
      "Уяви собі: вечір, останні промінчики сонця ховаються за обрієм,сосни легенько хитаються над головою, дрова тріщать у вогнищі, гітара, і сотні дитячих голосів тихенько співають пісень. Хіба не романтика?",
  },

  {
    image: selfImg,
    icon: paperPlainIcon,
    title: "САМОСТІЙНІСТЬ",
    name: "САМОСТІЙНІСТЬ",
    description:
      "Вдома є мама, тато, бабуся, пральна машинка, посудомийка та інші гаджети, які роблять життя простішим. В Скаутвіллі ти сам маєш доглядати за речами, дбати про гігієну, відповідати за свої вчинки. Тут реальне життя і тільки від твого бажання та готовності діяти, залежить історія твого успіху.",
  },
  {
    image: competitionImg,
    icon: bowIcon,
    title: "ЗМАГАННЯ",
    name: "ЗМАГАННЯ",
    description:
      "Колись давно, люди перетворювали реальні ігри на комп’ютерні. У Скаутвіллі ми робимо навпаки. Граємо в хрестики-нолики футболками, підвищуємо рівень сили та хоробрості у мотузковому містечку та на скеледромі, збираємо магічні артефакти у квестах, і щодня здобуваємо перемогу над собою.",
  },
  {
    image: walkImg,
    icon: mapIcon,
    title: "ПОХОДИ",
    name: "ПОХОДИ",
    description:
      "Скаутвілльці відважно ходять у піші та водні походи. Діти разом з інструкторами розбивають табір, готують їжу, грають, вчаться працювати командою. Маршрути враховують вікові особливості та фізичну підготовку дітей, а також рекомендації наших медиків.",
  },
  {
    image: waterExtImg,
    icon: kayakIcon,
    title: "ЕКСТРИМ",
    name: "ВОДНИЙ ЕКСТРИМ",
    description:
      "Скаутвілль розташований на березі Канівського моря. Біля нашого берега море мілке, чисте й тепле. Завдяки цьому тут комфортно себе почувають наймолодші мешканці Скаутвіллю. Та мілина не заважає нам влаштовувати перегони на байдарках, керувати каное, опанувати катамаран.",
  },
];

const ProgramSection = ({ mt }) => {
  return (
    <MDBox
      style={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center; center",
      }}
      py={mt}
    >
      <MDBox mx={"5%"}>
        <span>
          <FaAngleRight
            size={"25pt"}
            sx={{
              marginRight: "5pt",
            }}
          />
          <MDTypography
            fontWeight="bold"
            component="span"
            sx={{
              fontSize: "35pt",
            }}
            style={{
              letterSpacing: "2px",
              fontFamily: "Intro Black",
              color: "#ff9800",
            }}
          >
            Програма
          </MDTypography>
        </span>
        <MDTypography
          ml={"3%"}
          paragraph
          variant="body2"
          style={{
            color: "#656464",
          }}
        >
          Програма Скаутвіллю є унікальною та неповторною. Кожна зміна має свою тематику та історію,
          легенду, в яку занурюються всі мешканці Скаутвіллю, незалежно від віку та статусів. Наша
          програма максимально використовує всі можливості, надані нам природою, сучасні технології
          ігромайстерності, анімації та режисури. Вона на хвилі вподобань дітей та молоді та успішно
          враховує вік кожного мешканця нашого наметового містечка.
        </MDTypography>
      </MDBox>
      <MDBox component="section" py={7}>
        <Container>
          <Grid container spacing={3} rowSpacing={5}>
            {programs.map((program, index) => (
              <Grid key={index} item xs={12} lg={3} md={6} mb={{ xs: 3, lg: 0 }}>
                <DefaultProgramCard
                  image={program.image}
                  category={{ color: "warning", label: program.title }}
                  description={program.description}
                  icon={program.icon}
                  name={program.name}

                />
              </Grid>
            ))}
          </Grid>
        </Container>
      </MDBox>
    </MDBox>
  );
};

export default ProgramSection;
