import React from 'react'


import {FlagIcon} from 'react-flag-kit'
import MenuItem from "@mui/material/MenuItem";

const styles = {
  flag: {
    height: 15,
    marginRight: 10,
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: '0.5rem',
  },
}

const createLanguageOption = (code, label = code) => {
  const codeUppercase = code && code.toUpperCase && code.toUpperCase()

  const substitute = (lan) => {
    switch (lan) {
      case 'EN':
        return 'GB'
      case 'UK':
        return "UA"
      default:
        return lan

    }
  }

  return (
    <MenuItem
      style={styles.item}
      value={code}
      key={code}
    >
      <div style={styles.item}>
        <FlagIcon
          style={styles.flag}
          code={substitute(codeUppercase)}
        />
        {label || code}
      </div>
    </MenuItem>
  )
}


export default createLanguageOption