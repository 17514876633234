import {Card, Grid} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import backgroundImage from "assets/app/images/panoramic/photo12.jpg";

const EditHeader = ({name , age}) => {
  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          // backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
          //   `${linearGradient(
          //     rgba(gradients.info.main, 0.6),
          //     rgba(gradients.info.state, 0.6)
          //   )}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {name}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {age} {" "} years
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}></Grid>
        </Grid>
      </Card>
    </MDBox>
  );
};

export default EditHeader;
