export class VersionedEntity {
  static NAME = "VersionedEntity";
  version?: number | null;
}
export type VersionedEntityViewName = "_base" | "_instance_name" | "_local";
export type VersionedEntityView<
  V extends VersionedEntityViewName
> = V extends "_base"
  ? Pick<VersionedEntity, "version">
  : V extends "_local"
  ? Pick<VersionedEntity, "version">
  : never;
