/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useMainStore} from "@haulmont/jmix-react-core";
// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 PRO React context
import {
  setMiniSidenav,
  setOpenConfigurator, setTransparentNavbar, useMaterialUIController
} from "context";
// Material Dashboard 2 PRO React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";
// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer, navbarDesktopMenu, navbarIconButton, navbarMobileMenu, navbarRow
} from "examples/Navbars/DashboardNavbar/styles";
import {observer} from "mobx-react";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {
  MdAccountCircle,
  MdHome,
  MdMenu,
  MdMenuOpen,
  MdNoAccounts,
  MdOutlineExitToApp,
  MdOutlineLogout
} from "react-icons/md";
// react-router components
import {useLocation, useNavigate} from "react-router-dom";
import AppNotification from "../../../app/common/error/AppNotification";
import LanguageSwitcher from "../../../app/common/i18n/switcher/LanguageSwitcher";
import {localesStore} from "@haulmont/jmix-react-web";
import {FormattedMessage} from "react-intl";
import {action} from "mobx";
import {Divider, List, ListItemText} from "@mui/material";
import MDAlert from "../../../components/MDAlert";
import ProfileAlert from "../../../app/components/profile/alert/ProfileAlert";


const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

const DashboardNavbar = observer(({absolute = false, light = false, isMini = false}) => {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const {miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode} = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate()
  const route = useLocation().pathname.split("/").slice(1)
    .filter(r => !r.includes(':'))
    .filter(r => !Number(r))
    .filter(r => !regexExp.test(r));
  const mainsStore = useMainStore()
  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
     */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleLogout = () => {
    mainsStore.logout()
      .finally(() => {
          mainsStore.authToken = null
          navigate("/")
        }
      );
  }

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{mt: 2}}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages"/>
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions"/>
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed"/>
    </Menu>
  );


  // Styles for the navbar icons
  const iconsStyle = ({palette: {dark, white, text}, functions: {rgba}}) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });


  return (
    <>
      <AppBar
        position={absolute ? "absolute" : navbarType}
        color="inherit"
        sx={(theme) => navbar(theme, {transparentNavbar, absolute, light, darkMode})}
      >
        <Toolbar sx={(theme) => navbarContainer(theme)}>
          <MDBox color="inherit" mb={{xs: 1, md: 0}} sx={(theme) => navbarRow(theme, {isMini})}>
            <Breadcrumbs icon={<MdHome/>} title={route[route.length - 1]} route={route} light={light}/>
            <IconButton sx={navbarDesktopMenu} onClick={handleMiniSidenav} size="small" disableRipple>
              <Icon fontSize="medium" sx={iconsStyle}>
                {miniSidenav ? <MdMenuOpen/> : <MdMenu/>}
              </Icon>
            </IconButton>
          </MDBox>
          {isMini ? null : (
            <MDBox sx={(theme) => navbarRow(theme, {isMini})}>
              {localesStore?.localesInfo?.length > 1 ? <LanguageSwitcher/> : null}
              <MDBox color={light ? "inherit" : "inherit"}>

                <IconButton onClick={handleLogout} sx={navbarIconButton} size="small" disableRipple>
                  <MdOutlineExitToApp size={'20'} style={{...iconsStyle}}/><MDTypography variant="h6"><FormattedMessage
                  id={"logout"}/></MDTypography>
                </IconButton>

                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarMobileMenu}
                  onClick={handleMiniSidenav}
                >
                  <Icon sx={iconsStyle} fontSize="medium">
                    {miniSidenav ? <MdMenuOpen/> : <MdMenu/>}
                  </Icon>
                </IconButton>

                {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton> */}
                {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <MDBadge badgeContent={9} color="error" size="xs" circular>
                  <Icon sx={iconsStyle}>notifications</Icon>
                </MDBadge>
              </IconButton> */}
                {renderMenu()}
              </MDBox>
            </MDBox>
          )}
        </Toolbar>
      </AppBar>
      <ProfileAlert/>
      <AppNotification/>

    </>
  );
});

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
