/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useMainStore} from "@haulmont/jmix-react-core";
import CssBaseline from "@mui/material/CssBaseline";
// @mui material components
import {ThemeProvider} from "@mui/material/styles";
import PasswordReset from "app/common/authentication/reset-password/PasswordResset";
import SetNewPassword from "app/common/authentication/set-new-password/SetNewPassword";
import SignIn from "app/common/authentication/sign-in/SignIn";
import SignUp from "app/common/authentication/sign-up/SignUp";
import useAuthenticated from "app/common/authentication/useAuthenticated";
import CenteredLoader from "app/common/loader/CenteredLoader";
import Dashboard from "app/pages/dashboard/Dashboard";
import HomePage from "app/pages/home/HomePage";
// Material Dashboard 2 PRO React contexts
import 'assets/app/css/styles.scss';
// Material Dashboard 2 PRO React themes
import theme from "assets/theme";
// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";
import {useMaterialUIController} from "context";
import {observer} from "mobx-react";
// react-router components
import {Navigate, Route, Routes} from "react-router-dom";
import UserDataProvider from "./app/common/user/UserDataContext";
import {mapMdLocales} from "i18n/i18nInit";
import AppNotification from "./app/common/error/AppNotification";
import ResultAction from "app/common/success/ResultAction";
import VerifyEmail from "app/common/authentication/sign-up/VerifyEmail";

const App = observer(() => {

  const mainStore = useMainStore();
  const {initialized, locale} = mainStore;
  const [controller] = useMaterialUIController();
  const {darkMode} = controller;
  const isAuthenticated = useAuthenticated();



  const AppRoutes = (
    <Routes>
      <Route path={"/"} element={<HomePage/>}/>
      <Route path={"/sign-in"} element={<SignIn/>}/>
      <Route path={"/sign-up"} element={<SignUp/>}/>
      <Route path={"/password-reset"} element={<PasswordReset/>}/>
      <Route path={"/verify/password/:verify_code"} element={<SetNewPassword/>}/>
      <Route path={"/verify/email/:verify_code"} element={<VerifyEmail/>}/>
      {isAuthenticated() && <Route path="*" element={
        <UserDataProvider>
          <Dashboard/>
        </UserDataProvider>
      }/>}
      <Route path="*" element={<Navigate to="/sign-in"/>}/>
    </Routes>
  )


  if (!initialized || !locale) {
    return <CenteredLoader/>;
  }

  return (
    <ThemeProvider theme={
      darkMode ?
        themeDark(mapMdLocales(mainStore.locale)) :
        theme(mapMdLocales(mainStore.locale))}>
      <CssBaseline/>
      <AppNotification/>
      {AppRoutes}
    </ThemeProvider>
  );
});

export default App;
