/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import {checkResponseStatus} from "@haulmont/jmix-react-core";
import Card from "@mui/material/Card";
// Images
import bgImage from "assets/app/images/panoramic/photo13.jpg";
// Material Dashboard 2 PRO React components
import {APP_REST_API_URL} from "config";
import {useFormik} from "formik";
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import * as yup from "yup";
import {MdOutlineDone} from "react-icons/md";
import {AiOutlineCloseCircle} from "react-icons/all";
import {CircularProgress} from "@mui/material";
import SetNewPasswordForm from "./components/SetNewPasswordForm";
import ResultAction from "../../success/ResultAction";

const validationSchema = yup.object({
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  password2: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .when("password", {
      is: (val) => (val && val.length > 0),
      then: yup.string().oneOf([yup.ref("password")], "Both password need to be the same"),
    })
    .required("Password is required"),
});

const SetNewPassword = () => {
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const {verify_code} = useParams();
  const navigate = useNavigate();

  function getDefaultSignUpFetchOptions(data) {
    return {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
  }

  const onFinish = (values) => {
    setLoading(true);
    fetch(
      `${APP_REST_API_URL}password/set?code=${verify_code}`,
      getDefaultSignUpFetchOptions(values)
    )
      .then(checkResponseStatus)
      .then((resp) => {
        setLoading(false);
        setDone(true);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
        setDone(true)
      });
  };

  const errorRez = (<ResultAction
    color={"error"}
    cancelAction={{
      onClick: () => {
        navigate("/sign-in")
      },
      btnName: "Закрити"
    }}
    icon={<AiOutlineCloseCircle size={"50px"} color="inherit"/>}
    title="Щось пішло не так!"
    message="За більш детальною інформацією зверніться до адміністратора."
  />)

  const loadingRez = (<ResultAction
    color={"info"}
    icon={<CircularProgress color={'inherit'} style={{fontSize: "50px"}}/>}
    title="Зачекайте будь ласка"
    message="Ми перевіряем реесткаційні данні."
  />)


  const successRez = (<ResultAction
    color={"success"}
    icon={<MdOutlineDone size={"50px"} color="inherit"/>}
    title="Ви успішно змінили пароль!"
    message="Перейдіть, будь ласка, на сторінку вхід для входу в обліковий запис"
    okAction={{
      onClick: () => {
        navigate("/sign-in")
      },
      btnName: "На стрінку входу"
    }}
  />)


  const formik = useFormik({
    initialValues: {
      password: "",
      password2: "",
    },
    validationSchema: validationSchema,
    onSubmit: onFinish,
  });


  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        {loading && loadingRez}
        {error && done && !loading && errorRez}
        {done && !loading && !error && successRez}
        {!done && !loading && !error && <SetNewPasswordForm onFinish={onFinish}/>}
      </Card>
    </CoverLayout>
  );
};

export default SetNewPassword;
