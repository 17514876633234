import { AuditableEntity } from "./AuditableEntity";
import { OrderStatusType } from "../enums/enums";
import { ScoutvilleOrder } from "./ScoutvilleOrder";
export class ScoutvilleOrderStatus extends AuditableEntity {
  static NAME = "ScoutvilleOrderStatus";
  id?: string;
  description?: string | null;
  isDone?: boolean | null;
  statusType?: OrderStatusType | null;
  link?: string | null;
  order?: ScoutvilleOrder | null;
}
export type ScoutvilleOrderStatusViewName =
  | "_base"
  | "_instance_name"
  | "_local";
export type ScoutvilleOrderStatusView<
  V extends ScoutvilleOrderStatusViewName
> = V extends "_base"
  ? Pick<
      ScoutvilleOrderStatus,
      | "id"
      | "statusType"
      | "description"
      | "isDone"
      | "link"
      | "version"
      | "createdBy"
      | "createdDate"
      | "deletedBy"
      | "deletedDate"
      | "lastModifiedBy"
      | "lastModifiedDate"
    >
  : V extends "_instance_name"
  ? Pick<ScoutvilleOrderStatus, "id" | "statusType">
  : V extends "_local"
  ? Pick<
      ScoutvilleOrderStatus,
      | "id"
      | "description"
      | "isDone"
      | "statusType"
      | "link"
      | "version"
      | "createdBy"
      | "createdDate"
      | "deletedBy"
      | "deletedDate"
      | "lastModifiedBy"
      | "lastModifiedDate"
    >
  : never;
