export enum Type {
  CREATE = "CREATE",
  MODIFY = "MODIFY",
  DELETE = "DELETE",
  RESTORE = "RESTORE"
}

export enum ShowMode {
  NON_REMOVED = "NON_REMOVED",
  REMOVED = "REMOVED",
  ALL = "ALL"
}

export enum SendingStatus {
  QUEUE = "QUEUE",
  SENDING = "SENDING",
  SENT = "SENT",
  NOT_SENT = "NOT_SENT"
}

export enum TemplateType {
  JSON = "JSON",
  REPORT = "REPORT"
}

export enum ChartType {
  PIE = "PIE",
  SERIAL = "SERIAL"
}

export enum SeriesType {
  LINE = "LINE",
  COLUMN = "COLUMN",
  STEP = "STEP",
  SMOOTHED_LINE = "SMOOTHED_LINE"
}

export enum StackType {
  NONE = "NONE",
  REGULAR = "REGULAR",
  HUNDRED_PERCENTS = "HUNDRED_PERCENTS",
  THREE_D = "THREE_D"
}

export enum CustomTemplateDefinedBy {
  CLASS = "CLASS",
  SCRIPT = "SCRIPT",
  URL = "URL"
}

export enum DataSetType {}

export enum JsonSourceType {
  GROOVY_SCRIPT = "GROOVY_SCRIPT",
  URL = "URL",
  PARAMETER = "PARAMETER"
}

export enum Orientation {}

export enum ParameterType {
  DATE = "DATE",
  TEXT = "TEXT",
  ENTITY = "ENTITY",
  BOOLEAN = "BOOLEAN",
  NUMERIC = "NUMERIC",
  ENTITY_LIST = "ENTITY_LIST",
  ENUMERATION = "ENUMERATION",
  DATETIME = "DATETIME",
  TIME = "TIME"
}

export enum AggregationMode {
  COUNT = "COUNT",
  COUNT_UNIQUE_VALUES = "COUNT_UNIQUE_VALUES",
  LIST_UNIQUE_VALUES = "LIST_UNIQUE_VALUES",
  SUM = "SUM",
  INTEGER_SUM = "INTEGER_SUM",
  AVERAGE = "AVERAGE",
  MINIMUM = "MINIMUM",
  MAXIMUM = "MAXIMUM",
  SUM_OVER_SUM = "SUM_OVER_SUM",
  UPPER_BOUND_80 = "UPPER_BOUND_80",
  LOWER_BOUND_80 = "LOWER_BOUND_80",
  SUM_AS_FRACTION_OF_TOTAL = "SUM_AS_FRACTION_OF_TOTAL",
  SUM_AS_FRACTION_OF_ROWS = "SUM_AS_FRACTION_OF_ROWS",
  SUM_AS_FRACTION_OF_COLUMNS = "SUM_AS_FRACTION_OF_COLUMNS",
  COUNT_AS_FRACTION_OF_TOTAL = "COUNT_AS_FRACTION_OF_TOTAL",
  COUNT_AS_FRACTION_OF_ROWS = "COUNT_AS_FRACTION_OF_ROWS",
  COUNT_AS_FRACTION_OF_COLUMNS = "COUNT_AS_FRACTION_OF_COLUMNS"
}

export enum PivotTablePropertyType {
  AGGREGATIONS = "AGGREGATIONS",
  DERIVED = "DERIVED",
  COLUMNS = "COLUMNS",
  ROWS = "ROWS"
}

export enum RendererType {
  TABLE = "TABLE",
  TABLE_BAR_CHART = "TABLE_BAR_CHART",
  HEATMAP = "HEATMAP",
  ROW_HEATMAP = "ROW_HEATMAP",
  COL_HEATMAP = "COL_HEATMAP",
  LINE_CHART = "LINE_CHART",
  BAR_CHART = "BAR_CHART",
  STACKED_BAR_CHART = "STACKED_BAR_CHART",
  AREA_CHART = "AREA_CHART",
  SCATTER_CHART = "SCATTER_CHART",
  TSV_EXPORT = "TSV_EXPORT"
}

export enum PredefinedTransformation {
  STARTS_WITH = "STARTS_WITH",
  CONTAINS = "CONTAINS",
  ENDS_WITH = "ENDS_WITH"
}

export enum ReportOutputType {}

export enum ReportType {
  SIMPLE = "SIMPLE",
  PRINT_FORM = "PRINT_FORM",
  LIST_PRINT_FORM = "LIST_PRINT_FORM"
}

export enum TemplateFileType {
  HTML = "HTML",
  DOCX = "DOCX",
  XLSX = "XLSX",
  CHART = "CHART",
  CSV = "CSV",
  TABLE = "TABLE"
}

export enum RestFilterOp {
  CONTAINS = "CONTAINS",
  EQUAL = "EQUAL",
  IN = "IN",
  NOT_IN = "NOT_IN",
  NOT_EQUAL = "NOT_EQUAL",
  GREATER = "GREATER",
  GREATER_OR_EQUAL = "GREATER_OR_EQUAL",
  LESSER = "LESSER",
  LESSER_OR_EQUAL = "LESSER_OR_EQUAL",
  DOES_NOT_CONTAIN = "DOES_NOT_CONTAIN",
  NOT_EMPTY = "NOT_EMPTY",
  STARTS_WITH = "STARTS_WITH",
  DATE_INTERVAL = "DATE_INTERVAL",
  ENDS_WITH = "ENDS_WITH",
  IS_NULL = "IS_NULL"
}

export enum RowLevelPolicyAction {
  CREATE = "CREATE",
  READ = "READ",
  UPDATE = "UPDATE",
  DELETE = "DELETE"
}

export enum RowLevelPolicyType {
  JPQL = "JPQL",
  PREDICATE = "PREDICATE"
}

export enum io_jmix_ui_component_LogicalFilterComponent_Operation {}

export enum io_jmix_ui_component_PropertyFilter_Operation {}

export enum CaptionPosition {}

export enum AcquiringProvider {
  FONDY = "FONDY",
  LIQPAY = "LIQPAY"
}

export enum CanSwim {
  YES = "YES",
  NO = "NO",
  STUDYING = "STUDYING"
}

export enum Currency {
  UAH = "UAH",
  USD = "USD"
}

export enum DiscountType {
  PERCENTAGE = "PERCENTAGE",
  SUBTRACTION = "SUBTRACTION"
}

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE"
}

export enum GenderBlock {
  MALE = "MALE",
  FEMALE = "FEMALE",
  ALL = "ALL"
}

export enum OrderStatusType {
  NEW_ORDER = "NEW_ORDER",
  PROCESSING = "PROCESSING",
  COMPLETE = "COMPLETE",
  REFUNDED = "REFUNDED"
}

export enum PaymentType {
  CREDIT_CARD = "CREDIT_CARD",
  MONEY_TRANSFER = "MONEY_TRANSFER",
  CACHE = "CACHE"
}

export enum Relation {
  FATHER = "FATHER",
  MOTHER = "MOTHER",
  GUARDIAN = "GUARDIAN",
  FRIEND = "FRIEND",
  RELATIVE = "RELATIVE"
}

export enum TShortSize {
  SIX_TO_SEVEN = "SIX_TO_SEVEN",
  EIGHT_TO_NINE = "EIGHT_TO_NINE",
  TEN_TO_ELEVEN = "TEN_TO_ELEVEN",
  TWELVE_TO_FOURTEEN = "TWELVE_TO_FOURTEEN",
  S = "S",
  M = "M",
  L = "L",
  XL = "XL"
}
