/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import MDButton from "../../../../../../components/MDButton";
import {FaEye} from "react-icons/all";


function TourViewActionCell({value}) {

  return (
    <MDBox display="flex" alignItems="center" justifyContent='space-between'>

        <MDBox mr={1}>
          <Link to={"/orders/detail/" + value.id}>
          <MDButton variant="outlined" color={'success'} size="small" iconOnly circular>
            <FaEye/>
          </MDButton>
          </Link>
        </MDBox>
      <Link to={"/orders/detail/" + value.id}>
        <MDTypography variant="caption" fontWeight="medium" color="text" sx={{ lineHeight: 0 }}>
         Переглянути
        </MDTypography>
      </Link>
      </MDBox>
  );
}

// Typechecking props for the StatusCell
TourViewActionCell.propTypes = {
  value: PropTypes.object.isRequired,
};

export default TourViewActionCell;
