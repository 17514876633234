import {useState} from "react";

// @mui material components
import Divider from "@mui/material/Divider";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import {MdClose} from "react-icons/md";
import MDButton from "../../../components/MDButton";
import PropTypes from "prop-types";

// @mui icons

// Material Kit 2 PRO React components


const  ResultAction = ({okAction, cancelAction, title, message, icon, color})  => {
  const [show, setShow] = useState(false);
  return (<>
    {/*<MDBox component="section" py={6}>*/}
    {/*   <Container>*/}
    {/*<Grid container item xs={12} lg={10} justifyContent="center" mx="auto">*/}
    {/*  <MDButton variant="gradient" color="success" onClick={toggleModal}>*/}
    {/*    Launch Demo Modal*/}
    {/*  </MDButton>*/}
    {/*</Grid>*/}
    {/*<Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>*/}
    {/*  <Slide direction="down" in={show} timeout={500}>*/}
    <MDBox
      position="relative"
      width="100%"
      display="flex"
      flexDirection="column"
      borderRadius="xl"
      variant="gradient"
      bgColor={color}
      shadow="sm"
    >
      <MDBox
        display="flex"
        alginItems="center"
        justifyContent="end"
        py={3}
        px={3}
      >
        {cancelAction && cancelAction.onClick && <MdClose
          color="white"
          fontSize="medium"
          sx={{cursor: "pointer"}}
          onClick={cancelAction.onClick}
        />}
      </MDBox>
      <Divider light sx={{my: 0}}/>
      <MDBox p={6} textAlign="center" color="white">

        {icon}
        <MDTypography variant="h4" color="white" mt={3} mb={1}>
          {title}
        </MDTypography>
        <MDTypography variant="body2" color="white" opacity={0.8} mb={2}>
          {message}
        </MDTypography>
      </MDBox>
      <Divider light sx={{my: 0}}/>
      <MDBox display="flex" justifyContent={cancelAction && okAction ? "space-between" : "center"} py={2} px={1.5}>
        {okAction ? <MDButton color="white" onClick={okAction.onClick}>{okAction.btnName}</MDButton> : null}
        {cancelAction ? <MDButton variant="text" color="white" onClick={cancelAction.onClick}>
          {cancelAction.btnName}
        </MDButton> : null}
      </MDBox>
    </MDBox>
    {/*  </Slide>*/}
    {/*</Modal>*/}
    {/*</Container>*/}
    {/*</MDBox>*/}
  </>);
}


// // Setting default values for the props of MDBox
// ResultAction.defaultProps = {
//   variant: "contained",
//   bgColor: "transparent",
//   color: "dark",
//   opacity: 1,
//   borderRadius: "none",
//   shadow: "none",
//   coloredShadow: "none",
// };

// Typechecking props for the MDBox
ResultAction.propTypes = {
  okAction: PropTypes.object,
  cancelAction: PropTypes.object,
  title: PropTypes.string,
  message: PropTypes.string,
  icon: PropTypes.node,
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "none",
  ]),
};

export default ResultAction;