import { CircularProgress } from "@mui/material";
import React from "react";
import Centered from "../centered/Centered";
import styles from "./CenteredLoader.module.css";

const CenteredLoader = () => (
  <Centered>
    <CircularProgress className={styles.icon}  />
  </Centered>
);

export default CenteredLoader;
