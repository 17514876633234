import React, {useCallback, useState} from 'react';
import {SnackbarOrigin} from "@mui/material/Snackbar/Snackbar";

export enum NotificationType {
  Error = "Error",
  Warning = "Warning",
  Info = "Info",
  Primary = "Primary",
  Success = "Success",
  Neutral = "Neutral",
}

export enum NotificationComponent {
  Snackbar,
  Modal,
  Alert
}

export type Dismissible = {
  timeout?: number
}

export type Notification = {
  anchorOrigin?: SnackbarOrigin
  component?: NotificationComponent,
  type: NotificationType,
  dismissible?: boolean | number | undefined,
  title: string
  message?: string
}


type AppNotificationContextProps = {
  notification: Notification | null
  addNotificationSnackbar: (notification: Notification) => void,
  addNotificationModal: (notification: Notification) => void,
  addNotificationAlert: (notification: Notification) => void,

  removeNotification: () => void
}


export const AppNotificationContext = React.createContext<AppNotificationContextProps>({
  notification: null,
  addNotificationSnackbar: (notification: Notification) => {
  },
  addNotificationModal: (notification: Notification) => {
  },
  addNotificationAlert: (notification: Notification) => {
  },
  removeNotification: () => {
  }
});


export default function AppNotificationProvider({children}) {


  const [notification, setNotification] = useState<Notification | null>(null);

  const removeNotification = () => setNotification(null);

  const addNotificationSnackbar = (notification: Notification) => {
    console.log("Notification Snackbar: ", notification)
    if(!notification.title){
      throw new Error("Title is required!")
    }
    setNotification({
      component: NotificationComponent.Snackbar,
      message: "",
      anchorOrigin: {
        vertical: "top", horizontal: "center"
      },
      ...notification,
    });
  }
  const addNotificationModal = (notification: Notification) => {
    console.log("Notification Modal: ", notification)
    if(!notification.title){
      throw new Error("Title is required!")
    }
    setNotification({
      message: "",
      component: NotificationComponent.Modal,
      ...notification
    })
  };
  const addNotificationAlert = (notification: Notification) => {
    console.log("Notification Alert: ", notification)
    if(!notification.title){
      throw new Error("Title is required!")
    }
    setNotification({
      message: "",
      component: NotificationComponent.Alert,
      ...notification,
    })
  };

  const contextValue = {
    notification,
    addNotificationSnackbar: useCallback((notification: Notification) => addNotificationSnackbar(notification), []),
    addNotificationModal: useCallback((notification: Notification) => addNotificationModal(notification), []),
    addNotificationAlert: useCallback((notification: Notification) => addNotificationAlert(notification), []),
    removeNotification: useCallback(() => removeNotification(), [])
  } as AppNotificationContextProps;

  return (
    <AppNotificationContext.Provider value={contextValue}>
      {children}
    </AppNotificationContext.Provider>
  );
}
