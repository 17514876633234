import {IntlShape} from "react-intl/src/types";
import * as yup from "yup";

/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

const formData = (intl: IntlShape) => {
  const formFields = {
    firstName: {
      name: "firstName",
      label: intl.formatMessage({id: "form.label.first.name"}),
      type: "text",
      errorMsg: intl.formatMessage({id: "form.error.first.name.empty"}),
    },
    secondName: {
      name: "secondName",
      label: intl.formatMessage({id: "form.label.second.name"}),
      type: "text",
      errorMsg: intl.formatMessage({id: "form.error.second.name.empty"}),
    },
    lastName: {
      name: "lastName",
      label: intl.formatMessage({id: "form.label.last.name"}),
      type: "text",
      errorMsg: intl.formatMessage({id: "form.error.last.name.empty"}),
    },
    phone: {
      name: "phone",
      label: intl.formatMessage({id: "form.label.phone"}),
      type: "text",
      errorMsg: intl.formatMessage({id: "form.error.phone.empty"}),
    },
    email: {
      name: "email",
      label: intl.formatMessage({id: "form.label.email"}),
      type: "email",
      errorMsg: intl.formatMessage({id: "form.error.email.empty"}),
    },
    taxSerial: {
      name: "taxSerial",
      label: intl.formatMessage({id: "form.label.taxSerial"}),
      type: "text",
      errorMsg: intl.formatMessage({id: "form.error.taxSerial.empty"}),
    },
    passportSerial: {
      name: "passportSerial",
      label: intl.formatMessage({id: "form.label.passportSerial"}),
      type: "text",
      errorMsg: intl.formatMessage({id: "form.error.passportSerial.empty"}),
    },
    documents: {
      name: "documents",
      label: intl.formatMessage({id: "form.label.documents"}),
      type: "text",
      errorMsg: intl.formatMessage({id: "form.error.documents.empty"}),
    }
  }


  const validations = yup.object({
    // [bdCertificate.name]: yup.string().required(bdCertificate.errorMsg),
    [formFields.firstName.name]: yup.string().required(formFields.firstName.errorMsg),
    [formFields.secondName.name]: yup.string().required(formFields.secondName.errorMsg),
    [formFields.lastName.name]: yup.string().required(formFields.lastName.errorMsg),
    [formFields.phone.name]: yup.string().required(formFields.phone.errorMsg),
    [formFields.email.name]: yup.string().required(formFields.email.errorMsg),
    [formFields.taxSerial.name]: yup.string().required(formFields.taxSerial.errorMsg),
    [formFields.passportSerial.name]: yup.string().required(formFields.passportSerial.errorMsg),
    [formFields.documents.name]: yup.array()
      .min(1, formFields.passportSerial.errorMsg)
      .max(5, "to many files")

    // [relation.name]: yup.string().required(relation.errorMsg),
    // [canSwim.name]: yup.string().required(canSwim.errorMsg),
  })

  return {
    formFields: formFields,
    validations: validations,
  }

};

export default formData