import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { RawIntlProvider } from 'react-intl';
import {getMainStore, MainStore} from '@haulmont/jmix-react-core';
import { localesStore } from "@haulmont/jmix-react-web";
import { getI18n } from "./i18n";

type I18nProviderProps = {
  children: React.ReactNode | React.ReactNode[] | null,
  rtlLayout?: boolean
}

export const I18nProvider = observer(({ children }: I18nProviderProps) => {
  const mainStore = getMainStore()

  if (!mainStore || !mainStore.locale) {
    return null;
  }

  const i18n = getI18n();
  return (
    <RawIntlProvider value={i18n}>
        {children}
    </RawIntlProvider>
  );
});