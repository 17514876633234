import { AuditableEntity } from "./AuditableEntity";
import { ScoutvilleUser } from "./ScoutvilleUser";
import { ScoutvilleProduct } from "./ScoutvilleProduct";
import { AcquiringProvider, OrderStatusType } from "../enums/enums";
import { ScoutvillePayment } from "./ScoutvillePayment";
import { ScoutvilleDiscount } from "./ScoutvilleDiscount";
import { ScoutvilleOrderStatus } from "./ScoutvilleOrderStatus";
export class ScoutvilleOrder extends AuditableEntity {
  static NAME = "ScoutvilleOrder";
  id?: string;
  customer?: ScoutvilleUser | null;
  discountPrice?: any | null;
  responsible?: ScoutvilleUser | null;
  confirmed?: boolean | null;
  products?: ScoutvilleProduct[] | null;
  repayWith?: AcquiringProvider | null;
  payments?: ScoutvillePayment[] | null;
  discount?: ScoutvilleDiscount | null;
  statuses?: ScoutvilleOrderStatus[] | null;
  basePrice?: any | null;
  isPaid?: boolean | null;
  leftToPay?: any | null;
  isPartialPaid?: boolean | null;
  finalPrice?: any | null;
  currentStatus?: OrderStatusType | null;
  paidAmount?: any | null;
}
export type ScoutvilleOrderViewName = "_base" | "_instance_name" | "_local";
export type ScoutvilleOrderView<
  V extends ScoutvilleOrderViewName
> = V extends "_base"
  ? Pick<
      ScoutvilleOrder,
      | "id"
      | "customer"
      | "discountPrice"
      | "confirmed"
      | "repayWith"
      | "version"
      | "createdBy"
      | "createdDate"
      | "deletedBy"
      | "deletedDate"
      | "lastModifiedBy"
      | "lastModifiedDate"
    >
  : V extends "_instance_name"
  ? Pick<ScoutvilleOrder, "id" | "customer">
  : V extends "_local"
  ? Pick<
      ScoutvilleOrder,
      | "id"
      | "discountPrice"
      | "confirmed"
      | "repayWith"
      | "version"
      | "createdBy"
      | "createdDate"
      | "deletedBy"
      | "deletedDate"
      | "lastModifiedBy"
      | "lastModifiedDate"
    >
  : never;
