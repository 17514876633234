/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "../../../../../layouts/pages/users/new-user/components/FormField";

// Data
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import FileInputField from "app/common/form/fields/FileInputField.jsx";
import {MdLayers} from "react-icons/all";
import AutocompleteField from "../../../../common/form/fields/AutocompleteField";
import MDButton from "../../../../../components/MDButton";
import {gql, useMutation, useQuery} from "@apollo/client";
import useAppNotificationCopy from "../../../../common/error/useAppNotification";
import {useIntl} from "react-intl";
import formData from "./data/data";
import useUserData from "../../../../common/user/useUserData";
import {Form, Formik} from "formik";
import {useTrustedUserQuery, useUpdateTrustedPersonMutation} from "../../../../generated/generated-types";
import {useEffect} from "react";
import {NotificationType} from "../../../../common/error/AppNotificationContext";
import {Relation} from "../../../../../jmix/enums/enums";
import {ScoutvilleKidParentRelation} from "../../../../../jmix/entities/ScoutvilleKidParentRelation";
import getValueTranslation from "../../../../common/form/translations/getValueTranslation";
import getEnumValues from "../../../../common/form/translations/getEnumValues";

function TrustedInfo() {
  const [updateTrustedUser, response] = useUpdateTrustedPersonMutation();
  const {data, error, loading} = useTrustedUserQuery()
  const {addNotificationSnackbar} = useAppNotificationCopy();
  const intl = useIntl();
  const {formFields, validations} = formData(intl);


  function handleUpdateUser(values) {
    console.log("Values: ", values)
    updateTrustedUser({
      variables: {
        personDto: values
      }
    })
  }

  useEffect(() => {
    if (response.data && response.called) {
      addNotificationSnackbar({
        title: "Trusted user saved",
        type: NotificationType.Success,
        message: "Successfully saved trusted user.",
        dismissible: 800
      })
    }

  }, [response.data, response.called])

  return (
    <Formik
      validationSchema={validations}
      enableReinitialize={true}
      initialValues={
        {
          firstName: data?.trustedPerson?.firstName || "",
          secondName: data?.trustedPerson?.secondName || "",
          lastName: data?.trustedPerson?.lastName || "",
          email: data?.trustedPerson?.email || "",
          phone: data?.trustedPerson?.phone || "",
          relation: data?.trustedPerson?.relation,
        }}
      onSubmit={(values) => {

        console.log("Tr person values: ", values)
        // await new Promise((resolve) => setTimeout(resolve, 500));
        handleUpdateUser(values)
        // alert(JSON.stringify(values, null, 2));
      }}
    >
      {({isSubmitting, handleChange, handleSubmit}) => (
        <Form>
          <Card id="basic-info" sx={{overflow: "visible"}}>
            <MDBox p={3}>
              <MDTypography variant="h5">Trusted Person Info</MDTypography>
            </MDBox>
            <MDBox pb={3} px={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <FormField name={formFields.firstName.name}
                             label={formFields.firstName.label}
                             inputProps={{type: formFields.firstName.type}}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormField name={formFields.secondName.name}
                             label={formFields.secondName.label}
                             inputProps={{type: formFields.secondName.type}}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormField name={formFields.lastName.name}
                             label={formFields.lastName.label}
                             inputProps={{type: formFields.lastName.type}}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormField
                    name={formFields.email.name}
                    label={formFields.email.label}
                    inputProps={{type: formFields.email.type}}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormField
                    name={formFields.phone.name}
                    label={formFields.phone.label}
                    inputProps={{type: formFields.phone.type}}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <AutocompleteField
                    onChange={handleChange}
                    type={formFields.relation.type}
                    label={formFields.relation.label}
                    name={formFields.relation.name}
                    // value={relationV}
                    getOptionLabel={(v) =>
                      getValueTranslation(ScoutvilleKidParentRelation.NAME, formFields.relation.name, v)
                    }
                    options={getEnumValues(ScoutvilleKidParentRelation.NAME, formFields.relation.name).map(
                      (v) => v.name
                    )}
                  />
                </Grid>

                <Grid style={{textAlign: 'right'}} item md={12} sm={12}>
                  <MDButton onClick={handleSubmit} size={'small'} color={'info'} variant={'contained'}>Save</MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </Form>)}
    </Formik>


  );
}

export default TrustedInfo;
