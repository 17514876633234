import { Grid } from "@mui/material";
import RadioField from "app/common/form/fields/RadioField";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const KidExperienceInfo = ({ formData }) => {
  const { formFields, form } = formData;
  const { values, errors, touched, handleChange } = form;
  const { isFirsTour, isSawCamps, isDesMade } = formFields;
  const { isFirsTour: isFirsTourV, isSawCamps: isSawCampsV, isDesMade: isDesMadeV } = values;

  return (
    <MDBox fullWidth>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Kid experience</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory information
        </MDTypography>
      </MDBox>
      <MDBox fullWidth mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <RadioField
              label={isFirsTour.label}
              values={[
                "Вперше",
                "Вже відпочивала в Скаутвіллі",
                "Дитина їде тому, що я працюю в проекті",
              ]}
              name={isFirsTour.name}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <RadioField
              label={isSawCamps.label}
              values={[
                "Вперше",
                "Вже відпочивала в Скаутвіллі",
                "Дитина їде тому, що я працюю в проекті",
              ]}
              name={isSawCamps.name}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <RadioField
              label={isDesMade.label}
              values={[
                "Вперше",
                "Вже відпочивала в Скаутвіллі",
                "Дитина їде тому, що я працюю в проекті",
              ]}
              name={isDesMade.name}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

// // typechecking props for UserInfo
// KidGeneralInfo.propTypes = {
//   formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
// };

export default KidExperienceInfo;
