import {useMainStore} from "@haulmont/jmix-react-core";
import {useEffect, useState} from "react";
import MDBox from "components/MDBox";

declare global {
  interface Window {
    fondy: any;
  }
}


const JS_CHECKOUT_URL = "https://pay.fondy.eu/latest/checkout-vue/checkout.js";
const CSS_CHECKOUT_URL =
  "https://pay.fondy.eu/latest/checkout-vue/checkout.css";

function loadScript(url, onLoadCallback) {
  console.log("Loading: ", url)
  const script = document.createElement("script");
  script.src = url;
  script.async = true;
  document.body.appendChild(script);
  if (onLoadCallback) {
    script.onload = () => {
      console.log("Load script: callback")
      onLoadCallback();
    }
  }
  return script;
}

function loadCss(url, onLoadCallback) {
  console.log("Loading: ", url)
  const script = document.createElement("link");
  script.href = url;
  script.rel = "stylesheet";
  document.body.appendChild(script);
  if (onLoadCallback) {
    script.onload = () => {
      console.log("Load script: callback")
      onLoadCallback();
    }
  }
  return script;
}

function openFondy(options: object, onSuccess?: (status?: any) => void, onFailure?: (status?: any) => void) {
  console.log("Init Fondy")
  return window
    .fondy("#checkout-container", options)
    .$on("success", function (model) {
      console.log("success event handled");

      var order_status = model.attr("order.order_data.order_status");

      if (order_status === "approved") {
        console.log("Order is approved. Do somethng on approve...");
      }
      if (onSuccess) {
        onSuccess(model)
      }
    })
    .$on("error", function (model) {
      console.log("error event handled");
      var response_code = model.attr("error.code");
      var response_description = model.attr("error.message");
      console.log(
        "Order is declined: " +
        response_code +
        ", description: " +
        response_description
      );
      if (onFailure) {
        onFailure(model)
      }
    });
}

const options = (token: string, locale: string | null) => {
  return {
    options: {
      methods: ["card", "wallets"],
      methods_disabled: ['banklinks_eu', 'local_methods'],
      card_icons: ["mastercard", "visa"],
      active_tab: "card",
      fields: false,
      lang: locale ? locale : "en",
      title: "Tour payment",
      full_screen: false,
      button: true,
      email: false
    },
    params: {
      token: token
    }
  }
};

type FondyPayProps = {
  token: string
  onSuccess?: (status?: any) => void
  onFailure?: (status?: any) => void
}


const FondyPayment = (props: FondyPayProps) => {
  const mainStore = useMainStore();

  const [cssLoaded, setCssLoaded] = useState(false);
  const [jsLoaded, setJsLoaded] = useState(false);

  useEffect(() => {

    // const divElem = document.createElement("div");
    // divElem.id = "checkout-container";
    // document.body.appendChild(divElem);

    if (!jsLoaded) {
      loadScript(JS_CHECKOUT_URL, () => setJsLoaded(true))
    }
    if (!cssLoaded) {
      loadCss(CSS_CHECKOUT_URL, () => setCssLoaded(true))
    }
    if (window.fondy) {
      openFondy(options(props.token, mainStore.locale), props.onSuccess, props.onFailure)
    }

    return () => { }
  }, [jsLoaded, cssLoaded])

  return (
    <div style={{zIndex: '50'}} >
    <div id="checkout-container" />
    </div>
  );
};



export default FondyPayment;
