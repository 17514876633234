/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import {checkResponseStatus} from "@haulmont/jmix-react-core";
import Card from "@mui/material/Card";
// Images
import bgImage from "assets/app/images/panoramic/photo11.jpg";
// Material Dashboard 2 PRO React components
import {APP_REST_API_URL} from "config";
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import React, {useState} from "react";
import * as yup from "yup";
import PasswordResetForm from "./components/PasswordRessetForm";
import ResultAction from "../../success/ResultAction";
import {MdOutlineDone} from "react-icons/md";
import {AiOutlineCloseCircle} from "react-icons/all";
import {CircularProgress} from "@mui/material";
import {useNavigate} from "react-router-dom";

const validationSchema = yup.object({
  email: yup.string("Enter your email").email("Enter a valid email").required("Email is required"),
});

const PasswordReset = () => {
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  function getDefaultSignUpFetchOptions(data) {
    return {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
  }

  const onFinish = (values) => {

    setLoading(true);
    fetch(`${APP_REST_API_URL}password/reset`, getDefaultSignUpFetchOptions(values))
      .then(checkResponseStatus)
      .then((resp) => {
        setLoading(false);
        setDone(true);
      })
      .catch((reason) => {
        console.log("Error : ", reason)
        setLoading(false);
        setError(true);
        setDone(true)
      });
  };


  const errorRez = (<ResultAction
    color={"error"}
    cancelAction={{
      onClick: () => {
        navigate("/sign-in")
      },
      btnName: "Закрити"
    }}
    icon={<AiOutlineCloseCircle size={"50px"} color="inherit"/>}
    title="Щось пішло не так!"
    message="За більш детальною інформацією зверніться до адміністратора."
  />)

  const loadingRez = (<ResultAction
    color={"info"}
    icon={<CircularProgress color={'inherit'} style={{fontSize: "50px"}}/>}
    title="Зачекайте будь ласка"
    message="Ми перевіряем реесткаційні данні."
  />)


  const successRez = (<ResultAction
    color={"success"}
    icon={<MdOutlineDone size={"50px"} color="inherit"/>}
    title="Дакуемо за запит!"
    message="Перевірте, будь ласка, ваш ящик електронної пошти для подальших дій."
    okAction={{
      onClick: () => {
        navigate("/sign-in")
      },
      btnName: "На стрінку входу"
    }}
  />)

  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        {loading && loadingRez}
        {done && !error && !loading && successRez}
        {done && error && errorRez}
        {!done && !error && !loading &&  <PasswordResetForm onFinish={onFinish}/>}

      </Card>
    </CoverLayout>
  );
};

export default PasswordReset;
