import {useMainStore} from "@haulmont/jmix-react-core";
import {useEffect, useState} from "react";
import MDBox from "components/MDBox";

declare global {
  type Param = {
    data: string,
    signature: string,
    embedTo: string, // "#liqpay_checkout"

    // language: "ru",
    // mode:  "embed" // embed || popup
    mode: string // embed || popup
  }

  type PayInst = {
    on: (t: string, f: (any) => void) => PayInst
  }

  interface Window {
    LiqPayCheckout: {
      init: (Param) => PayInst
    };
  }
}


const JS_CHECKOUT_URL = "//static.liqpay.ua/libjs/checkout.js";

function loadScript(url, onLoadCallback) {
  console.log("Loading: ", url)
  const script = document.createElement("script");
  script.src = url;
  script.async = true;
  document.body.appendChild(script);
  if (onLoadCallback) {
    script.onload = () => {
      console.log("Load script: callback")
      onLoadCallback();
    }
  }
  return script;
}


function openLiqPay(options: object, onSuccess?: (status?: any) => void, onFailure?: (status?: any) => void) {
  console.log("Init LiqPay")
  console.log("Window :" , window)
  console.log("Window.LiqPayCheckout :" , window.LiqPayCheckout)

  return window
    .LiqPayCheckout.init({embedTo: "#liqpay_checkout", ...options} as Param)

    .on("liqpay.callback", function (model) {
      console.log("Callback: " , model)
    })
    .on("liqpay.ready", function (model) {
      console.log("success event handled");

      const order_status = model.attr("order.order_data.order_status");

      if (order_status === "approved") {
        console.log("Order is approved. Do somethng on approve...");
      }
      if (onSuccess) {
        onSuccess(model)
      }
    })
    .on("liqpay.error", function (model) {
      console.log("error event handled");
      var response_code = model.attr("error.code");
      var response_description = model.attr("error.message");
      console.log(
        "Order is declined: " +
        response_code +
        ", description: " +
        response_description
      );
      if (onFailure) {
        onFailure(model)
      }
    });
}

const options = (data: string, signature: string, locale: string | null) => {
  return {
    mode: "embed",
    data: data,
    signature: signature
  }
};

type LiqPayPayProps = {
  data: string
  signature: string
  onSuccess?: (status?: any) => void
  onFailure?: (status?: any) => void
}


const LiqPayPayment = (props: LiqPayPayProps) => {
  const mainStore = useMainStore();

  const [jsLoaded, setJsLoaded] = useState(false);

  useEffect(() => {

    // const divElem = document.createElement("div");
    // divElem.id = "checkout-container";
    // document.body.appendChild(divElem);

    if (!jsLoaded) {
      loadScript(JS_CHECKOUT_URL, () => setJsLoaded(true))
    }
    if (window.LiqPayCheckout) {
      openLiqPay(options(props.data, props.signature, mainStore.locale), props.onSuccess, props.onFailure)
    }

    return () => {
    }
  }, [jsLoaded])

  return (
      <div id="liqpay_checkout"/>
  );
};


export default LiqPayPayment;
