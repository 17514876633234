import {Grid} from "@mui/material";
import KidCardList from "app/components/kid/KidCardList";
import KidCreateForm from "app/components/kid/KidCreateForm";
import KidEditForm from "app/components/kid/KidEditForm";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {useState} from "react";
import {MdCreate} from "react-icons/md";

const KidsPage = () => {
  const [selectedKid, setSelectedKid] = useState();
  const [openCreate, setOpenCreate] = useState(false)

  const handleSelect = (kid) => {
    setSelectedKid(kid);
  };
  const handleReturn = () => {
    setSelectedKid(null);
  };

  const handleCreateClose = () => {
    setOpenCreate(false);
  };


  return (
    <DashboardLayout>
      <DashboardNavbar/>
      <MDBox p={5}>
        {openCreate && <KidCreateForm onClose={handleCreateClose}/>}
        {selectedKid && <KidEditForm kid={selectedKid} onReturn={handleReturn}/>}
        {!openCreate && !selectedKid && (
          <Grid container alignItems="center">
            <Grid item xs={12} md={12} sx={{textAlign: "right"}}>
              <MDBox mb={2}>
                <MDButton size={'small'} onClick={() => setOpenCreate(true)} variant="contained" color="info">
                  <MdCreate/>
                  &nbsp; Create
                </MDButton>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={12} sx={{textAlign: "center"}}>
              <KidCardList onSelect={handleSelect}/>
            </Grid>
          </Grid>
        )}
      </MDBox>
    </DashboardLayout>
  );
};

export default KidsPage;
