import { Autocomplete } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { ErrorMessage, useField } from "formik";

const AutocompleteField = ({ label, name, ...rest }) => {
  const [field, meta, helpers] = useField(name);
  return (
    <MDBox mb={1.5}>
      <Autocomplete
        {...rest}
        onChange={(_, value) => helpers.setValue(value)}
        renderInput={(props) => <MDInput {...field} label={label} variant="standard" {...props} />}
        isOptionEqualToValue={(option, value) => option === value}
        name={name}
        label={label}
        fullWidth
      />
      <MDBox mt={0.75}>
        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
          <ErrorMessage name={name} />
        </MDTypography>
      </MDBox>
    </MDBox>
  );
};

export default AutocompleteField;
