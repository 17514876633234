/**
 * To add new locale we need to
 * - register it in localesStore ('initializeLocales' function)
 * - register it in 'antdLocalesStore' ('addAntdLocale' function)
 * - register dayjs locale (as import below)
 */
import {localesStore} from "@haulmont/jmix-react-web";
import "dayjs/locale/en";
import "dayjs/locale/ru";
import "dayjs/locale/uk";
import en from "./en.json";
import ru from "./ru.json";
import uk from "./uk.json";
//
// localesStore.addLocale({
//   locale: "en",
//   caption: "English",
//   messages: en,
// });

// localesStore.addLocale({
//   locale: "ru",
//   caption: "Русский",
//   messages: ru,
// });


localesStore.addLocale({
  locale: "uk",
  caption: "Українська",
  messages: uk,
});

//

export function mapMdLocales (locale) {
  switch (locale){
    case "uk" :
      return "uk-UA"
    case "en":
      return "en-US"
    default:
      return "uk-UA"
  }
}