import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  /** Built-in java.math.BigInteger */
  BigInteger: any;
  /** Built-in Byte as Int */
  Byte: any;
  /** Built-in Char as Character */
  Char: any;
  /** Date type */
  Date: any;
  /** Date type with time */
  DateTime: any;
  /** Local date type */
  LocalDate: any;
  /** Local date type with time */
  LocalDateTime: any;
  /** Local time type */
  LocalTime: any;
  Long: any;
  /** Date type with offset */
  OffsetDateTime: any;
  /** Offset time type */
  OffsetTime: any;
  /** Built-in Short as Int */
  Short: any;
  /** Time type */
  Time: any;
  /** Use SPQR's SchemaPrinter to remove this from SDL */
  UNREPRESENTABLE: any;
  UUID: any;
  /** Type for file uploading */
  Upload: any;
  Void: any;
};

export enum AcquiringProvider {
  Fondy = 'FONDY',
  Liqpay = 'LIQPAY'
}

export enum CanSwim {
  No = 'NO',
  Studying = 'STUDYING',
  Yes = 'YES'
}

export enum CustomTemplateDefinedBy {
  Class = 'CLASS',
  Script = 'SCRIPT',
  Url = 'URL'
}

export enum DiscountType {
  Percentage = 'PERCENTAGE',
  Subtraction = 'SUBTRACTION'
}

export type EmailNotification = {
  __typename?: 'EmailNotification';
  _instanceName: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  isRead: Maybe<Scalars['Boolean']>;
  lastModifiedDate: Maybe<Scalars['DateTime']>;
  recipient: Maybe<ScoutvilleUser>;
  sender: Maybe<ScoutvilleUser>;
  template: Maybe<EmailTemplate>;
  title: Maybe<Scalars['String']>;
};

export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  _instanceName: Maybe<Scalars['String']>;
  emailTemplateName: Maybe<Scalars['String']>;
  html: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  version: Maybe<Scalars['Int']>;
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE'
}

export enum GenderBlock {
  All = 'ALL',
  Female = 'FEMALE',
  Male = 'MALE'
}

export type KidDto = {
  __typename?: 'KidDto';
  _instanceName: Maybe<Scalars['String']>;
  bdCertificate: Maybe<Scalars['Upload']>;
  bdCertificateSerial: Maybe<Scalars['String']>;
  birthDate: Maybe<Scalars['Date']>;
  canSwim: Maybe<CanSwim>;
  firstName: Maybe<Scalars['String']>;
  foodPreference: Maybe<Scalars['String']>;
  gender: Maybe<Gender>;
  healthInfo: Maybe<Scalars['String']>;
  hobbyInfo: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  isDesMade: Maybe<Scalars['String']>;
  isFirsTour: Maybe<Scalars['String']>;
  isSawCamps: Maybe<Scalars['String']>;
  lastName: Maybe<Scalars['String']>;
  relation: Maybe<Relation>;
  secondName: Maybe<Scalars['String']>;
  shortSize: Maybe<TShortSize>;
};

/** Mutation root */
export type Mutation = {
  __typename?: 'Mutation';
  createKid: Maybe<ScoutvilleKid>;
  deleteKid: Scalars['Boolean'];
  delete_EmailNotification: Maybe<Scalars['Void']>;
  delete_EmailTemplate: Maybe<Scalars['Void']>;
  delete_Notification: Maybe<Scalars['Void']>;
  delete_ScoutvilleDiscount: Maybe<Scalars['Void']>;
  delete_ScoutvilleDocument: Maybe<Scalars['Void']>;
  delete_ScoutvilleKid: Maybe<Scalars['Void']>;
  delete_ScoutvilleKidParentRelation: Maybe<Scalars['Void']>;
  delete_ScoutvilleOrder: Maybe<Scalars['Void']>;
  delete_ScoutvilleOrderStatus: Maybe<Scalars['Void']>;
  delete_ScoutvilleOrderTour: Maybe<Scalars['Void']>;
  delete_ScoutvillePayment: Maybe<Scalars['Void']>;
  delete_ScoutvilleProduct: Maybe<Scalars['Void']>;
  delete_ScoutvilleTeamMember: Maybe<Scalars['Void']>;
  delete_ScoutvilleTour: Maybe<Scalars['Void']>;
  delete_ScoutvilleTourBlock: Maybe<Scalars['Void']>;
  delete_ScoutvilleTrustedPerson: Maybe<Scalars['Void']>;
  delete_ScoutvilleUser: Maybe<Scalars['Void']>;
  delete_UiNotification: Maybe<Scalars['Void']>;
  delete_audit_EntityLog: Maybe<Scalars['Void']>;
  delete_audit_EntitySnapshot: Maybe<Scalars['Void']>;
  delete_audit_LoggedAttribute: Maybe<Scalars['Void']>;
  delete_audit_LoggedEntity: Maybe<Scalars['Void']>;
  delete_email_SendingAttachment: Maybe<Scalars['Void']>;
  delete_email_SendingMessage: Maybe<Scalars['Void']>;
  delete_emltmp_EmailTemplate: Maybe<Scalars['Void']>;
  delete_emltmp_EmailTemplateAttachment: Maybe<Scalars['Void']>;
  delete_emltmp_JsonEmailTemplate: Maybe<Scalars['Void']>;
  delete_emltmp_ParameterValue: Maybe<Scalars['Void']>;
  delete_emltmp_ReportEmailTemplate: Maybe<Scalars['Void']>;
  delete_emltmp_TemplateBlock: Maybe<Scalars['Void']>;
  delete_emltmp_TemplateBlockGroup: Maybe<Scalars['Void']>;
  delete_emltmp_TemplateGroup: Maybe<Scalars['Void']>;
  delete_emltmp_TemplateReport: Maybe<Scalars['Void']>;
  delete_report_Report: Maybe<Scalars['Void']>;
  delete_report_ReportExecution: Maybe<Scalars['Void']>;
  delete_report_ReportGroup: Maybe<Scalars['Void']>;
  delete_report_ReportTemplate: Maybe<Scalars['Void']>;
  delete_sec_ResourcePolicyEntity: Maybe<Scalars['Void']>;
  delete_sec_ResourceRoleEntity: Maybe<Scalars['Void']>;
  delete_sec_RoleAssignmentEntity: Maybe<Scalars['Void']>;
  delete_sec_RowLevelPolicyEntity: Maybe<Scalars['Void']>;
  delete_sec_RowLevelRoleEntity: Maybe<Scalars['Void']>;
  delete_sec_UserSubstitutionEntity: Maybe<Scalars['Void']>;
  delete_ui_FilterConfiguration: Maybe<Scalars['Void']>;
  delete_ui_Setting: Maybe<Scalars['Void']>;
  delete_ui_TablePresentation: Maybe<Scalars['Void']>;
  updateKid: Maybe<ScoutvilleKid>;
  updateUserData: Maybe<UserDto>;
  upsertTrustedPerson: Maybe<TrustedPersonDto>;
  upsert_EmailNotification: Maybe<EmailNotification>;
  upsert_EmailTemplate: Maybe<EmailTemplate>;
  upsert_Notification: Maybe<Notification>;
  upsert_ScoutvilleDiscount: Maybe<ScoutvilleDiscount>;
  upsert_ScoutvilleDocument: Maybe<ScoutvilleDocument>;
  upsert_ScoutvilleKid: Maybe<ScoutvilleKid>;
  upsert_ScoutvilleKidParentRelation: Maybe<ScoutvilleKidParentRelation>;
  upsert_ScoutvilleOrder: Maybe<ScoutvilleOrder>;
  upsert_ScoutvilleOrderStatus: Maybe<ScoutvilleOrderStatus>;
  upsert_ScoutvilleOrderTour: Maybe<ScoutvilleOrderTour>;
  upsert_ScoutvillePayment: Maybe<ScoutvillePayment>;
  upsert_ScoutvilleProduct: Maybe<ScoutvilleProduct>;
  upsert_ScoutvilleTeamMember: Maybe<ScoutvilleTeamMember>;
  upsert_ScoutvilleTour: Maybe<ScoutvilleTour>;
  upsert_ScoutvilleTourBlock: Maybe<ScoutvilleTourBlock>;
  upsert_ScoutvilleTrustedPerson: Maybe<ScoutvilleTrustedPerson>;
  upsert_ScoutvilleUser: Maybe<ScoutvilleUser>;
  upsert_UiNotification: Maybe<UiNotification>;
  upsert_audit_EntityLog: Maybe<Audit_EntityLog>;
  upsert_audit_EntitySnapshot: Maybe<Audit_EntitySnapshot>;
  upsert_audit_LoggedAttribute: Maybe<Audit_LoggedAttribute>;
  upsert_audit_LoggedEntity: Maybe<Audit_LoggedEntity>;
  upsert_email_SendingAttachment: Maybe<Email_SendingAttachment>;
  upsert_email_SendingMessage: Maybe<Email_SendingMessage>;
  upsert_emltmp_EmailTemplate: Maybe<Emltmp_EmailTemplate>;
  upsert_emltmp_EmailTemplateAttachment: Maybe<Emltmp_EmailTemplateAttachment>;
  upsert_emltmp_JsonEmailTemplate: Maybe<Emltmp_JsonEmailTemplate>;
  upsert_emltmp_ParameterValue: Maybe<Emltmp_ParameterValue>;
  upsert_emltmp_ReportEmailTemplate: Maybe<Emltmp_ReportEmailTemplate>;
  upsert_emltmp_TemplateBlock: Maybe<Emltmp_TemplateBlock>;
  upsert_emltmp_TemplateBlockGroup: Maybe<Emltmp_TemplateBlockGroup>;
  upsert_emltmp_TemplateGroup: Maybe<Emltmp_TemplateGroup>;
  upsert_emltmp_TemplateReport: Maybe<Emltmp_TemplateReport>;
  upsert_report_Report: Maybe<Report_Report>;
  upsert_report_ReportExecution: Maybe<Report_ReportExecution>;
  upsert_report_ReportGroup: Maybe<Report_ReportGroup>;
  upsert_report_ReportTemplate: Maybe<Report_ReportTemplate>;
  upsert_sec_ResourcePolicyEntity: Maybe<Sec_ResourcePolicyEntity>;
  upsert_sec_ResourceRoleEntity: Maybe<Sec_ResourceRoleEntity>;
  upsert_sec_RoleAssignmentEntity: Maybe<Sec_RoleAssignmentEntity>;
  upsert_sec_RowLevelPolicyEntity: Maybe<Sec_RowLevelPolicyEntity>;
  upsert_sec_RowLevelRoleEntity: Maybe<Sec_RowLevelRoleEntity>;
  upsert_sec_UserSubstitutionEntity: Maybe<Sec_UserSubstitutionEntity>;
  upsert_ui_FilterConfiguration: Maybe<Ui_FilterConfiguration>;
  upsert_ui_Setting: Maybe<Ui_Setting>;
  upsert_ui_TablePresentation: Maybe<Ui_TablePresentation>;
};


/** Mutation root */
export type MutationCreateKidArgs = {
  kidDto: InputMaybe<Inp_KidDto>;
};


/** Mutation root */
export type MutationDeleteKidArgs = {
  kidId: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_EmailNotificationArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_EmailTemplateArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_NotificationArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_ScoutvilleDiscountArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_ScoutvilleDocumentArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_ScoutvilleKidArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_ScoutvilleKidParentRelationArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_ScoutvilleOrderArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_ScoutvilleOrderStatusArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_ScoutvilleOrderTourArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_ScoutvillePaymentArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_ScoutvilleProductArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_ScoutvilleTeamMemberArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_ScoutvilleTourArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_ScoutvilleTourBlockArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_ScoutvilleTrustedPersonArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_ScoutvilleUserArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_UiNotificationArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_Audit_EntityLogArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_Audit_EntitySnapshotArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_Audit_LoggedAttributeArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_Audit_LoggedEntityArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_Email_SendingAttachmentArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_Email_SendingMessageArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_Emltmp_EmailTemplateArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_Emltmp_EmailTemplateAttachmentArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_Emltmp_JsonEmailTemplateArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_Emltmp_ParameterValueArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_Emltmp_ReportEmailTemplateArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_Emltmp_TemplateBlockArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_Emltmp_TemplateBlockGroupArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_Emltmp_TemplateGroupArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_Emltmp_TemplateReportArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_Report_ReportArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_Report_ReportExecutionArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_Report_ReportGroupArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_Report_ReportTemplateArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_Sec_ResourcePolicyEntityArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_Sec_ResourceRoleEntityArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_Sec_RoleAssignmentEntityArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_Sec_RowLevelPolicyEntityArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_Sec_RowLevelRoleEntityArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_Sec_UserSubstitutionEntityArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_Ui_FilterConfigurationArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_Ui_SettingArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationDelete_Ui_TablePresentationArgs = {
  id: Scalars['String'];
};


/** Mutation root */
export type MutationUpdateKidArgs = {
  kidDto: InputMaybe<Inp_KidDto>;
};


/** Mutation root */
export type MutationUpdateUserDataArgs = {
  userDto: InputMaybe<Inp_UserDto>;
};


/** Mutation root */
export type MutationUpsertTrustedPersonArgs = {
  personDto: InputMaybe<Inp_TrustedPersonDto>;
};


/** Mutation root */
export type MutationUpsert_EmailNotificationArgs = {
  emailNotification: Inp_EmailNotification;
};


/** Mutation root */
export type MutationUpsert_EmailTemplateArgs = {
  emailTemplate: Inp_EmailTemplate;
};


/** Mutation root */
export type MutationUpsert_NotificationArgs = {
  notification: Inp_Notification;
};


/** Mutation root */
export type MutationUpsert_ScoutvilleDiscountArgs = {
  scoutvilleDiscount: Inp_ScoutvilleDiscount;
};


/** Mutation root */
export type MutationUpsert_ScoutvilleDocumentArgs = {
  scoutvilleDocument: Inp_ScoutvilleDocument;
};


/** Mutation root */
export type MutationUpsert_ScoutvilleKidArgs = {
  scoutvilleKid: Inp_ScoutvilleKid;
};


/** Mutation root */
export type MutationUpsert_ScoutvilleKidParentRelationArgs = {
  scoutvilleKidParentRelation: Inp_ScoutvilleKidParentRelation;
};


/** Mutation root */
export type MutationUpsert_ScoutvilleOrderArgs = {
  scoutvilleOrder: Inp_ScoutvilleOrder;
};


/** Mutation root */
export type MutationUpsert_ScoutvilleOrderStatusArgs = {
  scoutvilleOrderStatus: Inp_ScoutvilleOrderStatus;
};


/** Mutation root */
export type MutationUpsert_ScoutvilleOrderTourArgs = {
  scoutvilleOrderTour: Inp_ScoutvilleOrderTour;
};


/** Mutation root */
export type MutationUpsert_ScoutvillePaymentArgs = {
  scoutvillePayment: Inp_ScoutvillePayment;
};


/** Mutation root */
export type MutationUpsert_ScoutvilleProductArgs = {
  scoutvilleProduct: Inp_ScoutvilleProduct;
};


/** Mutation root */
export type MutationUpsert_ScoutvilleTeamMemberArgs = {
  scoutvilleTeamMember: Inp_ScoutvilleTeamMember;
};


/** Mutation root */
export type MutationUpsert_ScoutvilleTourArgs = {
  scoutvilleTour: Inp_ScoutvilleTour;
};


/** Mutation root */
export type MutationUpsert_ScoutvilleTourBlockArgs = {
  scoutvilleTourBlock: Inp_ScoutvilleTourBlock;
};


/** Mutation root */
export type MutationUpsert_ScoutvilleTrustedPersonArgs = {
  scoutvilleTrustedPerson: Inp_ScoutvilleTrustedPerson;
};


/** Mutation root */
export type MutationUpsert_ScoutvilleUserArgs = {
  scoutvilleUser: Inp_ScoutvilleUser;
};


/** Mutation root */
export type MutationUpsert_UiNotificationArgs = {
  uiNotification: Inp_UiNotification;
};


/** Mutation root */
export type MutationUpsert_Audit_EntityLogArgs = {
  entityLogItem: Inp_Audit_EntityLog;
};


/** Mutation root */
export type MutationUpsert_Audit_EntitySnapshotArgs = {
  entitySnapshot: Inp_Audit_EntitySnapshot;
};


/** Mutation root */
export type MutationUpsert_Audit_LoggedAttributeArgs = {
  loggedAttribute: Inp_Audit_LoggedAttribute;
};


/** Mutation root */
export type MutationUpsert_Audit_LoggedEntityArgs = {
  loggedEntity: Inp_Audit_LoggedEntity;
};


/** Mutation root */
export type MutationUpsert_Email_SendingAttachmentArgs = {
  sendingAttachment: Inp_Email_SendingAttachment;
};


/** Mutation root */
export type MutationUpsert_Email_SendingMessageArgs = {
  sendingMessage: Inp_Email_SendingMessage;
};


/** Mutation root */
export type MutationUpsert_Emltmp_EmailTemplateArgs = {
  emailTemplate: Inp_Emltmp_EmailTemplate;
};


/** Mutation root */
export type MutationUpsert_Emltmp_EmailTemplateAttachmentArgs = {
  emailTemplateAttachment: Inp_Emltmp_EmailTemplateAttachment;
};


/** Mutation root */
export type MutationUpsert_Emltmp_JsonEmailTemplateArgs = {
  jsonEmailTemplate: Inp_Emltmp_JsonEmailTemplate;
};


/** Mutation root */
export type MutationUpsert_Emltmp_ParameterValueArgs = {
  parameterValue: Inp_Emltmp_ParameterValue;
};


/** Mutation root */
export type MutationUpsert_Emltmp_ReportEmailTemplateArgs = {
  reportEmailTemplate: Inp_Emltmp_ReportEmailTemplate;
};


/** Mutation root */
export type MutationUpsert_Emltmp_TemplateBlockArgs = {
  templateBlock: Inp_Emltmp_TemplateBlock;
};


/** Mutation root */
export type MutationUpsert_Emltmp_TemplateBlockGroupArgs = {
  templateBlockGroup: Inp_Emltmp_TemplateBlockGroup;
};


/** Mutation root */
export type MutationUpsert_Emltmp_TemplateGroupArgs = {
  templateGroup: Inp_Emltmp_TemplateGroup;
};


/** Mutation root */
export type MutationUpsert_Emltmp_TemplateReportArgs = {
  templateReport: Inp_Emltmp_TemplateReport;
};


/** Mutation root */
export type MutationUpsert_Report_ReportArgs = {
  report: Inp_Report_Report;
};


/** Mutation root */
export type MutationUpsert_Report_ReportExecutionArgs = {
  reportExecution: Inp_Report_ReportExecution;
};


/** Mutation root */
export type MutationUpsert_Report_ReportGroupArgs = {
  reportGroup: Inp_Report_ReportGroup;
};


/** Mutation root */
export type MutationUpsert_Report_ReportTemplateArgs = {
  reportTemplate: Inp_Report_ReportTemplate;
};


/** Mutation root */
export type MutationUpsert_Sec_ResourcePolicyEntityArgs = {
  resourcePolicyEntity: Inp_Sec_ResourcePolicyEntity;
};


/** Mutation root */
export type MutationUpsert_Sec_ResourceRoleEntityArgs = {
  resourceRoleEntity: Inp_Sec_ResourceRoleEntity;
};


/** Mutation root */
export type MutationUpsert_Sec_RoleAssignmentEntityArgs = {
  roleAssignmentEntity: Inp_Sec_RoleAssignmentEntity;
};


/** Mutation root */
export type MutationUpsert_Sec_RowLevelPolicyEntityArgs = {
  rowLevelPolicyEntity: Inp_Sec_RowLevelPolicyEntity;
};


/** Mutation root */
export type MutationUpsert_Sec_RowLevelRoleEntityArgs = {
  rowLevelRoleEntity: Inp_Sec_RowLevelRoleEntity;
};


/** Mutation root */
export type MutationUpsert_Sec_UserSubstitutionEntityArgs = {
  userSubstitutionEntity: Inp_Sec_UserSubstitutionEntity;
};


/** Mutation root */
export type MutationUpsert_Ui_FilterConfigurationArgs = {
  filterConfiguration: Inp_Ui_FilterConfiguration;
};


/** Mutation root */
export type MutationUpsert_Ui_SettingArgs = {
  uiSetting: Inp_Ui_Setting;
};


/** Mutation root */
export type MutationUpsert_Ui_TablePresentationArgs = {
  uiTablePresentation: Inp_Ui_TablePresentation;
};

export type Notification = {
  __typename?: 'Notification';
  _instanceName: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  isRead: Maybe<Scalars['Boolean']>;
  lastModifiedDate: Maybe<Scalars['DateTime']>;
  recipient: Maybe<ScoutvilleUser>;
  sender: Maybe<ScoutvilleUser>;
  title: Maybe<Scalars['String']>;
};

export enum OrderStatusType {
  Complete = 'COMPLETE',
  NewOrder = 'NEW_ORDER',
  Processing = 'PROCESSING',
  Refunded = 'REFUNDED'
}

export enum ParameterType {
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Entity = 'ENTITY',
  EntityList = 'ENTITY_LIST',
  Enumeration = 'ENUMERATION',
  Numeric = 'NUMERIC',
  Text = 'TEXT',
  Time = 'TIME'
}

export enum PaymentType {
  Cache = 'CACHE',
  CreditCard = 'CREDIT_CARD',
  MoneyTransfer = 'MONEY_TRANSFER'
}

/** Query root */
export type Query = {
  __typename?: 'Query';
  EmailNotificationById: Maybe<EmailNotification>;
  EmailNotificationCount: Maybe<Scalars['Long']>;
  EmailNotificationList: Maybe<Array<Maybe<EmailNotification>>>;
  EmailTemplateById: Maybe<EmailTemplate>;
  EmailTemplateCount: Maybe<Scalars['Long']>;
  EmailTemplateList: Maybe<Array<Maybe<EmailTemplate>>>;
  NotificationById: Maybe<Notification>;
  NotificationCount: Maybe<Scalars['Long']>;
  NotificationList: Maybe<Array<Maybe<Notification>>>;
  ScoutvilleDiscountById: Maybe<ScoutvilleDiscount>;
  ScoutvilleDiscountCount: Maybe<Scalars['Long']>;
  ScoutvilleDiscountList: Maybe<Array<Maybe<ScoutvilleDiscount>>>;
  ScoutvilleDocumentById: Maybe<ScoutvilleDocument>;
  ScoutvilleDocumentCount: Maybe<Scalars['Long']>;
  ScoutvilleDocumentList: Maybe<Array<Maybe<ScoutvilleDocument>>>;
  ScoutvilleKidById: Maybe<ScoutvilleKid>;
  ScoutvilleKidCount: Maybe<Scalars['Long']>;
  ScoutvilleKidList: Maybe<Array<Maybe<ScoutvilleKid>>>;
  ScoutvilleKidParentRelationById: Maybe<ScoutvilleKidParentRelation>;
  ScoutvilleKidParentRelationCount: Maybe<Scalars['Long']>;
  ScoutvilleKidParentRelationList: Maybe<Array<Maybe<ScoutvilleKidParentRelation>>>;
  ScoutvilleOrderById: Maybe<ScoutvilleOrder>;
  ScoutvilleOrderCount: Maybe<Scalars['Long']>;
  ScoutvilleOrderList: Maybe<Array<Maybe<ScoutvilleOrder>>>;
  ScoutvilleOrderStatusById: Maybe<ScoutvilleOrderStatus>;
  ScoutvilleOrderStatusCount: Maybe<Scalars['Long']>;
  ScoutvilleOrderStatusList: Maybe<Array<Maybe<ScoutvilleOrderStatus>>>;
  ScoutvilleOrderTourById: Maybe<ScoutvilleOrderTour>;
  ScoutvilleOrderTourCount: Maybe<Scalars['Long']>;
  ScoutvilleOrderTourList: Maybe<Array<Maybe<ScoutvilleOrderTour>>>;
  ScoutvillePaymentById: Maybe<ScoutvillePayment>;
  ScoutvillePaymentCount: Maybe<Scalars['Long']>;
  ScoutvillePaymentList: Maybe<Array<Maybe<ScoutvillePayment>>>;
  ScoutvilleProductById: Maybe<ScoutvilleProduct>;
  ScoutvilleProductCount: Maybe<Scalars['Long']>;
  ScoutvilleProductList: Maybe<Array<Maybe<ScoutvilleProduct>>>;
  ScoutvilleTeamMemberById: Maybe<ScoutvilleTeamMember>;
  ScoutvilleTeamMemberCount: Maybe<Scalars['Long']>;
  ScoutvilleTeamMemberList: Maybe<Array<Maybe<ScoutvilleTeamMember>>>;
  ScoutvilleTourBlockById: Maybe<ScoutvilleTourBlock>;
  ScoutvilleTourBlockCount: Maybe<Scalars['Long']>;
  ScoutvilleTourBlockList: Maybe<Array<Maybe<ScoutvilleTourBlock>>>;
  ScoutvilleTourById: Maybe<ScoutvilleTour>;
  ScoutvilleTourCount: Maybe<Scalars['Long']>;
  ScoutvilleTourList: Maybe<Array<Maybe<ScoutvilleTour>>>;
  ScoutvilleTrustedPersonById: Maybe<ScoutvilleTrustedPerson>;
  ScoutvilleTrustedPersonCount: Maybe<Scalars['Long']>;
  ScoutvilleTrustedPersonList: Maybe<Array<Maybe<ScoutvilleTrustedPerson>>>;
  ScoutvilleUserById: Maybe<ScoutvilleUser>;
  ScoutvilleUserCount: Maybe<Scalars['Long']>;
  ScoutvilleUserList: Maybe<Array<Maybe<ScoutvilleUser>>>;
  UiNotificationById: Maybe<UiNotification>;
  UiNotificationCount: Maybe<Scalars['Long']>;
  UiNotificationList: Maybe<Array<Maybe<UiNotification>>>;
  audit_EntityLogById: Maybe<Audit_EntityLog>;
  audit_EntityLogCount: Maybe<Scalars['Long']>;
  audit_EntityLogList: Maybe<Array<Maybe<Audit_EntityLog>>>;
  audit_EntitySnapshotById: Maybe<Audit_EntitySnapshot>;
  audit_EntitySnapshotCount: Maybe<Scalars['Long']>;
  audit_EntitySnapshotList: Maybe<Array<Maybe<Audit_EntitySnapshot>>>;
  audit_LoggedAttributeById: Maybe<Audit_LoggedAttribute>;
  audit_LoggedAttributeCount: Maybe<Scalars['Long']>;
  audit_LoggedAttributeList: Maybe<Array<Maybe<Audit_LoggedAttribute>>>;
  audit_LoggedEntityById: Maybe<Audit_LoggedEntity>;
  audit_LoggedEntityCount: Maybe<Scalars['Long']>;
  audit_LoggedEntityList: Maybe<Array<Maybe<Audit_LoggedEntity>>>;
  createOrderTour: Maybe<TokenPayload>;
  currentRelation: Maybe<ScoutvilleKidParentRelation>;
  email_SendingAttachmentById: Maybe<Email_SendingAttachment>;
  email_SendingAttachmentCount: Maybe<Scalars['Long']>;
  email_SendingAttachmentList: Maybe<Array<Maybe<Email_SendingAttachment>>>;
  email_SendingMessageById: Maybe<Email_SendingMessage>;
  email_SendingMessageCount: Maybe<Scalars['Long']>;
  email_SendingMessageList: Maybe<Array<Maybe<Email_SendingMessage>>>;
  emltmp_EmailTemplateAttachmentById: Maybe<Emltmp_EmailTemplateAttachment>;
  emltmp_EmailTemplateAttachmentCount: Maybe<Scalars['Long']>;
  emltmp_EmailTemplateAttachmentList: Maybe<Array<Maybe<Emltmp_EmailTemplateAttachment>>>;
  emltmp_EmailTemplateById: Maybe<Emltmp_EmailTemplate>;
  emltmp_EmailTemplateCount: Maybe<Scalars['Long']>;
  emltmp_EmailTemplateList: Maybe<Array<Maybe<Emltmp_EmailTemplate>>>;
  emltmp_JsonEmailTemplateById: Maybe<Emltmp_JsonEmailTemplate>;
  emltmp_JsonEmailTemplateCount: Maybe<Scalars['Long']>;
  emltmp_JsonEmailTemplateList: Maybe<Array<Maybe<Emltmp_JsonEmailTemplate>>>;
  emltmp_ParameterValueById: Maybe<Emltmp_ParameterValue>;
  emltmp_ParameterValueCount: Maybe<Scalars['Long']>;
  emltmp_ParameterValueList: Maybe<Array<Maybe<Emltmp_ParameterValue>>>;
  emltmp_ReportEmailTemplateById: Maybe<Emltmp_ReportEmailTemplate>;
  emltmp_ReportEmailTemplateCount: Maybe<Scalars['Long']>;
  emltmp_ReportEmailTemplateList: Maybe<Array<Maybe<Emltmp_ReportEmailTemplate>>>;
  emltmp_TemplateBlockById: Maybe<Emltmp_TemplateBlock>;
  emltmp_TemplateBlockCount: Maybe<Scalars['Long']>;
  emltmp_TemplateBlockGroupById: Maybe<Emltmp_TemplateBlockGroup>;
  emltmp_TemplateBlockGroupCount: Maybe<Scalars['Long']>;
  emltmp_TemplateBlockGroupList: Maybe<Array<Maybe<Emltmp_TemplateBlockGroup>>>;
  emltmp_TemplateBlockList: Maybe<Array<Maybe<Emltmp_TemplateBlock>>>;
  emltmp_TemplateGroupById: Maybe<Emltmp_TemplateGroup>;
  emltmp_TemplateGroupCount: Maybe<Scalars['Long']>;
  emltmp_TemplateGroupList: Maybe<Array<Maybe<Emltmp_TemplateGroup>>>;
  emltmp_TemplateReportById: Maybe<Emltmp_TemplateReport>;
  emltmp_TemplateReportCount: Maybe<Scalars['Long']>;
  emltmp_TemplateReportList: Maybe<Array<Maybe<Emltmp_TemplateReport>>>;
  entityMessages: Maybe<Array<Maybe<Gql_MessageDetail>>>;
  enumMessages: Maybe<Array<Maybe<Gql_MessageDetail>>>;
  kidHasTours: Scalars['Boolean'];
  permissions: Maybe<Sec_PermissionConfig>;
  profileCompleted: Scalars['Boolean'];
  report_ReportById: Maybe<Report_Report>;
  report_ReportCount: Maybe<Scalars['Long']>;
  report_ReportExecutionById: Maybe<Report_ReportExecution>;
  report_ReportExecutionCount: Maybe<Scalars['Long']>;
  report_ReportExecutionList: Maybe<Array<Maybe<Report_ReportExecution>>>;
  report_ReportGroupById: Maybe<Report_ReportGroup>;
  report_ReportGroupCount: Maybe<Scalars['Long']>;
  report_ReportGroupList: Maybe<Array<Maybe<Report_ReportGroup>>>;
  report_ReportList: Maybe<Array<Maybe<Report_Report>>>;
  report_ReportTemplateById: Maybe<Report_ReportTemplate>;
  report_ReportTemplateCount: Maybe<Scalars['Long']>;
  report_ReportTemplateList: Maybe<Array<Maybe<Report_ReportTemplate>>>;
  sec_ResourcePolicyEntityById: Maybe<Sec_ResourcePolicyEntity>;
  sec_ResourcePolicyEntityCount: Maybe<Scalars['Long']>;
  sec_ResourcePolicyEntityList: Maybe<Array<Maybe<Sec_ResourcePolicyEntity>>>;
  sec_ResourceRoleEntityById: Maybe<Sec_ResourceRoleEntity>;
  sec_ResourceRoleEntityCount: Maybe<Scalars['Long']>;
  sec_ResourceRoleEntityList: Maybe<Array<Maybe<Sec_ResourceRoleEntity>>>;
  sec_RoleAssignmentEntityById: Maybe<Sec_RoleAssignmentEntity>;
  sec_RoleAssignmentEntityCount: Maybe<Scalars['Long']>;
  sec_RoleAssignmentEntityList: Maybe<Array<Maybe<Sec_RoleAssignmentEntity>>>;
  sec_RowLevelPolicyEntityById: Maybe<Sec_RowLevelPolicyEntity>;
  sec_RowLevelPolicyEntityCount: Maybe<Scalars['Long']>;
  sec_RowLevelPolicyEntityList: Maybe<Array<Maybe<Sec_RowLevelPolicyEntity>>>;
  sec_RowLevelRoleEntityById: Maybe<Sec_RowLevelRoleEntity>;
  sec_RowLevelRoleEntityCount: Maybe<Scalars['Long']>;
  sec_RowLevelRoleEntityList: Maybe<Array<Maybe<Sec_RowLevelRoleEntity>>>;
  sec_UserSubstitutionEntityById: Maybe<Sec_UserSubstitutionEntity>;
  sec_UserSubstitutionEntityCount: Maybe<Scalars['Long']>;
  sec_UserSubstitutionEntityList: Maybe<Array<Maybe<Sec_UserSubstitutionEntity>>>;
  tokeByOrderId: Maybe<TokenPayload>;
  trustedCompleted: Scalars['Boolean'];
  trustedPerson: Maybe<TrustedPersonDto>;
  ui_FilterConfigurationById: Maybe<Ui_FilterConfiguration>;
  ui_FilterConfigurationCount: Maybe<Scalars['Long']>;
  ui_FilterConfigurationList: Maybe<Array<Maybe<Ui_FilterConfiguration>>>;
  ui_SettingById: Maybe<Ui_Setting>;
  ui_SettingCount: Maybe<Scalars['Long']>;
  ui_SettingList: Maybe<Array<Maybe<Ui_Setting>>>;
  ui_TablePresentationById: Maybe<Ui_TablePresentation>;
  ui_TablePresentationCount: Maybe<Scalars['Long']>;
  ui_TablePresentationList: Maybe<Array<Maybe<Ui_TablePresentation>>>;
  userData: Maybe<UserDto>;
  userInfo: Maybe<UserInfo>;
};


/** Query root */
export type QueryEmailNotificationByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmailNotificationCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_EmailNotificationFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmailNotificationListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_EmailNotificationFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_EmailNotificationOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmailTemplateByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmailTemplateCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_EmailTemplateFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmailTemplateListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_EmailTemplateFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_EmailTemplateOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryNotificationByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryNotificationCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_NotificationFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryNotificationListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_NotificationFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_NotificationOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleDiscountByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleDiscountCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_ScoutvilleDiscountFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleDiscountListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_ScoutvilleDiscountFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_ScoutvilleDiscountOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleDocumentByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleDocumentCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_ScoutvilleDocumentFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleDocumentListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_ScoutvilleDocumentFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_ScoutvilleDocumentOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleKidByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleKidCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_ScoutvilleKidFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleKidListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_ScoutvilleKidFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_ScoutvilleKidOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleKidParentRelationByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleKidParentRelationCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_ScoutvilleKidParentRelationFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleKidParentRelationListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_ScoutvilleKidParentRelationFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_ScoutvilleKidParentRelationOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleOrderByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleOrderCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_ScoutvilleOrderFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleOrderListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_ScoutvilleOrderFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_ScoutvilleOrderOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleOrderStatusByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleOrderStatusCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_ScoutvilleOrderStatusFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleOrderStatusListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_ScoutvilleOrderStatusFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_ScoutvilleOrderStatusOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleOrderTourByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleOrderTourCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_ScoutvilleOrderTourFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleOrderTourListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_ScoutvilleOrderTourFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_ScoutvilleOrderTourOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvillePaymentByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvillePaymentCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_ScoutvillePaymentFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvillePaymentListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_ScoutvillePaymentFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_ScoutvillePaymentOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleProductByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleProductCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_ScoutvilleProductFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleProductListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_ScoutvilleProductFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_ScoutvilleProductOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleTeamMemberByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleTeamMemberCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_ScoutvilleTeamMemberFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleTeamMemberListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_ScoutvilleTeamMemberFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_ScoutvilleTeamMemberOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleTourBlockByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleTourBlockCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_ScoutvilleTourBlockFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleTourBlockListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_ScoutvilleTourBlockFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_ScoutvilleTourBlockOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleTourByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleTourCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_ScoutvilleTourFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleTourListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_ScoutvilleTourFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_ScoutvilleTourOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleTrustedPersonByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleTrustedPersonCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_ScoutvilleTrustedPersonFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleTrustedPersonListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_ScoutvilleTrustedPersonFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_ScoutvilleTrustedPersonOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleUserByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleUserCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_ScoutvilleUserFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryScoutvilleUserListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_ScoutvilleUserFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_ScoutvilleUserOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryUiNotificationByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryUiNotificationCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_UiNotificationFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryUiNotificationListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_UiNotificationFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_UiNotificationOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryAudit_EntityLogByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryAudit_EntityLogCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Audit_EntityLogFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryAudit_EntityLogListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Audit_EntityLogFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_Audit_EntityLogOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryAudit_EntitySnapshotByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryAudit_EntitySnapshotCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Audit_EntitySnapshotFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryAudit_EntitySnapshotListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Audit_EntitySnapshotFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_Audit_EntitySnapshotOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryAudit_LoggedAttributeByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryAudit_LoggedAttributeCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Audit_LoggedAttributeFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryAudit_LoggedAttributeListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Audit_LoggedAttributeFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_Audit_LoggedAttributeOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryAudit_LoggedEntityByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryAudit_LoggedEntityCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Audit_LoggedEntityFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryAudit_LoggedEntityListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Audit_LoggedEntityFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_Audit_LoggedEntityOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryCreateOrderTourArgs = {
  request: InputMaybe<Inp_NewOrderTourRequest>;
};


/** Query root */
export type QueryCurrentRelationArgs = {
  kidId: Scalars['String'];
};


/** Query root */
export type QueryEmail_SendingAttachmentByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmail_SendingAttachmentCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Email_SendingAttachmentFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmail_SendingAttachmentListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Email_SendingAttachmentFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_Email_SendingAttachmentOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmail_SendingMessageByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmail_SendingMessageCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Email_SendingMessageFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmail_SendingMessageListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Email_SendingMessageFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_Email_SendingMessageOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmltmp_EmailTemplateAttachmentByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmltmp_EmailTemplateAttachmentCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Emltmp_EmailTemplateAttachmentFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmltmp_EmailTemplateAttachmentListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Emltmp_EmailTemplateAttachmentFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_Emltmp_EmailTemplateAttachmentOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmltmp_EmailTemplateByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmltmp_EmailTemplateCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Emltmp_EmailTemplateFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmltmp_EmailTemplateListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Emltmp_EmailTemplateFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_Emltmp_EmailTemplateOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmltmp_JsonEmailTemplateByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmltmp_JsonEmailTemplateCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Emltmp_JsonEmailTemplateFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmltmp_JsonEmailTemplateListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Emltmp_JsonEmailTemplateFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_Emltmp_JsonEmailTemplateOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmltmp_ParameterValueByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmltmp_ParameterValueCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Emltmp_ParameterValueFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmltmp_ParameterValueListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Emltmp_ParameterValueFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_Emltmp_ParameterValueOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmltmp_ReportEmailTemplateByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmltmp_ReportEmailTemplateCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Emltmp_ReportEmailTemplateFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmltmp_ReportEmailTemplateListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Emltmp_ReportEmailTemplateFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_Emltmp_ReportEmailTemplateOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmltmp_TemplateBlockByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmltmp_TemplateBlockCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Emltmp_TemplateBlockFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmltmp_TemplateBlockGroupByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmltmp_TemplateBlockGroupCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Emltmp_TemplateBlockGroupFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmltmp_TemplateBlockGroupListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Emltmp_TemplateBlockGroupFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_Emltmp_TemplateBlockGroupOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmltmp_TemplateBlockListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Emltmp_TemplateBlockFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_Emltmp_TemplateBlockOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmltmp_TemplateGroupByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmltmp_TemplateGroupCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Emltmp_TemplateGroupFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmltmp_TemplateGroupListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Emltmp_TemplateGroupFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_Emltmp_TemplateGroupOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmltmp_TemplateReportByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmltmp_TemplateReportCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Emltmp_TemplateReportFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEmltmp_TemplateReportListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Emltmp_TemplateReportFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_Emltmp_TemplateReportOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryEntityMessagesArgs = {
  className: InputMaybe<Scalars['String']>;
  locale: InputMaybe<Scalars['String']>;
};


/** Query root */
export type QueryEnumMessagesArgs = {
  className: InputMaybe<Scalars['String']>;
  locale: InputMaybe<Scalars['String']>;
};


/** Query root */
export type QueryKidHasToursArgs = {
  kidId: InputMaybe<Scalars['String']>;
};


/** Query root */
export type QueryReport_ReportByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryReport_ReportCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Report_ReportFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryReport_ReportExecutionByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryReport_ReportExecutionCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Report_ReportExecutionFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryReport_ReportExecutionListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Report_ReportExecutionFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_Report_ReportExecutionOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryReport_ReportGroupByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryReport_ReportGroupCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Report_ReportGroupFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryReport_ReportGroupListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Report_ReportGroupFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_Report_ReportGroupOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryReport_ReportListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Report_ReportFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_Report_ReportOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryReport_ReportTemplateByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryReport_ReportTemplateCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Report_ReportTemplateFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryReport_ReportTemplateListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Report_ReportTemplateFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_Report_ReportTemplateOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QuerySec_ResourcePolicyEntityByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QuerySec_ResourcePolicyEntityCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Sec_ResourcePolicyEntityFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QuerySec_ResourcePolicyEntityListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Sec_ResourcePolicyEntityFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_Sec_ResourcePolicyEntityOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QuerySec_ResourceRoleEntityByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QuerySec_ResourceRoleEntityCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Sec_ResourceRoleEntityFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QuerySec_ResourceRoleEntityListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Sec_ResourceRoleEntityFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_Sec_ResourceRoleEntityOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QuerySec_RoleAssignmentEntityByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QuerySec_RoleAssignmentEntityCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Sec_RoleAssignmentEntityFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QuerySec_RoleAssignmentEntityListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Sec_RoleAssignmentEntityFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_Sec_RoleAssignmentEntityOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QuerySec_RowLevelPolicyEntityByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QuerySec_RowLevelPolicyEntityCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Sec_RowLevelPolicyEntityFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QuerySec_RowLevelPolicyEntityListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Sec_RowLevelPolicyEntityFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_Sec_RowLevelPolicyEntityOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QuerySec_RowLevelRoleEntityByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QuerySec_RowLevelRoleEntityCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Sec_RowLevelRoleEntityFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QuerySec_RowLevelRoleEntityListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Sec_RowLevelRoleEntityFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_Sec_RowLevelRoleEntityOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QuerySec_UserSubstitutionEntityByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QuerySec_UserSubstitutionEntityCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Sec_UserSubstitutionEntityFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QuerySec_UserSubstitutionEntityListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Sec_UserSubstitutionEntityFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_Sec_UserSubstitutionEntityOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryTokeByOrderIdArgs = {
  orderId: InputMaybe<Scalars['UUID']>;
};


/** Query root */
export type QueryUi_FilterConfigurationByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryUi_FilterConfigurationCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Ui_FilterConfigurationFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryUi_FilterConfigurationListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Ui_FilterConfigurationFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_Ui_FilterConfigurationOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryUi_SettingByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryUi_SettingCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Ui_SettingFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryUi_SettingListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Ui_SettingFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_Ui_SettingOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryUi_TablePresentationByIdArgs = {
  id: Scalars['String'];
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryUi_TablePresentationCountArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Ui_TablePresentationFilterCondition>>>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};


/** Query root */
export type QueryUi_TablePresentationListArgs = {
  filter: InputMaybe<Array<InputMaybe<Inp_Ui_TablePresentationFilterCondition>>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_Ui_TablePresentationOrderBy>;
  softDeletion: InputMaybe<Scalars['Boolean']>;
};

export enum Relation {
  Father = 'FATHER',
  Friend = 'FRIEND',
  Guardian = 'GUARDIAN',
  Mother = 'MOTHER',
  Relative = 'RELATIVE'
}

export enum ReportOutputType {
  Chart = 'CHART',
  Csv = 'CSV',
  Custom = 'CUSTOM',
  Doc = 'DOC',
  Docx = 'DOCX',
  Html = 'HTML',
  Pdf = 'PDF',
  PivotTable = 'PIVOT_TABLE',
  Table = 'TABLE',
  Xls = 'XLS',
  Xlsx = 'XLSX'
}

export enum ReportType {
  ListPrintForm = 'LIST_PRINT_FORM',
  PrintForm = 'PRINT_FORM',
  Simple = 'SIMPLE'
}

export enum RowLevelPolicyAction {
  Create = 'CREATE',
  Delete = 'DELETE',
  Read = 'READ',
  Update = 'UPDATE'
}

export enum RowLevelPolicyType {
  Jpql = 'JPQL',
  Predicate = 'PREDICATE'
}

export type ScoutvilleDiscount = {
  __typename?: 'ScoutvilleDiscount';
  _instanceName: Maybe<Scalars['String']>;
  canBeUsed: Maybe<Scalars['Long']>;
  code: Maybe<Scalars['String']>;
  createdBy: Maybe<Scalars['String']>;
  createdDate: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<Scalars['String']>;
  deletedDate: Maybe<Scalars['DateTime']>;
  discountType: Maybe<DiscountType>;
  endDate: Maybe<Scalars['Date']>;
  id: Maybe<Scalars['Int']>;
  isActive: Maybe<Scalars['Boolean']>;
  kid: Maybe<ScoutvilleKid>;
  lastModifiedBy: Maybe<Scalars['String']>;
  lastModifiedDate: Maybe<Scalars['DateTime']>;
  name: Maybe<Scalars['String']>;
  orders: Maybe<Array<Maybe<ScoutvilleOrder>>>;
  product: Maybe<ScoutvilleProduct>;
  startDate: Maybe<Scalars['Date']>;
  total: Maybe<Scalars['Long']>;
  usedCount: Maybe<Scalars['Int']>;
  user: Maybe<ScoutvilleUser>;
  version: Maybe<Scalars['Int']>;
};

export type ScoutvilleDocument = {
  __typename?: 'ScoutvilleDocument';
  _instanceName: Maybe<Scalars['String']>;
  document: Maybe<Scalars['Upload']>;
  id: Maybe<Scalars['UUID']>;
  user: Maybe<ScoutvilleUser>;
};

export type ScoutvilleKid = {
  __typename?: 'ScoutvilleKid';
  _instanceName: Maybe<Scalars['String']>;
  bdCertificate: Maybe<Scalars['Upload']>;
  bdCertificateSerial: Maybe<Scalars['String']>;
  birthDate: Maybe<Scalars['Date']>;
  canSwim: Maybe<CanSwim>;
  createdBy: Maybe<Scalars['String']>;
  createdDate: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<Scalars['String']>;
  deletedDate: Maybe<Scalars['DateTime']>;
  firstName: Maybe<Scalars['String']>;
  foodPreference: Maybe<Scalars['String']>;
  gender: Maybe<Gender>;
  healthInfo: Maybe<Scalars['String']>;
  hobbyInfo: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  isDesMade: Maybe<Scalars['String']>;
  isFirsTour: Maybe<Scalars['String']>;
  isSawCamps: Maybe<Scalars['String']>;
  lastModifiedBy: Maybe<Scalars['String']>;
  lastModifiedDate: Maybe<Scalars['DateTime']>;
  lastName: Maybe<Scalars['String']>;
  relations: Maybe<Array<Maybe<ScoutvilleKidParentRelation>>>;
  secondName: Maybe<Scalars['String']>;
  shortSize: Maybe<TShortSize>;
  tours: Maybe<Array<Maybe<ScoutvilleOrderTour>>>;
  version: Maybe<Scalars['Int']>;
};

export type ScoutvilleKidParentRelation = {
  __typename?: 'ScoutvilleKidParentRelation';
  _instanceName: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  kid: Maybe<ScoutvilleKid>;
  parent: Maybe<ScoutvilleUser>;
  relation: Maybe<Relation>;
};

export type ScoutvilleOrder = {
  __typename?: 'ScoutvilleOrder';
  _instanceName: Maybe<Scalars['String']>;
  basePrice: Maybe<Scalars['Long']>;
  confirmed: Maybe<Scalars['Boolean']>;
  createdBy: Maybe<Scalars['String']>;
  createdDate: Maybe<Scalars['DateTime']>;
  currentStatus: Maybe<OrderStatusType>;
  customer: Maybe<ScoutvilleUser>;
  deletedBy: Maybe<Scalars['String']>;
  deletedDate: Maybe<Scalars['DateTime']>;
  discount: Maybe<ScoutvilleDiscount>;
  discountPrice: Maybe<Scalars['Long']>;
  finalPrice: Maybe<Scalars['Long']>;
  id: Maybe<Scalars['UUID']>;
  isPaid: Maybe<Scalars['Boolean']>;
  isPartialPaid: Maybe<Scalars['Boolean']>;
  lastModifiedBy: Maybe<Scalars['String']>;
  lastModifiedDate: Maybe<Scalars['DateTime']>;
  leftToPay: Maybe<Scalars['Long']>;
  paidAmount: Maybe<Scalars['Long']>;
  payments: Maybe<Array<Maybe<ScoutvillePayment>>>;
  products: Maybe<Array<Maybe<ScoutvilleProduct>>>;
  repayWith: Maybe<AcquiringProvider>;
  responsible: Maybe<ScoutvilleUser>;
  statuses: Maybe<Array<Maybe<ScoutvilleOrderStatus>>>;
  version: Maybe<Scalars['Int']>;
};

export type ScoutvilleOrderStatus = {
  __typename?: 'ScoutvilleOrderStatus';
  _instanceName: Maybe<Scalars['String']>;
  createdBy: Maybe<Scalars['String']>;
  createdDate: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<Scalars['String']>;
  deletedDate: Maybe<Scalars['DateTime']>;
  description: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Long']>;
  isDone: Maybe<Scalars['Boolean']>;
  lastModifiedBy: Maybe<Scalars['String']>;
  lastModifiedDate: Maybe<Scalars['DateTime']>;
  link: Maybe<Scalars['String']>;
  order: Maybe<ScoutvilleOrder>;
  statusType: Maybe<OrderStatusType>;
  version: Maybe<Scalars['Int']>;
};

export type ScoutvilleOrderTour = {
  __typename?: 'ScoutvilleOrderTour';
  _instanceName: Maybe<Scalars['String']>;
  additionalInfo: Maybe<Scalars['String']>;
  basePrice: Maybe<Scalars['Long']>;
  confirmed: Maybe<Scalars['Boolean']>;
  createdBy: Maybe<Scalars['String']>;
  createdDate: Maybe<Scalars['DateTime']>;
  currentStatus: Maybe<OrderStatusType>;
  customer: Maybe<ScoutvilleUser>;
  deletedBy: Maybe<Scalars['String']>;
  deletedDate: Maybe<Scalars['DateTime']>;
  discount: Maybe<ScoutvilleDiscount>;
  discountPrice: Maybe<Scalars['Long']>;
  finalPrice: Maybe<Scalars['Long']>;
  friends: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  isBooking: Maybe<Scalars['Boolean']>;
  isPaid: Maybe<Scalars['Boolean']>;
  isPartialPaid: Maybe<Scalars['Boolean']>;
  kid: Maybe<ScoutvilleKid>;
  lastModifiedBy: Maybe<Scalars['String']>;
  lastModifiedDate: Maybe<Scalars['DateTime']>;
  leftToPay: Maybe<Scalars['Long']>;
  paidAmount: Maybe<Scalars['Long']>;
  payments: Maybe<Array<Maybe<ScoutvillePayment>>>;
  products: Maybe<Array<Maybe<ScoutvilleProduct>>>;
  repayWith: Maybe<AcquiringProvider>;
  responsible: Maybe<ScoutvilleUser>;
  statuses: Maybe<Array<Maybe<ScoutvilleOrderStatus>>>;
  tour: Maybe<ScoutvilleTour>;
  version: Maybe<Scalars['Int']>;
};

export type ScoutvillePayment = {
  __typename?: 'ScoutvillePayment';
  _instanceName: Maybe<Scalars['String']>;
  acquiringProvider: Maybe<AcquiringProvider>;
  amount: Maybe<Scalars['Long']>;
  createdBy: Maybe<Scalars['String']>;
  createdDate: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<Scalars['String']>;
  deletedDate: Maybe<Scalars['DateTime']>;
  id: Maybe<Scalars['UUID']>;
  lastModifiedBy: Maybe<Scalars['String']>;
  lastModifiedDate: Maybe<Scalars['DateTime']>;
  order: Maybe<ScoutvilleOrder>;
  paidByType: Maybe<PaymentType>;
  paidByUser: Maybe<ScoutvilleUser>;
  paymentSystem: Maybe<Scalars['String']>;
  version: Maybe<Scalars['Int']>;
};

export type ScoutvilleProduct = {
  __typename?: 'ScoutvilleProduct';
  _instanceName: Maybe<Scalars['String']>;
  createdBy: Maybe<Scalars['String']>;
  createdDate: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<Scalars['String']>;
  deletedDate: Maybe<Scalars['DateTime']>;
  description: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  image: Maybe<Scalars['Upload']>;
  lastModifiedBy: Maybe<Scalars['String']>;
  lastModifiedDate: Maybe<Scalars['DateTime']>;
  name: Maybe<Scalars['String']>;
  orders: Maybe<Array<Maybe<ScoutvilleOrder>>>;
  payWith: Maybe<AcquiringProvider>;
  price: Maybe<Scalars['Long']>;
  version: Maybe<Scalars['Int']>;
};

export type ScoutvilleTeamMember = {
  __typename?: 'ScoutvilleTeamMember';
  _instanceName: Maybe<Scalars['String']>;
  createdBy: Maybe<Scalars['String']>;
  createdDate: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<Scalars['String']>;
  deletedDate: Maybe<Scalars['DateTime']>;
  description: Maybe<Scalars['String']>;
  fullName: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  image: Maybe<Scalars['Upload']>;
  lastModifiedBy: Maybe<Scalars['String']>;
  lastModifiedDate: Maybe<Scalars['DateTime']>;
  ordinal: Maybe<Scalars['Int']>;
  position: Maybe<Scalars['String']>;
  version: Maybe<Scalars['Int']>;
};

export type ScoutvilleTour = {
  __typename?: 'ScoutvilleTour';
  _instanceName: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  archived: Maybe<Scalars['Boolean']>;
  availableForBooking: Maybe<Scalars['Boolean']>;
  badgeInfo: Maybe<Scalars['String']>;
  blocks: Maybe<Array<Maybe<ScoutvilleTourBlock>>>;
  createdBy: Maybe<Scalars['String']>;
  createdDate: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<Scalars['String']>;
  deletedDate: Maybe<Scalars['DateTime']>;
  description: Maybe<Scalars['String']>;
  endDate: Maybe<Scalars['Date']>;
  id: Maybe<Scalars['UUID']>;
  image: Maybe<Scalars['Upload']>;
  juniorTicketsSold: Maybe<Scalars['Int']>;
  kidsTicketsSold: Maybe<Scalars['Int']>;
  lastModifiedBy: Maybe<Scalars['String']>;
  lastModifiedDate: Maybe<Scalars['DateTime']>;
  maxDateOfBirth: Maybe<Scalars['Date']>;
  minDateOfBirth: Maybe<Scalars['Date']>;
  name: Maybe<Scalars['String']>;
  orders: Maybe<Array<Maybe<ScoutvilleOrder>>>;
  payWith: Maybe<AcquiringProvider>;
  price: Maybe<Scalars['Long']>;
  startDate: Maybe<Scalars['Date']>;
  teensTicketsSold: Maybe<Scalars['Int']>;
  version: Maybe<Scalars['Int']>;
};

export type ScoutvilleTourBlock = {
  __typename?: 'ScoutvilleTourBlock';
  _instanceName: Maybe<Scalars['String']>;
  blockedFor: Maybe<GenderBlock>;
  blockedYear: Maybe<Scalars['Int']>;
  createdBy: Maybe<Scalars['String']>;
  createdDate: Maybe<Scalars['DateTime']>;
  id: Maybe<Scalars['UUID']>;
  lastModifiedBy: Maybe<Scalars['String']>;
  lastModifiedDate: Maybe<Scalars['Date']>;
  tour: Maybe<ScoutvilleTour>;
};

export type ScoutvilleTrustedPerson = {
  __typename?: 'ScoutvilleTrustedPerson';
  _instanceName: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  lastName: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  relation: Maybe<Relation>;
  secondName: Maybe<Scalars['String']>;
  user: Maybe<ScoutvilleUser>;
};

export type ScoutvilleUser = {
  __typename?: 'ScoutvilleUser';
  _instanceName: Maybe<Scalars['String']>;
  active: Maybe<Scalars['Boolean']>;
  documents: Maybe<Array<Maybe<ScoutvilleDocument>>>;
  emailActivationToken: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  lastName: Maybe<Scalars['String']>;
  needsEmailActivation: Maybe<Scalars['Boolean']>;
  needsPhoneActivation: Maybe<Scalars['Boolean']>;
  passportSerial: Maybe<Scalars['String']>;
  password: Maybe<Scalars['String']>;
  passwordResetToken: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  phoneActivationToken: Maybe<Scalars['String']>;
  relations: Maybe<Array<Maybe<ScoutvilleKidParentRelation>>>;
  secondName: Maybe<Scalars['String']>;
  taxSerial: Maybe<Scalars['String']>;
  timeZoneId: Maybe<Scalars['String']>;
  trustedPerson: Maybe<ScoutvilleTrustedPerson>;
  username: Maybe<Scalars['String']>;
  version: Maybe<Scalars['Int']>;
};

export enum SendingStatus {
  NotSent = 'NOT_SENT',
  Queue = 'QUEUE',
  Sending = 'SENDING',
  Sent = 'SENT'
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum TShortSize {
  EightToNine = 'EIGHT_TO_NINE',
  L = 'L',
  M = 'M',
  S = 'S',
  SixToSeven = 'SIX_TO_SEVEN',
  TenToEleven = 'TEN_TO_ELEVEN',
  TwelveToFourteen = 'TWELVE_TO_FOURTEEN',
  Xl = 'XL'
}

export enum TemplateType {
  Json = 'JSON',
  Report = 'REPORT'
}

export type TokenPayload = {
  __typename?: 'TokenPayload';
  data: Maybe<Scalars['String']>;
  provider: Maybe<AcquiringProvider>;
  signature: Maybe<Scalars['String']>;
  token: Maybe<Scalars['String']>;
};

export type TrustedPersonDto = {
  __typename?: 'TrustedPersonDto';
  _instanceName: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  lastName: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  relation: Maybe<Relation>;
  secondName: Maybe<Scalars['String']>;
};

export enum Type {
  Create = 'CREATE',
  Delete = 'DELETE',
  Modify = 'MODIFY',
  Restore = 'RESTORE'
}

export type UiNotification = {
  __typename?: 'UiNotification';
  _instanceName: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  isRead: Maybe<Scalars['Boolean']>;
  lastModifiedDate: Maybe<Scalars['DateTime']>;
  message: Maybe<Scalars['String']>;
  recipient: Maybe<ScoutvilleUser>;
  sender: Maybe<ScoutvilleUser>;
  title: Maybe<Scalars['String']>;
};

export type UserDto = {
  __typename?: 'UserDto';
  _instanceName: Maybe<Scalars['String']>;
  docFive: Maybe<Scalars['Upload']>;
  docFour: Maybe<Scalars['Upload']>;
  docOne: Maybe<Scalars['Upload']>;
  docSix: Maybe<Scalars['Upload']>;
  docTree: Maybe<Scalars['Upload']>;
  docTwo: Maybe<Scalars['Upload']>;
  email: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  lastName: Maybe<Scalars['String']>;
  passportSerial: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  secondName: Maybe<Scalars['String']>;
  taxSerial: Maybe<Scalars['String']>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  locale: Maybe<Scalars['String']>;
  username: Maybe<Scalars['String']>;
};

export type Audit_EntityLog = {
  __typename?: 'audit_EntityLog';
  _instanceName: Maybe<Scalars['String']>;
  attributes: Maybe<Array<Maybe<Scalars['String']>>>;
  changes: Maybe<Scalars['String']>;
  createTs: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  entity: Maybe<Scalars['String']>;
  entityInstanceName: Maybe<Scalars['String']>;
  entityRef: Maybe<Scalars['String']>;
  eventTs: Maybe<Scalars['DateTime']>;
  id: Maybe<Scalars['UUID']>;
  sysTenantId: Maybe<Scalars['String']>;
  type: Maybe<Type>;
  username: Maybe<Scalars['String']>;
};

export type Audit_EntitySnapshot = {
  __typename?: 'audit_EntitySnapshot';
  _instanceName: Maybe<Scalars['String']>;
  authorUsername: Maybe<Scalars['String']>;
  createdBy: Maybe<Scalars['String']>;
  createdDate: Maybe<Scalars['DateTime']>;
  entity: Maybe<Scalars['String']>;
  entityMetaClass: Maybe<Scalars['String']>;
  fetchPlanXml: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  snapshotDate: Maybe<Scalars['DateTime']>;
  snapshotXml: Maybe<Scalars['String']>;
  sysTenantId: Maybe<Scalars['String']>;
};

export type Audit_LoggedAttribute = {
  __typename?: 'audit_LoggedAttribute';
  _instanceName: Maybe<Scalars['String']>;
  createTs: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  entity: Maybe<Audit_LoggedEntity>;
  id: Maybe<Scalars['UUID']>;
  name: Maybe<Scalars['String']>;
};

export type Audit_LoggedEntity = {
  __typename?: 'audit_LoggedEntity';
  _instanceName: Maybe<Scalars['String']>;
  attributes: Maybe<Array<Maybe<Audit_LoggedAttribute>>>;
  auto: Maybe<Scalars['Boolean']>;
  createTs: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  manual: Maybe<Scalars['Boolean']>;
  name: Maybe<Scalars['String']>;
};

export type Email_SendingAttachment = {
  __typename?: 'email_SendingAttachment';
  _instanceName: Maybe<Scalars['String']>;
  content: Maybe<Scalars['String']>;
  contentFile: Maybe<Scalars['Upload']>;
  contentId: Maybe<Scalars['String']>;
  createTs: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  deleteTs: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<Scalars['String']>;
  disposition: Maybe<Scalars['String']>;
  encoding: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  message: Maybe<Email_SendingMessage>;
  name: Maybe<Scalars['String']>;
  sysTenantId: Maybe<Scalars['String']>;
  updateTs: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['String']>;
  version: Maybe<Scalars['Int']>;
};

export type Email_SendingMessage = {
  __typename?: 'email_SendingMessage';
  _instanceName: Maybe<Scalars['String']>;
  address: Maybe<Scalars['String']>;
  attachments: Maybe<Array<Maybe<Email_SendingAttachment>>>;
  attachmentsName: Maybe<Scalars['String']>;
  attemptsLimit: Maybe<Scalars['Int']>;
  attemptsMade: Maybe<Scalars['Int']>;
  bcc: Maybe<Scalars['String']>;
  bodyContentType: Maybe<Scalars['String']>;
  cc: Maybe<Scalars['String']>;
  contentText: Maybe<Scalars['String']>;
  contentTextFile: Maybe<Scalars['Upload']>;
  createTs: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  dateSent: Maybe<Scalars['DateTime']>;
  deadline: Maybe<Scalars['DateTime']>;
  deleteTs: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<Scalars['String']>;
  from: Maybe<Scalars['String']>;
  headers: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  important: Maybe<Scalars['Boolean']>;
  status: Maybe<SendingStatus>;
  subject: Maybe<Scalars['String']>;
  sysTenantId: Maybe<Scalars['String']>;
  updateTs: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['String']>;
  version: Maybe<Scalars['Int']>;
};

export type Emltmp_EmailTemplate = {
  __typename?: 'emltmp_EmailTemplate';
  _instanceName: Maybe<Scalars['String']>;
  attachedFiles: Maybe<Array<Maybe<Emltmp_EmailTemplateAttachment>>>;
  attachedTemplateReports: Maybe<Array<Maybe<Emltmp_TemplateReport>>>;
  bcc: Maybe<Scalars['String']>;
  cc: Maybe<Scalars['String']>;
  code: Maybe<Scalars['String']>;
  createTs: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  deleteTs: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<Scalars['String']>;
  from: Maybe<Scalars['String']>;
  group: Maybe<Emltmp_TemplateGroup>;
  id: Maybe<Scalars['UUID']>;
  name: Maybe<Scalars['String']>;
  subject: Maybe<Scalars['String']>;
  to: Maybe<Scalars['String']>;
  type: Maybe<TemplateType>;
  updateTs: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['String']>;
  useReportSubject: Maybe<Scalars['Boolean']>;
  version: Maybe<Scalars['Int']>;
};

export type Emltmp_EmailTemplateAttachment = {
  __typename?: 'emltmp_EmailTemplateAttachment';
  _instanceName: Maybe<Scalars['String']>;
  contentFile: Maybe<Scalars['Upload']>;
  createTs: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  deleteTs: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<Scalars['String']>;
  emailTemplate: Maybe<Emltmp_EmailTemplate>;
  id: Maybe<Scalars['UUID']>;
  name: Maybe<Scalars['String']>;
  updateTs: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['String']>;
  version: Maybe<Scalars['Int']>;
};

export type Emltmp_JsonEmailTemplate = {
  __typename?: 'emltmp_JsonEmailTemplate';
  _instanceName: Maybe<Scalars['String']>;
  attachedFiles: Maybe<Array<Maybe<Emltmp_EmailTemplateAttachment>>>;
  attachedTemplateReports: Maybe<Array<Maybe<Emltmp_TemplateReport>>>;
  bcc: Maybe<Scalars['String']>;
  cc: Maybe<Scalars['String']>;
  code: Maybe<Scalars['String']>;
  createTs: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  deleteTs: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<Scalars['String']>;
  from: Maybe<Scalars['String']>;
  group: Maybe<Emltmp_TemplateGroup>;
  html: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  name: Maybe<Scalars['String']>;
  reportJson: Maybe<Scalars['String']>;
  subject: Maybe<Scalars['String']>;
  to: Maybe<Scalars['String']>;
  type: Maybe<TemplateType>;
  updateTs: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['String']>;
  useReportSubject: Maybe<Scalars['Boolean']>;
  version: Maybe<Scalars['Int']>;
};

export type Emltmp_ParameterValue = {
  __typename?: 'emltmp_ParameterValue';
  _instanceName: Maybe<Scalars['String']>;
  alias: Maybe<Scalars['String']>;
  createTs: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  defaultValue: Maybe<Scalars['String']>;
  deleteTs: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  parameterType: Maybe<ParameterType>;
  templateReport: Maybe<Emltmp_TemplateReport>;
  updateTs: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['String']>;
  version: Maybe<Scalars['Int']>;
};

export type Emltmp_ReportEmailTemplate = {
  __typename?: 'emltmp_ReportEmailTemplate';
  _instanceName: Maybe<Scalars['String']>;
  attachedFiles: Maybe<Array<Maybe<Emltmp_EmailTemplateAttachment>>>;
  attachedTemplateReports: Maybe<Array<Maybe<Emltmp_TemplateReport>>>;
  bcc: Maybe<Scalars['String']>;
  cc: Maybe<Scalars['String']>;
  code: Maybe<Scalars['String']>;
  createTs: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  deleteTs: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<Scalars['String']>;
  emailBodyReport: Maybe<Emltmp_TemplateReport>;
  from: Maybe<Scalars['String']>;
  group: Maybe<Emltmp_TemplateGroup>;
  id: Maybe<Scalars['UUID']>;
  name: Maybe<Scalars['String']>;
  subject: Maybe<Scalars['String']>;
  to: Maybe<Scalars['String']>;
  type: Maybe<TemplateType>;
  updateTs: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['String']>;
  useReportSubject: Maybe<Scalars['Boolean']>;
  version: Maybe<Scalars['Int']>;
};

export type Emltmp_TemplateBlock = {
  __typename?: 'emltmp_TemplateBlock';
  _instanceName: Maybe<Scalars['String']>;
  category: Maybe<Emltmp_TemplateBlockGroup>;
  content: Maybe<Scalars['String']>;
  createTs: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  deleteTs: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<Scalars['String']>;
  icon: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  label: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  updateTs: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['String']>;
  version: Maybe<Scalars['Int']>;
};

export type Emltmp_TemplateBlockGroup = {
  __typename?: 'emltmp_TemplateBlockGroup';
  _instanceName: Maybe<Scalars['String']>;
  createTs: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  deleteTs: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  name: Maybe<Scalars['String']>;
  updateTs: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['String']>;
  version: Maybe<Scalars['Int']>;
};

export type Emltmp_TemplateGroup = {
  __typename?: 'emltmp_TemplateGroup';
  _instanceName: Maybe<Scalars['String']>;
  createTs: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  deleteTs: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  name: Maybe<Scalars['String']>;
  updateTs: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['String']>;
  version: Maybe<Scalars['Int']>;
};

export type Emltmp_TemplateReport = {
  __typename?: 'emltmp_TemplateReport';
  _instanceName: Maybe<Scalars['String']>;
  createTs: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  deleteTs: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<Scalars['String']>;
  emailTemplate: Maybe<Emltmp_EmailTemplate>;
  id: Maybe<Scalars['UUID']>;
  name: Maybe<Scalars['String']>;
  parameterValues: Maybe<Array<Maybe<Emltmp_ParameterValue>>>;
  report: Maybe<Report_Report>;
  updateTs: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['String']>;
  version: Maybe<Scalars['Int']>;
};

export type Gql_MessageDetail = {
  __typename?: 'gql_MessageDetail';
  key: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

export type Inp_AcquiringProviderFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_AcquiringProviderFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_AcquiringProviderFilterCondition>>>;
  /** equals */
  _eq: InputMaybe<AcquiringProvider>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<AcquiringProvider>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** not equals */
  _neq: InputMaybe<AcquiringProvider>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<AcquiringProvider>>>;
};

/** expression to compare columns of type BigDecimal. All fields are combined with logical 'AND' */
export type Inp_BigDecimalFilterCondition = {
  /** equals */
  _eq: InputMaybe<Scalars['BigDecimal']>;
  /** greater than */
  _gt: InputMaybe<Scalars['BigDecimal']>;
  /** greater than or equals */
  _gte: InputMaybe<Scalars['BigDecimal']>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<Scalars['BigDecimal']>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** less that */
  _lt: InputMaybe<Scalars['BigDecimal']>;
  /** less than or equals */
  _lte: InputMaybe<Scalars['BigDecimal']>;
  /** not equals */
  _neq: InputMaybe<Scalars['BigDecimal']>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<Scalars['BigDecimal']>>>;
};

/** expression to compare columns of type BigInteger. All fields are combined with logical 'AND' */
export type Inp_BigIntegerFilterCondition = {
  /** equals */
  _eq: InputMaybe<Scalars['BigInteger']>;
  /** greater than */
  _gt: InputMaybe<Scalars['BigInteger']>;
  /** greater than or equals */
  _gte: InputMaybe<Scalars['BigInteger']>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<Scalars['BigInteger']>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** less that */
  _lt: InputMaybe<Scalars['BigInteger']>;
  /** less than or equals */
  _lte: InputMaybe<Scalars['BigInteger']>;
  /** not equals */
  _neq: InputMaybe<Scalars['BigInteger']>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<Scalars['BigInteger']>>>;
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND' */
export type Inp_BooleanFilterCondition = {
  /** equals */
  _eq: InputMaybe<Scalars['Boolean']>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** not equals */
  _neq: InputMaybe<Scalars['Boolean']>;
};

export type Inp_CanSwimFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_CanSwimFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_CanSwimFilterCondition>>>;
  /** equals */
  _eq: InputMaybe<CanSwim>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<CanSwim>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** not equals */
  _neq: InputMaybe<CanSwim>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<CanSwim>>>;
};

/** expression to compare columns of type Char. All fields are combined with logical 'AND' */
export type Inp_CharFilterCondition = {
  /** equals */
  _eq: InputMaybe<Scalars['Char']>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<Scalars['Char']>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** not equals */
  _neq: InputMaybe<Scalars['Char']>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<Scalars['Char']>>>;
};

export type Inp_CustomTemplateDefinedByFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_CustomTemplateDefinedByFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_CustomTemplateDefinedByFilterCondition>>>;
  /** equals */
  _eq: InputMaybe<CustomTemplateDefinedBy>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<CustomTemplateDefinedBy>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** not equals */
  _neq: InputMaybe<CustomTemplateDefinedBy>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<CustomTemplateDefinedBy>>>;
};

/** expression to compare columns of type Date. All fields are combined with logical 'AND' */
export type Inp_DateFilterCondition = {
  /** equals */
  _eq: InputMaybe<Scalars['Date']>;
  /** greater than */
  _gt: InputMaybe<Scalars['Date']>;
  /** greater than or equals */
  _gte: InputMaybe<Scalars['Date']>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** less that */
  _lt: InputMaybe<Scalars['Date']>;
  /** less than or equals */
  _lte: InputMaybe<Scalars['Date']>;
  /** not equals */
  _neq: InputMaybe<Scalars['Date']>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
};

/** expression to compare columns of type DateTime. All fields are combined with logical 'AND' */
export type Inp_DateTimeFilterCondition = {
  /** equals */
  _eq: InputMaybe<Scalars['DateTime']>;
  /** greater than */
  _gt: InputMaybe<Scalars['DateTime']>;
  /** greater than or equals */
  _gte: InputMaybe<Scalars['DateTime']>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** less that */
  _lt: InputMaybe<Scalars['DateTime']>;
  /** less than or equals */
  _lte: InputMaybe<Scalars['DateTime']>;
  /** not equals */
  _neq: InputMaybe<Scalars['DateTime']>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

export type Inp_DiscountTypeFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_DiscountTypeFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_DiscountTypeFilterCondition>>>;
  /** equals */
  _eq: InputMaybe<DiscountType>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<DiscountType>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** not equals */
  _neq: InputMaybe<DiscountType>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<DiscountType>>>;
};

export type Inp_EmailNotification = {
  id: InputMaybe<Scalars['UUID']>;
  isRead: InputMaybe<Scalars['Boolean']>;
  lastModifiedDate: InputMaybe<Scalars['DateTime']>;
  recipient: InputMaybe<Inp_ScoutvilleUser>;
  sender: InputMaybe<Inp_ScoutvilleUser>;
  template: InputMaybe<Inp_EmailTemplate>;
  title: InputMaybe<Scalars['String']>;
};

export type Inp_EmailNotificationFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_EmailNotificationFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_EmailNotificationFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  isRead: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  lastModifiedDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  recipient: InputMaybe<Array<InputMaybe<Inp_ScoutvilleUserFilterCondition>>>;
  sender: InputMaybe<Array<InputMaybe<Inp_ScoutvilleUserFilterCondition>>>;
  template: InputMaybe<Array<InputMaybe<Inp_EmailTemplateFilterCondition>>>;
  title: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
};

export type Inp_EmailNotificationOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  isRead: InputMaybe<SortOrder>;
  lastModifiedDate: InputMaybe<SortOrder>;
  recipient: InputMaybe<Inp_ScoutvilleUserOrderBy>;
  sender: InputMaybe<Inp_ScoutvilleUserOrderBy>;
  template: InputMaybe<Inp_EmailTemplateOrderBy>;
  title: InputMaybe<SortOrder>;
};

export type Inp_EmailTemplate = {
  emailTemplateName: InputMaybe<Scalars['String']>;
  html: InputMaybe<Scalars['String']>;
  id: InputMaybe<Scalars['UUID']>;
  version: InputMaybe<Scalars['Int']>;
};

export type Inp_EmailTemplateFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_EmailTemplateFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_EmailTemplateFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  emailTemplateName: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  html: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  version: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
};

export type Inp_EmailTemplateOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  emailTemplateName: InputMaybe<SortOrder>;
  html: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  version: InputMaybe<SortOrder>;
};

/** expression to compare columns of type Float. All fields are combined with logical 'AND' */
export type Inp_FloatFilterCondition = {
  /** equals */
  _eq: InputMaybe<Scalars['Float']>;
  /** greater than */
  _gt: InputMaybe<Scalars['Float']>;
  /** greater than or equals */
  _gte: InputMaybe<Scalars['Float']>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** less that */
  _lt: InputMaybe<Scalars['Float']>;
  /** less than or equals */
  _lte: InputMaybe<Scalars['Float']>;
  /** not equals */
  _neq: InputMaybe<Scalars['Float']>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
};

export type Inp_GenderBlockFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_GenderBlockFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_GenderBlockFilterCondition>>>;
  /** equals */
  _eq: InputMaybe<GenderBlock>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<GenderBlock>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** not equals */
  _neq: InputMaybe<GenderBlock>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<GenderBlock>>>;
};

export type Inp_GenderFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_GenderFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_GenderFilterCondition>>>;
  /** equals */
  _eq: InputMaybe<Gender>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<Gender>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** not equals */
  _neq: InputMaybe<Gender>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<Gender>>>;
};

/** expression to compare columns of type Int. All fields are combined with logical 'AND' */
export type Inp_IntFilterCondition = {
  /** equals */
  _eq: InputMaybe<Scalars['Int']>;
  /** greater than */
  _gt: InputMaybe<Scalars['Int']>;
  /** greater than or equals */
  _gte: InputMaybe<Scalars['Int']>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** less that */
  _lt: InputMaybe<Scalars['Int']>;
  /** less than or equals */
  _lte: InputMaybe<Scalars['Int']>;
  /** not equals */
  _neq: InputMaybe<Scalars['Int']>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type Inp_KidDto = {
  bdCertificate: InputMaybe<Scalars['Upload']>;
  bdCertificateSerial: InputMaybe<Scalars['String']>;
  birthDate: InputMaybe<Scalars['Date']>;
  canSwim: InputMaybe<CanSwim>;
  firstName: InputMaybe<Scalars['String']>;
  foodPreference: InputMaybe<Scalars['String']>;
  gender: InputMaybe<Gender>;
  healthInfo: InputMaybe<Scalars['String']>;
  hobbyInfo: InputMaybe<Scalars['String']>;
  id: InputMaybe<Scalars['UUID']>;
  isDesMade: InputMaybe<Scalars['String']>;
  isFirsTour: InputMaybe<Scalars['String']>;
  isSawCamps: InputMaybe<Scalars['String']>;
  lastName: InputMaybe<Scalars['String']>;
  relation: InputMaybe<Relation>;
  secondName: InputMaybe<Scalars['String']>;
  shortSize: InputMaybe<TShortSize>;
};

/** expression to compare columns of type LocalDate. All fields are combined with logical 'AND' */
export type Inp_LocalDateFilterCondition = {
  /** equals */
  _eq: InputMaybe<Scalars['LocalDate']>;
  /** greater than */
  _gt: InputMaybe<Scalars['LocalDate']>;
  /** greater than or equals */
  _gte: InputMaybe<Scalars['LocalDate']>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<Scalars['LocalDate']>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** less that */
  _lt: InputMaybe<Scalars['LocalDate']>;
  /** less than or equals */
  _lte: InputMaybe<Scalars['LocalDate']>;
  /** not equals */
  _neq: InputMaybe<Scalars['LocalDate']>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<Scalars['LocalDate']>>>;
};

/** expression to compare columns of type LocalDateTime. All fields are combined with logical 'AND' */
export type Inp_LocalDateTimeFilterCondition = {
  /** equals */
  _eq: InputMaybe<Scalars['LocalDateTime']>;
  /** greater than */
  _gt: InputMaybe<Scalars['LocalDateTime']>;
  /** greater than or equals */
  _gte: InputMaybe<Scalars['LocalDateTime']>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<Scalars['LocalDateTime']>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** less that */
  _lt: InputMaybe<Scalars['LocalDateTime']>;
  /** less than or equals */
  _lte: InputMaybe<Scalars['LocalDateTime']>;
  /** not equals */
  _neq: InputMaybe<Scalars['LocalDateTime']>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<Scalars['LocalDateTime']>>>;
};

/** expression to compare columns of type LocalTime. All fields are combined with logical 'AND' */
export type Inp_LocalTimeFilterCondition = {
  /** equals */
  _eq: InputMaybe<Scalars['LocalTime']>;
  /** greater than */
  _gt: InputMaybe<Scalars['LocalTime']>;
  /** greater than or equals */
  _gte: InputMaybe<Scalars['LocalTime']>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** less that */
  _lt: InputMaybe<Scalars['LocalTime']>;
  /** less than or equals */
  _lte: InputMaybe<Scalars['LocalTime']>;
  /** not equals */
  _neq: InputMaybe<Scalars['LocalTime']>;
};

/** expression to compare columns of type Long. All fields are combined with logical 'AND' */
export type Inp_LongFilterCondition = {
  /** equals */
  _eq: InputMaybe<Scalars['Long']>;
  /** greater than */
  _gt: InputMaybe<Scalars['Long']>;
  /** greater than or equals */
  _gte: InputMaybe<Scalars['Long']>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<Scalars['Long']>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** less that */
  _lt: InputMaybe<Scalars['Long']>;
  /** less than or equals */
  _lte: InputMaybe<Scalars['Long']>;
  /** not equals */
  _neq: InputMaybe<Scalars['Long']>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<Scalars['Long']>>>;
};

export type Inp_NewOrderTourRequest = {
  booking: InputMaybe<Scalars['Boolean']>;
  friends: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  kidId: InputMaybe<Scalars['UUID']>;
  tourId: InputMaybe<Scalars['UUID']>;
};

export type Inp_Notification = {
  id: InputMaybe<Scalars['UUID']>;
  isRead: InputMaybe<Scalars['Boolean']>;
  lastModifiedDate: InputMaybe<Scalars['DateTime']>;
  recipient: InputMaybe<Inp_ScoutvilleUser>;
  sender: InputMaybe<Inp_ScoutvilleUser>;
  title: InputMaybe<Scalars['String']>;
};

export type Inp_NotificationFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_NotificationFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_NotificationFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  isRead: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  lastModifiedDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  recipient: InputMaybe<Array<InputMaybe<Inp_ScoutvilleUserFilterCondition>>>;
  sender: InputMaybe<Array<InputMaybe<Inp_ScoutvilleUserFilterCondition>>>;
  title: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
};

export type Inp_NotificationOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  isRead: InputMaybe<SortOrder>;
  lastModifiedDate: InputMaybe<SortOrder>;
  recipient: InputMaybe<Inp_ScoutvilleUserOrderBy>;
  sender: InputMaybe<Inp_ScoutvilleUserOrderBy>;
  title: InputMaybe<SortOrder>;
};

/** expression to compare columns of type OffsetDateTime. All fields are combined with logical 'AND' */
export type Inp_OffsetDateTimeFilterCondition = {
  /** equals */
  _eq: InputMaybe<Scalars['OffsetDateTime']>;
  /** greater than */
  _gt: InputMaybe<Scalars['OffsetDateTime']>;
  /** greater than or equals */
  _gte: InputMaybe<Scalars['OffsetDateTime']>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<Scalars['OffsetDateTime']>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** less that */
  _lt: InputMaybe<Scalars['OffsetDateTime']>;
  /** less than or equals */
  _lte: InputMaybe<Scalars['OffsetDateTime']>;
  /** not equals */
  _neq: InputMaybe<Scalars['OffsetDateTime']>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<Scalars['OffsetDateTime']>>>;
};

/** expression to compare columns of type OffsetTime. All fields are combined with logical 'AND' */
export type Inp_OffsetTimeFilterCondition = {
  /** equals */
  _eq: InputMaybe<Scalars['OffsetTime']>;
  /** greater than */
  _gt: InputMaybe<Scalars['OffsetTime']>;
  /** greater than or equals */
  _gte: InputMaybe<Scalars['OffsetTime']>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** less that */
  _lt: InputMaybe<Scalars['OffsetTime']>;
  /** less than or equals */
  _lte: InputMaybe<Scalars['OffsetTime']>;
  /** not equals */
  _neq: InputMaybe<Scalars['OffsetTime']>;
};

export type Inp_OrderStatusTypeFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_OrderStatusTypeFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_OrderStatusTypeFilterCondition>>>;
  /** equals */
  _eq: InputMaybe<OrderStatusType>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<OrderStatusType>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** not equals */
  _neq: InputMaybe<OrderStatusType>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<OrderStatusType>>>;
};

export type Inp_ParameterTypeFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_ParameterTypeFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_ParameterTypeFilterCondition>>>;
  /** equals */
  _eq: InputMaybe<ParameterType>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<ParameterType>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** not equals */
  _neq: InputMaybe<ParameterType>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<ParameterType>>>;
};

export type Inp_PaymentTypeFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_PaymentTypeFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_PaymentTypeFilterCondition>>>;
  /** equals */
  _eq: InputMaybe<PaymentType>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<PaymentType>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** not equals */
  _neq: InputMaybe<PaymentType>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<PaymentType>>>;
};

export type Inp_RelationFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_RelationFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_RelationFilterCondition>>>;
  /** equals */
  _eq: InputMaybe<Relation>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<Relation>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** not equals */
  _neq: InputMaybe<Relation>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<Relation>>>;
};

export type Inp_ReportOutputTypeFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_ReportOutputTypeFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_ReportOutputTypeFilterCondition>>>;
  /** equals */
  _eq: InputMaybe<ReportOutputType>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<ReportOutputType>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** not equals */
  _neq: InputMaybe<ReportOutputType>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<ReportOutputType>>>;
};

export type Inp_ReportTypeFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_ReportTypeFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_ReportTypeFilterCondition>>>;
  /** equals */
  _eq: InputMaybe<ReportType>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<ReportType>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** not equals */
  _neq: InputMaybe<ReportType>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<ReportType>>>;
};

export type Inp_RowLevelPolicyActionFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_RowLevelPolicyActionFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_RowLevelPolicyActionFilterCondition>>>;
  /** equals */
  _eq: InputMaybe<RowLevelPolicyAction>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<RowLevelPolicyAction>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** not equals */
  _neq: InputMaybe<RowLevelPolicyAction>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<RowLevelPolicyAction>>>;
};

export type Inp_RowLevelPolicyTypeFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_RowLevelPolicyTypeFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_RowLevelPolicyTypeFilterCondition>>>;
  /** equals */
  _eq: InputMaybe<RowLevelPolicyType>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<RowLevelPolicyType>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** not equals */
  _neq: InputMaybe<RowLevelPolicyType>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<RowLevelPolicyType>>>;
};

export type Inp_ScoutvilleDiscount = {
  canBeUsed: InputMaybe<Scalars['Long']>;
  code: InputMaybe<Scalars['String']>;
  createdBy: InputMaybe<Scalars['String']>;
  createdDate: InputMaybe<Scalars['DateTime']>;
  deletedBy: InputMaybe<Scalars['String']>;
  deletedDate: InputMaybe<Scalars['DateTime']>;
  discountType: InputMaybe<DiscountType>;
  endDate: InputMaybe<Scalars['Date']>;
  id: InputMaybe<Scalars['Int']>;
  isActive: InputMaybe<Scalars['Boolean']>;
  kid: InputMaybe<Inp_ScoutvilleKid>;
  lastModifiedBy: InputMaybe<Scalars['String']>;
  lastModifiedDate: InputMaybe<Scalars['DateTime']>;
  name: InputMaybe<Scalars['String']>;
  orders: InputMaybe<Array<InputMaybe<Inp_ScoutvilleOrder>>>;
  product: InputMaybe<Inp_ScoutvilleProduct>;
  startDate: InputMaybe<Scalars['Date']>;
  total: InputMaybe<Scalars['Long']>;
  usedCount: InputMaybe<Scalars['Int']>;
  user: InputMaybe<Inp_ScoutvilleUser>;
  version: InputMaybe<Scalars['Int']>;
};

export type Inp_ScoutvilleDiscountFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_ScoutvilleDiscountFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_ScoutvilleDiscountFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  canBeUsed: InputMaybe<Array<InputMaybe<Inp_LongFilterCondition>>>;
  code: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  createdDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  deletedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  deletedDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  discountType: InputMaybe<Array<InputMaybe<Inp_DiscountTypeFilterCondition>>>;
  endDate: InputMaybe<Array<InputMaybe<Inp_DateFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
  isActive: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  kid: InputMaybe<Array<InputMaybe<Inp_ScoutvilleKidFilterCondition>>>;
  lastModifiedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  lastModifiedDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  name: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  product: InputMaybe<Array<InputMaybe<Inp_ScoutvilleProductFilterCondition>>>;
  startDate: InputMaybe<Array<InputMaybe<Inp_DateFilterCondition>>>;
  total: InputMaybe<Array<InputMaybe<Inp_LongFilterCondition>>>;
  usedCount: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
  user: InputMaybe<Array<InputMaybe<Inp_ScoutvilleUserFilterCondition>>>;
  version: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
};

export type Inp_ScoutvilleDiscountOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  canBeUsed: InputMaybe<SortOrder>;
  code: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  createdDate: InputMaybe<SortOrder>;
  deletedBy: InputMaybe<SortOrder>;
  deletedDate: InputMaybe<SortOrder>;
  discountType: InputMaybe<SortOrder>;
  endDate: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  isActive: InputMaybe<SortOrder>;
  kid: InputMaybe<Inp_ScoutvilleKidOrderBy>;
  lastModifiedBy: InputMaybe<SortOrder>;
  lastModifiedDate: InputMaybe<SortOrder>;
  name: InputMaybe<SortOrder>;
  product: InputMaybe<Inp_ScoutvilleProductOrderBy>;
  startDate: InputMaybe<SortOrder>;
  total: InputMaybe<SortOrder>;
  usedCount: InputMaybe<SortOrder>;
  user: InputMaybe<Inp_ScoutvilleUserOrderBy>;
  version: InputMaybe<SortOrder>;
};

export type Inp_ScoutvilleDocument = {
  document: InputMaybe<Scalars['Upload']>;
  id: InputMaybe<Scalars['UUID']>;
  user: InputMaybe<Inp_ScoutvilleUser>;
};

export type Inp_ScoutvilleDocumentFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_ScoutvilleDocumentFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_ScoutvilleDocumentFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  document: InputMaybe<Array<InputMaybe<Inp_UploadFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  user: InputMaybe<Array<InputMaybe<Inp_ScoutvilleUserFilterCondition>>>;
};

export type Inp_ScoutvilleDocumentOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  document: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  user: InputMaybe<Inp_ScoutvilleUserOrderBy>;
};

export type Inp_ScoutvilleKid = {
  bdCertificate: InputMaybe<Scalars['Upload']>;
  bdCertificateSerial: InputMaybe<Scalars['String']>;
  birthDate: InputMaybe<Scalars['Date']>;
  canSwim: InputMaybe<CanSwim>;
  createdBy: InputMaybe<Scalars['String']>;
  createdDate: InputMaybe<Scalars['DateTime']>;
  deletedBy: InputMaybe<Scalars['String']>;
  deletedDate: InputMaybe<Scalars['DateTime']>;
  firstName: InputMaybe<Scalars['String']>;
  foodPreference: InputMaybe<Scalars['String']>;
  gender: InputMaybe<Gender>;
  healthInfo: InputMaybe<Scalars['String']>;
  hobbyInfo: InputMaybe<Scalars['String']>;
  id: InputMaybe<Scalars['UUID']>;
  isDesMade: InputMaybe<Scalars['String']>;
  isFirsTour: InputMaybe<Scalars['String']>;
  isSawCamps: InputMaybe<Scalars['String']>;
  lastModifiedBy: InputMaybe<Scalars['String']>;
  lastModifiedDate: InputMaybe<Scalars['DateTime']>;
  lastName: InputMaybe<Scalars['String']>;
  relations: InputMaybe<Array<InputMaybe<Inp_ScoutvilleKidParentRelation>>>;
  secondName: InputMaybe<Scalars['String']>;
  shortSize: InputMaybe<TShortSize>;
  tours: InputMaybe<Array<InputMaybe<Inp_ScoutvilleOrderTour>>>;
  version: InputMaybe<Scalars['Int']>;
};

export type Inp_ScoutvilleKidFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_ScoutvilleKidFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_ScoutvilleKidFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  bdCertificate: InputMaybe<Array<InputMaybe<Inp_UploadFilterCondition>>>;
  bdCertificateSerial: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  birthDate: InputMaybe<Array<InputMaybe<Inp_DateFilterCondition>>>;
  canSwim: InputMaybe<Array<InputMaybe<Inp_CanSwimFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  createdDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  deletedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  deletedDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  firstName: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  foodPreference: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  gender: InputMaybe<Array<InputMaybe<Inp_GenderFilterCondition>>>;
  healthInfo: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  hobbyInfo: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  isDesMade: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  isFirsTour: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  isSawCamps: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  lastModifiedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  lastModifiedDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  lastName: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  secondName: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  shortSize: InputMaybe<Array<InputMaybe<Inp_TShortSizeFilterCondition>>>;
  version: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
};

export type Inp_ScoutvilleKidOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  bdCertificate: InputMaybe<SortOrder>;
  bdCertificateSerial: InputMaybe<SortOrder>;
  birthDate: InputMaybe<SortOrder>;
  canSwim: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  createdDate: InputMaybe<SortOrder>;
  deletedBy: InputMaybe<SortOrder>;
  deletedDate: InputMaybe<SortOrder>;
  firstName: InputMaybe<SortOrder>;
  foodPreference: InputMaybe<SortOrder>;
  gender: InputMaybe<SortOrder>;
  healthInfo: InputMaybe<SortOrder>;
  hobbyInfo: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  isDesMade: InputMaybe<SortOrder>;
  isFirsTour: InputMaybe<SortOrder>;
  isSawCamps: InputMaybe<SortOrder>;
  lastModifiedBy: InputMaybe<SortOrder>;
  lastModifiedDate: InputMaybe<SortOrder>;
  lastName: InputMaybe<SortOrder>;
  secondName: InputMaybe<SortOrder>;
  shortSize: InputMaybe<SortOrder>;
  version: InputMaybe<SortOrder>;
};

export type Inp_ScoutvilleKidParentRelation = {
  id: InputMaybe<Scalars['UUID']>;
  kid: InputMaybe<Inp_ScoutvilleKid>;
  parent: InputMaybe<Inp_ScoutvilleUser>;
  relation: InputMaybe<Relation>;
};

export type Inp_ScoutvilleKidParentRelationFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_ScoutvilleKidParentRelationFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_ScoutvilleKidParentRelationFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  kid: InputMaybe<Array<InputMaybe<Inp_ScoutvilleKidFilterCondition>>>;
  parent: InputMaybe<Array<InputMaybe<Inp_ScoutvilleUserFilterCondition>>>;
  relation: InputMaybe<Array<InputMaybe<Inp_RelationFilterCondition>>>;
};

export type Inp_ScoutvilleKidParentRelationOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  kid: InputMaybe<Inp_ScoutvilleKidOrderBy>;
  parent: InputMaybe<Inp_ScoutvilleUserOrderBy>;
  relation: InputMaybe<SortOrder>;
};

export type Inp_ScoutvilleOrder = {
  basePrice: InputMaybe<Scalars['Long']>;
  confirmed: InputMaybe<Scalars['Boolean']>;
  createdBy: InputMaybe<Scalars['String']>;
  createdDate: InputMaybe<Scalars['DateTime']>;
  currentStatus: InputMaybe<OrderStatusType>;
  customer: InputMaybe<Inp_ScoutvilleUser>;
  deletedBy: InputMaybe<Scalars['String']>;
  deletedDate: InputMaybe<Scalars['DateTime']>;
  discount: InputMaybe<Inp_ScoutvilleDiscount>;
  discountPrice: InputMaybe<Scalars['Long']>;
  finalPrice: InputMaybe<Scalars['Long']>;
  id: InputMaybe<Scalars['UUID']>;
  isPaid: InputMaybe<Scalars['Boolean']>;
  isPartialPaid: InputMaybe<Scalars['Boolean']>;
  lastModifiedBy: InputMaybe<Scalars['String']>;
  lastModifiedDate: InputMaybe<Scalars['DateTime']>;
  leftToPay: InputMaybe<Scalars['Long']>;
  paidAmount: InputMaybe<Scalars['Long']>;
  payments: InputMaybe<Array<InputMaybe<Inp_ScoutvillePayment>>>;
  products: InputMaybe<Array<InputMaybe<Inp_ScoutvilleProduct>>>;
  repayWith: InputMaybe<AcquiringProvider>;
  responsible: InputMaybe<Inp_ScoutvilleUser>;
  statuses: InputMaybe<Array<InputMaybe<Inp_ScoutvilleOrderStatus>>>;
  version: InputMaybe<Scalars['Int']>;
};

export type Inp_ScoutvilleOrderFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_ScoutvilleOrderFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_ScoutvilleOrderFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  basePrice: InputMaybe<Array<InputMaybe<Inp_LongFilterCondition>>>;
  confirmed: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  createdDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  currentStatus: InputMaybe<Array<InputMaybe<Inp_OrderStatusTypeFilterCondition>>>;
  customer: InputMaybe<Array<InputMaybe<Inp_ScoutvilleUserFilterCondition>>>;
  deletedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  deletedDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  discount: InputMaybe<Array<InputMaybe<Inp_ScoutvilleDiscountFilterCondition>>>;
  discountPrice: InputMaybe<Array<InputMaybe<Inp_LongFilterCondition>>>;
  finalPrice: InputMaybe<Array<InputMaybe<Inp_LongFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  isPaid: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  isPartialPaid: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  lastModifiedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  lastModifiedDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  leftToPay: InputMaybe<Array<InputMaybe<Inp_LongFilterCondition>>>;
  paidAmount: InputMaybe<Array<InputMaybe<Inp_LongFilterCondition>>>;
  repayWith: InputMaybe<Array<InputMaybe<Inp_AcquiringProviderFilterCondition>>>;
  responsible: InputMaybe<Array<InputMaybe<Inp_ScoutvilleUserFilterCondition>>>;
  version: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
};

export type Inp_ScoutvilleOrderOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  basePrice: InputMaybe<SortOrder>;
  confirmed: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  createdDate: InputMaybe<SortOrder>;
  currentStatus: InputMaybe<SortOrder>;
  customer: InputMaybe<Inp_ScoutvilleUserOrderBy>;
  deletedBy: InputMaybe<SortOrder>;
  deletedDate: InputMaybe<SortOrder>;
  discount: InputMaybe<Inp_ScoutvilleDiscountOrderBy>;
  discountPrice: InputMaybe<SortOrder>;
  finalPrice: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  isPaid: InputMaybe<SortOrder>;
  isPartialPaid: InputMaybe<SortOrder>;
  lastModifiedBy: InputMaybe<SortOrder>;
  lastModifiedDate: InputMaybe<SortOrder>;
  leftToPay: InputMaybe<SortOrder>;
  paidAmount: InputMaybe<SortOrder>;
  repayWith: InputMaybe<SortOrder>;
  responsible: InputMaybe<Inp_ScoutvilleUserOrderBy>;
  version: InputMaybe<SortOrder>;
};

export type Inp_ScoutvilleOrderStatus = {
  createdBy: InputMaybe<Scalars['String']>;
  createdDate: InputMaybe<Scalars['DateTime']>;
  deletedBy: InputMaybe<Scalars['String']>;
  deletedDate: InputMaybe<Scalars['DateTime']>;
  description: InputMaybe<Scalars['String']>;
  id: InputMaybe<Scalars['Long']>;
  isDone: InputMaybe<Scalars['Boolean']>;
  lastModifiedBy: InputMaybe<Scalars['String']>;
  lastModifiedDate: InputMaybe<Scalars['DateTime']>;
  link: InputMaybe<Scalars['String']>;
  order: InputMaybe<Inp_ScoutvilleOrder>;
  statusType: InputMaybe<OrderStatusType>;
  version: InputMaybe<Scalars['Int']>;
};

export type Inp_ScoutvilleOrderStatusFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_ScoutvilleOrderStatusFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_ScoutvilleOrderStatusFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  createdDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  deletedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  deletedDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  description: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_LongFilterCondition>>>;
  isDone: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  lastModifiedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  lastModifiedDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  link: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  order: InputMaybe<Array<InputMaybe<Inp_ScoutvilleOrderFilterCondition>>>;
  statusType: InputMaybe<Array<InputMaybe<Inp_OrderStatusTypeFilterCondition>>>;
  version: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
};

export type Inp_ScoutvilleOrderStatusOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  createdDate: InputMaybe<SortOrder>;
  deletedBy: InputMaybe<SortOrder>;
  deletedDate: InputMaybe<SortOrder>;
  description: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  isDone: InputMaybe<SortOrder>;
  lastModifiedBy: InputMaybe<SortOrder>;
  lastModifiedDate: InputMaybe<SortOrder>;
  link: InputMaybe<SortOrder>;
  order: InputMaybe<Inp_ScoutvilleOrderOrderBy>;
  statusType: InputMaybe<SortOrder>;
  version: InputMaybe<SortOrder>;
};

export type Inp_ScoutvilleOrderTour = {
  additionalInfo: InputMaybe<Scalars['String']>;
  basePrice: InputMaybe<Scalars['Long']>;
  confirmed: InputMaybe<Scalars['Boolean']>;
  createdBy: InputMaybe<Scalars['String']>;
  createdDate: InputMaybe<Scalars['DateTime']>;
  currentStatus: InputMaybe<OrderStatusType>;
  customer: InputMaybe<Inp_ScoutvilleUser>;
  deletedBy: InputMaybe<Scalars['String']>;
  deletedDate: InputMaybe<Scalars['DateTime']>;
  discount: InputMaybe<Inp_ScoutvilleDiscount>;
  discountPrice: InputMaybe<Scalars['Long']>;
  finalPrice: InputMaybe<Scalars['Long']>;
  friends: InputMaybe<Scalars['String']>;
  id: InputMaybe<Scalars['UUID']>;
  isBooking: InputMaybe<Scalars['Boolean']>;
  isPaid: InputMaybe<Scalars['Boolean']>;
  isPartialPaid: InputMaybe<Scalars['Boolean']>;
  kid: InputMaybe<Inp_ScoutvilleKid>;
  lastModifiedBy: InputMaybe<Scalars['String']>;
  lastModifiedDate: InputMaybe<Scalars['DateTime']>;
  leftToPay: InputMaybe<Scalars['Long']>;
  paidAmount: InputMaybe<Scalars['Long']>;
  payments: InputMaybe<Array<InputMaybe<Inp_ScoutvillePayment>>>;
  products: InputMaybe<Array<InputMaybe<Inp_ScoutvilleProduct>>>;
  repayWith: InputMaybe<AcquiringProvider>;
  responsible: InputMaybe<Inp_ScoutvilleUser>;
  statuses: InputMaybe<Array<InputMaybe<Inp_ScoutvilleOrderStatus>>>;
  tour: InputMaybe<Inp_ScoutvilleTour>;
  version: InputMaybe<Scalars['Int']>;
};

export type Inp_ScoutvilleOrderTourFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_ScoutvilleOrderTourFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_ScoutvilleOrderTourFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  additionalInfo: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  basePrice: InputMaybe<Array<InputMaybe<Inp_LongFilterCondition>>>;
  confirmed: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  createdDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  currentStatus: InputMaybe<Array<InputMaybe<Inp_OrderStatusTypeFilterCondition>>>;
  customer: InputMaybe<Array<InputMaybe<Inp_ScoutvilleUserFilterCondition>>>;
  deletedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  deletedDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  discount: InputMaybe<Array<InputMaybe<Inp_ScoutvilleDiscountFilterCondition>>>;
  discountPrice: InputMaybe<Array<InputMaybe<Inp_LongFilterCondition>>>;
  finalPrice: InputMaybe<Array<InputMaybe<Inp_LongFilterCondition>>>;
  friends: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  isBooking: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  isPaid: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  isPartialPaid: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  kid: InputMaybe<Array<InputMaybe<Inp_ScoutvilleKidFilterCondition>>>;
  lastModifiedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  lastModifiedDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  leftToPay: InputMaybe<Array<InputMaybe<Inp_LongFilterCondition>>>;
  paidAmount: InputMaybe<Array<InputMaybe<Inp_LongFilterCondition>>>;
  repayWith: InputMaybe<Array<InputMaybe<Inp_AcquiringProviderFilterCondition>>>;
  responsible: InputMaybe<Array<InputMaybe<Inp_ScoutvilleUserFilterCondition>>>;
  tour: InputMaybe<Array<InputMaybe<Inp_ScoutvilleTourFilterCondition>>>;
  version: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
};

export type Inp_ScoutvilleOrderTourOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  additionalInfo: InputMaybe<SortOrder>;
  basePrice: InputMaybe<SortOrder>;
  confirmed: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  createdDate: InputMaybe<SortOrder>;
  currentStatus: InputMaybe<SortOrder>;
  customer: InputMaybe<Inp_ScoutvilleUserOrderBy>;
  deletedBy: InputMaybe<SortOrder>;
  deletedDate: InputMaybe<SortOrder>;
  discount: InputMaybe<Inp_ScoutvilleDiscountOrderBy>;
  discountPrice: InputMaybe<SortOrder>;
  finalPrice: InputMaybe<SortOrder>;
  friends: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  isBooking: InputMaybe<SortOrder>;
  isPaid: InputMaybe<SortOrder>;
  isPartialPaid: InputMaybe<SortOrder>;
  kid: InputMaybe<Inp_ScoutvilleKidOrderBy>;
  lastModifiedBy: InputMaybe<SortOrder>;
  lastModifiedDate: InputMaybe<SortOrder>;
  leftToPay: InputMaybe<SortOrder>;
  paidAmount: InputMaybe<SortOrder>;
  repayWith: InputMaybe<SortOrder>;
  responsible: InputMaybe<Inp_ScoutvilleUserOrderBy>;
  tour: InputMaybe<Inp_ScoutvilleTourOrderBy>;
  version: InputMaybe<SortOrder>;
};

export type Inp_ScoutvillePayment = {
  acquiringProvider: InputMaybe<AcquiringProvider>;
  amount: InputMaybe<Scalars['Long']>;
  createdBy: InputMaybe<Scalars['String']>;
  createdDate: InputMaybe<Scalars['DateTime']>;
  deletedBy: InputMaybe<Scalars['String']>;
  deletedDate: InputMaybe<Scalars['DateTime']>;
  id: InputMaybe<Scalars['UUID']>;
  lastModifiedBy: InputMaybe<Scalars['String']>;
  lastModifiedDate: InputMaybe<Scalars['DateTime']>;
  order: InputMaybe<Inp_ScoutvilleOrder>;
  paidByType: InputMaybe<PaymentType>;
  paidByUser: InputMaybe<Inp_ScoutvilleUser>;
  paymentSystem: InputMaybe<Scalars['String']>;
  version: InputMaybe<Scalars['Int']>;
};

export type Inp_ScoutvillePaymentFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_ScoutvillePaymentFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_ScoutvillePaymentFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  acquiringProvider: InputMaybe<Array<InputMaybe<Inp_AcquiringProviderFilterCondition>>>;
  amount: InputMaybe<Array<InputMaybe<Inp_LongFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  createdDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  deletedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  deletedDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  lastModifiedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  lastModifiedDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  order: InputMaybe<Array<InputMaybe<Inp_ScoutvilleOrderFilterCondition>>>;
  paidByType: InputMaybe<Array<InputMaybe<Inp_PaymentTypeFilterCondition>>>;
  paidByUser: InputMaybe<Array<InputMaybe<Inp_ScoutvilleUserFilterCondition>>>;
  paymentSystem: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  version: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
};

export type Inp_ScoutvillePaymentOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  acquiringProvider: InputMaybe<SortOrder>;
  amount: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  createdDate: InputMaybe<SortOrder>;
  deletedBy: InputMaybe<SortOrder>;
  deletedDate: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  lastModifiedBy: InputMaybe<SortOrder>;
  lastModifiedDate: InputMaybe<SortOrder>;
  order: InputMaybe<Inp_ScoutvilleOrderOrderBy>;
  paidByType: InputMaybe<SortOrder>;
  paidByUser: InputMaybe<Inp_ScoutvilleUserOrderBy>;
  paymentSystem: InputMaybe<SortOrder>;
  version: InputMaybe<SortOrder>;
};

export type Inp_ScoutvilleProduct = {
  createdBy: InputMaybe<Scalars['String']>;
  createdDate: InputMaybe<Scalars['DateTime']>;
  deletedBy: InputMaybe<Scalars['String']>;
  deletedDate: InputMaybe<Scalars['DateTime']>;
  description: InputMaybe<Scalars['String']>;
  id: InputMaybe<Scalars['UUID']>;
  image: InputMaybe<Scalars['Upload']>;
  lastModifiedBy: InputMaybe<Scalars['String']>;
  lastModifiedDate: InputMaybe<Scalars['DateTime']>;
  name: InputMaybe<Scalars['String']>;
  orders: InputMaybe<Array<InputMaybe<Inp_ScoutvilleOrder>>>;
  payWith: InputMaybe<AcquiringProvider>;
  price: InputMaybe<Scalars['Long']>;
  version: InputMaybe<Scalars['Int']>;
};

export type Inp_ScoutvilleProductFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_ScoutvilleProductFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_ScoutvilleProductFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  createdDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  deletedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  deletedDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  description: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  image: InputMaybe<Array<InputMaybe<Inp_UploadFilterCondition>>>;
  lastModifiedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  lastModifiedDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  name: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  payWith: InputMaybe<Array<InputMaybe<Inp_AcquiringProviderFilterCondition>>>;
  price: InputMaybe<Array<InputMaybe<Inp_LongFilterCondition>>>;
  version: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
};

export type Inp_ScoutvilleProductOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  createdDate: InputMaybe<SortOrder>;
  deletedBy: InputMaybe<SortOrder>;
  deletedDate: InputMaybe<SortOrder>;
  description: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  image: InputMaybe<SortOrder>;
  lastModifiedBy: InputMaybe<SortOrder>;
  lastModifiedDate: InputMaybe<SortOrder>;
  name: InputMaybe<SortOrder>;
  payWith: InputMaybe<SortOrder>;
  price: InputMaybe<SortOrder>;
  version: InputMaybe<SortOrder>;
};

export type Inp_ScoutvilleTeamMember = {
  createdBy: InputMaybe<Scalars['String']>;
  createdDate: InputMaybe<Scalars['DateTime']>;
  deletedBy: InputMaybe<Scalars['String']>;
  deletedDate: InputMaybe<Scalars['DateTime']>;
  description: InputMaybe<Scalars['String']>;
  fullName: InputMaybe<Scalars['String']>;
  id: InputMaybe<Scalars['UUID']>;
  image: InputMaybe<Scalars['Upload']>;
  lastModifiedBy: InputMaybe<Scalars['String']>;
  lastModifiedDate: InputMaybe<Scalars['DateTime']>;
  ordinal: InputMaybe<Scalars['Int']>;
  position: InputMaybe<Scalars['String']>;
  version: InputMaybe<Scalars['Int']>;
};

export type Inp_ScoutvilleTeamMemberFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_ScoutvilleTeamMemberFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_ScoutvilleTeamMemberFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  createdDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  deletedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  deletedDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  description: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  fullName: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  image: InputMaybe<Array<InputMaybe<Inp_UploadFilterCondition>>>;
  lastModifiedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  lastModifiedDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  ordinal: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
  position: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  version: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
};

export type Inp_ScoutvilleTeamMemberOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  createdDate: InputMaybe<SortOrder>;
  deletedBy: InputMaybe<SortOrder>;
  deletedDate: InputMaybe<SortOrder>;
  description: InputMaybe<SortOrder>;
  fullName: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  image: InputMaybe<SortOrder>;
  lastModifiedBy: InputMaybe<SortOrder>;
  lastModifiedDate: InputMaybe<SortOrder>;
  ordinal: InputMaybe<SortOrder>;
  position: InputMaybe<SortOrder>;
  version: InputMaybe<SortOrder>;
};

export type Inp_ScoutvilleTour = {
  active: InputMaybe<Scalars['Boolean']>;
  archived: InputMaybe<Scalars['Boolean']>;
  availableForBooking: InputMaybe<Scalars['Boolean']>;
  badgeInfo: InputMaybe<Scalars['String']>;
  blocks: InputMaybe<Array<InputMaybe<Inp_ScoutvilleTourBlock>>>;
  createdBy: InputMaybe<Scalars['String']>;
  createdDate: InputMaybe<Scalars['DateTime']>;
  deletedBy: InputMaybe<Scalars['String']>;
  deletedDate: InputMaybe<Scalars['DateTime']>;
  description: InputMaybe<Scalars['String']>;
  endDate: InputMaybe<Scalars['Date']>;
  id: InputMaybe<Scalars['UUID']>;
  image: InputMaybe<Scalars['Upload']>;
  juniorTicketsSold: InputMaybe<Scalars['Int']>;
  kidsTicketsSold: InputMaybe<Scalars['Int']>;
  lastModifiedBy: InputMaybe<Scalars['String']>;
  lastModifiedDate: InputMaybe<Scalars['DateTime']>;
  maxDateOfBirth: InputMaybe<Scalars['Date']>;
  minDateOfBirth: InputMaybe<Scalars['Date']>;
  name: InputMaybe<Scalars['String']>;
  orders: InputMaybe<Array<InputMaybe<Inp_ScoutvilleOrder>>>;
  payWith: InputMaybe<AcquiringProvider>;
  price: InputMaybe<Scalars['Long']>;
  startDate: InputMaybe<Scalars['Date']>;
  teensTicketsSold: InputMaybe<Scalars['Int']>;
  version: InputMaybe<Scalars['Int']>;
};

export type Inp_ScoutvilleTourBlock = {
  blockedFor: InputMaybe<GenderBlock>;
  blockedYear: InputMaybe<Scalars['Int']>;
  createdBy: InputMaybe<Scalars['String']>;
  createdDate: InputMaybe<Scalars['DateTime']>;
  id: InputMaybe<Scalars['UUID']>;
  lastModifiedBy: InputMaybe<Scalars['String']>;
  lastModifiedDate: InputMaybe<Scalars['Date']>;
  tour: InputMaybe<Inp_ScoutvilleTour>;
};

export type Inp_ScoutvilleTourBlockFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_ScoutvilleTourBlockFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_ScoutvilleTourBlockFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  blockedFor: InputMaybe<Array<InputMaybe<Inp_GenderBlockFilterCondition>>>;
  blockedYear: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  createdDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  lastModifiedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  lastModifiedDate: InputMaybe<Array<InputMaybe<Inp_DateFilterCondition>>>;
  tour: InputMaybe<Array<InputMaybe<Inp_ScoutvilleTourFilterCondition>>>;
};

export type Inp_ScoutvilleTourBlockOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  blockedFor: InputMaybe<SortOrder>;
  blockedYear: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  createdDate: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  lastModifiedBy: InputMaybe<SortOrder>;
  lastModifiedDate: InputMaybe<SortOrder>;
  tour: InputMaybe<Inp_ScoutvilleTourOrderBy>;
};

export type Inp_ScoutvilleTourFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_ScoutvilleTourFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_ScoutvilleTourFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  active: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  archived: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  availableForBooking: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  badgeInfo: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  createdDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  deletedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  deletedDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  description: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  endDate: InputMaybe<Array<InputMaybe<Inp_DateFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  image: InputMaybe<Array<InputMaybe<Inp_UploadFilterCondition>>>;
  juniorTicketsSold: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
  kidsTicketsSold: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
  lastModifiedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  lastModifiedDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  maxDateOfBirth: InputMaybe<Array<InputMaybe<Inp_DateFilterCondition>>>;
  minDateOfBirth: InputMaybe<Array<InputMaybe<Inp_DateFilterCondition>>>;
  name: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  payWith: InputMaybe<Array<InputMaybe<Inp_AcquiringProviderFilterCondition>>>;
  price: InputMaybe<Array<InputMaybe<Inp_LongFilterCondition>>>;
  startDate: InputMaybe<Array<InputMaybe<Inp_DateFilterCondition>>>;
  teensTicketsSold: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
  version: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
};

export type Inp_ScoutvilleTourOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  active: InputMaybe<SortOrder>;
  archived: InputMaybe<SortOrder>;
  availableForBooking: InputMaybe<SortOrder>;
  badgeInfo: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  createdDate: InputMaybe<SortOrder>;
  deletedBy: InputMaybe<SortOrder>;
  deletedDate: InputMaybe<SortOrder>;
  description: InputMaybe<SortOrder>;
  endDate: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  image: InputMaybe<SortOrder>;
  juniorTicketsSold: InputMaybe<SortOrder>;
  kidsTicketsSold: InputMaybe<SortOrder>;
  lastModifiedBy: InputMaybe<SortOrder>;
  lastModifiedDate: InputMaybe<SortOrder>;
  maxDateOfBirth: InputMaybe<SortOrder>;
  minDateOfBirth: InputMaybe<SortOrder>;
  name: InputMaybe<SortOrder>;
  payWith: InputMaybe<SortOrder>;
  price: InputMaybe<SortOrder>;
  startDate: InputMaybe<SortOrder>;
  teensTicketsSold: InputMaybe<SortOrder>;
  version: InputMaybe<SortOrder>;
};

export type Inp_ScoutvilleTrustedPerson = {
  email: InputMaybe<Scalars['String']>;
  firstName: InputMaybe<Scalars['String']>;
  id: InputMaybe<Scalars['UUID']>;
  lastName: InputMaybe<Scalars['String']>;
  phone: InputMaybe<Scalars['String']>;
  relation: InputMaybe<Relation>;
  secondName: InputMaybe<Scalars['String']>;
  user: InputMaybe<Inp_ScoutvilleUser>;
};

export type Inp_ScoutvilleTrustedPersonFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_ScoutvilleTrustedPersonFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_ScoutvilleTrustedPersonFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  email: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  firstName: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  lastName: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  phone: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  relation: InputMaybe<Array<InputMaybe<Inp_RelationFilterCondition>>>;
  secondName: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  user: InputMaybe<Array<InputMaybe<Inp_ScoutvilleUserFilterCondition>>>;
};

export type Inp_ScoutvilleTrustedPersonOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  email: InputMaybe<SortOrder>;
  firstName: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  lastName: InputMaybe<SortOrder>;
  phone: InputMaybe<SortOrder>;
  relation: InputMaybe<SortOrder>;
  secondName: InputMaybe<SortOrder>;
  user: InputMaybe<Inp_ScoutvilleUserOrderBy>;
};

export type Inp_ScoutvilleUser = {
  active: InputMaybe<Scalars['Boolean']>;
  documents: InputMaybe<Array<InputMaybe<Inp_ScoutvilleDocument>>>;
  emailActivationToken: InputMaybe<Scalars['String']>;
  firstName: InputMaybe<Scalars['String']>;
  id: InputMaybe<Scalars['UUID']>;
  lastName: InputMaybe<Scalars['String']>;
  needsEmailActivation: InputMaybe<Scalars['Boolean']>;
  needsPhoneActivation: InputMaybe<Scalars['Boolean']>;
  passportSerial: InputMaybe<Scalars['String']>;
  password: InputMaybe<Scalars['String']>;
  passwordResetToken: InputMaybe<Scalars['String']>;
  phone: InputMaybe<Scalars['String']>;
  phoneActivationToken: InputMaybe<Scalars['String']>;
  relations: InputMaybe<Array<InputMaybe<Inp_ScoutvilleKidParentRelation>>>;
  secondName: InputMaybe<Scalars['String']>;
  taxSerial: InputMaybe<Scalars['String']>;
  timeZoneId: InputMaybe<Scalars['String']>;
  trustedPerson: InputMaybe<Inp_ScoutvilleTrustedPerson>;
  username: InputMaybe<Scalars['String']>;
  version: InputMaybe<Scalars['Int']>;
};

export type Inp_ScoutvilleUserFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_ScoutvilleUserFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_ScoutvilleUserFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  active: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  emailActivationToken: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  firstName: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  lastName: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  needsEmailActivation: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  needsPhoneActivation: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  passportSerial: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  password: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  passwordResetToken: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  phone: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  phoneActivationToken: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  secondName: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  taxSerial: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  timeZoneId: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  trustedPerson: InputMaybe<Array<InputMaybe<Inp_ScoutvilleTrustedPersonFilterCondition>>>;
  username: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  version: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
};

export type Inp_ScoutvilleUserOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  active: InputMaybe<SortOrder>;
  emailActivationToken: InputMaybe<SortOrder>;
  firstName: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  lastName: InputMaybe<SortOrder>;
  needsEmailActivation: InputMaybe<SortOrder>;
  needsPhoneActivation: InputMaybe<SortOrder>;
  passportSerial: InputMaybe<SortOrder>;
  password: InputMaybe<SortOrder>;
  passwordResetToken: InputMaybe<SortOrder>;
  phone: InputMaybe<SortOrder>;
  phoneActivationToken: InputMaybe<SortOrder>;
  secondName: InputMaybe<SortOrder>;
  taxSerial: InputMaybe<SortOrder>;
  timeZoneId: InputMaybe<SortOrder>;
  trustedPerson: InputMaybe<Inp_ScoutvilleTrustedPersonOrderBy>;
  username: InputMaybe<SortOrder>;
  version: InputMaybe<SortOrder>;
};

export type Inp_SendingStatusFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_SendingStatusFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_SendingStatusFilterCondition>>>;
  /** equals */
  _eq: InputMaybe<SendingStatus>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<SendingStatus>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** not equals */
  _neq: InputMaybe<SendingStatus>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<SendingStatus>>>;
};

/** expression to compare columns of type Short. All fields are combined with logical 'AND' */
export type Inp_ShortFilterCondition = {
  /** equals */
  _eq: InputMaybe<Scalars['Short']>;
  /** greater than */
  _gt: InputMaybe<Scalars['Short']>;
  /** greater than or equals */
  _gte: InputMaybe<Scalars['Short']>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<Scalars['Short']>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** less that */
  _lt: InputMaybe<Scalars['Short']>;
  /** less than or equals */
  _lte: InputMaybe<Scalars['Short']>;
  /** not equals */
  _neq: InputMaybe<Scalars['Short']>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<Scalars['Short']>>>;
};

/** expression to compare columns of type String. All fields are combined with logical 'AND' */
export type Inp_StringFilterCondition = {
  /** contains substring */
  _contains: InputMaybe<Scalars['String']>;
  /** ends with substring */
  _endsWith: InputMaybe<Scalars['String']>;
  /** equals */
  _eq: InputMaybe<Scalars['String']>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** not equals */
  _neq: InputMaybe<Scalars['String']>;
  /** not contains substring */
  _notContains: InputMaybe<Scalars['String']>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** starts with substring */
  _startsWith: InputMaybe<Scalars['String']>;
};

export type Inp_TShortSizeFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_TShortSizeFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_TShortSizeFilterCondition>>>;
  /** equals */
  _eq: InputMaybe<TShortSize>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<TShortSize>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** not equals */
  _neq: InputMaybe<TShortSize>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<TShortSize>>>;
};

export type Inp_TemplateTypeFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_TemplateTypeFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_TemplateTypeFilterCondition>>>;
  /** equals */
  _eq: InputMaybe<TemplateType>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<TemplateType>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** not equals */
  _neq: InputMaybe<TemplateType>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<TemplateType>>>;
};

/** expression to compare columns of type Time. All fields are combined with logical 'AND' */
export type Inp_TimeFilterCondition = {
  /** equals */
  _eq: InputMaybe<Scalars['Time']>;
  /** greater than */
  _gt: InputMaybe<Scalars['Time']>;
  /** greater than or equals */
  _gte: InputMaybe<Scalars['Time']>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** less that */
  _lt: InputMaybe<Scalars['Time']>;
  /** less than or equals */
  _lte: InputMaybe<Scalars['Time']>;
  /** not equals */
  _neq: InputMaybe<Scalars['Time']>;
};

export type Inp_TrustedPersonDto = {
  email: InputMaybe<Scalars['String']>;
  firstName: InputMaybe<Scalars['String']>;
  id: InputMaybe<Scalars['UUID']>;
  lastName: InputMaybe<Scalars['String']>;
  phone: InputMaybe<Scalars['String']>;
  relation: InputMaybe<Relation>;
  secondName: InputMaybe<Scalars['String']>;
};

export type Inp_TypeFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_TypeFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_TypeFilterCondition>>>;
  /** equals */
  _eq: InputMaybe<Type>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<Type>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** not equals */
  _neq: InputMaybe<Type>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<Type>>>;
};

/** expression to compare columns of type UUID. All fields are combined with logical 'AND' */
export type Inp_UuidFilterCondition = {
  /** equals */
  _eq: InputMaybe<Scalars['UUID']>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** not equals */
  _neq: InputMaybe<Scalars['UUID']>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
};

export type Inp_UiNotification = {
  id: InputMaybe<Scalars['UUID']>;
  isRead: InputMaybe<Scalars['Boolean']>;
  lastModifiedDate: InputMaybe<Scalars['DateTime']>;
  message: InputMaybe<Scalars['String']>;
  recipient: InputMaybe<Inp_ScoutvilleUser>;
  sender: InputMaybe<Inp_ScoutvilleUser>;
  title: InputMaybe<Scalars['String']>;
};

export type Inp_UiNotificationFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_UiNotificationFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_UiNotificationFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  isRead: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  lastModifiedDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  message: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  recipient: InputMaybe<Array<InputMaybe<Inp_ScoutvilleUserFilterCondition>>>;
  sender: InputMaybe<Array<InputMaybe<Inp_ScoutvilleUserFilterCondition>>>;
  title: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
};

export type Inp_UiNotificationOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  isRead: InputMaybe<SortOrder>;
  lastModifiedDate: InputMaybe<SortOrder>;
  message: InputMaybe<SortOrder>;
  recipient: InputMaybe<Inp_ScoutvilleUserOrderBy>;
  sender: InputMaybe<Inp_ScoutvilleUserOrderBy>;
  title: InputMaybe<SortOrder>;
};

/** expression to compare columns of type Upload. All fields are combined with logical 'AND' */
export type Inp_UploadFilterCondition = {
  /** equals */
  _eq: InputMaybe<Scalars['Upload']>;
  /** in list */
  _in: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  /** not equals */
  _neq: InputMaybe<Scalars['Upload']>;
  /** not in list */
  _notIn: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
};

export type Inp_UserDto = {
  docFive: InputMaybe<Scalars['Upload']>;
  docFour: InputMaybe<Scalars['Upload']>;
  docOne: InputMaybe<Scalars['Upload']>;
  docSix: InputMaybe<Scalars['Upload']>;
  docTree: InputMaybe<Scalars['Upload']>;
  docTwo: InputMaybe<Scalars['Upload']>;
  email: InputMaybe<Scalars['String']>;
  firstName: InputMaybe<Scalars['String']>;
  id: InputMaybe<Scalars['UUID']>;
  lastName: InputMaybe<Scalars['String']>;
  passportSerial: InputMaybe<Scalars['String']>;
  phone: InputMaybe<Scalars['String']>;
  secondName: InputMaybe<Scalars['String']>;
  taxSerial: InputMaybe<Scalars['String']>;
};

export type Inp_Audit_EntityLog = {
  attributes: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  changes: InputMaybe<Scalars['String']>;
  createTs: InputMaybe<Scalars['DateTime']>;
  createdBy: InputMaybe<Scalars['String']>;
  entity: InputMaybe<Scalars['String']>;
  entityInstanceName: InputMaybe<Scalars['String']>;
  entityRef: InputMaybe<Scalars['String']>;
  eventTs: InputMaybe<Scalars['DateTime']>;
  id: InputMaybe<Scalars['UUID']>;
  sysTenantId: InputMaybe<Scalars['String']>;
  type: InputMaybe<Type>;
  username: InputMaybe<Scalars['String']>;
};

export type Inp_Audit_EntityLogFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_Audit_EntityLogFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_Audit_EntityLogFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  changes: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  createTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  entity: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  entityInstanceName: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  entityRef: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  eventTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  sysTenantId: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  type: InputMaybe<Array<InputMaybe<Inp_TypeFilterCondition>>>;
  username: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
};

export type Inp_Audit_EntityLogOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  changes: InputMaybe<SortOrder>;
  createTs: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  entity: InputMaybe<SortOrder>;
  entityInstanceName: InputMaybe<SortOrder>;
  eventTs: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  sysTenantId: InputMaybe<SortOrder>;
  type: InputMaybe<SortOrder>;
  username: InputMaybe<SortOrder>;
};

export type Inp_Audit_EntitySnapshot = {
  authorUsername: InputMaybe<Scalars['String']>;
  createdBy: InputMaybe<Scalars['String']>;
  createdDate: InputMaybe<Scalars['DateTime']>;
  entity: InputMaybe<Scalars['String']>;
  entityMetaClass: InputMaybe<Scalars['String']>;
  fetchPlanXml: InputMaybe<Scalars['String']>;
  id: InputMaybe<Scalars['UUID']>;
  snapshotDate: InputMaybe<Scalars['DateTime']>;
  snapshotXml: InputMaybe<Scalars['String']>;
  sysTenantId: InputMaybe<Scalars['String']>;
};

export type Inp_Audit_EntitySnapshotFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_Audit_EntitySnapshotFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_Audit_EntitySnapshotFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  authorUsername: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  createdDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  entity: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  entityMetaClass: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  fetchPlanXml: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  snapshotDate: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  snapshotXml: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  sysTenantId: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
};

export type Inp_Audit_EntitySnapshotOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  authorUsername: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  createdDate: InputMaybe<SortOrder>;
  entityMetaClass: InputMaybe<SortOrder>;
  fetchPlanXml: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  snapshotDate: InputMaybe<SortOrder>;
  snapshotXml: InputMaybe<SortOrder>;
  sysTenantId: InputMaybe<SortOrder>;
};

export type Inp_Audit_LoggedAttribute = {
  createTs: InputMaybe<Scalars['DateTime']>;
  createdBy: InputMaybe<Scalars['String']>;
  entity: InputMaybe<Inp_Audit_LoggedEntity>;
  id: InputMaybe<Scalars['UUID']>;
  name: InputMaybe<Scalars['String']>;
};

export type Inp_Audit_LoggedAttributeFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_Audit_LoggedAttributeFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_Audit_LoggedAttributeFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  createTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  entity: InputMaybe<Array<InputMaybe<Inp_Audit_LoggedEntityFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  name: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
};

export type Inp_Audit_LoggedAttributeOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  createTs: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  entity: InputMaybe<Inp_Audit_LoggedEntityOrderBy>;
  id: InputMaybe<SortOrder>;
  name: InputMaybe<SortOrder>;
};

export type Inp_Audit_LoggedEntity = {
  attributes: InputMaybe<Array<InputMaybe<Inp_Audit_LoggedAttribute>>>;
  auto: InputMaybe<Scalars['Boolean']>;
  createTs: InputMaybe<Scalars['DateTime']>;
  createdBy: InputMaybe<Scalars['String']>;
  id: InputMaybe<Scalars['UUID']>;
  manual: InputMaybe<Scalars['Boolean']>;
  name: InputMaybe<Scalars['String']>;
};

export type Inp_Audit_LoggedEntityFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_Audit_LoggedEntityFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_Audit_LoggedEntityFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  auto: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  createTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  manual: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  name: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
};

export type Inp_Audit_LoggedEntityOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  auto: InputMaybe<SortOrder>;
  createTs: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  manual: InputMaybe<SortOrder>;
  name: InputMaybe<SortOrder>;
};

export type Inp_Email_SendingAttachment = {
  content: InputMaybe<Scalars['String']>;
  contentFile: InputMaybe<Scalars['Upload']>;
  contentId: InputMaybe<Scalars['String']>;
  createTs: InputMaybe<Scalars['DateTime']>;
  createdBy: InputMaybe<Scalars['String']>;
  deleteTs: InputMaybe<Scalars['DateTime']>;
  deletedBy: InputMaybe<Scalars['String']>;
  disposition: InputMaybe<Scalars['String']>;
  encoding: InputMaybe<Scalars['String']>;
  id: InputMaybe<Scalars['UUID']>;
  message: InputMaybe<Inp_Email_SendingMessage>;
  name: InputMaybe<Scalars['String']>;
  sysTenantId: InputMaybe<Scalars['String']>;
  updateTs: InputMaybe<Scalars['DateTime']>;
  updatedBy: InputMaybe<Scalars['String']>;
  version: InputMaybe<Scalars['Int']>;
};

export type Inp_Email_SendingAttachmentFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_Email_SendingAttachmentFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_Email_SendingAttachmentFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  content: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  contentFile: InputMaybe<Array<InputMaybe<Inp_UploadFilterCondition>>>;
  contentId: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  createTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  deleteTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  deletedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  disposition: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  encoding: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  message: InputMaybe<Array<InputMaybe<Inp_Email_SendingMessageFilterCondition>>>;
  name: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  sysTenantId: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  updateTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  updatedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  version: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
};

export type Inp_Email_SendingAttachmentOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  content: InputMaybe<SortOrder>;
  contentFile: InputMaybe<SortOrder>;
  contentId: InputMaybe<SortOrder>;
  createTs: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  deleteTs: InputMaybe<SortOrder>;
  deletedBy: InputMaybe<SortOrder>;
  disposition: InputMaybe<SortOrder>;
  encoding: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  message: InputMaybe<Inp_Email_SendingMessageOrderBy>;
  name: InputMaybe<SortOrder>;
  sysTenantId: InputMaybe<SortOrder>;
  updateTs: InputMaybe<SortOrder>;
  updatedBy: InputMaybe<SortOrder>;
  version: InputMaybe<SortOrder>;
};

export type Inp_Email_SendingMessage = {
  address: InputMaybe<Scalars['String']>;
  attachments: InputMaybe<Array<InputMaybe<Inp_Email_SendingAttachment>>>;
  attachmentsName: InputMaybe<Scalars['String']>;
  attemptsLimit: InputMaybe<Scalars['Int']>;
  attemptsMade: InputMaybe<Scalars['Int']>;
  bcc: InputMaybe<Scalars['String']>;
  bodyContentType: InputMaybe<Scalars['String']>;
  cc: InputMaybe<Scalars['String']>;
  contentText: InputMaybe<Scalars['String']>;
  contentTextFile: InputMaybe<Scalars['Upload']>;
  createTs: InputMaybe<Scalars['DateTime']>;
  createdBy: InputMaybe<Scalars['String']>;
  dateSent: InputMaybe<Scalars['DateTime']>;
  deadline: InputMaybe<Scalars['DateTime']>;
  deleteTs: InputMaybe<Scalars['DateTime']>;
  deletedBy: InputMaybe<Scalars['String']>;
  from: InputMaybe<Scalars['String']>;
  headers: InputMaybe<Scalars['String']>;
  id: InputMaybe<Scalars['UUID']>;
  important: InputMaybe<Scalars['Boolean']>;
  status: InputMaybe<SendingStatus>;
  subject: InputMaybe<Scalars['String']>;
  sysTenantId: InputMaybe<Scalars['String']>;
  updateTs: InputMaybe<Scalars['DateTime']>;
  updatedBy: InputMaybe<Scalars['String']>;
  version: InputMaybe<Scalars['Int']>;
};

export type Inp_Email_SendingMessageFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_Email_SendingMessageFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_Email_SendingMessageFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  address: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  attachmentsName: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  attemptsLimit: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
  attemptsMade: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
  bcc: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  bodyContentType: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  cc: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  contentText: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  contentTextFile: InputMaybe<Array<InputMaybe<Inp_UploadFilterCondition>>>;
  createTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  dateSent: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  deadline: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  deleteTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  deletedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  from: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  headers: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  important: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  status: InputMaybe<Array<InputMaybe<Inp_SendingStatusFilterCondition>>>;
  subject: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  sysTenantId: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  updateTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  updatedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  version: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
};

export type Inp_Email_SendingMessageOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  address: InputMaybe<SortOrder>;
  attachmentsName: InputMaybe<SortOrder>;
  attemptsLimit: InputMaybe<SortOrder>;
  attemptsMade: InputMaybe<SortOrder>;
  bcc: InputMaybe<SortOrder>;
  bodyContentType: InputMaybe<SortOrder>;
  cc: InputMaybe<SortOrder>;
  contentText: InputMaybe<SortOrder>;
  contentTextFile: InputMaybe<SortOrder>;
  createTs: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  dateSent: InputMaybe<SortOrder>;
  deadline: InputMaybe<SortOrder>;
  deleteTs: InputMaybe<SortOrder>;
  deletedBy: InputMaybe<SortOrder>;
  from: InputMaybe<SortOrder>;
  headers: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  important: InputMaybe<SortOrder>;
  status: InputMaybe<SortOrder>;
  subject: InputMaybe<SortOrder>;
  sysTenantId: InputMaybe<SortOrder>;
  updateTs: InputMaybe<SortOrder>;
  updatedBy: InputMaybe<SortOrder>;
  version: InputMaybe<SortOrder>;
};

export type Inp_Emltmp_EmailTemplate = {
  attachedFiles: InputMaybe<Array<InputMaybe<Inp_Emltmp_EmailTemplateAttachment>>>;
  attachedTemplateReports: InputMaybe<Array<InputMaybe<Inp_Emltmp_TemplateReport>>>;
  bcc: InputMaybe<Scalars['String']>;
  cc: InputMaybe<Scalars['String']>;
  code: InputMaybe<Scalars['String']>;
  createTs: InputMaybe<Scalars['DateTime']>;
  createdBy: InputMaybe<Scalars['String']>;
  deleteTs: InputMaybe<Scalars['DateTime']>;
  deletedBy: InputMaybe<Scalars['String']>;
  from: InputMaybe<Scalars['String']>;
  group: InputMaybe<Inp_Emltmp_TemplateGroup>;
  id: InputMaybe<Scalars['UUID']>;
  name: InputMaybe<Scalars['String']>;
  subject: InputMaybe<Scalars['String']>;
  to: InputMaybe<Scalars['String']>;
  type: InputMaybe<TemplateType>;
  updateTs: InputMaybe<Scalars['DateTime']>;
  updatedBy: InputMaybe<Scalars['String']>;
  useReportSubject: InputMaybe<Scalars['Boolean']>;
  version: InputMaybe<Scalars['Int']>;
};

export type Inp_Emltmp_EmailTemplateAttachment = {
  contentFile: InputMaybe<Scalars['Upload']>;
  createTs: InputMaybe<Scalars['DateTime']>;
  createdBy: InputMaybe<Scalars['String']>;
  deleteTs: InputMaybe<Scalars['DateTime']>;
  deletedBy: InputMaybe<Scalars['String']>;
  emailTemplate: InputMaybe<Inp_Emltmp_EmailTemplate>;
  id: InputMaybe<Scalars['UUID']>;
  name: InputMaybe<Scalars['String']>;
  updateTs: InputMaybe<Scalars['DateTime']>;
  updatedBy: InputMaybe<Scalars['String']>;
  version: InputMaybe<Scalars['Int']>;
};

export type Inp_Emltmp_EmailTemplateAttachmentFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_Emltmp_EmailTemplateAttachmentFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_Emltmp_EmailTemplateAttachmentFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  contentFile: InputMaybe<Array<InputMaybe<Inp_UploadFilterCondition>>>;
  createTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  deleteTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  deletedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  emailTemplate: InputMaybe<Array<InputMaybe<Inp_Emltmp_EmailTemplateFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  name: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  updateTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  updatedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  version: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
};

export type Inp_Emltmp_EmailTemplateAttachmentOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  contentFile: InputMaybe<SortOrder>;
  createTs: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  deleteTs: InputMaybe<SortOrder>;
  deletedBy: InputMaybe<SortOrder>;
  emailTemplate: InputMaybe<Inp_Emltmp_EmailTemplateOrderBy>;
  id: InputMaybe<SortOrder>;
  name: InputMaybe<SortOrder>;
  updateTs: InputMaybe<SortOrder>;
  updatedBy: InputMaybe<SortOrder>;
  version: InputMaybe<SortOrder>;
};

export type Inp_Emltmp_EmailTemplateFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_Emltmp_EmailTemplateFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_Emltmp_EmailTemplateFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  bcc: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  cc: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  code: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  createTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  deleteTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  deletedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  from: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  group: InputMaybe<Array<InputMaybe<Inp_Emltmp_TemplateGroupFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  name: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  subject: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  to: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  type: InputMaybe<Array<InputMaybe<Inp_TemplateTypeFilterCondition>>>;
  updateTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  updatedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  useReportSubject: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  version: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
};

export type Inp_Emltmp_EmailTemplateOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  bcc: InputMaybe<SortOrder>;
  cc: InputMaybe<SortOrder>;
  code: InputMaybe<SortOrder>;
  createTs: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  deleteTs: InputMaybe<SortOrder>;
  deletedBy: InputMaybe<SortOrder>;
  from: InputMaybe<SortOrder>;
  group: InputMaybe<Inp_Emltmp_TemplateGroupOrderBy>;
  id: InputMaybe<SortOrder>;
  name: InputMaybe<SortOrder>;
  subject: InputMaybe<SortOrder>;
  to: InputMaybe<SortOrder>;
  type: InputMaybe<SortOrder>;
  updateTs: InputMaybe<SortOrder>;
  updatedBy: InputMaybe<SortOrder>;
  useReportSubject: InputMaybe<SortOrder>;
  version: InputMaybe<SortOrder>;
};

export type Inp_Emltmp_JsonEmailTemplate = {
  attachedFiles: InputMaybe<Array<InputMaybe<Inp_Emltmp_EmailTemplateAttachment>>>;
  attachedTemplateReports: InputMaybe<Array<InputMaybe<Inp_Emltmp_TemplateReport>>>;
  bcc: InputMaybe<Scalars['String']>;
  cc: InputMaybe<Scalars['String']>;
  code: InputMaybe<Scalars['String']>;
  createTs: InputMaybe<Scalars['DateTime']>;
  createdBy: InputMaybe<Scalars['String']>;
  deleteTs: InputMaybe<Scalars['DateTime']>;
  deletedBy: InputMaybe<Scalars['String']>;
  from: InputMaybe<Scalars['String']>;
  group: InputMaybe<Inp_Emltmp_TemplateGroup>;
  html: InputMaybe<Scalars['String']>;
  id: InputMaybe<Scalars['UUID']>;
  name: InputMaybe<Scalars['String']>;
  reportJson: InputMaybe<Scalars['String']>;
  subject: InputMaybe<Scalars['String']>;
  to: InputMaybe<Scalars['String']>;
  type: InputMaybe<TemplateType>;
  updateTs: InputMaybe<Scalars['DateTime']>;
  updatedBy: InputMaybe<Scalars['String']>;
  useReportSubject: InputMaybe<Scalars['Boolean']>;
  version: InputMaybe<Scalars['Int']>;
};

export type Inp_Emltmp_JsonEmailTemplateFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_Emltmp_JsonEmailTemplateFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_Emltmp_JsonEmailTemplateFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  bcc: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  cc: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  code: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  createTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  deleteTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  deletedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  from: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  group: InputMaybe<Array<InputMaybe<Inp_Emltmp_TemplateGroupFilterCondition>>>;
  html: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  name: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  reportJson: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  subject: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  to: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  type: InputMaybe<Array<InputMaybe<Inp_TemplateTypeFilterCondition>>>;
  updateTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  updatedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  useReportSubject: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  version: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
};

export type Inp_Emltmp_JsonEmailTemplateOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  bcc: InputMaybe<SortOrder>;
  cc: InputMaybe<SortOrder>;
  code: InputMaybe<SortOrder>;
  createTs: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  deleteTs: InputMaybe<SortOrder>;
  deletedBy: InputMaybe<SortOrder>;
  from: InputMaybe<SortOrder>;
  group: InputMaybe<Inp_Emltmp_TemplateGroupOrderBy>;
  html: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  name: InputMaybe<SortOrder>;
  reportJson: InputMaybe<SortOrder>;
  subject: InputMaybe<SortOrder>;
  to: InputMaybe<SortOrder>;
  type: InputMaybe<SortOrder>;
  updateTs: InputMaybe<SortOrder>;
  updatedBy: InputMaybe<SortOrder>;
  useReportSubject: InputMaybe<SortOrder>;
  version: InputMaybe<SortOrder>;
};

export type Inp_Emltmp_ParameterValue = {
  alias: InputMaybe<Scalars['String']>;
  createTs: InputMaybe<Scalars['DateTime']>;
  createdBy: InputMaybe<Scalars['String']>;
  defaultValue: InputMaybe<Scalars['String']>;
  deleteTs: InputMaybe<Scalars['DateTime']>;
  deletedBy: InputMaybe<Scalars['String']>;
  id: InputMaybe<Scalars['UUID']>;
  parameterType: InputMaybe<ParameterType>;
  templateReport: InputMaybe<Inp_Emltmp_TemplateReport>;
  updateTs: InputMaybe<Scalars['DateTime']>;
  updatedBy: InputMaybe<Scalars['String']>;
  version: InputMaybe<Scalars['Int']>;
};

export type Inp_Emltmp_ParameterValueFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_Emltmp_ParameterValueFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_Emltmp_ParameterValueFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  alias: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  createTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  defaultValue: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  deleteTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  deletedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  parameterType: InputMaybe<Array<InputMaybe<Inp_ParameterTypeFilterCondition>>>;
  templateReport: InputMaybe<Array<InputMaybe<Inp_Emltmp_TemplateReportFilterCondition>>>;
  updateTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  updatedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  version: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
};

export type Inp_Emltmp_ParameterValueOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  alias: InputMaybe<SortOrder>;
  createTs: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  defaultValue: InputMaybe<SortOrder>;
  deleteTs: InputMaybe<SortOrder>;
  deletedBy: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  parameterType: InputMaybe<SortOrder>;
  templateReport: InputMaybe<Inp_Emltmp_TemplateReportOrderBy>;
  updateTs: InputMaybe<SortOrder>;
  updatedBy: InputMaybe<SortOrder>;
  version: InputMaybe<SortOrder>;
};

export type Inp_Emltmp_ReportEmailTemplate = {
  attachedFiles: InputMaybe<Array<InputMaybe<Inp_Emltmp_EmailTemplateAttachment>>>;
  attachedTemplateReports: InputMaybe<Array<InputMaybe<Inp_Emltmp_TemplateReport>>>;
  bcc: InputMaybe<Scalars['String']>;
  cc: InputMaybe<Scalars['String']>;
  code: InputMaybe<Scalars['String']>;
  createTs: InputMaybe<Scalars['DateTime']>;
  createdBy: InputMaybe<Scalars['String']>;
  deleteTs: InputMaybe<Scalars['DateTime']>;
  deletedBy: InputMaybe<Scalars['String']>;
  emailBodyReport: InputMaybe<Inp_Emltmp_TemplateReport>;
  from: InputMaybe<Scalars['String']>;
  group: InputMaybe<Inp_Emltmp_TemplateGroup>;
  id: InputMaybe<Scalars['UUID']>;
  name: InputMaybe<Scalars['String']>;
  subject: InputMaybe<Scalars['String']>;
  to: InputMaybe<Scalars['String']>;
  type: InputMaybe<TemplateType>;
  updateTs: InputMaybe<Scalars['DateTime']>;
  updatedBy: InputMaybe<Scalars['String']>;
  useReportSubject: InputMaybe<Scalars['Boolean']>;
  version: InputMaybe<Scalars['Int']>;
};

export type Inp_Emltmp_ReportEmailTemplateFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_Emltmp_ReportEmailTemplateFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_Emltmp_ReportEmailTemplateFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  bcc: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  cc: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  code: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  createTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  deleteTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  deletedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  emailBodyReport: InputMaybe<Array<InputMaybe<Inp_Emltmp_TemplateReportFilterCondition>>>;
  from: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  group: InputMaybe<Array<InputMaybe<Inp_Emltmp_TemplateGroupFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  name: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  subject: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  to: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  type: InputMaybe<Array<InputMaybe<Inp_TemplateTypeFilterCondition>>>;
  updateTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  updatedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  useReportSubject: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  version: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
};

export type Inp_Emltmp_ReportEmailTemplateOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  bcc: InputMaybe<SortOrder>;
  cc: InputMaybe<SortOrder>;
  code: InputMaybe<SortOrder>;
  createTs: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  deleteTs: InputMaybe<SortOrder>;
  deletedBy: InputMaybe<SortOrder>;
  emailBodyReport: InputMaybe<Inp_Emltmp_TemplateReportOrderBy>;
  from: InputMaybe<SortOrder>;
  group: InputMaybe<Inp_Emltmp_TemplateGroupOrderBy>;
  id: InputMaybe<SortOrder>;
  name: InputMaybe<SortOrder>;
  subject: InputMaybe<SortOrder>;
  to: InputMaybe<SortOrder>;
  type: InputMaybe<SortOrder>;
  updateTs: InputMaybe<SortOrder>;
  updatedBy: InputMaybe<SortOrder>;
  useReportSubject: InputMaybe<SortOrder>;
  version: InputMaybe<SortOrder>;
};

export type Inp_Emltmp_TemplateBlock = {
  category: InputMaybe<Inp_Emltmp_TemplateBlockGroup>;
  content: InputMaybe<Scalars['String']>;
  createTs: InputMaybe<Scalars['DateTime']>;
  createdBy: InputMaybe<Scalars['String']>;
  deleteTs: InputMaybe<Scalars['DateTime']>;
  deletedBy: InputMaybe<Scalars['String']>;
  icon: InputMaybe<Scalars['String']>;
  id: InputMaybe<Scalars['UUID']>;
  label: InputMaybe<Scalars['String']>;
  name: InputMaybe<Scalars['String']>;
  updateTs: InputMaybe<Scalars['DateTime']>;
  updatedBy: InputMaybe<Scalars['String']>;
  version: InputMaybe<Scalars['Int']>;
};

export type Inp_Emltmp_TemplateBlockFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_Emltmp_TemplateBlockFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_Emltmp_TemplateBlockFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  category: InputMaybe<Array<InputMaybe<Inp_Emltmp_TemplateBlockGroupFilterCondition>>>;
  content: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  createTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  deleteTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  deletedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  icon: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  label: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  name: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  updateTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  updatedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  version: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
};

export type Inp_Emltmp_TemplateBlockGroup = {
  createTs: InputMaybe<Scalars['DateTime']>;
  createdBy: InputMaybe<Scalars['String']>;
  deleteTs: InputMaybe<Scalars['DateTime']>;
  deletedBy: InputMaybe<Scalars['String']>;
  id: InputMaybe<Scalars['UUID']>;
  name: InputMaybe<Scalars['String']>;
  updateTs: InputMaybe<Scalars['DateTime']>;
  updatedBy: InputMaybe<Scalars['String']>;
  version: InputMaybe<Scalars['Int']>;
};

export type Inp_Emltmp_TemplateBlockGroupFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_Emltmp_TemplateBlockGroupFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_Emltmp_TemplateBlockGroupFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  createTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  deleteTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  deletedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  name: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  updateTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  updatedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  version: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
};

export type Inp_Emltmp_TemplateBlockGroupOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  createTs: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  deleteTs: InputMaybe<SortOrder>;
  deletedBy: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  name: InputMaybe<SortOrder>;
  updateTs: InputMaybe<SortOrder>;
  updatedBy: InputMaybe<SortOrder>;
  version: InputMaybe<SortOrder>;
};

export type Inp_Emltmp_TemplateBlockOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  category: InputMaybe<Inp_Emltmp_TemplateBlockGroupOrderBy>;
  content: InputMaybe<SortOrder>;
  createTs: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  deleteTs: InputMaybe<SortOrder>;
  deletedBy: InputMaybe<SortOrder>;
  icon: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  label: InputMaybe<SortOrder>;
  name: InputMaybe<SortOrder>;
  updateTs: InputMaybe<SortOrder>;
  updatedBy: InputMaybe<SortOrder>;
  version: InputMaybe<SortOrder>;
};

export type Inp_Emltmp_TemplateGroup = {
  createTs: InputMaybe<Scalars['DateTime']>;
  createdBy: InputMaybe<Scalars['String']>;
  deleteTs: InputMaybe<Scalars['DateTime']>;
  deletedBy: InputMaybe<Scalars['String']>;
  id: InputMaybe<Scalars['UUID']>;
  name: InputMaybe<Scalars['String']>;
  updateTs: InputMaybe<Scalars['DateTime']>;
  updatedBy: InputMaybe<Scalars['String']>;
  version: InputMaybe<Scalars['Int']>;
};

export type Inp_Emltmp_TemplateGroupFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_Emltmp_TemplateGroupFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_Emltmp_TemplateGroupFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  createTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  deleteTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  deletedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  name: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  updateTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  updatedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  version: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
};

export type Inp_Emltmp_TemplateGroupOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  createTs: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  deleteTs: InputMaybe<SortOrder>;
  deletedBy: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  name: InputMaybe<SortOrder>;
  updateTs: InputMaybe<SortOrder>;
  updatedBy: InputMaybe<SortOrder>;
  version: InputMaybe<SortOrder>;
};

export type Inp_Emltmp_TemplateReport = {
  createTs: InputMaybe<Scalars['DateTime']>;
  createdBy: InputMaybe<Scalars['String']>;
  deleteTs: InputMaybe<Scalars['DateTime']>;
  deletedBy: InputMaybe<Scalars['String']>;
  emailTemplate: InputMaybe<Inp_Emltmp_EmailTemplate>;
  id: InputMaybe<Scalars['UUID']>;
  name: InputMaybe<Scalars['String']>;
  parameterValues: InputMaybe<Array<InputMaybe<Inp_Emltmp_ParameterValue>>>;
  report: InputMaybe<Inp_Report_Report>;
  updateTs: InputMaybe<Scalars['DateTime']>;
  updatedBy: InputMaybe<Scalars['String']>;
  version: InputMaybe<Scalars['Int']>;
};

export type Inp_Emltmp_TemplateReportFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_Emltmp_TemplateReportFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_Emltmp_TemplateReportFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  createTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  deleteTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  deletedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  emailTemplate: InputMaybe<Array<InputMaybe<Inp_Emltmp_EmailTemplateFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  name: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  report: InputMaybe<Array<InputMaybe<Inp_Report_ReportFilterCondition>>>;
  updateTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  updatedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  version: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
};

export type Inp_Emltmp_TemplateReportOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  createTs: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  deleteTs: InputMaybe<SortOrder>;
  deletedBy: InputMaybe<SortOrder>;
  emailTemplate: InputMaybe<Inp_Emltmp_EmailTemplateOrderBy>;
  id: InputMaybe<SortOrder>;
  name: InputMaybe<SortOrder>;
  report: InputMaybe<Inp_Report_ReportOrderBy>;
  updateTs: InputMaybe<SortOrder>;
  updatedBy: InputMaybe<SortOrder>;
  version: InputMaybe<SortOrder>;
};

export type Inp_Report_Report = {
  bands: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  code: InputMaybe<Scalars['String']>;
  createTs: InputMaybe<Scalars['DateTime']>;
  createdBy: InputMaybe<Scalars['String']>;
  defaultTemplate: InputMaybe<Inp_Report_ReportTemplate>;
  deleteTs: InputMaybe<Scalars['DateTime']>;
  deletedBy: InputMaybe<Scalars['String']>;
  description: InputMaybe<Scalars['String']>;
  group: InputMaybe<Inp_Report_ReportGroup>;
  id: InputMaybe<Scalars['UUID']>;
  inputEntityTypesIdx: InputMaybe<Scalars['String']>;
  inputParameters: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  localeNames: InputMaybe<Scalars['String']>;
  name: InputMaybe<Scalars['String']>;
  reportRoles: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  reportScreens: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  reportType: InputMaybe<ReportType>;
  restAccess: InputMaybe<Scalars['Boolean']>;
  rolesIdx: InputMaybe<Scalars['String']>;
  rootBandDefinition: InputMaybe<Scalars['String']>;
  screensIdx: InputMaybe<Scalars['String']>;
  sysTenantId: InputMaybe<Scalars['String']>;
  system: InputMaybe<Scalars['Boolean']>;
  templates: InputMaybe<Array<InputMaybe<Inp_Report_ReportTemplate>>>;
  updateTs: InputMaybe<Scalars['DateTime']>;
  updatedBy: InputMaybe<Scalars['String']>;
  validationOn: InputMaybe<Scalars['Boolean']>;
  validationScript: InputMaybe<Scalars['String']>;
  valuesFormats: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  version: InputMaybe<Scalars['Int']>;
  xml: InputMaybe<Scalars['String']>;
};

export type Inp_Report_ReportExecution = {
  cancelled: InputMaybe<Scalars['Boolean']>;
  createTs: InputMaybe<Scalars['DateTime']>;
  createdBy: InputMaybe<Scalars['String']>;
  errorMessage: InputMaybe<Scalars['String']>;
  executionTimeSec: InputMaybe<Scalars['Long']>;
  finishTime: InputMaybe<Scalars['DateTime']>;
  id: InputMaybe<Scalars['UUID']>;
  outputDocument: InputMaybe<Scalars['Upload']>;
  params: InputMaybe<Scalars['String']>;
  report: InputMaybe<Inp_Report_Report>;
  reportCode: InputMaybe<Scalars['String']>;
  reportName: InputMaybe<Scalars['String']>;
  startTime: InputMaybe<Scalars['DateTime']>;
  success: InputMaybe<Scalars['Boolean']>;
  username: InputMaybe<Scalars['String']>;
};

export type Inp_Report_ReportExecutionFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_Report_ReportExecutionFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_Report_ReportExecutionFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  cancelled: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  createTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  errorMessage: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  executionTimeSec: InputMaybe<Array<InputMaybe<Inp_LongFilterCondition>>>;
  finishTime: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  outputDocument: InputMaybe<Array<InputMaybe<Inp_UploadFilterCondition>>>;
  params: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  report: InputMaybe<Array<InputMaybe<Inp_Report_ReportFilterCondition>>>;
  reportCode: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  reportName: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  startTime: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  success: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  username: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
};

export type Inp_Report_ReportExecutionOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  cancelled: InputMaybe<SortOrder>;
  createTs: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  errorMessage: InputMaybe<SortOrder>;
  executionTimeSec: InputMaybe<SortOrder>;
  finishTime: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  outputDocument: InputMaybe<SortOrder>;
  params: InputMaybe<SortOrder>;
  report: InputMaybe<Inp_Report_ReportOrderBy>;
  reportCode: InputMaybe<SortOrder>;
  reportName: InputMaybe<SortOrder>;
  startTime: InputMaybe<SortOrder>;
  success: InputMaybe<SortOrder>;
  username: InputMaybe<SortOrder>;
};

export type Inp_Report_ReportFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_Report_ReportFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_Report_ReportFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  code: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  createTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  defaultTemplate: InputMaybe<Array<InputMaybe<Inp_Report_ReportTemplateFilterCondition>>>;
  deleteTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  deletedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  description: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  group: InputMaybe<Array<InputMaybe<Inp_Report_ReportGroupFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  inputEntityTypesIdx: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  localeNames: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  name: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  reportType: InputMaybe<Array<InputMaybe<Inp_ReportTypeFilterCondition>>>;
  restAccess: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  rolesIdx: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  rootBandDefinition: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  screensIdx: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  sysTenantId: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  system: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  updateTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  updatedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  validationOn: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  validationScript: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  version: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
  xml: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
};

export type Inp_Report_ReportGroup = {
  code: InputMaybe<Scalars['String']>;
  createTs: InputMaybe<Scalars['DateTime']>;
  createdBy: InputMaybe<Scalars['String']>;
  deleteTs: InputMaybe<Scalars['DateTime']>;
  deletedBy: InputMaybe<Scalars['String']>;
  id: InputMaybe<Scalars['UUID']>;
  localeNames: InputMaybe<Scalars['String']>;
  sysTenantId: InputMaybe<Scalars['String']>;
  systemFlag: InputMaybe<Scalars['Boolean']>;
  title: InputMaybe<Scalars['String']>;
  updateTs: InputMaybe<Scalars['DateTime']>;
  updatedBy: InputMaybe<Scalars['String']>;
  version: InputMaybe<Scalars['Int']>;
};

export type Inp_Report_ReportGroupFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_Report_ReportGroupFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_Report_ReportGroupFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  code: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  createTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  deleteTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  deletedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  localeNames: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  sysTenantId: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  systemFlag: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  title: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  updateTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  updatedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  version: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
};

export type Inp_Report_ReportGroupOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  code: InputMaybe<SortOrder>;
  createTs: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  deleteTs: InputMaybe<SortOrder>;
  deletedBy: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  localeNames: InputMaybe<SortOrder>;
  sysTenantId: InputMaybe<SortOrder>;
  systemFlag: InputMaybe<SortOrder>;
  title: InputMaybe<SortOrder>;
  updateTs: InputMaybe<SortOrder>;
  updatedBy: InputMaybe<SortOrder>;
  version: InputMaybe<SortOrder>;
};

export type Inp_Report_ReportOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  code: InputMaybe<SortOrder>;
  createTs: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  defaultTemplate: InputMaybe<Inp_Report_ReportTemplateOrderBy>;
  deleteTs: InputMaybe<SortOrder>;
  deletedBy: InputMaybe<SortOrder>;
  description: InputMaybe<SortOrder>;
  group: InputMaybe<Inp_Report_ReportGroupOrderBy>;
  id: InputMaybe<SortOrder>;
  inputEntityTypesIdx: InputMaybe<SortOrder>;
  localeNames: InputMaybe<SortOrder>;
  name: InputMaybe<SortOrder>;
  reportType: InputMaybe<SortOrder>;
  restAccess: InputMaybe<SortOrder>;
  rolesIdx: InputMaybe<SortOrder>;
  screensIdx: InputMaybe<SortOrder>;
  sysTenantId: InputMaybe<SortOrder>;
  system: InputMaybe<SortOrder>;
  updateTs: InputMaybe<SortOrder>;
  updatedBy: InputMaybe<SortOrder>;
  validationOn: InputMaybe<SortOrder>;
  validationScript: InputMaybe<SortOrder>;
  version: InputMaybe<SortOrder>;
  xml: InputMaybe<SortOrder>;
};

export type Inp_Report_ReportTemplate = {
  alterable: InputMaybe<Scalars['Boolean']>;
  code: InputMaybe<Scalars['String']>;
  content: InputMaybe<Scalars['String']>;
  createTs: InputMaybe<Scalars['DateTime']>;
  createdBy: InputMaybe<Scalars['String']>;
  custom: InputMaybe<Scalars['Boolean']>;
  customDefinedBy: InputMaybe<CustomTemplateDefinedBy>;
  customDefinition: InputMaybe<Scalars['String']>;
  deleteTs: InputMaybe<Scalars['DateTime']>;
  deletedBy: InputMaybe<Scalars['String']>;
  groovy: InputMaybe<Scalars['Boolean']>;
  id: InputMaybe<Scalars['UUID']>;
  name: InputMaybe<Scalars['String']>;
  outputNamePattern: InputMaybe<Scalars['String']>;
  report: InputMaybe<Inp_Report_Report>;
  reportOutputType: InputMaybe<ReportOutputType>;
  updateTs: InputMaybe<Scalars['DateTime']>;
  updatedBy: InputMaybe<Scalars['String']>;
  version: InputMaybe<Scalars['Int']>;
};

export type Inp_Report_ReportTemplateFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_Report_ReportTemplateFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_Report_ReportTemplateFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  alterable: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  code: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  content: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  createTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  custom: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  customDefinedBy: InputMaybe<Array<InputMaybe<Inp_CustomTemplateDefinedByFilterCondition>>>;
  customDefinition: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  deleteTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  deletedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  groovy: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  name: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  outputNamePattern: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  report: InputMaybe<Array<InputMaybe<Inp_Report_ReportFilterCondition>>>;
  reportOutputType: InputMaybe<Array<InputMaybe<Inp_ReportOutputTypeFilterCondition>>>;
  updateTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  updatedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  version: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
};

export type Inp_Report_ReportTemplateOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  alterable: InputMaybe<SortOrder>;
  code: InputMaybe<SortOrder>;
  content: InputMaybe<SortOrder>;
  createTs: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  custom: InputMaybe<SortOrder>;
  customDefinedBy: InputMaybe<SortOrder>;
  customDefinition: InputMaybe<SortOrder>;
  deleteTs: InputMaybe<SortOrder>;
  deletedBy: InputMaybe<SortOrder>;
  groovy: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  name: InputMaybe<SortOrder>;
  outputNamePattern: InputMaybe<SortOrder>;
  report: InputMaybe<Inp_Report_ReportOrderBy>;
  reportOutputType: InputMaybe<SortOrder>;
  updateTs: InputMaybe<SortOrder>;
  updatedBy: InputMaybe<SortOrder>;
  version: InputMaybe<SortOrder>;
};

export type Inp_Sec_ResourcePolicyEntity = {
  action: InputMaybe<Scalars['String']>;
  createTs: InputMaybe<Scalars['DateTime']>;
  createdBy: InputMaybe<Scalars['String']>;
  deleteTs: InputMaybe<Scalars['DateTime']>;
  deletedBy: InputMaybe<Scalars['String']>;
  effect: InputMaybe<Scalars['String']>;
  id: InputMaybe<Scalars['UUID']>;
  policyGroup: InputMaybe<Scalars['String']>;
  resource: InputMaybe<Scalars['String']>;
  role: InputMaybe<Inp_Sec_ResourceRoleEntity>;
  type: InputMaybe<Scalars['String']>;
  updateTs: InputMaybe<Scalars['DateTime']>;
  updatedBy: InputMaybe<Scalars['String']>;
  version: InputMaybe<Scalars['Int']>;
};

export type Inp_Sec_ResourcePolicyEntityFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_Sec_ResourcePolicyEntityFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_Sec_ResourcePolicyEntityFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  action: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  createTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  deleteTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  deletedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  effect: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  policyGroup: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  resource: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  role: InputMaybe<Array<InputMaybe<Inp_Sec_ResourceRoleEntityFilterCondition>>>;
  type: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  updateTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  updatedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  version: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
};

export type Inp_Sec_ResourcePolicyEntityOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  action: InputMaybe<SortOrder>;
  createTs: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  deleteTs: InputMaybe<SortOrder>;
  deletedBy: InputMaybe<SortOrder>;
  effect: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  policyGroup: InputMaybe<SortOrder>;
  resource: InputMaybe<SortOrder>;
  role: InputMaybe<Inp_Sec_ResourceRoleEntityOrderBy>;
  type: InputMaybe<SortOrder>;
  updateTs: InputMaybe<SortOrder>;
  updatedBy: InputMaybe<SortOrder>;
  version: InputMaybe<SortOrder>;
};

export type Inp_Sec_ResourceRoleEntity = {
  childRoles: InputMaybe<Scalars['String']>;
  code: InputMaybe<Scalars['String']>;
  createTs: InputMaybe<Scalars['DateTime']>;
  createdBy: InputMaybe<Scalars['String']>;
  deleteTs: InputMaybe<Scalars['DateTime']>;
  deletedBy: InputMaybe<Scalars['String']>;
  description: InputMaybe<Scalars['String']>;
  id: InputMaybe<Scalars['UUID']>;
  name: InputMaybe<Scalars['String']>;
  resourcePolicies: InputMaybe<Array<InputMaybe<Inp_Sec_ResourcePolicyEntity>>>;
  scopes: InputMaybe<Scalars['String']>;
  sysTenantId: InputMaybe<Scalars['String']>;
  updateTs: InputMaybe<Scalars['DateTime']>;
  updatedBy: InputMaybe<Scalars['String']>;
  version: InputMaybe<Scalars['Int']>;
};

export type Inp_Sec_ResourceRoleEntityFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_Sec_ResourceRoleEntityFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_Sec_ResourceRoleEntityFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  childRoles: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  code: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  createTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  deleteTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  deletedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  description: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  name: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  scopes: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  sysTenantId: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  updateTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  updatedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  version: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
};

export type Inp_Sec_ResourceRoleEntityOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  childRoles: InputMaybe<SortOrder>;
  code: InputMaybe<SortOrder>;
  createTs: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  deleteTs: InputMaybe<SortOrder>;
  deletedBy: InputMaybe<SortOrder>;
  description: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  name: InputMaybe<SortOrder>;
  scopes: InputMaybe<SortOrder>;
  sysTenantId: InputMaybe<SortOrder>;
  updateTs: InputMaybe<SortOrder>;
  updatedBy: InputMaybe<SortOrder>;
  version: InputMaybe<SortOrder>;
};

export type Inp_Sec_RoleAssignmentEntity = {
  createTs: InputMaybe<Scalars['DateTime']>;
  createdBy: InputMaybe<Scalars['String']>;
  deleteTs: InputMaybe<Scalars['DateTime']>;
  deletedBy: InputMaybe<Scalars['String']>;
  id: InputMaybe<Scalars['UUID']>;
  roleCode: InputMaybe<Scalars['String']>;
  roleType: InputMaybe<Scalars['String']>;
  updateTs: InputMaybe<Scalars['DateTime']>;
  updatedBy: InputMaybe<Scalars['String']>;
  username: InputMaybe<Scalars['String']>;
  version: InputMaybe<Scalars['Int']>;
};

export type Inp_Sec_RoleAssignmentEntityFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_Sec_RoleAssignmentEntityFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_Sec_RoleAssignmentEntityFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  createTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  deleteTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  deletedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  roleCode: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  roleType: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  updateTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  updatedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  username: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  version: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
};

export type Inp_Sec_RoleAssignmentEntityOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  createTs: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  deleteTs: InputMaybe<SortOrder>;
  deletedBy: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  roleCode: InputMaybe<SortOrder>;
  roleType: InputMaybe<SortOrder>;
  updateTs: InputMaybe<SortOrder>;
  updatedBy: InputMaybe<SortOrder>;
  username: InputMaybe<SortOrder>;
  version: InputMaybe<SortOrder>;
};

export type Inp_Sec_RowLevelPolicyEntity = {
  action: InputMaybe<RowLevelPolicyAction>;
  createTs: InputMaybe<Scalars['DateTime']>;
  createdBy: InputMaybe<Scalars['String']>;
  deleteTs: InputMaybe<Scalars['DateTime']>;
  deletedBy: InputMaybe<Scalars['String']>;
  entityName: InputMaybe<Scalars['String']>;
  id: InputMaybe<Scalars['UUID']>;
  joinClause: InputMaybe<Scalars['String']>;
  role: InputMaybe<Inp_Sec_RowLevelRoleEntity>;
  script: InputMaybe<Scalars['String']>;
  type: InputMaybe<RowLevelPolicyType>;
  updateTs: InputMaybe<Scalars['DateTime']>;
  updatedBy: InputMaybe<Scalars['String']>;
  version: InputMaybe<Scalars['Int']>;
  whereClause: InputMaybe<Scalars['String']>;
};

export type Inp_Sec_RowLevelPolicyEntityFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_Sec_RowLevelPolicyEntityFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_Sec_RowLevelPolicyEntityFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  action: InputMaybe<Array<InputMaybe<Inp_RowLevelPolicyActionFilterCondition>>>;
  createTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  deleteTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  deletedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  entityName: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  joinClause: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  role: InputMaybe<Array<InputMaybe<Inp_Sec_RowLevelRoleEntityFilterCondition>>>;
  script: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  type: InputMaybe<Array<InputMaybe<Inp_RowLevelPolicyTypeFilterCondition>>>;
  updateTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  updatedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  version: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
  whereClause: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
};

export type Inp_Sec_RowLevelPolicyEntityOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  action: InputMaybe<SortOrder>;
  createTs: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  deleteTs: InputMaybe<SortOrder>;
  deletedBy: InputMaybe<SortOrder>;
  entityName: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  joinClause: InputMaybe<SortOrder>;
  role: InputMaybe<Inp_Sec_RowLevelRoleEntityOrderBy>;
  script: InputMaybe<SortOrder>;
  type: InputMaybe<SortOrder>;
  updateTs: InputMaybe<SortOrder>;
  updatedBy: InputMaybe<SortOrder>;
  version: InputMaybe<SortOrder>;
  whereClause: InputMaybe<SortOrder>;
};

export type Inp_Sec_RowLevelRoleEntity = {
  childRoles: InputMaybe<Scalars['String']>;
  code: InputMaybe<Scalars['String']>;
  createTs: InputMaybe<Scalars['DateTime']>;
  createdBy: InputMaybe<Scalars['String']>;
  deleteTs: InputMaybe<Scalars['DateTime']>;
  deletedBy: InputMaybe<Scalars['String']>;
  description: InputMaybe<Scalars['String']>;
  id: InputMaybe<Scalars['UUID']>;
  name: InputMaybe<Scalars['String']>;
  rowLevelPolicies: InputMaybe<Array<InputMaybe<Inp_Sec_RowLevelPolicyEntity>>>;
  sysTenantId: InputMaybe<Scalars['String']>;
  updateTs: InputMaybe<Scalars['DateTime']>;
  updatedBy: InputMaybe<Scalars['String']>;
  version: InputMaybe<Scalars['Int']>;
};

export type Inp_Sec_RowLevelRoleEntityFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_Sec_RowLevelRoleEntityFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_Sec_RowLevelRoleEntityFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  childRoles: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  code: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  createTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  deleteTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  deletedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  description: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  name: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  sysTenantId: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  updateTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  updatedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  version: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
};

export type Inp_Sec_RowLevelRoleEntityOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  childRoles: InputMaybe<SortOrder>;
  code: InputMaybe<SortOrder>;
  createTs: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  deleteTs: InputMaybe<SortOrder>;
  deletedBy: InputMaybe<SortOrder>;
  description: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  name: InputMaybe<SortOrder>;
  sysTenantId: InputMaybe<SortOrder>;
  updateTs: InputMaybe<SortOrder>;
  updatedBy: InputMaybe<SortOrder>;
  version: InputMaybe<SortOrder>;
};

export type Inp_Sec_UserSubstitutionEntity = {
  createTs: InputMaybe<Scalars['DateTime']>;
  createdBy: InputMaybe<Scalars['String']>;
  deleteTs: InputMaybe<Scalars['DateTime']>;
  deletedBy: InputMaybe<Scalars['String']>;
  endDate: InputMaybe<Scalars['Date']>;
  id: InputMaybe<Scalars['UUID']>;
  startDate: InputMaybe<Scalars['Date']>;
  substitutedUsername: InputMaybe<Scalars['String']>;
  updateTs: InputMaybe<Scalars['DateTime']>;
  updatedBy: InputMaybe<Scalars['String']>;
  username: InputMaybe<Scalars['String']>;
  version: InputMaybe<Scalars['Int']>;
};

export type Inp_Sec_UserSubstitutionEntityFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_Sec_UserSubstitutionEntityFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_Sec_UserSubstitutionEntityFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  createTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  deleteTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  deletedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  endDate: InputMaybe<Array<InputMaybe<Inp_DateFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  startDate: InputMaybe<Array<InputMaybe<Inp_DateFilterCondition>>>;
  substitutedUsername: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  updateTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  updatedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  username: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  version: InputMaybe<Array<InputMaybe<Inp_IntFilterCondition>>>;
};

export type Inp_Sec_UserSubstitutionEntityOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  createTs: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  deleteTs: InputMaybe<SortOrder>;
  deletedBy: InputMaybe<SortOrder>;
  endDate: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  startDate: InputMaybe<SortOrder>;
  substitutedUsername: InputMaybe<SortOrder>;
  updateTs: InputMaybe<SortOrder>;
  updatedBy: InputMaybe<SortOrder>;
  username: InputMaybe<SortOrder>;
  version: InputMaybe<SortOrder>;
};

export type Inp_Ui_FilterConfiguration = {
  componentId: InputMaybe<Scalars['String']>;
  configurationId: InputMaybe<Scalars['String']>;
  defaultForAll: InputMaybe<Scalars['Boolean']>;
  defaultForMe: InputMaybe<Scalars['Boolean']>;
  id: InputMaybe<Scalars['UUID']>;
  name: InputMaybe<Scalars['String']>;
  rootCondition: InputMaybe<Scalars['String']>;
  sysTenantId: InputMaybe<Scalars['String']>;
  username: InputMaybe<Scalars['String']>;
};

export type Inp_Ui_FilterConfigurationFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_Ui_FilterConfigurationFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_Ui_FilterConfigurationFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  componentId: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  configurationId: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  defaultForAll: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  defaultForMe: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  name: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  rootCondition: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  sysTenantId: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  username: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
};

export type Inp_Ui_FilterConfigurationOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  componentId: InputMaybe<SortOrder>;
  configurationId: InputMaybe<SortOrder>;
  defaultForAll: InputMaybe<SortOrder>;
  defaultForMe: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  name: InputMaybe<SortOrder>;
  sysTenantId: InputMaybe<SortOrder>;
  username: InputMaybe<SortOrder>;
};

export type Inp_Ui_Setting = {
  createTs: InputMaybe<Scalars['DateTime']>;
  createdBy: InputMaybe<Scalars['String']>;
  id: InputMaybe<Scalars['UUID']>;
  name: InputMaybe<Scalars['String']>;
  username: InputMaybe<Scalars['String']>;
  value: InputMaybe<Scalars['String']>;
};

export type Inp_Ui_SettingFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_Ui_SettingFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_Ui_SettingFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  createTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  name: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  username: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  value: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
};

export type Inp_Ui_SettingOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  createTs: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  name: InputMaybe<SortOrder>;
  username: InputMaybe<SortOrder>;
  value: InputMaybe<SortOrder>;
};

export type Inp_Ui_TablePresentation = {
  autoSave: InputMaybe<Scalars['Boolean']>;
  componentId: InputMaybe<Scalars['String']>;
  createTs: InputMaybe<Scalars['DateTime']>;
  createdBy: InputMaybe<Scalars['String']>;
  id: InputMaybe<Scalars['UUID']>;
  isDefault: InputMaybe<Scalars['Boolean']>;
  name: InputMaybe<Scalars['String']>;
  settings: InputMaybe<Scalars['String']>;
  sysTenantId: InputMaybe<Scalars['String']>;
  updateTs: InputMaybe<Scalars['DateTime']>;
  updatedBy: InputMaybe<Scalars['String']>;
  username: InputMaybe<Scalars['String']>;
};

export type Inp_Ui_TablePresentationFilterCondition = {
  AND: InputMaybe<Array<InputMaybe<Inp_Ui_TablePresentationFilterCondition>>>;
  OR: InputMaybe<Array<InputMaybe<Inp_Ui_TablePresentationFilterCondition>>>;
  /** is null */
  _isNull: InputMaybe<Scalars['Boolean']>;
  autoSave: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  componentId: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  createTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  createdBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  id: InputMaybe<Array<InputMaybe<Inp_UuidFilterCondition>>>;
  isDefault: InputMaybe<Array<InputMaybe<Inp_BooleanFilterCondition>>>;
  name: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  settings: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  sysTenantId: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  updateTs: InputMaybe<Array<InputMaybe<Inp_DateTimeFilterCondition>>>;
  updatedBy: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
  username: InputMaybe<Array<InputMaybe<Inp_StringFilterCondition>>>;
};

export type Inp_Ui_TablePresentationOrderBy = {
  _instanceName: InputMaybe<SortOrder>;
  autoSave: InputMaybe<SortOrder>;
  componentId: InputMaybe<SortOrder>;
  createTs: InputMaybe<SortOrder>;
  createdBy: InputMaybe<SortOrder>;
  id: InputMaybe<SortOrder>;
  isDefault: InputMaybe<SortOrder>;
  name: InputMaybe<SortOrder>;
  settings: InputMaybe<SortOrder>;
  sysTenantId: InputMaybe<SortOrder>;
  updateTs: InputMaybe<SortOrder>;
  updatedBy: InputMaybe<SortOrder>;
  username: InputMaybe<SortOrder>;
};

export type Report_Report = {
  __typename?: 'report_Report';
  _instanceName: Maybe<Scalars['String']>;
  bands: Maybe<Array<Maybe<Scalars['String']>>>;
  code: Maybe<Scalars['String']>;
  createTs: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  defaultTemplate: Maybe<Report_ReportTemplate>;
  deleteTs: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  group: Maybe<Report_ReportGroup>;
  id: Maybe<Scalars['UUID']>;
  inputEntityTypesIdx: Maybe<Scalars['String']>;
  inputParameters: Maybe<Array<Maybe<Scalars['String']>>>;
  localeNames: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  reportRoles: Maybe<Array<Maybe<Scalars['String']>>>;
  reportScreens: Maybe<Array<Maybe<Scalars['String']>>>;
  reportType: Maybe<ReportType>;
  restAccess: Maybe<Scalars['Boolean']>;
  rolesIdx: Maybe<Scalars['String']>;
  rootBandDefinition: Maybe<Scalars['String']>;
  screensIdx: Maybe<Scalars['String']>;
  sysTenantId: Maybe<Scalars['String']>;
  system: Maybe<Scalars['Boolean']>;
  templates: Maybe<Array<Maybe<Report_ReportTemplate>>>;
  updateTs: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['String']>;
  validationOn: Maybe<Scalars['Boolean']>;
  validationScript: Maybe<Scalars['String']>;
  valuesFormats: Maybe<Array<Maybe<Scalars['String']>>>;
  version: Maybe<Scalars['Int']>;
  xml: Maybe<Scalars['String']>;
};

export type Report_ReportExecution = {
  __typename?: 'report_ReportExecution';
  _instanceName: Maybe<Scalars['String']>;
  cancelled: Maybe<Scalars['Boolean']>;
  createTs: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  errorMessage: Maybe<Scalars['String']>;
  executionTimeSec: Maybe<Scalars['Long']>;
  finishTime: Maybe<Scalars['DateTime']>;
  id: Maybe<Scalars['UUID']>;
  outputDocument: Maybe<Scalars['Upload']>;
  params: Maybe<Scalars['String']>;
  report: Maybe<Report_Report>;
  reportCode: Maybe<Scalars['String']>;
  reportName: Maybe<Scalars['String']>;
  startTime: Maybe<Scalars['DateTime']>;
  success: Maybe<Scalars['Boolean']>;
  username: Maybe<Scalars['String']>;
};

export type Report_ReportGroup = {
  __typename?: 'report_ReportGroup';
  _instanceName: Maybe<Scalars['String']>;
  code: Maybe<Scalars['String']>;
  createTs: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  deleteTs: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  localeNames: Maybe<Scalars['String']>;
  sysTenantId: Maybe<Scalars['String']>;
  systemFlag: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  updateTs: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['String']>;
  version: Maybe<Scalars['Int']>;
};

export type Report_ReportTemplate = {
  __typename?: 'report_ReportTemplate';
  _instanceName: Maybe<Scalars['String']>;
  alterable: Maybe<Scalars['Boolean']>;
  code: Maybe<Scalars['String']>;
  content: Maybe<Scalars['String']>;
  createTs: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  custom: Maybe<Scalars['Boolean']>;
  customDefinedBy: Maybe<CustomTemplateDefinedBy>;
  customDefinition: Maybe<Scalars['String']>;
  deleteTs: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<Scalars['String']>;
  groovy: Maybe<Scalars['Boolean']>;
  id: Maybe<Scalars['UUID']>;
  name: Maybe<Scalars['String']>;
  outputNamePattern: Maybe<Scalars['String']>;
  report: Maybe<Report_Report>;
  reportOutputType: Maybe<ReportOutputType>;
  updateTs: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['String']>;
  version: Maybe<Scalars['Int']>;
};

export type Sec_Permission = {
  __typename?: 'sec_Permission';
  target: Maybe<Scalars['String']>;
  value: Maybe<Scalars['Int']>;
};

export type Sec_PermissionConfig = {
  __typename?: 'sec_PermissionConfig';
  entities: Maybe<Array<Maybe<Sec_Permission>>>;
  entityAttributes: Maybe<Array<Maybe<Sec_Permission>>>;
  specifics: Maybe<Array<Maybe<Sec_Permission>>>;
};

export type Sec_ResourcePolicyEntity = {
  __typename?: 'sec_ResourcePolicyEntity';
  _instanceName: Maybe<Scalars['String']>;
  action: Maybe<Scalars['String']>;
  createTs: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  deleteTs: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<Scalars['String']>;
  effect: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  policyGroup: Maybe<Scalars['String']>;
  resource: Maybe<Scalars['String']>;
  role: Maybe<Sec_ResourceRoleEntity>;
  type: Maybe<Scalars['String']>;
  updateTs: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['String']>;
  version: Maybe<Scalars['Int']>;
};

export type Sec_ResourceRoleEntity = {
  __typename?: 'sec_ResourceRoleEntity';
  _instanceName: Maybe<Scalars['String']>;
  childRoles: Maybe<Scalars['String']>;
  code: Maybe<Scalars['String']>;
  createTs: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  deleteTs: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  name: Maybe<Scalars['String']>;
  resourcePolicies: Maybe<Array<Maybe<Sec_ResourcePolicyEntity>>>;
  scopes: Maybe<Scalars['String']>;
  sysTenantId: Maybe<Scalars['String']>;
  updateTs: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['String']>;
  version: Maybe<Scalars['Int']>;
};

export type Sec_RoleAssignmentEntity = {
  __typename?: 'sec_RoleAssignmentEntity';
  _instanceName: Maybe<Scalars['String']>;
  createTs: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  deleteTs: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  roleCode: Maybe<Scalars['String']>;
  roleType: Maybe<Scalars['String']>;
  updateTs: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['String']>;
  username: Maybe<Scalars['String']>;
  version: Maybe<Scalars['Int']>;
};

export type Sec_RowLevelPolicyEntity = {
  __typename?: 'sec_RowLevelPolicyEntity';
  _instanceName: Maybe<Scalars['String']>;
  action: Maybe<RowLevelPolicyAction>;
  createTs: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  deleteTs: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<Scalars['String']>;
  entityName: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  joinClause: Maybe<Scalars['String']>;
  role: Maybe<Sec_RowLevelRoleEntity>;
  script: Maybe<Scalars['String']>;
  type: Maybe<RowLevelPolicyType>;
  updateTs: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['String']>;
  version: Maybe<Scalars['Int']>;
  whereClause: Maybe<Scalars['String']>;
};

export type Sec_RowLevelRoleEntity = {
  __typename?: 'sec_RowLevelRoleEntity';
  _instanceName: Maybe<Scalars['String']>;
  childRoles: Maybe<Scalars['String']>;
  code: Maybe<Scalars['String']>;
  createTs: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  deleteTs: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  name: Maybe<Scalars['String']>;
  rowLevelPolicies: Maybe<Array<Maybe<Sec_RowLevelPolicyEntity>>>;
  sysTenantId: Maybe<Scalars['String']>;
  updateTs: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['String']>;
  version: Maybe<Scalars['Int']>;
};

export type Sec_UserSubstitutionEntity = {
  __typename?: 'sec_UserSubstitutionEntity';
  _instanceName: Maybe<Scalars['String']>;
  createTs: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  deleteTs: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<Scalars['String']>;
  endDate: Maybe<Scalars['Date']>;
  id: Maybe<Scalars['UUID']>;
  startDate: Maybe<Scalars['Date']>;
  substitutedUsername: Maybe<Scalars['String']>;
  updateTs: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['String']>;
  username: Maybe<Scalars['String']>;
  version: Maybe<Scalars['Int']>;
};

export type Ui_FilterConfiguration = {
  __typename?: 'ui_FilterConfiguration';
  _instanceName: Maybe<Scalars['String']>;
  componentId: Maybe<Scalars['String']>;
  configurationId: Maybe<Scalars['String']>;
  defaultForAll: Maybe<Scalars['Boolean']>;
  defaultForMe: Maybe<Scalars['Boolean']>;
  id: Maybe<Scalars['UUID']>;
  name: Maybe<Scalars['String']>;
  rootCondition: Maybe<Scalars['String']>;
  sysTenantId: Maybe<Scalars['String']>;
  username: Maybe<Scalars['String']>;
};

export type Ui_Setting = {
  __typename?: 'ui_Setting';
  _instanceName: Maybe<Scalars['String']>;
  createTs: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  name: Maybe<Scalars['String']>;
  username: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

export type Ui_TablePresentation = {
  __typename?: 'ui_TablePresentation';
  _instanceName: Maybe<Scalars['String']>;
  autoSave: Maybe<Scalars['Boolean']>;
  componentId: Maybe<Scalars['String']>;
  createTs: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['String']>;
  id: Maybe<Scalars['UUID']>;
  isDefault: Maybe<Scalars['Boolean']>;
  name: Maybe<Scalars['String']>;
  settings: Maybe<Scalars['String']>;
  sysTenantId: Maybe<Scalars['String']>;
  updateTs: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['String']>;
  username: Maybe<Scalars['String']>;
};

export type CreateKidMutationVariables = Exact<{
  kidDto: Inp_KidDto;
}>;


export type CreateKidMutation = { __typename?: 'Mutation', createKid: { __typename?: 'ScoutvilleKid', id: any | null, birthDate: any | null, canSwim: CanSwim | null, firstName: string | null, foodPreference: string | null, gender: Gender | null, healthInfo: string | null, hobbyInfo: string | null, lastName: string | null, secondName: string | null } | null };

export type DeleteKidByIdMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteKidByIdMutation = { __typename?: 'Mutation', delete_ScoutvilleKid: any | null };

export type KidListQueryVariables = Exact<{
  filter: InputMaybe<Array<InputMaybe<Inp_ScoutvilleKidFilterCondition>> | InputMaybe<Inp_ScoutvilleKidFilterCondition>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_ScoutvilleKidOrderBy>;
}>;


export type KidListQuery = { __typename?: 'Query', ScoutvilleKidList: Array<{ __typename?: 'ScoutvilleKid', id: any | null, firstName: string | null, secondName: string | null, lastName: string | null, birthDate: any | null, canSwim: CanSwim | null, gender: Gender | null, healthInfo: string | null, hobbyInfo: string | null, foodPreference: string | null, bdCertificate: any | null, bdCertificateSerial: string | null, shortSize: TShortSize | null } | null> | null };

export type UpdateKidMutationVariables = Exact<{
  kidDto: Inp_KidDto;
}>;


export type UpdateKidMutation = { __typename?: 'Mutation', updateKid: { __typename?: 'ScoutvilleKid', id: any | null, birthDate: any | null, canSwim: CanSwim | null, firstName: string | null, foodPreference: string | null, gender: Gender | null, healthInfo: string | null, hobbyInfo: string | null, lastName: string | null, secondName: string | null } | null };

export type ScoutvilleKidByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ScoutvilleKidByIdQuery = { __typename?: 'Query', kidHasTours: boolean, currentRelation: { __typename?: 'ScoutvilleKidParentRelation', relation: Relation | null } | null, ScoutvilleKidById: { __typename?: 'ScoutvilleKid', id: any | null, firstName: string | null, secondName: string | null, lastName: string | null, birthDate: any | null, canSwim: CanSwim | null, gender: Gender | null, healthInfo: string | null, hobbyInfo: string | null, foodPreference: string | null, bdCertificate: any | null, bdCertificateSerial: string | null, shortSize: TShortSize | null } | null };

export type CreatOrderTourQueryVariables = Exact<{
  request: InputMaybe<Inp_NewOrderTourRequest>;
}>;


export type CreatOrderTourQuery = { __typename?: 'Query', createOrderTour: { __typename?: 'TokenPayload', data: string | null, provider: AcquiringProvider | null, signature: string | null, token: string | null } | null };

export type OrderTourListQueryVariables = Exact<{
  filter: InputMaybe<Array<InputMaybe<Inp_ScoutvilleOrderTourFilterCondition>> | InputMaybe<Inp_ScoutvilleOrderTourFilterCondition>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_ScoutvilleOrderTourOrderBy>;
}>;


export type OrderTourListQuery = { __typename?: 'Query', ScoutvilleOrderTourList: Array<{ __typename?: 'ScoutvilleOrderTour', id: any | null, isBooking: boolean | null, finalPrice: any | null, leftToPay: any | null, currentStatus: OrderStatusType | null, isPaid: boolean | null, kid: { __typename?: 'ScoutvilleKid', id: any | null, birthDate: any | null, canSwim: CanSwim | null, firstName: string | null, foodPreference: string | null, gender: Gender | null, healthInfo: string | null, hobbyInfo: string | null, lastName: string | null, secondName: string | null } | null, tour: { __typename?: 'ScoutvilleTour', id: any | null, image: any | null, active: boolean | null, archived: boolean | null, availableForBooking: boolean | null, badgeInfo: string | null, description: string | null, startDate: any | null, endDate: any | null, maxDateOfBirth: any | null, minDateOfBirth: any | null, name: string | null, price: any | null, blocks: Array<{ __typename?: 'ScoutvilleTourBlock', blockedFor: GenderBlock | null, blockedYear: number | null } | null> | null } | null } | null> | null };

export type OrderTourByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type OrderTourByIdQuery = { __typename?: 'Query', ScoutvilleOrderTourById: { __typename?: 'ScoutvilleOrderTour', id: any | null, isBooking: boolean | null, createdDate: any | null, finalPrice: any | null, basePrice: any | null, paidAmount: any | null, leftToPay: any | null, isPaid: boolean | null, currentStatus: OrderStatusType | null, tour: { __typename?: 'ScoutvilleTour', active: boolean | null, archived: boolean | null, availableForBooking: boolean | null, badgeInfo: string | null, description: string | null, startDate: any | null, endDate: any | null, id: any | null, image: any | null, maxDateOfBirth: any | null, minDateOfBirth: any | null, name: string | null, price: any | null, blocks: Array<{ __typename?: 'ScoutvilleTourBlock', blockedFor: GenderBlock | null, blockedYear: number | null } | null> | null } | null, kid: { __typename?: 'ScoutvilleKid', id: any | null, birthDate: any | null, canSwim: CanSwim | null, firstName: string | null, foodPreference: string | null, gender: Gender | null, healthInfo: string | null, hobbyInfo: string | null, lastName: string | null, secondName: string | null } | null, statuses: Array<{ __typename?: 'ScoutvilleOrderStatus', description: string | null, createdDate: any | null, isDone: boolean | null, statusType: OrderStatusType | null, link: string | null, id: any | null } | null> | null, payments: Array<{ __typename?: 'ScoutvillePayment', id: any | null, amount: any | null, paidByType: PaymentType | null, paymentSystem: string | null, acquiringProvider: AcquiringProvider | null } | null> | null } | null };

export type OrderTourTokenQueryVariables = Exact<{
  orderId: InputMaybe<Scalars['UUID']>;
}>;


export type OrderTourTokenQuery = { __typename?: 'Query', tokeByOrderId: { __typename?: 'TokenPayload', data: string | null, provider: AcquiringProvider | null, signature: string | null, token: string | null } | null };

export type TourListQueryVariables = Exact<{
  filter: InputMaybe<Array<InputMaybe<Inp_ScoutvilleTourFilterCondition>> | InputMaybe<Inp_ScoutvilleTourFilterCondition>>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Inp_ScoutvilleTourOrderBy>;
}>;


export type TourListQuery = { __typename?: 'Query', ScoutvilleTourList: Array<{ __typename?: 'ScoutvilleTour', active: boolean | null, archived: boolean | null, availableForBooking: boolean | null, badgeInfo: string | null, kidsTicketsSold: number | null, juniorTicketsSold: number | null, teensTicketsSold: number | null, description: string | null, startDate: any | null, endDate: any | null, id: any | null, image: any | null, maxDateOfBirth: any | null, minDateOfBirth: any | null, name: string | null, price: any | null, blocks: Array<{ __typename?: 'ScoutvilleTourBlock', blockedFor: GenderBlock | null, blockedYear: number | null } | null> | null } | null> | null };

export type UpdateTrustedPersonMutationVariables = Exact<{
  personDto: Inp_TrustedPersonDto;
}>;


export type UpdateTrustedPersonMutation = { __typename?: 'Mutation', upsertTrustedPerson: { __typename?: 'TrustedPersonDto', email: string | null, firstName: string | null, id: any | null, lastName: string | null, phone: string | null, relation: Relation | null, secondName: string | null } | null };

export type TrustedUserQueryVariables = Exact<{ [key: string]: never; }>;


export type TrustedUserQuery = { __typename?: 'Query', trustedPerson: { __typename?: 'TrustedPersonDto', email: string | null, firstName: string | null, id: any | null, lastName: string | null, phone: string | null, relation: Relation | null, secondName: string | null } | null };

export type UpdateUserDataMutationVariables = Exact<{
  userDto: Inp_UserDto;
}>;


export type UpdateUserDataMutation = { __typename?: 'Mutation', updateUserData: { __typename?: 'UserDto', docFive: any | null, docFour: any | null, docOne: any | null, docSix: any | null, docTree: any | null, docTwo: any | null, email: string | null, firstName: string | null, id: any | null, lastName: string | null, passportSerial: string | null, phone: string | null, secondName: string | null, taxSerial: string | null } | null };

export type GetUserDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserDataQuery = { __typename?: 'Query', trustedCompleted: boolean, profileCompleted: boolean, ScoutvilleKidCount: any | null, userData: { __typename?: 'UserDto', docFive: any | null, docFour: any | null, docOne: any | null, docSix: any | null, docTree: any | null, docTwo: any | null, email: string | null, firstName: string | null, id: any | null, lastName: string | null, passportSerial: string | null, phone: string | null, secondName: string | null, taxSerial: string | null } | null };


export const CreateKidDocument = gql`
    mutation CreateKid($kidDto: inp_KidDto!) {
  createKid(kidDto: $kidDto) {
    id
    birthDate
    canSwim
    firstName
    foodPreference
    gender
    healthInfo
    hobbyInfo
    lastName
    secondName
  }
}
    `;
export type CreateKidMutationFn = Apollo.MutationFunction<CreateKidMutation, CreateKidMutationVariables>;

/**
 * __useCreateKidMutation__
 *
 * To run a mutation, you first call `useCreateKidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKidMutation, { data, loading, error }] = useCreateKidMutation({
 *   variables: {
 *      kidDto: // value for 'kidDto'
 *   },
 * });
 */
export function useCreateKidMutation(baseOptions?: Apollo.MutationHookOptions<CreateKidMutation, CreateKidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateKidMutation, CreateKidMutationVariables>(CreateKidDocument, options);
      }
export type CreateKidMutationHookResult = ReturnType<typeof useCreateKidMutation>;
export type CreateKidMutationResult = Apollo.MutationResult<CreateKidMutation>;
export type CreateKidMutationOptions = Apollo.BaseMutationOptions<CreateKidMutation, CreateKidMutationVariables>;
export const DeleteKidByIdDocument = gql`
    mutation DeleteKidById($id: String!) {
  delete_ScoutvilleKid(id: $id)
}
    `;
export type DeleteKidByIdMutationFn = Apollo.MutationFunction<DeleteKidByIdMutation, DeleteKidByIdMutationVariables>;

/**
 * __useDeleteKidByIdMutation__
 *
 * To run a mutation, you first call `useDeleteKidByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKidByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKidByIdMutation, { data, loading, error }] = useDeleteKidByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteKidByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteKidByIdMutation, DeleteKidByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteKidByIdMutation, DeleteKidByIdMutationVariables>(DeleteKidByIdDocument, options);
      }
export type DeleteKidByIdMutationHookResult = ReturnType<typeof useDeleteKidByIdMutation>;
export type DeleteKidByIdMutationResult = Apollo.MutationResult<DeleteKidByIdMutation>;
export type DeleteKidByIdMutationOptions = Apollo.BaseMutationOptions<DeleteKidByIdMutation, DeleteKidByIdMutationVariables>;
export const KidListDocument = gql`
    query KidList($filter: [inp_ScoutvilleKidFilterCondition], $limit: Int, $offset: Int, $orderBy: inp_ScoutvilleKidOrderBy) {
  ScoutvilleKidList(
    limit: $limit
    offset: $offset
    orderBy: $orderBy
    filter: $filter
  ) {
    id
    firstName
    secondName
    lastName
    birthDate
    canSwim
    gender
    healthInfo
    hobbyInfo
    foodPreference
    bdCertificate
    bdCertificateSerial
    shortSize
  }
}
    `;

/**
 * __useKidListQuery__
 *
 * To run a query within a React component, call `useKidListQuery` and pass it any options that fit your needs.
 * When your component renders, `useKidListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKidListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useKidListQuery(baseOptions?: Apollo.QueryHookOptions<KidListQuery, KidListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KidListQuery, KidListQueryVariables>(KidListDocument, options);
      }
export function useKidListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KidListQuery, KidListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KidListQuery, KidListQueryVariables>(KidListDocument, options);
        }
export type KidListQueryHookResult = ReturnType<typeof useKidListQuery>;
export type KidListLazyQueryHookResult = ReturnType<typeof useKidListLazyQuery>;
export type KidListQueryResult = Apollo.QueryResult<KidListQuery, KidListQueryVariables>;
export function refetchKidListQuery(variables?: KidListQueryVariables) {
      return { query: KidListDocument, variables: variables }
    }
export const UpdateKidDocument = gql`
    mutation UpdateKid($kidDto: inp_KidDto!) {
  updateKid(kidDto: $kidDto) {
    id
    birthDate
    canSwim
    firstName
    foodPreference
    gender
    healthInfo
    hobbyInfo
    lastName
    secondName
  }
}
    `;
export type UpdateKidMutationFn = Apollo.MutationFunction<UpdateKidMutation, UpdateKidMutationVariables>;

/**
 * __useUpdateKidMutation__
 *
 * To run a mutation, you first call `useUpdateKidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKidMutation, { data, loading, error }] = useUpdateKidMutation({
 *   variables: {
 *      kidDto: // value for 'kidDto'
 *   },
 * });
 */
export function useUpdateKidMutation(baseOptions?: Apollo.MutationHookOptions<UpdateKidMutation, UpdateKidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateKidMutation, UpdateKidMutationVariables>(UpdateKidDocument, options);
      }
export type UpdateKidMutationHookResult = ReturnType<typeof useUpdateKidMutation>;
export type UpdateKidMutationResult = Apollo.MutationResult<UpdateKidMutation>;
export type UpdateKidMutationOptions = Apollo.BaseMutationOptions<UpdateKidMutation, UpdateKidMutationVariables>;
export const ScoutvilleKidByIdDocument = gql`
    query ScoutvilleKidById($id: String!) {
  currentRelation(kidId: $id) {
    relation
  }
  kidHasTours(kidId: $id)
  ScoutvilleKidById(id: $id) {
    id
    firstName
    secondName
    lastName
    birthDate
    canSwim
    gender
    healthInfo
    hobbyInfo
    foodPreference
    bdCertificate
    bdCertificateSerial
    shortSize
  }
}
    `;

/**
 * __useScoutvilleKidByIdQuery__
 *
 * To run a query within a React component, call `useScoutvilleKidByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useScoutvilleKidByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScoutvilleKidByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useScoutvilleKidByIdQuery(baseOptions: Apollo.QueryHookOptions<ScoutvilleKidByIdQuery, ScoutvilleKidByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScoutvilleKidByIdQuery, ScoutvilleKidByIdQueryVariables>(ScoutvilleKidByIdDocument, options);
      }
export function useScoutvilleKidByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScoutvilleKidByIdQuery, ScoutvilleKidByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScoutvilleKidByIdQuery, ScoutvilleKidByIdQueryVariables>(ScoutvilleKidByIdDocument, options);
        }
export type ScoutvilleKidByIdQueryHookResult = ReturnType<typeof useScoutvilleKidByIdQuery>;
export type ScoutvilleKidByIdLazyQueryHookResult = ReturnType<typeof useScoutvilleKidByIdLazyQuery>;
export type ScoutvilleKidByIdQueryResult = Apollo.QueryResult<ScoutvilleKidByIdQuery, ScoutvilleKidByIdQueryVariables>;
export function refetchScoutvilleKidByIdQuery(variables: ScoutvilleKidByIdQueryVariables) {
      return { query: ScoutvilleKidByIdDocument, variables: variables }
    }
export const CreatOrderTourDocument = gql`
    query CreatOrderTour($request: inp_NewOrderTourRequest) {
  createOrderTour(request: $request) {
    data
    provider
    signature
    token
  }
}
    `;

/**
 * __useCreatOrderTourQuery__
 *
 * To run a query within a React component, call `useCreatOrderTourQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreatOrderTourQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatOrderTourQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreatOrderTourQuery(baseOptions?: Apollo.QueryHookOptions<CreatOrderTourQuery, CreatOrderTourQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreatOrderTourQuery, CreatOrderTourQueryVariables>(CreatOrderTourDocument, options);
      }
export function useCreatOrderTourLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreatOrderTourQuery, CreatOrderTourQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreatOrderTourQuery, CreatOrderTourQueryVariables>(CreatOrderTourDocument, options);
        }
export type CreatOrderTourQueryHookResult = ReturnType<typeof useCreatOrderTourQuery>;
export type CreatOrderTourLazyQueryHookResult = ReturnType<typeof useCreatOrderTourLazyQuery>;
export type CreatOrderTourQueryResult = Apollo.QueryResult<CreatOrderTourQuery, CreatOrderTourQueryVariables>;
export function refetchCreatOrderTourQuery(variables?: CreatOrderTourQueryVariables) {
      return { query: CreatOrderTourDocument, variables: variables }
    }
export const OrderTourListDocument = gql`
    query OrderTourList($filter: [inp_ScoutvilleOrderTourFilterCondition], $limit: Int, $offset: Int, $orderBy: inp_ScoutvilleOrderTourOrderBy) {
  ScoutvilleOrderTourList(
    limit: $limit
    offset: $offset
    orderBy: $orderBy
    filter: $filter
  ) {
    id
    isBooking
    finalPrice
    leftToPay
    currentStatus
    isPaid
    kid {
      id
      birthDate
      canSwim
      firstName
      foodPreference
      gender
      healthInfo
      hobbyInfo
      lastName
      secondName
    }
    tour {
      id
      image
      active
      archived
      availableForBooking
      badgeInfo
      blocks {
        blockedFor
        blockedYear
      }
      description
      startDate
      endDate
      id
      image
      maxDateOfBirth
      minDateOfBirth
      name
      price
    }
  }
}
    `;

/**
 * __useOrderTourListQuery__
 *
 * To run a query within a React component, call `useOrderTourListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderTourListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderTourListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useOrderTourListQuery(baseOptions?: Apollo.QueryHookOptions<OrderTourListQuery, OrderTourListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderTourListQuery, OrderTourListQueryVariables>(OrderTourListDocument, options);
      }
export function useOrderTourListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderTourListQuery, OrderTourListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderTourListQuery, OrderTourListQueryVariables>(OrderTourListDocument, options);
        }
export type OrderTourListQueryHookResult = ReturnType<typeof useOrderTourListQuery>;
export type OrderTourListLazyQueryHookResult = ReturnType<typeof useOrderTourListLazyQuery>;
export type OrderTourListQueryResult = Apollo.QueryResult<OrderTourListQuery, OrderTourListQueryVariables>;
export function refetchOrderTourListQuery(variables?: OrderTourListQueryVariables) {
      return { query: OrderTourListDocument, variables: variables }
    }
export const OrderTourByIdDocument = gql`
    query OrderTourById($id: String!) {
  ScoutvilleOrderTourById(id: $id) {
    id
    isBooking
    createdDate
    finalPrice
    basePrice
    paidAmount
    leftToPay
    isPaid
    tour {
      active
      archived
      availableForBooking
      badgeInfo
      blocks {
        blockedFor
        blockedYear
      }
      description
      startDate
      endDate
      id
      image
      maxDateOfBirth
      minDateOfBirth
      name
      price
    }
    kid {
      id
      birthDate
      canSwim
      firstName
      foodPreference
      gender
      healthInfo
      hobbyInfo
      lastName
      secondName
    }
    statuses {
      description
      createdDate
      isDone
      statusType
      link
      id
    }
    currentStatus
    payments {
      id
      amount
      paidByType
      paymentSystem
      acquiringProvider
    }
  }
}
    `;

/**
 * __useOrderTourByIdQuery__
 *
 * To run a query within a React component, call `useOrderTourByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderTourByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderTourByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderTourByIdQuery(baseOptions: Apollo.QueryHookOptions<OrderTourByIdQuery, OrderTourByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderTourByIdQuery, OrderTourByIdQueryVariables>(OrderTourByIdDocument, options);
      }
export function useOrderTourByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderTourByIdQuery, OrderTourByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderTourByIdQuery, OrderTourByIdQueryVariables>(OrderTourByIdDocument, options);
        }
export type OrderTourByIdQueryHookResult = ReturnType<typeof useOrderTourByIdQuery>;
export type OrderTourByIdLazyQueryHookResult = ReturnType<typeof useOrderTourByIdLazyQuery>;
export type OrderTourByIdQueryResult = Apollo.QueryResult<OrderTourByIdQuery, OrderTourByIdQueryVariables>;
export function refetchOrderTourByIdQuery(variables: OrderTourByIdQueryVariables) {
      return { query: OrderTourByIdDocument, variables: variables }
    }
export const OrderTourTokenDocument = gql`
    query OrderTourToken($orderId: UUID) {
  tokeByOrderId(orderId: $orderId) {
    data
    provider
    signature
    token
  }
}
    `;

/**
 * __useOrderTourTokenQuery__
 *
 * To run a query within a React component, call `useOrderTourTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderTourTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderTourTokenQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderTourTokenQuery(baseOptions?: Apollo.QueryHookOptions<OrderTourTokenQuery, OrderTourTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderTourTokenQuery, OrderTourTokenQueryVariables>(OrderTourTokenDocument, options);
      }
export function useOrderTourTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderTourTokenQuery, OrderTourTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderTourTokenQuery, OrderTourTokenQueryVariables>(OrderTourTokenDocument, options);
        }
export type OrderTourTokenQueryHookResult = ReturnType<typeof useOrderTourTokenQuery>;
export type OrderTourTokenLazyQueryHookResult = ReturnType<typeof useOrderTourTokenLazyQuery>;
export type OrderTourTokenQueryResult = Apollo.QueryResult<OrderTourTokenQuery, OrderTourTokenQueryVariables>;
export function refetchOrderTourTokenQuery(variables?: OrderTourTokenQueryVariables) {
      return { query: OrderTourTokenDocument, variables: variables }
    }
export const TourListDocument = gql`
    query TourList($filter: [inp_ScoutvilleTourFilterCondition], $limit: Int, $offset: Int, $orderBy: inp_ScoutvilleTourOrderBy) {
  ScoutvilleTourList(
    limit: $limit
    offset: $offset
    orderBy: $orderBy
    filter: $filter
  ) {
    active
    archived
    availableForBooking
    badgeInfo
    kidsTicketsSold
    juniorTicketsSold
    teensTicketsSold
    blocks {
      blockedFor
      blockedYear
    }
    description
    startDate
    endDate
    id
    image
    maxDateOfBirth
    minDateOfBirth
    name
    price
  }
}
    `;

/**
 * __useTourListQuery__
 *
 * To run a query within a React component, call `useTourListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTourListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTourListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useTourListQuery(baseOptions?: Apollo.QueryHookOptions<TourListQuery, TourListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TourListQuery, TourListQueryVariables>(TourListDocument, options);
      }
export function useTourListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TourListQuery, TourListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TourListQuery, TourListQueryVariables>(TourListDocument, options);
        }
export type TourListQueryHookResult = ReturnType<typeof useTourListQuery>;
export type TourListLazyQueryHookResult = ReturnType<typeof useTourListLazyQuery>;
export type TourListQueryResult = Apollo.QueryResult<TourListQuery, TourListQueryVariables>;
export function refetchTourListQuery(variables?: TourListQueryVariables) {
      return { query: TourListDocument, variables: variables }
    }
export const UpdateTrustedPersonDocument = gql`
    mutation UpdateTrustedPerson($personDto: inp_TrustedPersonDto!) {
  upsertTrustedPerson(personDto: $personDto) {
    email
    firstName
    id
    lastName
    phone
    relation
    secondName
  }
}
    `;
export type UpdateTrustedPersonMutationFn = Apollo.MutationFunction<UpdateTrustedPersonMutation, UpdateTrustedPersonMutationVariables>;

/**
 * __useUpdateTrustedPersonMutation__
 *
 * To run a mutation, you first call `useUpdateTrustedPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrustedPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrustedPersonMutation, { data, loading, error }] = useUpdateTrustedPersonMutation({
 *   variables: {
 *      personDto: // value for 'personDto'
 *   },
 * });
 */
export function useUpdateTrustedPersonMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTrustedPersonMutation, UpdateTrustedPersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTrustedPersonMutation, UpdateTrustedPersonMutationVariables>(UpdateTrustedPersonDocument, options);
      }
export type UpdateTrustedPersonMutationHookResult = ReturnType<typeof useUpdateTrustedPersonMutation>;
export type UpdateTrustedPersonMutationResult = Apollo.MutationResult<UpdateTrustedPersonMutation>;
export type UpdateTrustedPersonMutationOptions = Apollo.BaseMutationOptions<UpdateTrustedPersonMutation, UpdateTrustedPersonMutationVariables>;
export const TrustedUserDocument = gql`
    query TrustedUser {
  trustedPerson {
    email
    firstName
    id
    lastName
    phone
    relation
    secondName
  }
}
    `;

/**
 * __useTrustedUserQuery__
 *
 * To run a query within a React component, call `useTrustedUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrustedUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrustedUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useTrustedUserQuery(baseOptions?: Apollo.QueryHookOptions<TrustedUserQuery, TrustedUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrustedUserQuery, TrustedUserQueryVariables>(TrustedUserDocument, options);
      }
export function useTrustedUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrustedUserQuery, TrustedUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrustedUserQuery, TrustedUserQueryVariables>(TrustedUserDocument, options);
        }
export type TrustedUserQueryHookResult = ReturnType<typeof useTrustedUserQuery>;
export type TrustedUserLazyQueryHookResult = ReturnType<typeof useTrustedUserLazyQuery>;
export type TrustedUserQueryResult = Apollo.QueryResult<TrustedUserQuery, TrustedUserQueryVariables>;
export function refetchTrustedUserQuery(variables?: TrustedUserQueryVariables) {
      return { query: TrustedUserDocument, variables: variables }
    }
export const UpdateUserDataDocument = gql`
    mutation UpdateUserData($userDto: inp_UserDto!) {
  updateUserData(userDto: $userDto) {
    docFive
    docFour
    docOne
    docSix
    docTree
    docTwo
    email
    firstName
    id
    lastName
    passportSerial
    phone
    secondName
    taxSerial
  }
}
    `;
export type UpdateUserDataMutationFn = Apollo.MutationFunction<UpdateUserDataMutation, UpdateUserDataMutationVariables>;

/**
 * __useUpdateUserDataMutation__
 *
 * To run a mutation, you first call `useUpdateUserDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserDataMutation, { data, loading, error }] = useUpdateUserDataMutation({
 *   variables: {
 *      userDto: // value for 'userDto'
 *   },
 * });
 */
export function useUpdateUserDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserDataMutation, UpdateUserDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserDataMutation, UpdateUserDataMutationVariables>(UpdateUserDataDocument, options);
      }
export type UpdateUserDataMutationHookResult = ReturnType<typeof useUpdateUserDataMutation>;
export type UpdateUserDataMutationResult = Apollo.MutationResult<UpdateUserDataMutation>;
export type UpdateUserDataMutationOptions = Apollo.BaseMutationOptions<UpdateUserDataMutation, UpdateUserDataMutationVariables>;
export const GetUserDataDocument = gql`
    query GetUserData {
  userData {
    docFive
    docFour
    docOne
    docSix
    docTree
    docTwo
    email
    firstName
    id
    lastName
    passportSerial
    phone
    secondName
    taxSerial
  }
  trustedCompleted
  profileCompleted
  ScoutvilleKidCount(filter: [])
}
    `;

/**
 * __useGetUserDataQuery__
 *
 * To run a query within a React component, call `useGetUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserDataQuery(baseOptions?: Apollo.QueryHookOptions<GetUserDataQuery, GetUserDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDataQuery, GetUserDataQueryVariables>(GetUserDataDocument, options);
      }
export function useGetUserDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDataQuery, GetUserDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDataQuery, GetUserDataQueryVariables>(GetUserDataDocument, options);
        }
export type GetUserDataQueryHookResult = ReturnType<typeof useGetUserDataQuery>;
export type GetUserDataLazyQueryHookResult = ReturnType<typeof useGetUserDataLazyQuery>;
export type GetUserDataQueryResult = Apollo.QueryResult<GetUserDataQuery, GetUserDataQueryVariables>;
export function refetchGetUserDataQuery(variables?: GetUserDataQueryVariables) {
      return { query: GetUserDataDocument, variables: variables }
    }
export const namedOperations = {
  Query: {
    KidList: 'KidList',
    ScoutvilleKidById: 'ScoutvilleKidById',
    CreatOrderTour: 'CreatOrderTour',
    OrderTourList: 'OrderTourList',
    OrderTourById: 'OrderTourById',
    OrderTourToken: 'OrderTourToken',
    TourList: 'TourList',
    TrustedUser: 'TrustedUser',
    GetUserData: 'GetUserData'
  },
  Mutation: {
    CreateKid: 'CreateKid',
    DeleteKidById: 'DeleteKidById',
    UpdateKid: 'UpdateKid',
    UpdateTrustedPerson: 'UpdateTrustedPerson',
    UpdateUserData: 'UpdateUserData'
  }
}