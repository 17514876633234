/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
// Images
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import {Form, FormikProvider, useFormik} from "formik";
// Authentication layout components
import React from "react";
import * as yup from "yup";

const validationSchema = yup.object({
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  password2: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .when("password", {
      is: (val) => (val && val.length > 0),
      then: yup.string().oneOf([yup.ref("password")], "Both password need to be the same"),
    })
    .required("Password is required"),
});

const SetNewPasswordForm = ({onFinish}) => {


  const formik = useFormik({
    initialValues: {
      password: "",
      password2: "",
    },
    validationSchema: validationSchema,
    onSubmit: onFinish,
  });


  return (
    <FormikProvider value={formik}>
      <Form>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Set new password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Set new password and try to use it on the login page.
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox>
            <MDBox mb={4}>
              <MDInput
                name="password"
                id="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                success={formik.touched.password && !Boolean(formik.errors.password)}
                FormHelperTextProps={{error: true}}
                helperText={formik.touched.password && formik.errors.password}
                type="password"
                label="Password"
                variant="standard"
                fullWidth
              />
            </MDBox>
            <MDBox mb={4}>
              <MDInput
                name="password2"
                id="password2"
                value={formik.values.password2}
                onChange={formik.handleChange}
                error={formik.touched.password2 && Boolean(formik.errors.password2)}
                success={formik.touched.password2 && !Boolean(formik.errors.password2)}
                FormHelperTextProps={{error: true}}
                helperText={formik.touched.password2 && formik.errors.password2}
                type="password"
                label="Confirm password"
                variant="standard"
                fullWidth
              />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton
                onClick={formik.onSubmitForm}
                type="submit"
                value="submit"
                variant="gradient"
                color="info"
                fullWidth
              >
                reset
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Form>
    </FormikProvider>
  );
};

export default SetNewPasswordForm;
