import { Grid } from "@mui/material";
import CenteredBlogCard from "app/common/cards/CenteredBlogCard";
import background from "assets/app/images/backgrounds/bg3.jpg";
import junior from "assets/app/images/ditricts/junior.png";
import kids from "assets/app/images/ditricts/kids.png";
import teens from "assets/app/images/ditricts/teens.png";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { FaAngleRight } from "react-icons/fa";

const districts = [
  {
    image: kids,
    name: "Kids",
    description:
      "Квартал для наймолодших мешканців Скаутвіллю - дітей, віком від 7 до 9 років.Адаптована під вік інфраструктура, маленькі групи – 10-12 дітей, спеціально підготовлені педагоги. Великий ігровий майданчик та багато пригод.",
  },

  {
    image: junior,
    name: "junior",
    description:
      "Квартал для дітей віком від 10 до 13 років. Перші важливі самостійні кроки відбуваються тут. Відпрацювання навичок самостійності, лісове будівництво, нові друзі та знання, досвідчені педагоги-наставники. Все це дає можливість відчути себе дорослим.",
  },
  {
    image: teens,
    name: "Teens",
    description:
      "Територія підлітків. Вік мешканців – 14-17 років. Двоповерховий житлові комплекси. Максимально можливі умови для самореалізації та випробувань. Споглядання зірок та лісова романтика.",
  },
];

const DistrictSection = ({ mt }) => {
  return (
    <MDBox
      style={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center; center",
      }}
      py={mt}
    >
      <MDBox mx={"5%"}>
        <span>
          <FaAngleRight
            size={"25pt"}
            sx={{
              marginRight: "5pt",
            }}
          />
          <MDTypography
            fontWeight="bold"
            component="span"
            sx={{
              fontSize: "35pt",
            }}
            style={{
              letterSpacing: "2px",
              fontFamily: "Intro Black",
              color: "#ff9800",
            }}
          >
            Квартали
          </MDTypography>
        </span>

        <MDTypography
          ml={"3%"}
          paragraph
          variant="body2"
          style={{
            fontSize: "medium",
            //   // fontWeight: "400",
            //   color: "#656464",
          }}
        >
          Квартали: Скаутвілль адміністративно поділяється на три квартали, які мають власну
          інфраструктуру - житлова зона, їдальня, місця для роботи та відпочинку малих груп,
          вбиральні, гігієнічні зони (місце для прання, умивальники, сушки тощо). Квартали
          розраховані на 50-70 дітей та враховують вік своїх мешканців, як технічно, так і ідейно,
          наприклад, у наймолодших є дитячий ігровий майданчик, а найстарші - живуть у
          "двоповерхових" наметах. Кожен квартал зручно розміщений до основних програмних локацій
          табору, безпечно віддалений від берегової лінії та огороджений парканом.
        </MDTypography>
      </MDBox>
      <MDBox mt={10} mx={"5%"}>
        <Grid container justifyContent={"space-around"} spacing={5}>
          {districts.map((dis) => (
            <Grid key={dis.name} item md={4} lg={4} sm={10}>
              <CenteredBlogCard
                key={dis.name}
                title={dis.name}
                image={dis.image}
                description={dis.description}
              />
            </Grid>
          ))}
        </Grid>
      </MDBox>
    </MDBox>
  );
};

export default DistrictSection;
