import { AuditableEntity } from "./AuditableEntity";
import { CanSwim, Gender, TShortSize } from "../enums/enums";
import { ScoutvilleKidParentRelation } from "./ScoutvilleKidParentRelation";
export class ScoutvilleKid extends AuditableEntity {
  static NAME = "ScoutvilleKid";
  id?: string;
  birthDate?: any | null;
  canSwim?: CanSwim | null;
  firstName?: string | null;
  foodPreference?: string | null;
  gender?: Gender | null;
  healthInfo?: string | null;
  hobbyInfo?: string | null;
  lastName?: string | null;
  secondName?: string | null;
  isFirsTour?: string | null;
  isSawCamps?: string | null;
  isDesMade?: string | null;
  relations?: ScoutvilleKidParentRelation[] | null;
  bdCertificateSerial?: string | null;
  bdCertificate?: any | null;
  shortSize?: TShortSize | null;
}
export type ScoutvilleKidViewName = "_base" | "_instance_name" | "_local";
export type ScoutvilleKidView<
  V extends ScoutvilleKidViewName
> = V extends "_base"
  ? Pick<
      ScoutvilleKid,
      | "id"
      | "firstName"
      | "secondName"
      | "lastName"
      | "birthDate"
      | "canSwim"
      | "foodPreference"
      | "gender"
      | "healthInfo"
      | "hobbyInfo"
      | "isFirsTour"
      | "isSawCamps"
      | "isDesMade"
      | "bdCertificateSerial"
      | "bdCertificate"
      | "shortSize"
      | "version"
      | "createdBy"
      | "createdDate"
      | "deletedBy"
      | "deletedDate"
      | "lastModifiedBy"
      | "lastModifiedDate"
    >
  : V extends "_instance_name"
  ? Pick<ScoutvilleKid, "id" | "firstName" | "secondName" | "lastName">
  : V extends "_local"
  ? Pick<
      ScoutvilleKid,
      | "id"
      | "birthDate"
      | "canSwim"
      | "firstName"
      | "foodPreference"
      | "gender"
      | "healthInfo"
      | "hobbyInfo"
      | "lastName"
      | "secondName"
      | "isFirsTour"
      | "isSawCamps"
      | "isDesMade"
      | "bdCertificateSerial"
      | "bdCertificate"
      | "shortSize"
      | "version"
      | "createdBy"
      | "createdDate"
      | "deletedBy"
      | "deletedDate"
      | "lastModifiedBy"
      | "lastModifiedDate"
    >
  : never;
