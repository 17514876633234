import { ScoutvilleUser } from "./ScoutvilleUser";
import { ScoutvilleKid } from "./ScoutvilleKid";
import { Relation } from "../enums/enums";
export class ScoutvilleKidParentRelation {
  static NAME = "ScoutvilleKidParentRelation";
  id?: string;
  parent?: ScoutvilleUser | null;
  kid?: ScoutvilleKid | null;
  relation?: Relation | null;
}
export type ScoutvilleKidParentRelationViewName =
  | "_base"
  | "_instance_name"
  | "_local";
export type ScoutvilleKidParentRelationView<
  V extends ScoutvilleKidParentRelationViewName
> = V extends "_base"
  ? Pick<ScoutvilleKidParentRelation, "id" | "parent" | "kid" | "relation">
  : V extends "_instance_name"
  ? Pick<ScoutvilleKidParentRelation, "id" | "parent" | "kid">
  : V extends "_local"
  ? Pick<ScoutvilleKidParentRelation, "id" | "relation">
  : never;
