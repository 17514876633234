import React, {useCallback, useState} from 'react'

import LanguageSelect from './LanguageSelect'
import {observer} from "mobx-react";
import {useMainStore} from "@haulmont/jmix-react-core";
import {runInAction} from "mobx";
import {localesStore} from "@haulmont/jmix-react-web";

const LanguageSwitcher = observer(({props}) => {

  const mainStore = useMainStore();

  if (localesStore.localesInfo.length === 1) {
    return null; // Do not show LanguageSwitcher if there is only a single locale option
  }


  const onLanguageChange = useCallback(
    (event) => {
      let value = event?.target?.value;
      runInAction(() => (mainStore.locale = value || "uk"))
      window.localStorage.setItem(mainStore.localeStorageKey, value)
    },
    [mainStore]
  );

  return (
    <LanguageSelect
      noLabel
      value={mainStore.locale}
      onChange={onLanguageChange}
      languages={localesStore.localesInfo.map(({locale, caption}) => {
          return {
            code: locale,
            name: caption
          }
        }
      )}
      {...props}
    />
  )
});

LanguageSwitcher.propTypes = {
  // i18n: PropTypes.object.isRequired,
  // languages: PropTypes.arrayOf(
  //   PropTypes.exact({
  //     code: PropTypes.string.isRequired,
  //     name: PropTypes.string,
  //   })
  // ).isRequired,
}

export default LanguageSwitcher