import Card from "@mui/material/Card";
import getAge from "app/common/form/getAge";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import {observer} from "mobx-react-lite";
import {MdPerson} from "react-icons/md";

const KidCard = observer(({kid, onSelect, onDelete}) => {

  const color = "secondary"
  const alignment = "center";
  const direction = "center";
  console.log("Kid: " , kid)
  return (
    <Card key={kid.id}>
      <MDBox
        display="flex"
        flexDirection="column"
        alignItems={alignment}
        textAlign={direction}
        p={direction === "center" ? 2 : 0}
        lineHeight={1}
      >
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="3rem"
          height="3rem"
          borderRadius="xl"
          variant="gradient"
          color="white"
          bgColor={color}
          coloredShadow={color}
        >
          <MdPerson/>
        </MDBox>
        <MDTypography display="block" variant="5" fontWeight="bold" mt={2.5} mb={1.5}>
          {`${kid.firstName} ${kid.secondName} ${kid.lastName}`}
        </MDTypography>
        <MDTypography display="block" variant="body2" color="text">
          {`${getAge(kid.birthDate)} years old`}
        </MDTypography>
        <MDBox
          className={'w-full'}
          mt={3}
          display="flex"
          justifyContent="space-around"
          // alignItems="space-around"
          // mt={-10}
          position="relative"
          zIndex={1}
        >
          {/*<Grid>*/}
          <MDButton onClick={() => onSelect(kid)} variant="contained" color="info" size="small">
            edit
          </MDButton>
          <MDButton onClick={() => onDelete(kid)} variant="outlined" color="dark" size="small">
            remove
          </MDButton>
        </MDBox>
      </MDBox>
    </Card>
    // <SimpleInfoCard
    //   className="h-full"
    //   color="secondary"
    //   icon={<MdPerson />}
    //   title={`${kid.firstName} ${kid.secondName} ${kid.lastName}`}
    //   description={`${getAge(kid.birthDate)} years old`}
    //   direction="center"
    // />
    // <Card

    //   sx={{
    //     "&:hover .card-header": {
    //       transform: "translate3d(0, -20px, 0)",
    //     },
    //     "&:hover .header": {
    //       transform: "translate3d(0, -10px, 0)",
    //     },
    //   }}
    // >
    //   {/* <MDBox
    //     position="relative"
    //     borderRadius="lg"
    //     mt={-3}
    //     mx={2}
    //     className="card-header"
    //     sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
    //   >
    //     <MDBox
    //       component="img"
    //       src={productImage}
    //       alt="Product Image"
    //       borderRadius="lg"
    //       shadow="sm"
    //       width="100%"
    //       height="100%"
    //       position="relative"
    //       zIndex={10}
    //       // mb={2}
    //       sx={{
    //         objectFit: "cover",
    //         // maxWidth: "100px",
    //         // borderRadius: "100%",
    //       }}
    //     />
    //   </MDBox> */}

    //   <MDBox textAlign="center" pt={1} pb={3} px={3}>
    //     <MDTypography
    //       className="header"
    //       sx={{ transition: "transform 1000ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
    //       variant="h5"
    //       fontWeight="regular"
    //       sx={{ mt: 4 }}
    //     >
    //       {kid.firstName} {kid.secondName} {kid.lastName}
    //     </MDTypography>
    //     <MDBox p={2}>
    //       <EntityProperty entityName="ScoutvilleKid" propertyName="canSwim" value={kid.canSwim} />
    //       {/* {renderItems()} */}
    //       <EntityProperty
    //         entityName={ScoutvilleKid.NAME}
    //         propertyName="birthDate"
    //         value={kid.birthDate}
    //       />
    //       <EntityProperty
    //         entityName={ScoutvilleKid.NAME}
    //         propertyName="shortSize"
    //         value={kid.shortSize}
    //       />
    //       <EntityProperty
    //         entityName={ScoutvilleKid.NAME}
    //         propertyName="bdCertificateSerial"
    //         value={kid.bdCertificateSerial}
    //       />
    //     </MDBox>
    //     <MDBox
    //       display="flex"
    //       justifyContent="center"
    //       alignItems="center"
    //       // mt={-10}
    //       position="relative"
    //       zIndex={1}
    //     >
    //       <MDBox mr={1}>
    //         <MDButton onClick={() => onSelect(kid)} variant="gradient" color="info" size="small">
    //           edit
    //         </MDButton>
    //       </MDBox>
    //       <MDButton onClick={() => onDelete(kid)} variant="outlined" color="dark" size="small">
    //         remove
    //       </MDButton>
    //     </MDBox>
    //   </MDBox>
    // </Card>
  );
});

export default KidCard;
