// Soft UI Context Provider
import {ApolloProvider} from "@apollo/client";
import {
  ContentDisplayMode,
  initializeApolloClient,
  JmixAppProvider,
  ProjectModelMetadata
} from "@haulmont/jmix-react-core";
import {IntlDocumentTitle, localesStore} from "@haulmont/jmix-react-web";
import {initializeApp} from "@haulmont/jmix-rest";
import AuthenticationProvider from "app/common/authentication/AuthenticationProvider";
import AppNotificationProvider from "app/common/error/AppNotificationContext";
import {AppErrorBoundary} from "app/common/error/AppErrorBoundary";
import {initializeI18n} from "app/common/i18n/i18n";
import {I18nProvider} from "app/common/i18n/I18nProvider";
import {MaterialUIControllerProvider} from "context";
import {Modals} from "modal/Modals";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import {ApolloClient, InMemoryCache, HttpLink, from} from "@apollo/client";

import App from "./App";
import {
  AUTH_TOKEN_REQUEST_URL,
  AUTH_TOKEN_REVOKE_URL,
  GRAPHQL_URI,
  JMIX_REST_URL,
  REST_CLIENT_ID,
  REST_CLIENT_SECRET
} from "./config";
import "./i18n/i18nInit";
import metadata from "./jmix/metadata.json";
import {onError} from "@apollo/client/link/error";
import {ApolloLink} from "@apollo/client/link/core";
import {ApolloCache} from "@apollo/client/cache";
import register from "registerServiceWorker";


initializeI18n(
  {
    defaultLocale: "uk",
    getMessages: locale => localesStore.messagesMapping[locale]
  });

//
// const errorLink: ApolloLink = onError(({graphQLErrors, networkError}) => {
//   if (graphQLErrors)
//     graphQLErrors.forEach(({message, locations, path}) =>
//       console.log(
//         `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
//       ),
//     );
//
//   if (networkError) console.log(`[Network error]: ${networkError}`);
// });


export const jmixREST = initializeApp({
  name: "",
  apiUrl: JMIX_REST_URL,
  restClientId: REST_CLIENT_ID,
  restClientSecret: REST_CLIENT_SECRET,
  storage: window.localStorage,
  defaultLocale: "en"
});

const client = initializeApolloClient({
  graphqlEndpoint: GRAPHQL_URI,
  tokenStorageKey: "_jmixRestAccessToken",
  localeStorageKey: "_jmixLocale",
});

register()

ReactDOM.render(
  <MaterialUIControllerProvider>
    <BrowserRouter>
      <JmixAppProvider

        apolloClient={client}
        jmixREST={jmixREST}
        config={{
          contentDisplayMode: ContentDisplayMode.NoTabs,
          appName: "",
          clientId: REST_CLIENT_ID, // TODO Rename once we remove REST
          secret: REST_CLIENT_SECRET,
          locale: "uk",
          obtainTokenEndpoint: AUTH_TOKEN_REQUEST_URL,
          revokeTokenEndpoint: AUTH_TOKEN_REVOKE_URL,
          graphqlEndpoint: GRAPHQL_URI
        }}
        metadata={metadata as ProjectModelMetadata}
        Modals={Modals}
      >

        <ApolloProvider client={client}>
          <I18nProvider>
            <IntlDocumentTitle>
              <AppErrorBoundary>
                <AuthenticationProvider>
                  <AppNotificationProvider>
                    <App/>
                  </AppNotificationProvider>
                </AuthenticationProvider>
              </AppErrorBoundary>
            </IntlDocumentTitle>
          </I18nProvider>
        </ApolloProvider>
      </JmixAppProvider>
    </BrowserRouter>
  </MaterialUIControllerProvider>
  ,
  document.getElementById("root")
);
