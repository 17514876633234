/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import {gql} from "@apollo/client";
import {useEntityListData} from "@haulmont/jmix-react-core";
import {Box} from "@mui/material";
import Card from "@mui/material/Card";
import CenteredLoader from "app/common/loader/CenteredLoader";
import DataTable from "app/common/tables/DataTable";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Footer from "examples/Footer";
// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {OrderStatusType} from "jmix/enums/enums";
import {observer} from "mobx-react";
import DefaultCell from "./components/DefaultCell";
import StatusCell from "./components/StatusCell";
import TourRepayActionCell from "./components/TourRepayActionCell";
import {useOrderTourListQuery} from "../../../generated/generated-types";
import TourViewActionCell from "./components/TourViewActionCell";
import {getOrderStatusName, orderStatusNameToIcon} from "../utils";
import ActionsCell from "./components/ActionsCell";
import {FaEye, MdPayment} from "react-icons/all";

const Orders = observer(() => {

  var {data, loading, error} = useOrderTourListQuery();

  if (loading) {
    return <CenteredLoader/>;
  }


  // if (error) {
  //   console.error("Error: ", error)
  // }

  const columns = [
    {
      Header: "Зміна",
      accessor: (row) => row,
      Cell: ({value}) =>
        <DefaultCell value={value.tour.name} suffix={`(${value.tour.startDate} - ${value.tour.endDate})`}/>,
    },
    {
      Header: "Cтатус",
      accessor: "currentStatus",
      Cell: ({value}) => {
        if (!value) {
          return <></>;
        }

        return (<StatusCell
          color={"info"}
          icon={orderStatusNameToIcon(value)}
          status={getOrderStatusName(value)}/>);
      },
    },
    {
      Header: "Дитина",
      accessor: (row) => `${row.kid.firstName} ${row.kid.lastName}`,
      Cell: ({value}) => <DefaultCell value={value}/>,
    },
    {
      Header: "Ціна",
      accessor: "finalPrice",
      Cell: ({value}) => {
        return value ? <DefaultCell value={value}/> : <></>
      },
    },
    {
      Header: "До оплатити",
      accessor: "leftToPay",
      Cell: ({value}) => <DefaultCell value={value}/>,
    },
    {
      Header: "Дії",
      accessor: (row) => row,
      Cell: ({value}) => <ActionsCell row={value} />,
    }
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar/>
      <Box my={2}>
        <MDBox my={3}>
          <Card>
            {data?.ScoutvilleOrderTourList &&
              <DataTable items={data.ScoutvilleOrderTourList} schema={columns} entriesPerPage={false}/>}
          </Card>
        </MDBox>
      </Box>
      <Footer/>
    </DashboardLayout>
  );
});

export default Orders;
