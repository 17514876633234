/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Material Kit 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";

const HorizontalTeamCard = ({ image, name, position, description }) => {
  return (
    <Card className="h-full" sx={{ mt: 3 }}>
      <Grid container rowSpacing={5}>
        <Grid item xs={12} md={6} lg={4} sx={{ mt: -4 }}>
          <MDBox width="100%" pt={2} pb={1} px={2}>
            {image ? (
              <MDBox
                component="img"
                src={image}
                alt={name}
                width="100%"
                borderRadius="md"
                shadow="lg"
              />
            ) : null}
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={8} sx={{ my: "auto" }}>
          <MDBox pt={{ xs: 1, lg: 2.5 }} pb={2.5} pr={4} pl={{ xs: 4, lg: 1 }} lineHeight={1}>
            <MDTypography variant="h5">{name}</MDTypography>
            <MDTypography
              textTransform="uppercase"
              variant="caption"
              fontWeight="bold"
              color={position.color}
              mb={1}
            >
              {position.label}
            </MDTypography>
            <MDTypography
              paragraph
              opacity={0.8}
              sx={{
                fontSize: "10pt",
                fontFamily: "Intro Regular",
              }}
              component="p"
            >
              {description}
            </MDTypography>
          </MDBox>
        </Grid>
      </Grid>
    </Card>
  );
};

// Typechecking props for the HorizontalTeamCard
HorizontalTeamCard.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  position: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
  description: PropTypes.string.isRequired,
};

export default HorizontalTeamCard;
