import {checkResponseStatus} from "@haulmont/jmix-react-core";
import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {APP_REST_API_URL} from "config";
import ResultAction from "app/common/success/ResultAction";
import {AiOutlineCloseCircle} from "react-icons/all";
import Card from "@mui/material/Card";
import {CircularProgress} from "@mui/material";
import {MdOutlineDone} from "react-icons/md";
import bgImage from "assets/app/images/panoramic/photo4.jpg";
import CoverLayout from "../../../../layouts/authentication/components/CoverLayout";

const VerifyEmail = observer(() => {
  const {verify_code} = useParams();
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [done, setDone] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    // Clean up the subscription
    setLoading(true)
    fetch(`${APP_REST_API_URL}registration/verify/email?code=${verify_code}`)
      .then(checkResponseStatus)
      .then((resp) => {
        console.log("Success : ", resp)
        setLoading(false);
        setDone(true);
      }).catch((any) => {
      setLoading(false);
      setError(true);
      setDone(true);
    })
    ;
  }, [verify_code]);


  const errorRez = (<ResultAction
    color={"error"}
    cancelAction={{
      onClick: () => {
        navigate("/sign-in")
      },
      btnName: "Закрити"
    }}
    icon={<AiOutlineCloseCircle size={"50px"} color="inherit"/>}
    title="Щось пішло не так!"
    message="За більш детальною інформацією зверніться до адміністратора."
  />)

  const loadingRez = (<ResultAction
    color={"info"}
    icon={<CircularProgress color={'inherit'} style={{fontSize: "50px"}}/>}
    title="Зачекайте будь ласка"
    message="Ми перевіряем реесткаційні данні."
  />)


  const successRez = (<ResultAction
    color={"success"}
    icon={<MdOutlineDone size={"50px"} color="inherit"/>}
    title="Дакуемо за реестрацію!"
    message="Для входу в систему будь ласка перейдить за посиланням нижче."
    okAction={{
      onClick: () => {
        navigate("/sign-in")
      },
      btnName: "На стрінку входу"
    }}
  />)

  //
  // if (loading) {
  //   return <>
  //     <Result
  //       title="Зачекайте будь ласка!"
  //       subTitle="Ми перевіряем реесткаційні данні."
  //       // extra={< CenteredLoader />}
  //       extra={<Spin size="large"/>}
  //     />
  //   </>
  // }
  //
  // if (resultSuccess) {
  //   return <Result
  //     status="success"
  //     title="Дакуемо за реестрацію!"
  //     subTitle="Для входу в систему будь ласка перейдить за посиланням нижче."
  //     extra={[
  //       <Link to="/sign-in">
  //         <Button type="primary" key="console">
  //           На стрінку входу
  //         </Button>
  //       </Link>
  //       ,
  //     ]}
  //   />
  //
  // }

  //
  // if (!resultSuccess) {
  //   return <Result
  //     status={'error'}
  //     title="Щось пішло не так!"
  //     subTitle="За більш детальной інформацією звернітся до адміністратора."
  //
  //   />
  // }


  return (
    <CoverLayout image={bgImage}>
      <Card>
        {loading && loadingRez}
        {done && !error && !loading && successRez}
        {done && error && errorRez}
      </Card>
    </CoverLayout>
  );
});


export default VerifyEmail;