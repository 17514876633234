import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ProfileInfo from "app/components/profile/ProfileInfo";

export default function ProfilePage() {
  return (
    <DashboardLayout>
      <DashboardNavbar/>
      <ProfileInfo/>
      {/*{!editOpen && <ProfileOverview onEdit={handelOpenEdit}/>}*/}
    </DashboardLayout>
  )
}