import { VersionedEntity } from "./VersionedEntity";
export class AuditableEntity extends VersionedEntity {
  static NAME = "AuditableEntity";
  createdBy?: string | null;
  createdDate?: any | null;
  deletedBy?: string | null;
  deletedDate?: any | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: any | null;
}
export type AuditableEntityViewName = "_base" | "_instance_name" | "_local";
export type AuditableEntityView<
  V extends AuditableEntityViewName
> = V extends "_base"
  ? Pick<
      AuditableEntity,
      | "createdBy"
      | "createdDate"
      | "deletedBy"
      | "deletedDate"
      | "lastModifiedBy"
      | "lastModifiedDate"
      | "version"
    >
  : V extends "_local"
  ? Pick<
      AuditableEntity,
      | "createdBy"
      | "createdDate"
      | "deletedBy"
      | "deletedDate"
      | "lastModifiedBy"
      | "lastModifiedDate"
      | "version"
    >
  : never;
