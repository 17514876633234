import {useMainStore} from "@haulmont/jmix-react-core";
import {observer} from "mobx-react-lite";
import React, {useCallback, useState} from "react";
import {boolean} from "yup/lib/locale";

export const AuthenticationContext = React.createContext({
  isAuthenticated: () => boolean,
});

const AuthenticationProvider = observer(({children}) => {
  const mainStore = useMainStore();
  const value = {
    isAuthenticated: useCallback(
      () => mainStore.authToken && mainStore.authenticated && mainStore.userName !== "anonymous",
      []
    ),
  };

  return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>;
});

export default AuthenticationProvider;
