/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React example components
import {useIntl} from "react-intl";
import TimelineItem from "../../../../../common/timeline/TimelineItem";
import {addLinks} from 'react-link-text';
import {getOrderStatusName, orderStatusNameToIcon} from "../../../utils";

function OrdersOverview({order}) {
  // console.log("Order", order)
  const intl = useIntl();

  return (
    <>
      <MDTypography variant="h6" fontWeight="medium">
        Track order
      </MDTypography>
      <MDBox mt={2}>
        {order?.statuses?.map((status, index) =>

          <TimelineItem
            key={index}
            color={status.isDone ? "success" : "info"}
            icon={orderStatusNameToIcon(status.statusType)}
            title={getOrderStatusName(status.statusType)}
            link={status?.link ?  addLinks(status.link): ""}
            description={status?.description ? status?.description : "" }
            dateTime={intl.formatDate(status.createdDate)}
            lastItem={(order.statuses.length - 1) === index}
          />
        )}
      </MDBox>
    </>
  );
}

export default OrdersOverview;
