import {Card, Grid, List, ListItem} from "@mui/material";
import useErrorNotification from "app/common/error/useErrorNotification";
import useSuccessNotification from "app/common/error/useSuccessNotification";
import getAge from "app/common/form/getAge";
import CenteredLoader from "app/common/loader/CenteredLoader";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import {Form, Formik} from "formik";
import {observer} from "mobx-react";
import {useEffect, useState} from "react";
import {MdCancel, MdSave} from "react-icons/md";
import EditHeader from "./components/EditHeader";
import KidAdditionalInfo from "./components/KidAddtionalInfo";
import KidDocumentsInfo from "./components/KidDocumentsInfo";
import KidGeneralInfo from "./components/KidGeneralInfo";
import {form, formValues} from "./data/data";
import {useScoutvilleKidByIdQuery, useUpdateKidMutation} from "../../generated/generated-types";


const KidEditForm = observer(({kid, onReturn}) => {

  const id = kid.id;
  // const { addNotificationAlert } = useAppNotification();
  const errorNotification = useErrorNotification();
  const onSuccess = useSuccessNotification();

  const [initialVals, setInitialVals] = useState({
    updated: false,
    bdCertificate: "",
    bdCertificateSerial: "",
    hobbyInfo: "",
    healthInfo: "",
    foodPreference: "",
    firstName: "",
    secondName: "",
    lastName: "",
    birthDate: "",
    relation: "",
    gender: "",
    canSwim: "",
    shortSize: "",
  });

  const {formId, formFields} = form;
  const [general, additional, documents] = formValues;

  const validations = general.validations.concat(additional.validations, documents.validations);
  const [updateKid, {data, error}] = useUpdateKidMutation();
  const kidLoadResponse = useScoutvilleKidByIdQuery({
    fetchPolicy: "network-only",
    variables: {
      id: id,
    },
  })

  const hasTours = kidLoadResponse?.data?.kidHasTours;
  useEffect(() => {
    if (data) {
      onSuccess("Зміни збережені");
      onReturn();
    }
  }, [data, onSuccess]);

  useEffect(() => {
    if (error) {
      errorNotification(error);
    }
  }, [error]);

  useEffect(() => {
    if (kidLoadResponse.data) {
      const ScoutvilleKidById = kidLoadResponse?.data?.ScoutvilleKidById;
      const currentRelation = kidLoadResponse.data.currentRelation;
      setInitialVals({
        bdCertificate: ScoutvilleKidById?.bdCertificate,
        bdCertificateSerial: ScoutvilleKidById?.bdCertificateSerial || "",
        hobbyInfo: ScoutvilleKidById?.hobbyInfo || "",
        healthInfo: ScoutvilleKidById?.healthInfo || "",
        foodPreference: ScoutvilleKidById?.foodPreference || "",
        firstName: ScoutvilleKidById?.firstName || "",
        secondName: ScoutvilleKidById?.secondName || "",
        lastName: ScoutvilleKidById?.lastName || "",
        birthDate: ScoutvilleKidById?.birthDate || "",
        relation: currentRelation?.relation,
        gender: ScoutvilleKidById?.gender,
        canSwim: ScoutvilleKidById?.canSwim,
        shortSize: ScoutvilleKidById?.shortSize,
      });
    }
  }, [kidLoadResponse.data]);

  // const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const submitForm = async (values, actions) => {
    values.id = id;
    actions.setSubmitting(true);
    updateKid({
      variables: {
        kidDto: values,
      },
    });

    actions.setSubmitting(false);
    // actions.resetForm();
  };

  if (kidLoadResponse.error) {
    console.log("Error: ", kidLoadResponse.error);
  }

  if ((kidLoadResponse.loading || !kidLoadResponse.data, !kidLoadResponse.called)) {
    return <CenteredLoader/>;
  }

  return (
    <>

    <Formik
      enableReinitialize={true}
      initialValues={initialVals} validationSchema={validations} onSubmit={submitForm}>
      {(form) => (
        <Form id={formId} autoComplete="off">
          <EditHeader
            name={`${form.values.firstName} ${form.values.secondName} ${form.values.lastName}`}
            age={getAge(form.values.birthDate)}
          />
          <MDBox pb={3}>
            <Grid container alignItems="center">
              <Grid item xs={12} md={7}>
                <MDBox mb={1}>
                  <MDTypography variant="h5">Зверніть увагу:</MDTypography>
                </MDBox>
                <MDBox mb={2} lg={2}>
                  <List>
                    <ListItem>
                      <MDTypography variant="body2" color="text">
                        •&nbsp;Будь ласка, оновлюйте дані дитини кожного року перед оформленням путівки.
                      </MDTypography>
                    </ListItem>
                    <ListItem>
                      <MDTypography variant="body2" color="text">
                        •&nbsp;Після оформлення путівки редагування даних дитини буде недоступно.
                      </MDTypography>
                    </ListItem>
                    <ListItem>
                      <MDTypography variant="body2" color="text">
                        •&nbsp;Заповнюйте усі дані українською мовою.
                      </MDTypography>
                    </ListItem>
                  </List>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={2} sx={{textAlign: "right"}}>
                <MDButton onClick={form.handleSubmit} variant="gradient" color="info">
                  <MdSave/>&nbsp; Save
                </MDButton>
              </Grid>
              <Grid item xs={12} md={2} sx={{textAlign: "right"}}>
                <MDButton onClick={onReturn} variant="gradient" color="secondary">
                  <MdCancel/>&nbsp; Cancel
                </MDButton>
              </Grid>
            </Grid>
            <MDBox mt={5}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  <MDBox mb={1.5} mt={1.5}>
                    <Card>
                      <MDBox p={2}>
                        <KidGeneralInfo
                          disableFields={hasTours}
                          formData={{
                            formFields,
                            form,
                          }}
                        />
                      </MDBox>
                    </Card>
                    {/* <ComplexProjectCard
                  image={logoSlack}
                  title="slack bot"
                  description="If everything I did failed - which it doesn't, I think that it actually succeeds."
                  dateTime="02.03.22"
                  members={[team1, team2, team3, team4, team5]}
                  dropdown={{
                    action: openSlackBotMenu,
                    menu: renderMenu(slackBotMenu, closeSlackBotMenu),
                  }}
                /> */}
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={5} lg={5}>
                  <MDBox mb={1.5} mt={1.5}>
                    <Card>
                      <MDBox p={3}>
                        <KidAdditionalInfo
                          disableFields={hasTours}
                          formData={{
                            formFields,
                            form,
                          }}
                        />
                      </MDBox>
                    </Card>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                  <MDBox mb={1.5} mt={1.5}>
                    <Card>
                      <MDBox p={3}>
                        <KidDocumentsInfo
                          disableFields={hasTours}
                          formData={{
                            formFields,
                            form,
                          }}
                        />
                      </MDBox>
                    </Card>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        </Form>
      )}
    </Formik></>);
})

export default KidEditForm;
