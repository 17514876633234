import {gql, useMutation} from "@apollo/client";
import {Card, Grid, List, ListItem, Step, StepLabel, Stepper} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import {Form, Formik} from "formik";
import {observer} from "mobx-react";
import {useState} from "react";
import KidAdditionalInfo from "./components/KidAddtionalInfo";
import KidDocumentsInfo from "./components/KidDocumentsInfo";
import KidExperienceInfo from "./components/KidExperienceInfo";
import KidGeneralInfo from "./components/KidGeneralInfo";
import {form, formValues, initialValues} from "./data/data";
import {useCreateKidMutation, useUpdateKidMutation} from "../../generated/generated-types";
import useUserData from "../../common/user/useUserData";
import {MdCancel, MdSave} from "react-icons/md";
import MDTypography from "../../../components/MDTypography";


function getSteps() {
  return ["Info", "Additional", "Experience", "Documents"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <KidGeneralInfo formData={formData}/>;
    case 1:
      return <KidAdditionalInfo formData={formData}/>;
    case 2:
      return <KidExperienceInfo formData={formData}/>;

    case 3:
      return <KidDocumentsInfo formData={formData}/>;
    // case 3:
    // return <Profile formData={formData} />;
    default:
      return null;
  }
}

const KidCreateForm = observer(({onClose}) => {
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

  const {formId, formFields} = form;
  const {validations} = formValues[activeStep];
  const [addKid, {data, loading, error}] = useCreateKidMutation()
  const {refreshData} = useUserData();


  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values, actions) => {
    addKid({
      variables: {
        kidDto: values,
      },
    });
    await sleep(1000);
    // eslint-disable-next-line no-alert
    // alert(JSON.stringify(values, null, 2));

    actions.setSubmitting(false);
    actions.resetForm();
    setActiveStep(0);
    refreshData()
    onClose();
  };

  const onSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <MDBox py={3} mb={20} height="65vh">
      <Grid container alignItems="center">
        <Grid item xs={12} md={12}>
          <Card>
            <MDBox m={2}>
              <MDBox mb={1}>
                <MDTypography variant="h5">Зверніть увагу:</MDTypography>
              </MDBox>
              <MDBox mb={2} lg={2}>
                <List>
                  <ListItem>
                    <MDTypography variant="body2" color="text">
                      •&nbsp;Будь ласка, оновлюйте дані дитини кожного року перед оформленням путівки.
                    </MDTypography>
                  </ListItem>
                  <ListItem>
                    <MDTypography variant="body2" color="text">
                      •&nbsp;Після оформлення путівки редагування даних дитини буде недоступно.
                    </MDTypography>
                  </ListItem>
                  <ListItem>
                    <MDTypography variant="body2" color="text">
                      •&nbsp;Заповнюйте усі дані українською мовою.
                    </MDTypography>
                  </ListItem>
                </List>

              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" sx={{height: "100%", mt: 8}}>
        <Grid item xs={12} lg={8}>
          <Formik

            initialValues={initialValues} validationSchema={validations} onSubmit={onSubmit}>
            {(form) => (
              <Form id={formId} autoComplete="off">
                <Card sx={{height: "100%"}}>
                  <MDBox mx={2} mt={-3}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </MDBox>
                  <MDBox p={3}>
                    <MDBox>
                      {getStepContent(activeStep, {
                        formFields,
                        form,
                      })}
                      <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                        {activeStep === 0 ? (
                          <MDButton
                            onClick={() => onClose()}
                            variant="gradient"
                            color="light"
                          >
                            cancel
                          </MDButton>
                        ) : (
                          <MDButton variant="gradient" color="light" onClick={handleBack}>
                            back
                          </MDButton>
                        )}
                        <MDButton
                          disabled={form.isSubmitting}
                          type="submit"
                          variant="gradient"
                          color="dark"
                        >
                          {isLastStep ? "send" : "next"}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </MDBox>
  );
});

export default KidCreateForm;
