import { Grid } from "@mui/material";
import cooking from "assets/app/icons/custom/cooking.svg";
import forrest_and_camp from "assets/app/icons/custom/forrest-and-camp.svg";
import people from "assets/app/icons/custom/people.svg";
import security from "assets/app/icons/custom/security.svg";
import sun_and_mountains from "assets/app/icons/custom/sun-and-mountains.svg";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const data = [
  {
    icon: cooking,
    title: "Харчування",
    description: "П’ятиразове смачне та збалансоване харчування",
  },
  {
    icon: security,
    title: "Безпека",
    description: "Цілодобова охорона та медичне забезпечення. Страхування",
  },
  {
    icon: people,
    title: "Персонал",
    description: "#кращакомандаліта – мультидисциплінарна команда професіоналів",
  },
  {
    icon: forrest_and_camp,
    title: "Інфраструктура",
    description: "Три житлові квартали, власна кухня, душ з гарячою водою",
  },
  {
    icon: sun_and_mountains,
    title: "Природа",
    description: "Сосновий ліс на березі Канівського водосховища",
  },
];

const IconsPromo = ({ mt }) => {
  return (
    <Grid mt={mt} container justifyContent={"space-around"}>
      {data.map(({ icon, title, description }) => (
        <Grid key={title} item xs={10} md={2} lg={2} my={4}>
          <MDBox p={2} textAlign="center" borderRadius="lg">
            <MDBox component="img" src={icon} width="5em" />
            <MDTypography variant="h6">{title}</MDTypography>
            <MDTypography
              opacity={0.8}
              component="p"
              style={{ lineHeight: "1.6em", fontFamily: "Intro Regular" }}
              variant="overline"
            >
              {description}
            </MDTypography>
          </MDBox>
        </Grid>
      ))}
    </Grid>
  );
};

export default IconsPromo;
