import React, {useCallback, useEffect, useState} from 'react';
import {useGetUserDataQuery} from "app/generated/generated-types";
import {UserDtoFieldPolicy, UserDtoKeySpecifier} from "app/generated/apollo-helpers";
import {UserDto} from "jmix/entities/UserDto";
import useAuthenticated from "app/common/authentication/useAuthenticated";


export type UserData = {
  id?: string;
  firstName?: string | null;
  secondName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  email?: string | null;
  passportSerial?: string | null;
  taxSerial?: string | null;
  documents?: any | null;

}


type UserDataContextProps = {
  loading: boolean
  userData: UserData | null
  userProfileCompleted: boolean
  trustedCompleted: boolean
  kidsCount: number
  getUserData: () => UserData | null
  refreshData: () => void
}


export const UserDataContext = React.createContext<UserDataContextProps>({
  loading: true,
  userData: null,
  userProfileCompleted: false,
  trustedCompleted: false,
  kidsCount: 0,
  getUserData: () => null,
  refreshData: () => {
  },
});

export default function UserDataProvider({children}) {
  const isAuthenticated = useAuthenticated();
  const {data, error, loading, refetch, networkStatus} = useGetUserDataQuery()
  const [userDataState, setUserDataState] = useState<UserDto | null>(null);
  const [userProfileCompleted, setUserUserProfileCompleted] = useState<boolean>(false);
  const [trustedCompleted, setTrustedCompleted] = useState<boolean>(false);
  const [kidsCount, setKidCount] = useState<number>(0);

  if (error) {

    console.log("Error : ", error)
    console.log("networkStatus : ", error)
    console.log("isAuthenticated : ", isAuthenticated())

  }
  useEffect(() => {

    data?.userData && setUserDataState(data.userData as UserDto)
    data?.profileCompleted && setUserUserProfileCompleted(data.profileCompleted)
    data?.trustedCompleted && setTrustedCompleted(data.trustedCompleted)
    data?.ScoutvilleKidCount && setKidCount(data.ScoutvilleKidCount)

  }, [data?.userData, data?.profileCompleted, data?.trustedCompleted, data?.ScoutvilleKidCount]);


  const contextValue = {
    loading: loading,
    userData: userDataState,
    userProfileCompleted: userProfileCompleted,
    trustedCompleted: trustedCompleted,
    kidsCount: kidsCount,
    getUserData: useCallback(() => [userDataState], [userDataState]),
    refreshData: useCallback(() => refetch(), [userDataState, userProfileCompleted, trustedCompleted, kidsCount]),
  } as UserDataContextProps;

  return (
    <UserDataContext.Provider value={contextValue}>
      {children}
    </UserDataContext.Provider>
  );
}
