import { gql, useQuery } from "@apollo/client";
import { useGetFile } from "@haulmont/jmix-react-core";
import { loginMapJmixRestErrorToIntlId } from "@haulmont/jmix-react-web";
import { Container, Grid } from "@mui/material";
import HorizontalTeamCard from "app/common/cards/HorizontalTeamCard";
import CenteredLoader from "app/common/loader/CenteredLoader";
import background from "assets/app/images/backgrounds/bg4.jpg";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa";
import { useIntl } from "react-intl";

export const SCOUTVILLE_TEAM_LIST = gql`
  query ScoutvilleTeamMemberList($orderBy: inp_ScoutvilleTeamMemberOrderBy) {
    ScoutvilleTeamMemberList(orderBy: $orderBy) {
      image
      fullName
      position
      description
    }
  }
`;

function prepare(arg) {
  function replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, "g"), replace);
  }
  return replaceAll(arg + "", /\s+|\+/g, "_");
}

const MemberCard = ({ member }) => {
  const getFile = useGetFile();
  const [fileData, setFileData] = useState();

  useEffect(() => {
    if (member.image) {
      getFile(prepare(member.image))
        .then((data) => setFileData(data))
        .catch((reason) => {
          console.log("Can't get file :", reason);
        });
    }
  }, [member?.image]);

  return (
    <HorizontalTeamCard
      className="h-full"
      image={fileData ? URL.createObjectURL(fileData) : null}
      name={member.fullName}
      position={{ color: "info", label: member.position }}
      description={member.description}
    />
  );
};

const TeamSection = ({ mt }) => {
  const intl = useIntl();

  const [alertMessage, setAlertMessage] = useState("");

  const { data, error, loading } = useQuery(SCOUTVILLE_TEAM_LIST, {
    variables: {
      orderBy: {
        ordinal: "ASC",
      },
    },
  });

  if (loading) {
    return <CenteredLoader />;
  }

  if (error) {
    const loginMessageErrorIntlId = loginMapJmixRestErrorToIntlId(error);
    setAlertMessage(intl.formatMessage({ id: loginMessageErrorIntlId }));
  }

  return (
    <>
      {/* {alertMessage ? (
        <MDAlert dismissible={true} color={"error"}>
          {alertMessage}
        </MDAlert>
      ) : null} */}

      <MDBox
        style={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center; center",
        }}
        py={mt}
      >
        <MDBox mx={"5%"}>
          <span>
            <FaAngleRight
              size={"25pt"}
              sx={{
                marginRight: "5pt",
              }}
            />
            <MDTypography
              fontWeight="bold"
              component="span"
              sx={{
                fontSize: "35pt",
              }}
              style={{
                letterSpacing: "2px",
                fontFamily: "Intro Black",
                color: "#4caf50",
              }}
            >
              Команда
            </MDTypography>
          </span>
          <MDTypography
            ml={"3%"}
            paragraph
            variant="body2"
            style={{
              color: "#656464",
            }}
          >
            Команда дорослих Скаутвіллю складається з професіоналів своєї справи, які по-справжньому
            люблять дітей і свою роботу.
          </MDTypography>
        </MDBox>
        <MDBox component="section" py={7}>
          <Container>
            <Grid container spacing={3}>
              {data
                ? data.ScoutvilleTeamMemberList.map((member, index) => (
                    <Grid key={index} item xs={12} lg={4}>
                      <MDBox key={index} className="h-full" mb={1}>
                        <MemberCard className="h-full" member={member} />
                      </MDBox>
                    </Grid>
                  ))
                : null}
            </Grid>
          </Container>
        </MDBox>
      </MDBox>
    </>
  );
};

export default TeamSection;
