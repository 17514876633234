import MDAlert from "../../../../components/MDAlert";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import useUserData from "../../../common/user/useUserData";
import {Divider, List, ListItemText} from "@mui/material";
import MDButton from "../../../../components/MDButton";
import {AiOutlineLink, FiExternalLink, FiLink2, GoLink, GoLinkExternal, MdLink} from "react-icons/all";
import {Link} from "react-router-dom";


export default function ProfileAlert() {
  let {kidsCount, trustedCompleted, userProfileCompleted, loading} = useUserData();

  return (
   !loading && <MDBox zIndex={2000} px={5}>
      <MDAlert title={"Some title"}
               color={'warning'}
               close={() => ""}>
        {/*<MdOutlineWarning color={'dark'}/>*/}
        <MDBox>
          <MDTypography variant="h4" color="white" mb={1}>
            Увага!
          </MDTypography>
          <MDTypography variant="body2" color="white">
            Для оформлення путівки вам необхідно:

          </MDTypography>
          <List>
            {!userProfileCompleted && <ListItemText primaryTypographyProps={{style: {color: 'white'}}}
                                                    primary={
             <> • Заповнити ваш профіль за посиланням.&nbsp;&nbsp;
               <Link to={"/pages/profile"}><FiExternalLink/></Link>
             </>
            }/>
                                                    }
            {!trustedCompleted && <ListItemText primaryTypographyProps={{style: {color: 'white'}}}
                                                primary={
            <>• Заповнити профіль довіренної особи за посиланням..&nbsp;&nbsp;
              <Link to={"/pages/profile"}><FiExternalLink/></Link>
            </>
            }/>}
            {(kidsCount < 1) && <ListItemText primaryTypographyProps={{style: {color: 'white'}}}
                                              primary={
                                                <> • Додати дитину за посланням профіль довіренної особи за
                                                  посиланням.&nbsp;&nbsp;
                                                  <Link to={"/kids"}><FiExternalLink/></Link></>
                                              }/>}
          </List>

        </MDBox>
      </MDAlert>
    </MDBox>
  )

}