import { Button } from "@mui/material";
// core components
// import Button from "components/CustomButtons/Button.jsx";
import defaultImage from "assets/app/images/image_placeholder.jpg";
import defaultAvatar from "assets/app/images/placeholder.jpg";
// used for making the prop types of this component
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { isImageFile } from "../utils/files";
import useFileDownload from "./useFileDownload";

const ImageUpload = ({
  avatar,
  addButtonProps,
  changeButtonProps,
  removeButtonProps,
  onChange,
  onSubmit,
  value,
  ...rest
}) => {
  const inputRef = useRef(null);

  const [state, setState] = useState({
    file: undefined,
    imagePreviewUrl: avatar ? defaultAvatar : defaultImage,
  });

  const resolved = useFileDownload(value);

  useEffect(() => {
    if (resolved && resolved.objectUrl && state.file === undefined) {
      setState({
        imagePreviewUrl: resolved.objectUrl,
        file: {
          name: resolved.fileName,
        },
      });
    }
  }, [resolved, state]);


  function handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
    if (onChange) {
      onChange(file);
    }
  }
  function handleSubmit(e) {
    e.preventDefault();
    if (onSubmit) {
      onSubmit(e);
    }
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }
  function handleClick() {
    let f = inputRef.current.click();
  }
  function handleRemove() {
    setState({
      file: null,
      imagePreviewUrl: avatar ? defaultAvatar : defaultImage,
    });
    inputRef.current.value = null;
  }

  return (
    <div className="fileinput text-center">
      <input {...rest} type="file" onChange={handleImageChange} ref={inputRef} />
      <div className={"thumbnail" + (avatar ? " img-circle" : "")}>
        {state.file && state.file.name && !isImageFile(state.file.name) ? (
          <embed src={state.imagePreviewUrl} alt="..." />
        ) : (
          <img src={state.imagePreviewUrl} alt="..." />
        )}
      </div>
      <div>
        {!state.file ? (
          <Button {...addButtonProps} onClick={() => handleClick()}>
            {avatar ? "Add Photo" : "Select image"}
          </Button>
        ) : (
          <span>
            <Button {...changeButtonProps} onClick={() => handleClick()}>
              <MdEdit />
            </Button>
            {avatar ? <br /> : null}
            <Button {...removeButtonProps} onClick={() => handleRemove()}>
              <i className="fas fa-times" /> <MdDelete />
            </Button>
          </span>
        )}
      </div>
    </div>
  );
};

ImageUpload.propTypes = {
  // value: PropTypes.object,
  avatar: PropTypes.bool,
  // onChange: PropTypes.func,
  // onsubmit: PropTypes.func,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};

export default ImageUpload;
