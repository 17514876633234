/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

/**
 All of the routes for the Material Dashboard 2 PRO React are added here,
 You can add a new route, customize the routes and delete the routes here.

 Once you add a new route on this file it will be visible automatically on
 the Sidenav.

 For adding a new route you can follow the existing routes in the routes array.
 1. The `type` key with the `collapse` value is used for a route.
 2. The `type` key with the `title` value is used for a title inside the Sidenav.
 3. The `type` key with the `divider` value is used for a divider between Sidenav items.
 4. The `name` key is used for the name of the route on the Sidenav.
 5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
 6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
 7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
 inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
 8. The `route` key is used to store the route location which is used for the react router.
 9. The `href` key is used to store the external links location.
 10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
 10. The `component` key is used to store the component of its route.
 */

// Material Dashboard 2 PRO React layouts
// @mui icons
import {Icon} from "@mui/material";
import Checkout from "app/components/checkout/Checkout";
import Payment from "app/components/payment/Payment";
import KidsPage from "app/pages/kid/KidsPage";
import Orders from "app/pages/orders/order-list/Orders";
import ProfilePage from "app/pages/profile/ProfilePage";
import TourPage from "app/pages/tour/TourPage";
// Images
import profilePicture from "assets/app/images/placeholder.jpg";
// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";
import {FaCampground, FaChild} from "react-icons/fa";
import {MdShoppingBasket} from "react-icons/md";
import OrderDetails from "./app/pages/orders/order-details/OrderDetails";


const routes = (userData, intl) => {
  return [
    {
      type: "collapse",
      name: `${userData?.firstName || ""} ${userData?.lastName || ""}`,
      key: "account",
      icon: <MDAvatar src={profilePicture} alt={intl.formatMessage({id: "my_profile"})} size="sm"/>,
      collapse: [
        {
          name: intl.formatMessage({id: "my_profile"}),
          key: "profile",
          route: "/pages/profile",
          component: <ProfilePage/>,
        },
        // {
        //   name: "Logout",
        //   key: "logout",
        //   route: "/authentication/sign-in/basic",
        //   component: <SignInBasic />,
        // },
      ],
    },
    {type: "divider", key: "divider-0"}
    , {
      type: "root",
      icon: <Icon fontSize="medium"><FaCampground/></Icon>,
      name: "Зміни",
      key: "tours",
      route: "/tours",
      component: <TourPage/>,
    },
    {
      type: "root",
      icon: <Icon fontSize="medium"><MdShoppingBasket/></Icon>,
      name: "Замовлення",
      key: "замовлення",
      route: "/orders",
      component: <Orders/>,
    },
    {
      type: "root",
      icon: <Icon fontSize="medium"><FaChild/></Icon>,
      name: "Діти",
      key: "діти",
      route: "/kids",
      component: <KidsPage/>,
    },
    {
      type: "hide",
      name: "Оплати",
      key: "оплати",
      route: "/payment/:id",
      component: <Payment/>,
    },
    {
      type: "hide",
      name: "Купити",
      key: "купити",
      route: "/checkout",
      component: <Checkout/>,
    },
    {
      type: "hide",
      name: "Order details",
      key: "orders-details",
      route: "/orders/detail/:id",
      component: <OrderDetails/>,
    },
  ];
}

export default routes;
