import {useMainStore} from "@haulmont/jmix-react-core";
import brandDark from "assets/app/logo/md-gray-logo.png";
import brandWhite from "assets/app/logo/md-white-logo.png";
// Material Dashboard 2 PRO React contexts
import {setMiniSidenav, useMaterialUIController} from "context";
import Configurator from "examples/Configurator";
// Material Dashboard 2 PRO React example components
import Sidenav from "examples/Sidenav";
import {observer, useLocalStore} from "mobx-react";
import {useEffect, useState} from "react";
// react-router components
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
// Material Dashboard 2 PRO React routes
import routes from "routes";
import useAuthenticated from "app/common/authentication/useAuthenticated";
import useUserData from "../../common/user/useUserData";
import useTimeoutCallback from "../../common/utils/useTimeoutCallback";
import {useIntl} from "react-intl";


const Dashboard = observer(() => {
  let {userData} = useUserData();
  let intl = useIntl();

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const {pathname} = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };


  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.scrollingElement ? document.scrollingElement.scrollTop = 0 : console.log("doc scrollingElement is null ")
    document.documentElement.scrollTop = 0;

  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route path={route.route} element={route.component} key={route.key}/>;
      }

      return null;
    });


  return (
    <>
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="Скаутвілль"
            routes={routes(userData, intl)}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator/>
          {/* {configsButton} */}
        </>
      )}
      {layout === "vr" && <Configurator/>}
      <Routes>
        {getRoutes(routes(userData, intl))}
        <Route path="*" element={<Navigate to="/tours"/>}/>
      </Routes>
    </>
  )


})

export default Dashboard;