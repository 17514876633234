import { Grid } from "@mui/material";
import RadioField from "app/common/form/fields/RadioField";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "layouts/applications/wizard/components/FormField";

const KidAdditionalInfo = ({ formData, disableFields }) => {
  const { formFields, form } = formData;
  const { values, errors, touched, handleChange } = form;
  const { hobbyInfo, healthInfo, foodPreference } = formFields;
  const {
    hobbyInfo: hobbyInfoV,
    healthInfo: healthInfoV,
    foodPreference: foodPreferenceV,
  } = values;


  return (
    <MDBox fullWidth>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Kid preference</MDTypography>
        <MDTypography variant="button" color="text">
          Optional information
        </MDTypography>
      </MDBox>
      <MDBox fullWidth mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <FormField
              multiline
              rows={4}
              onChange={handleChange}
              type={hobbyInfo.type}
              label={hobbyInfo.label}
              name={hobbyInfo.name}
              value={hobbyInfoV}
              placeholder={hobbyInfo.placeholder}
              error={errors.lastName && touched.lastName}
              success={hobbyInfoV.length > 0 && !errors.lastName}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <FormField
              multiline
              rows={4}
              onChange={handleChange}
              type={healthInfo.type}
              label={healthInfo.label}
              name={healthInfo.name}
              value={healthInfoV}
              placeholder={healthInfo.placeholder}
              error={errors.healthInfo && touched.healthInfo}
              success={healthInfoV.length > 0 && !errors.healthInfo}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <FormField
              multiline
              rows={4}
              onChange={handleChange}
              type={foodPreference.type}
              label={foodPreference.label}
              name={foodPreference.name}
              value={foodPreferenceV}
              placeholder={foodPreference.placeholder}
              error={errors.foodPreference && touched.foodPreference}
              success={foodPreferenceV.length > 0 && !errors.foodPreference}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

// // typechecking props for UserInfo
// KidGeneralInfo.propTypes = {
//   formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
// };

export default KidAdditionalInfo;
