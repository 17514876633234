import TourCardGrid from "app/components/tour/TourCardGrid";
import background from "assets/app/images/backgrounds/bg7.jpg";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { FaAngleRight } from "react-icons/fa";

const TourSection = ({ mt }) => {
  return (
    <MDBox
      style={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center; center",
      }}
      py={mt}
    >
      <MDBox mx={"5%"}>
        <span>
          <FaAngleRight
            size={"25pt"}
            sx={{
              marginRight: "5pt",
            }}
          />
          <MDTypography
            fontWeight="bold"
            component="span"
            // variant="h2"
            sx={{
              fontSize: "35pt"
            }}

            style={{
              letterSpacing: "2px",
              fontFamily: "Intro Black",
              color: "#9c27b0",
            }}
          >
            Зміни
          </MDTypography>
        </span>
        <MDTypography
          ml={"3%"}
          paragraph
          style={{
            fontSize: "medium",
            fontWeight: "400",
            color: "#656464",
          }}
        >
          Кожна зміна унікальна та самобутня, має свою тему та ідею. За десять років існування
          табору жодна тема не повторювалася. Але завжди на дітей чекають традиційні лісові
          випробування, ігри, квести, цікаві мандрівки та змагання. Тож, обираючи зміну, зважайте
          виключно на інтереси вашої дитини, календарні строки, які вам підходять або інформацію про
          те, на яку зміну збираються друзі вашої дитини, адже разом веселіше!
        </MDTypography>
      </MDBox>
      <MDBox mx={"2%"}>
        <TourCardGrid />
      </MDBox>
    </MDBox>
  );
};

export default TourSection;
