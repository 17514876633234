import { useState } from "react";
import MDSnackbar from "app/common/snackbar/MDSnackbar";
import MDAlert from "components/MDAlert";
import MDTypographyRoot from "components/MDTypography/MDTypographyRoot";
import MDTypography from "components/MDTypography";

const AlertNotification = ({
  title,
  message,
  icon,
  dismissible,
  timeout,
  color,
  onClose,
}) => {
  const [errorOpen, setErrorOpen] = useState(true);

  const close = () => {
    setErrorOpen(false);
    onClose();
  };

  return (
    <MDAlert
      color={color}
      icon={icon}
      title={title}
      timeout={timeout}
      dismissible={dismissible}
      open={errorOpen}
      onClose={close}
      close={close}
      bgWhite
    >
      <MDTypography color="white">{title}</MDTypography>
      <MDTypography color="white">{message}</MDTypography>
    </MDAlert>
  );
};

export default AlertNotification;
