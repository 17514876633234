/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
// @mui material components
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function SimpleTourCard({ image, title, badge, description, other, action }) {
  return (
    <Card className={'wh-full'}>
      <Grid container direction="column" justifyContent="space-between" className="wh-full">
        <Grid item md={3}>
          {image ? (
            <MDBox position="relative" borderRadius="lg" mt={-3} mx={2}>
              <MDBox
                component="img"
                src={image}
                alt={title}
                borderRadius="lg"
                shadow="md"
                width="100%"
                height="100%"
                position="relative"
                zIndex={1}
              />
              <MDBox
                borderRadius="lg"
                shadow="md"
                width="100%"
                height="100%"
                position="absolute"
                left={0}
                top="3%"
                sx={{
                  backgroundImage: `url(${image})`,
                  transform: "scale(0.94)",
                  filter: "blur(12px)",
                  backgroundSize: "cover",
                }}
              />
            </MDBox>
          ) : null}
          <div style={{ textAlign: "center" }}>
            <MDBox p={2}>
              <MDTypography
                display="inline"
                variant="h5"
                textTransform="capitalize"
                fontWeight="bold"
              >
                {title}
              </MDTypography>
            </MDBox>
          </div>
        </Grid>
        <Grid item>
          <div style={{ textAlign: "center" }}>
            <MDBox pb={2} px={2}>
              {description ? (
                <MDBox mt={1} mb={1}>
                  <MDTypography variant="body2" component="p" color="text">
                    {description}
                  </MDTypography>
                </MDBox>
              ) : null}
              {other ? (
                <MDBox mt={1} mb={2}>
                  {other}
                </MDBox>
              ) : null}
              {badge ? (
                <MDBox mt={1} pb={action ? 2 : 0}>
                  {badge}
                </MDBox>
              ) : null}
              {action ? (
                action.type === "external" ? (
                  <MuiLink href={action.route} target="_blank" rel="noreferrer">
                    <MDButton color={action.color ? action.color : "dark"}>{action.label}</MDButton>
                  </MuiLink>
                ) : (
                  <Link to={action.route}>
                    <MDButton fullWidth color={action.color ? action.color : "dark"}>
                      {action.label}
                    </MDButton>
                  </Link>
                )
              ) : null}
            </MDBox>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
}

// Typechecking props for the SimpleBlogCard
SimpleTourCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  other: PropTypes.node,
  badge: PropTypes.node,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
      "default",
    ]),
    label: PropTypes.string.isRequired,
  }),
};

export default SimpleTourCard;
