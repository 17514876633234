import React, {useRef, useState} from 'react';
import {ErrorMessage, useField} from "formik";
import {
  Avatar,
  FormLabel,
  Input,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "../../../../components/MDButton";
import {MdAccountCircle, MdDelete, MdDocumentScanner, MdFileCopy, MdFolder} from "react-icons/md";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import {green} from "@mui/material/colors";
import {IoDocumentSharp} from "react-icons/all";

const FileInputField = ({
                          name,
                          label,
                          value,
                          shrink,
                          multiple,
                          filesFilter,
                          inputProps,
                          startButton,
                          endButton,
                          onFileLoad,
                          ...rest
                        }) => {
  const [field, meta, helpers] = useField(name);

  const ref = useRef()
  const [state, setState] = useState({
    files: meta.initialValue
  });

  const onFocus = e => {
    ref.current.click(e);
    helpers.setTouched(true)
  };

  let buttonStart = {};
  let buttonEnd = {};
  if (startButton) {
    buttonStart = {
      startAdornment: (
        <>
          {startButton.icon !== undefined ?
            <InputAdornment position="start">
              <MDButton {...endButton.buttonProps} size={'small'}>  {startButton.icon}</MDButton>
            </InputAdornment> : null}
          {startButton.text !== undefined ? startButton.text : null}
        </>)
    };
  }
  if (endButton) {
    buttonEnd = {
      endAdornment: (
        <>
          {endButton.icon !== undefined ?
            <InputAdornment position="end">
              <MDButton {...endButton.buttonProps} size={'small'}>{endButton.icon}</MDButton>
            </InputAdornment> : null}
          {endButton.text !== undefined ? endButton.text : null}
        </>
      )
    };
  }
  const addFile = (e) => {

    const files = state.files || [];
    for (let i = 0; i < e.target.files.length; i++) {
      files.push(e.target.files[i]);
    }
    setState(st => {
        // files.push(...st.files)
        return {
          files: files,
        }
      }
    );
    helpers?.setValue(files)
    // onFileLoad && onFileLoad(files)
  };

  function removeFile(file) {
    let array = state.files
    const index = array.indexOf(file);
    if (index > -1) {
      array.splice(index, 1); // 2nd parameter means remove one item only
    }
    setState({files: array})
    helpers?.setValue(array)
  }

  return (
    <>
      <MDBox mb={2} onClick={onFocus}>
        <input
          type="file"
          style={{
            display: 'none',
            opacity: "0",
            position: "absolute",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            width: "100%",
            height: "100%",
            zIndex: "-1"
          }}
          accept={filesFilter}
          multiple={multiple}
          ref={ref}
          onChange={addFile}
        />

        <MDInput
          variant="standard"
          label={label}
          fullWidth
          InputProps={{
            ...inputProps, ...buttonStart, ...buttonEnd,
          }}
          value={value}
          InputLabelProps={{shrink: shrink}}
          {...rest}
        />
      </MDBox>
      <List>
        {state.files.map((file, index) =>

          <ListItem key={file.name + "-" + index}

                    secondaryAction={
                      <IconButton onClick={() => removeFile(file)} edge="end" aria-label="delete">
                        <MdDelete/>
                      </IconButton>
                    }
          >
            <ListItemAvatar>
              <Avatar color={'info'} sx={{bgcolor: green[500], width: 24, height: 24}}>
                <IoDocumentSharp/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{variant: "body2"}}
              primary={file.name}
              // secondary={secondary ? 'Secondary text' : null}
            />
          </ListItem>
        )}
      </List>
      <MDBox mt={0.75}>
        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
          <ErrorMessage name={name}/>
        </MDTypography>
      </MDBox>

    </>
  );
};

export default FileInputField;
