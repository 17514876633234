import {Grid} from "@mui/material";
import ImageUploadField from "app/common/form/fields/ImageUploadField";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// @mui material components
// Material Dashboard 2 PRO React components
// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
import PropTypes from "prop-types";

function KidDocumentsInfo({formData,  disableFields}) {
  const {formFields, form} = formData;
  const {values, errors, touched, handleChange} = form;
  const {bdCertificateSerial, bdCertificate} = formFields;
  const {
    bdCertificateSerial: bdCertificateSerialV,
    bdCertificate: bdCertificateV,
  } = values;

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">About kid</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory information
        </MDTypography>
      </MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormField
              disabled={disableFields}
              onChange={handleChange}
              type={bdCertificateSerial.type}
              label={bdCertificateSerial.label}
              name={bdCertificateSerial.name}
              value={bdCertificateSerialV}
              placeholder={bdCertificateSerial.placeholder}
              error={errors.bdCertificateSerial && touched.bdCertificateSerial}
              success={bdCertificateSerialV.length > 0 && !errors.bdCertificateSerial}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <ImageUploadField
              disabled={disableFields}
              accept={".png, .jpg, .jpeg, .pdf"}
              value={bdCertificateV}
              label={bdCertificate.label}
              name={bdCertificate.name}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for UserInfo
KidDocumentsInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default KidDocumentsInfo;
