import { getEnumCaption, getMainStore, getMetadata, getPropertyInfo } from "@haulmont/jmix-react-core";
import getValueTranslation from "./getValueTranslation";

const getEnumValues = (entityName: string, propName: string) => {

  const mainStore = getMainStore();
  const metadata = getMetadata();
  const propertyInfo = getPropertyInfo(metadata.entities, entityName, propName)!;
  const enumInfo = metadata.enums.find((enm) => {
    return enm.name === propertyInfo.type;
  });

  return enumInfo?.values || [];



  // console.log("ENUM VALUES: " , enumValues)
  // console.log(
  //   "ENUM TR: ",
  //   enumValues.map((v) =>
  //     getValueTranslation(entityName, propName, v.name))
  // );
  // console.log(
  //   "ENUM CAP: ",
  //   enumValues.map((v) =>
  //     getEnumCaption(v.name, propertyInfo, metadata.enums, mainStore.enumMessages)
  //   )
  // );
  // return enumValues.map((v) =>
  //   getEnumCaption(v.name, propertyInfo, metadata.enums, mainStore.enumMessages)
  // );
};

export default getEnumValues;
