import {ReactNode, useEffect, useState} from "react";
import {MdInfoOutline, MdOutlineDone, MdOutlinePriorityHigh, MdOutlineWarning} from "react-icons/md";
import {NotificationComponent, NotificationType} from "./AppNotificationContext";
import AlertNotification from "./components/AlertNotification";
import ModalNotification from "./components/ModalNotification";
import SnackbarNotification from "./components/SnackbarNotification";
import useAppNotification from "./useAppNotification";


export enum NotificationColor {
  primary = "primary",
  secondary = "secondary",
  info = "info",
  success = "success",
  warning = "warning",
  error = "error",
  dark = "dark",
  light = "light",
}


const AppNotification = () => {
  const {notification, removeNotification} = useAppNotification();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (notification) {
      setOpen(true);
    }
  }, [notification]);

  const handleClose = () => {
    removeNotification();
    setOpen(false);
  };

  function getIcon(type: NotificationType): ReactNode {
    switch (type) {
      case NotificationType.Info: {
        return <MdInfoOutline/>
      }
      case NotificationType.Warning: {
        return <MdOutlineWarning/>
      }

      case NotificationType.Error: {
        return <MdOutlinePriorityHigh/>
      }

      case NotificationType.Success: {
        return <MdOutlineDone/>
      }
      default: {
        return <MdInfoOutline/>
      }


    }
  }

  function getColor(type: NotificationType): NotificationColor {
    switch (type) {
      case NotificationType.Info: {
        return NotificationColor.info
      }
      case NotificationType.Warning: {
        return NotificationColor.warning
      }
      case NotificationType.Error: {
        return NotificationColor.error
      }
      case NotificationType.Neutral: {
        return NotificationColor.dark
      }
      case NotificationType.Primary: {
        return NotificationColor.primary
      }
      case NotificationType.Success: {
        return NotificationColor.success
      }
      default: {
        return NotificationColor.info
      }
    }
  }

  if (!notification || !open) return <></>;


  switch (notification.component) {
    case NotificationComponent.Snackbar: {
      const icon = getIcon(notification.type)
      const color = getColor(notification.type)
      const dismissible: boolean = !!notification.dismissible
      const timeout = typeof notification.dismissible === 'number' ? notification.dismissible : null
      return <SnackbarNotification
        onClose={handleClose}
        icon={icon}
        color={color}
        dismissible={dismissible}
        timeout={timeout}
        anchorOrigin={notification.anchorOrigin}
        title={notification.title}
        message={notification.message!}/>
    }

    case NotificationComponent.Modal: {
      const icon = getIcon(notification.type)
      const color = getColor(notification.type)
      return <ModalNotification
        onClose={handleClose}
        icon={icon}
        color={color}
        title={notification.title}
        message={notification.message}/>
    }

    case NotificationComponent.Alert: {
      const icon = getIcon(notification.type)
      const color = getColor(notification.type)
      const dismissible: boolean = !!notification.dismissible
      const timeout = typeof notification.dismissible === 'number' ? notification.dismissible : null
      return <AlertNotification
        onClose={handleClose}
        icon={icon}
        dismissible={dismissible}
        timeout={timeout}
        color={color}
        title={notification.title}
        message={notification.message}/>
    }
    default:
      return <></>

  }
};

export default AppNotification;
