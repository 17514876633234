/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
// Data
import FileInputField from "app/common/form/fields/FileInputField.jsx";
import {MdLayers} from "react-icons/all";
import MDButton from "../../../../../components/MDButton";
import {Form, Formik} from "formik";
import FormField from "../../../../../layouts/pages/users/new-user/components/FormField";
import useAppNotificationCopy from "../../../../common/error/useAppNotification";
import {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import formData from "./data/data";
import {useUpdateUserDataMutation} from "../../../../generated/generated-types";
import {extractName, useGetFile} from "@haulmont/jmix-react-core";


const BasicInfo = ({userData, refreshUserData}) => {
  // let callback = useTimeoutCallback(setUserDataState(getUserData()));
  // let getFile = useGetFile();
  const getFile = useGetFile();
  let [docs, seDocs] = useState([]);
  let [updateUser, {data, called, loading, error}] = useUpdateUserDataMutation();
  const {addNotificationSnackbar} = useAppNotificationCopy();
  let intl = useIntl();
  let {formFields, validations} = formData(intl);

  useEffect(() => {
    if (data) {
      addNotificationSnackbar({
        type: "Success", dismissible: 3000, anchorOrigin: {
          vertical: 'top', horizontal: 'center',
        }, title: intl.formatMessage({id: "notification.user.saved"})

      })
    }
  }, [data])

  useEffect(() => {
    mapDocsFrom(userData)
  }, [userData])

  useEffect(() => {
    if (called && data && !error) {
      console.log("refreshUserData")
      refreshUserData && refreshUserData()
    }
  }, [called, data, error])

  function mapDocsTo(values) {
    let docs = values?.documents || [];
    values.docOne = docs[0]
    values.docTwo = docs[1]
    values.docTree = docs[2]
    values.docFour = docs[3]
    values.docFive = docs[4]
    values.docSix = docs[5]
    delete values.documents
    return values;
  }


  const mapDocsFrom = (values) => {
    values.docOne && getFile(encodeURIComponent(values.docOne)).then((Blob) => seDocs(docs => {
      Blob.name = extractName(values.docOne)
      docs[0] = Blob
      return docs
    }))
    values.docTwo && getFile(encodeURIComponent(values.docTwo)).then((Blob) => seDocs(docs => {
      Blob.name = extractName(values.docTwo)
      docs[1] = Blob
      return docs
    }))
    values.docTree && getFile(encodeURIComponent(values.docTree)).then((Blob) => seDocs(docs => {
      Blob.name = extractName(values.docTree)
      docs[2] = Blob
      return docs
    }))
    values.docFour && getFile(encodeURIComponent(values.docFour)).then((Blob) => seDocs(docs => {
      Blob.name = extractName(values.docFour)
      docs[3] = Blob
      return docs
    }))
    values.docFive && getFile(encodeURIComponent(values.docFive)).then((Blob) => seDocs(docs => {
      Blob.name = extractName(values.docFive)
      docs[4] = Blob
      return docs
    }))
    values.docSix && getFile(encodeURIComponent(values.docSix)).then((Blob) => seDocs(docs => {
      Blob.name = extractName(values.docSix)
      docs[5] = Blob
      return docs
    }))
  }


  function handleUpdateUser(values) {
    updateUser({
      variables: {
        userDto: mapDocsTo(values)
      }
    })
  }


  return (<Formik
    enableReinitialize={true}
    validationSchema={validations}
    initialValues={{
      firstName: userData?.firstName || "",
      secondName: userData?.secondName || "",
      lastName: userData?.lastName || "",
      email: userData?.email || "",
      phone: userData?.phone || "",
      passportSerial: userData?.passportSerial || "",
      taxSerial: userData?.taxSerial || "",
      documents: docs
    }}
    onSubmit={(values) => {


      // await new Promise((resolve) => setTimeout(resolve, 500));
      handleUpdateUser(values)
      // alert(JSON.stringify(values, null, 2));
    }}
  >
    {({isSubmitting, handleSubmit}) => (<Form>
      <Card id="basic-info" sx={{overflow: "visible"}}>
        <MDBox p={3}>
          <MDTypography variant="h5">Basic Info</MDTypography>
        </MDBox>
        <MDBox pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <FormField name={formFields.firstName.name} label={formFields.firstName.label}/>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField name={formFields.secondName.name} label={formFields.secondName.label}/>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField name={formFields.lastName.name} label={formFields.lastName.label}/>
            </Grid>
            {/*<Grid item xs={12}>*/}
            {/*  <Grid container spacing={3}>*/}
            {/*    <Grid item xs={12} sm={4}>*/}
            {/*      <Autocomplete*/}
            {/*        defaultValue="Male"*/}
            {/*        options={selectData.gender}*/}
            {/*        renderInput={(params) => (*/}
            {/*          <FormField {...params} label="I'm" InputLabelProps={{shrink: true}}/>*/}
            {/*        )}*/}
            {/*      />*/}
            {/*    </Grid>*/}
            {/*    /!*<Grid item xs={12} sm={8}>*!/*/}
            {/*    /!*  <Grid container spacing={3}>*!/*/}
            {/*    /!*    <Grid item xs={12} sm={5}>*!/*/}
            {/*    /!*      <Autocomplete*!/*/}
            {/*    /!*        defaultValue="February"*!/*/}
            {/*    /!*        options={selectData.birthDate}*!/*/}
            {/*    /!*        renderInput={(params) => (*!/*/}
            {/*    /!*          <FormField*!/*/}
            {/*    /!*            {...params}*!/*/}
            {/*    /!*            label="Birth Date"*!/*/}
            {/*    /!*            InputLabelProps={{shrink: true}}*!/*/}
            {/*    /!*          />*!/*/}
            {/*    /!*        )}*!/*/}
            {/*    /!*      />*!/*/}
            {/*    /!*    </Grid>*!/*/}
            {/*    /!*    <Grid item xs={12} sm={4}>*!/*/}
            {/*    /!*      <Autocomplete*!/*/}
            {/*    /!*        defaultValue="1"*!/*/}
            {/*    /!*        options={selectData.days}*!/*/}
            {/*    /!*        renderInput={(params) => (*!/*/}
            {/*    /!*          <FormField {...params} InputLabelProps={{shrink: true}}/>*!/*/}
            {/*    /!*        )}*!/*/}
            {/*    /!*      />*!/*/}
            {/*    /!*    </Grid>*!/*/}
            {/*    /!*    <Grid item xs={12} sm={3}>*!/*/}
            {/*    /!*      <Autocomplete*!/*/}
            {/*    /!*        defaultValue="2021"*!/*/}
            {/*    /!*        options={selectData.years}*!/*/}
            {/*    /!*        renderInput={(params) => (*!/*/}
            {/*    /!*          <FormField {...params} InputLabelProps={{shrink: true}}/>*!/*/}
            {/*    /!*        )}*!/*/}
            {/*    /!*      />*!/*/}
            {/*    /!*    </Grid>*!/*/}
            {/*    /!*  </Grid>*!/*/}
            {/*    /!*</Grid>*!/*/}
            {/*  </Grid>*/}
            {/*</Grid>*/}
            <Grid item xs={12} sm={4}>
              <FormField
                disabled
                name={formFields.email.name}
                label={formFields.email.label}
                inputProps={{type: "email"}}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormField
                name={formFields.phone.name}
                label={formFields.phone.label}
                inputProps={{type: formFields.phone.type}}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormField
                name={formFields.passportSerial.name}
                label={formFields.passportSerial.label}
                inputProps={{type: formFields.passportSerial.type}}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormField name={formFields.taxSerial.name}
                         label={formFields.taxSerial.label}
                         inputProps={{type: formFields.taxSerial.type}}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FileInputField
                shrink
                multiple
                filesFilter={".png, .jpg, .jpeg .pdf"}
                inputProps={{
                  placeholder: "Всі заповнені сторінки паспорта та ідентифікаційний код."
                }}
                endButton={{
                  buttonProps: {
                    circular: true, color: "info", iconOnly: true,
                  }, icon: <MdLayers/>
                }}
                label={"Скан копії"}
                name={'documents'}
              />
              {/*<FileInputField name={'documents'}/>*/}
              {/*  <List dense>*/}
              {/*      state.files.map((file) =>*/}

              {/*          <ListItem key={file.name}*/}
              {/*            secondaryAction={*/}
              {/*              <IconButton edge="end" aria-label="delete">*/}
              {/*                <MdDelete />*/}
              {/*              </IconButton>*/}
              {/*            }*/}
              {/*          >*/}
              {/*            <ListItemAvatar  >*/}
              {/*                <MdOutlineFileDownloadDone />*/}
              {/*            </ListItemAvatar>*/}
              {/*            <ListItemText*/}
              {/*              primary={file.name}*/}
              {/*            />*/}
              {/*          </ListItem>*/}
              {/*      )*/}
              {/*    }*/}


              {/*  </List>*/}
            </Grid>
            <Grid style={{textAlign: 'right'}} item md={12} sm={12}>
              <MDButton onClick={handleSubmit} size={'small'} color={'info'} variant={'contained'}>Save</MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </Form>)}
  </Formik>);
};

export default BasicInfo;
