import {
  getEnumCaption,
  getMainStore,
  getMetadata,
  getPropertyInfo
} from "@haulmont/jmix-react-core";
import { toDisplayValue } from "@haulmont/jmix-react-web";
import { toJS } from "mobx";


export default function getValueTranslation(entityName, propertyName, value) {
  const metadata = getMetadata();
  const mainStore = getMainStore();
  if (!value || value === '' || !metadata.enums || !metadata.entities || !mainStore.initialized) {
    return "";
  }

  const propertyInfo = getPropertyInfo(
    metadata.entities,
    entityName,
    propertyName);

    if (!propertyInfo) {
      console.log("No propertyInfo entityName: ", entityName)
      console.log("No propertyInfo propertyName: ", propertyName)
      console.log("No propertyInfo value: ", value)
      console.log("No propertyInfo entities: ", metadata.entities)
    return "";
  }

  const displayValue = propertyInfo.attributeType === 'ENUM'
    ? getEnumCaption(value, propertyInfo, metadata.enums, mainStore.enumMessages)
    : toDisplayValue(toJS(value), propertyInfo);

  return formatValue(displayValue)
}


function formatValue(value) {
  const valType = typeof value;
  if (valType === "string") {
    return value;
  }
  if (valType === "object") {
    if (Object.prototype.hasOwnProperty.call(value, '_instanceName')) {
      return value._instanceName ?? value.id;
    }
    if (Array.isArray(value)) {
      const items = value.map(formatValue);
      return items.join(", ");
    }
  }
  console.log("Transledted to: ", JSON.stringify(value))
  return JSON.stringify(value);
}