/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import canteenIcon from "assets/app/icons/custom/canteen.svg";
// Icons
import medicineIcon from "assets/app/icons/custom/medicine.svg";
import pathIcon from "assets/app/icons/custom/path-signs.svg";
import showerIcon from "assets/app/icons/custom/shower.svg";
import campsIcon from "assets/app/icons/custom/two-level-camps.svg";
// Images
import background from "assets/app/images/backgrounds/bg2.jpg";
import medicine from "assets/app/images/conditions/1.png";
import canteen from "assets/app/images/conditions/2.png";
import conditions from "assets/app/images/conditions/3.png";
import location from "assets/app/images/conditions/4.png";
import conditions5 from "assets/app/images/conditions/5.png";
// Material Kit 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useRef, useState } from "react";
import { FaAngleRight, FaChevronLeft, FaChevronRight } from "react-icons/fa";
// SwiperJS
import SwiperCore, { Autoplay, Navigation } from "swiper";
import "swiper/modules/navigation/navigation.min.css";
// SwiperJS react components
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
// SwiperJS styles
import "swiper/swiper.min.css";

const ConditionsSection = ({ mt }) => {
  // install SwiperJS modules
  SwiperCore.use([Autoplay, Navigation]);

  // SwiperJS navigation buttons ref
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const [swiperEl, setSwiperEl] = useState(null);

  const slideTo = (index) => swiperEl && swiperEl.slideTo(index);

  const slides = [
    {
      icon: campsIcon,
      image: conditions,
      label: "Проживання",
      title: "Проживання",
      description:
        "Скаутвільці живуть у сучасних комфортних наметах по три особи однієї статі. Ми використовуємо якісні намети відомих фірм Tramp, Sol, Hannah. Всі намети розташовані на спеціально сконструйованих дерев’яних настилах, що забезпечує природну вентиляцію та виключає промокання. Намети двошарові з великим тамбуром для зберігання речей. В них зручно, тепло та безпечно. Антимоскитні сітки захищають мешканців від комах та павуків.",
    },
    {
      icon: showerIcon,
      image: conditions5,
      label: "Гігієна",
      title: "Гігієна",
      description:
        "В Скаутвіллі є власний душовий комплекс. Це окремі, обладнані всім необхідним, душові кабінки з теплою та гарячою водою. Для нагрівання води ми використовуємо спеціальну систему. Ми живемо поза цивілізацією, але дотримуємось гігієни :) Кожен житловий квартал табору має свою гігієнічну зону – вбиральні та умивальники. Наші лісові вбиральні - це окремі будиночки зі зручними сидіннями та дверями, які зачиняються. Туалети вигрібного типу, тож прибираються вони двічі на день технічним персоналом табору. Туалети мають накриття від дощу та збудовані, відповідно до санітарних вимог, затверджених законодавством.",
    },
    {
      icon: canteenIcon,
      image: canteen,
      label: "Їдальні",
      title: "Їдальні",
      description:
        "Кожен житловий квартал Скаутвіллю обладнаний власними критими їдальнями. Мешканці кварталу збираються за довгими столами для того, щоб поїсти, поспілкуватися, пограти в настільні ігри. Тут, на зручних вішачках зберігається дитячий посуд. Поруч для дітей обладнані місця для миття посуду та встановлені великі бочки з питною водою.",
    },
    {
      icon: medicineIcon,
      image: medicine,
      label: "МЕДИЦИНА",
      title: "Медицина",
      description:
        "На території табору є власний медичний пункт та ізолятор. В команді кожної зміни працюють лікар та медична сестра. Тому діти мають можливість отримати медичну допомогу цілодобово. До найближчої лікарні, з якою підписано договір про співробтництво - 10 кілометрів. В таборі є черговий автомобіль. Усі діти та співробітники Скаутвіллю застраховані. Безпека дітей та персоналу є нашим найважливішим пріорітетом.",
    },

    {
      icon: pathIcon,
      image: location,
      label: "Розташування",
      title: "Розташування",
      description:
        "Скаутвілль - це 100% природи. Табір займає територію у 8 гектарів соснового лісу на березі Канівського водосховища. Це затишне місце з неймовірними краєвидами. Перевагами соснового лісу є відсутність кліщів та отруйних рослин, а також надзвичайно чисте повітря. У прозорій воді Канівського моря видно зграйки риб та прісноводних рачків гамарусів. А в схилах берега живуть птахи серпокрильці. В таборі піщаний грунт, тож, навіть, після сильного дощу, під ногами немає калюж, сухо та тепло.",
    },
  ];

  const steps = [
    {
      icon: campsIcon,
      label: "Проживання",
    },
    {
      icon: showerIcon,
      label: "Гігієна",
    },
    {
      icon: canteenIcon,
      label: "Їдальні",
    },
    {
      icon: medicineIcon,
      label: "Медицина",
    },
    {
      icon: pathIcon,
      label: "Розташування",
    },
  ];

  // Swiper navigation buttons styles
  const navigationStyles = {
    opacity: 0.5,
    cursor: "pointer",
    transition: "opacity 0.15s ease",

    "&:hover, &:focus": {
      opacity: 1,
    },
  };

  return (
    <MDBox
      style={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center; center",
      }}
      component="section"
      py={12}
      position="relative"
    >
      <MDBox mx={5} fullWidth>
        <span>
          <FaAngleRight
            size={"25pt"}
            sx={{
              marginRight: "5pt",
            }}
          />
          <MDTypography
            fontWeight="bold"
            component="span"
            sx={{
              fontSize: "35pt"
            }}
            style={{
              letterSpacing: "2px",
              fontFamily: "Intro Black",
              color: "#9c27b0",
            }}
          >
            Умови та комфорт
          </MDTypography>
        </span>
      </MDBox>
      <Container>

        <Swiper
          onInit={(swiper) => {
            setSwiperEl(swiper);

            const { navigation: nav } = swiper.params;
            const { navigation } = swiper;

            nav.prevEl = navigationPrevRef.current;
            nav.nextEl = navigationNextRef.current;
            navigation.init();
            navigation.update();
          }}
          autoplay={{ delay: 5000 }}
          speed={800}
          spaceBetween={0}
          slidesPerView={1}
          allowTouchMove={true}
          loop
        >
          {slides.map(({ image, label, title, description , icon }) => (
            <SwiperSlide key={label}>
              <Grid container spacing={3} alignItems="center" sx={{ mb: { xs: 6, md: 0 } }}>
                <Grid item xs={12} md={5} ml={{ xs: 0, lg: "auto" }}>
                  <MDBox p={2}>
                    <MDBox
                      component="img"
                      src={image}
                      alt={title}
                      width="100%"
                      borderRadius="xl"
                      maxHeight="37.5rem"
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={5} mr={{ xs: 0, lg: "auto" }} position="relative">
                  <MDBox flex={1} mr={1} mt={1} component="img" src={icon} width="2em" />
                  <MDTypography
                    display="inline"
                    variant="h6"
                    fontWeight="bold"

                  >
                    {title}
                  </MDTypography>

                  <MDTypography variant="body2" my={3}>
                    {description}
                  </MDTypography>
                </Grid>
              </Grid>
            </SwiperSlide>
          ))}
          <MDBox
            display="flex"
            position="absolute"
            bottom={{ xs: 0, lg: "10%" }}
            right="5%"
            zIndex={2}
          >
            <MDTypography
              variant="h2"
              ref={navigationPrevRef}
              color="dark"
              mr={{ xs: 8, md: 2, lg: 8 }}
              sx={navigationStyles}
            >
              <MDBox>
                <FaChevronLeft />
              </MDBox>
            </MDTypography>
            <MDTypography variant="h2" ref={navigationNextRef} color="dark" sx={navigationStyles}>
              <MDBox>
                <FaChevronRight />
              </MDBox>
            </MDTypography>
          </MDBox>
        </Swiper>
      </Container>
    </MDBox>
  );
};

export default ConditionsSection;
