import { useCallback, useContext, useEffect, useState } from 'react';
import { AppNotificationContext, NotificationType } from './AppNotificationContext';


const useSuccessNotification = () => {
  const { addNotificationSnackbar } = useContext(AppNotificationContext);

  return useCallback((title: string, message?: string) => new Promise(() => {
    addNotificationSnackbar({
      title: title,
      message: message,
      dismissible: 2000 ,
      type: NotificationType.Success,
    })
  }), []);
};



export default useSuccessNotification;