/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import {useQuery} from "@apollo/client";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import CenteredLoader from "app/common/loader/CenteredLoader";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Footer from "examples/Footer";
// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// OrderDetails page components
import {useParams} from "react-router-dom";
import Header from "./components/Header";
import KidInformation from "./components/KidnIormation";
import OrderInfo from "./components/OrderInfo";
import OrderSummary from "./components/OrderSummary/OrderSummary";
import TrackOrder from "./components/TrackOrder";
import orderById from "app/queries/OrderTourById.query.graphql"
import {useOrderTourByIdLazyQuery, useOrderTourByIdQuery} from "../../../generated/generated-types";


const OrderDetails = () => {
  const {id} = useParams()

  console.log("Id: ", id)

  const {loading, data, error} = useOrderTourByIdQuery({variables: {id : id}})


  if (loading) {
    return <CenteredLoader/>
  }

  if (error) {
    return <h1>Error {error}</h1>
  }


  const order = data.ScoutvilleOrderTourById

  return (
    <DashboardLayout>
      <DashboardNavbar/>
      <MDBox my={6}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox pt={2} px={2}>
                <Header/>
              </MDBox>
              <Divider/>
              <MDBox pt={1} pb={3} px={2}>
                <MDBox mb={3}>
                  {order && <OrderInfo order={order}/>}
                </MDBox>
                <Divider/>
                <MDBox mt={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={4}>
                      {order &&  <TrackOrder order={order}/>}
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      {/* <MDBox mt={3}>
                        <BillingInformation />
                      </MDBox> */}
                      <MDBox mt={3}>
                        <KidInformation kid={order.kid}/>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} lg={4} sx={{ml: "auto"}}>
                      <OrderSummary order={order}/>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer/>
    </DashboardLayout>
  );
}

export default OrderDetails;

