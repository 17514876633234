/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import {checkResponseStatus} from "@haulmont/jmix-react-core";
import Card from "@mui/material/Card";
import CenteredLoader from "app/common/loader/CenteredLoader";
// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import {APP_REST_API_URL} from "config";
import {Form, FormikProvider, useFormik} from "formik";
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import {useState} from "react";
import * as yup from "yup";

const validationSchema = yup.object({
  email: yup.string("Enter your email").email("Enter a valid email").required("Email is required"),
});

const PasswordResetForm = ({onFinish}) => {

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: onFinish,
  });


  return (

    <FormikProvider value={formik}>
      <Form>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You will receive an e-mail in maximum 60 seconds
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox>
            <MDBox mb={4}>
              <MDInput
                name="email"
                id="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                success={formik.touched.email && !Boolean(formik.errors.email)}
                FormHelperTextProps={{error: true}}
                helperText={formik.touched.email && formik.errors.email}
                type="email"
                label="Email"
                variant="standard"
                fullWidth
              />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton
                onClick={formik.onSubmitForm}
                type="submit"
                value="submit"
                variant="gradient"
                color="info"
                fullWidth
              >
                reset
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Form>
    </FormikProvider>
  );
};

export default PasswordResetForm;
