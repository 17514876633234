import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import {useIntl} from "react-intl";
const Information = ({ mt }) => {
  let intl = useIntl();
  return (
    <Grid
      mt={mt}
      direction={"column"}
      container
      alignItems={"center"}
      justifyContent={"flex-start"}
    >
      <Grid
        item
        style={{
          textAlign: "center",
          display: "inline-block",
        }}
        md={12}
        lg={12}
        sm={12}
        xs={12}
      >
        <FaAngleRight
          size={"20pt"}
          sx={{
            marginRight: "5pt",
          }}
        />
        <MDTypography
          textTransform="capitalize"
          component="span"
          // variant="h1"
          sx={{
            fontSize: "2em",
            // fontSize: ({ typography: { d3, d4, d5, d6, } }) => ({
            //   xs: d6.fontSize,
            //   md: d6.fontSize,
            //   lg: d6.fontSize,
            // }),
          }}
          style={{
            letterSpacing: "2px",
            display: "inline-block",
            position: "relative",
            color: "#4caf50",
            fontFamily: "Intro Black",
            textTransform: "uppercase"
          }}
        >{intl.formatMessage({id: "title.scoutville"})}</MDTypography>
        <FaAngleLeft
          size={"20pt"}
          sx={{
            marginLeft: "5pt",
          }}
        />
      </Grid>
      <Grid container justifyContent={"center"}>
        <Grid
          item
          md={7}
          lg={7}
          style={{
            textAlign: "justify",
            display: "inline-block",
          }}
        >
          <MDBox mx={4}>
            <MDTypography
              component="p"
              opacity={0.7}
              style={{ lineHeight: "1.5em", fontFamily: "Intro Book" }}
              variant="button"
            >
              Найкращий літній наметовий табір України. Заснований у 2010 році Молодіжною
              громадською організацією “Скаути Києва”. В основі філософії табору – гармонія з
              природою, робота в малих групах, особистісний розвиток, традиції та правила, навчання
              через справу, підтримка дорослих, символьна основа.
            </MDTypography>
            <MDTypography
              opacity={0.7}
              component="p"
              style={{ lineHeight: "1.5em", fontFamily: "Intro Book" }}
              variant="button"
            >
              Табір розташований на березі Канівського водосховища, поблизу м.
              Переяслав-Хмельницький. Затишний сосновий ліс, неймовірні пейзажі, сонячний піщаний
              пляж, комфортні побутові умови та цікава насичена програма табору - все для найкращого
              дитячого відпочинку.
            </MDTypography>
          </MDBox>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Information;
