import { Autocomplete, FormLabel, Typography } from "@mui/material";
import ImageUpload from "app/common/files/ImageUpload";
import useFileDownload from "app/common/files/useFileDownload";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { ErrorMessage, useField } from "formik";
import { useCallback, useEffect, useState } from "react";

const ImageUploadField = ({ label, value, onChange, name, ...rest }) => {
  const [field, meta, helpers] = useField(name);



  // useEffect(() => {

  //   setPreviewUrl(

  //     )

  // }, [value])


  return (
    <MDBox mb={1.5}>
      <FormLabel>
        <Typography variant="body2" color="textSecondary">
          {label}
        </Typography>
      </FormLabel>
      <ImageUpload
        {...rest}
        onChange={(e) => {
          helpers.setValue(e);
        }}
        value={value}
      />
      <MDBox mt={0.75}>
        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
          <ErrorMessage name={name} />
        </MDTypography>
      </MDBox>
    </MDBox>
  );
};

export default ImageUploadField;
