import * as yup from "yup";


// export const form = {
//   initialValues: {
//     firstName: "",
//     secondName: "",
//     lastName: "",
//   }
// }

export const form = {
  formId: "new-kid-form",
  formFields: {
    firstName: {
      name: "firstName",
      label: "First Name",
      type: "text",
      errorMsg: "First name is required.",
    },
    secondName: {
      name: "secondName",
      label: "Second Name",
      type: "text",
      errorMsg: "Second name is required.",
    },
    lastName: {
      name: "lastName",
      label: "Last Name",
      type: "text",
      errorMsg: "Last name is required.",
    },
    gender: {
      name: "gender",
      label: "Gender",
      type: "text",
      errorMsg: "Gender is required.",
    },
    relation: {
      name: "relation",
      label: "Relation",
      type: "text",
      errorMsg: "Relation is required.",
    },
    birthDate: {
      name: "birthDate",
      label: "Birth date",
      type: "date",
      errorMsg: "Birth date is required.",
    },

    canSwim: {
      name: "canSwim",
      label: "Can swim",
      type: "text",
      errorMsg: "Can swim is required.",
    },
    shortSize: {
      name: "shortSize",
      label: "T-Short size",
      type: "text",
      errorMsg: "T-Short size is required.",
    },
    isFirsTour: {
      name: "isFirsTour",
      label: "Дитина їде до ЛНК \"Лісове містечко 'Скаутвілль'\"",
      type: "radio",
      errorMsg: "Can swim is required.",
    },
    isSawCamps: {
      name: "isSawCamps",
      label: "Ваша дитина бачила як виглядають намети, уявляє собі побутові умови наметового кемпінгу?",
      type: "radio",
      errorMsg: "Can swim is required.",

    },
    isDesMade: {
      name: "isDesMade",
      label: "Ваша дитина свідомо зробила вибір відпочивати в наметовому кемпінгу  \"Скаутвілль\"?",
      type: "radio",
      errorMsg: "Can swim is required.",
    },
    hobbyInfo: {
      name: "hobbyInfo",
      label: "Харчові особливості дитини",
      type: "text",
      errorMsg: "Can swim is required.",
    },
    healthInfo: {
      name: "healthInfo",
      label: "Стан здоров`я дитини",
      type: "text",
      errorMsg: "Хоббі та захоплення дитини.",
    },
    foodPreference: {
      name: "foodPreference",
      label: "Ваша дитина свідомо зробила вибір відпочивати в наметовому кемпінгу  \"Скаутвілль\"?",
      type: "text",
      errorMsg: "Can swim is required.",
    },
    bdCertificateSerial: {
      name: "bdCertificateSerial",
      label: "Cерія та номер свідоцтва про народження",
      type: "text",
      errorMsg: "Номер та серія свідоцтва про народження oбов'язкова.",
    },
    bdCertificate: {
      name: "bdCertificate",
      label: "Cвідоцтвo про народження дитини",
      type: "text",
      errorMsg: "Can swim is required.",
    },
  }
};



const { firstName, secondName, lastName, canSwim, birthDate, gender, relation, isFirsTour, isSawCamps, isDesMade, bdCertificateSerial, bdCertificate, shortSize } = form.formFields;
export const initialValues = {
  bdCertificate: null,
  bdCertificateSerial: "",
  hobbyInfo: "",
  healthInfo: "",
  foodPreference: "",
  isFirsTour: "",
  isSawCamps: "",
  isDesMade: "",
  firstName: "",
  secondName: "",
  lastName: "",
  birthDate: "",
  relation: null,
  gender: null,
  canSwim: null,
  shortSize: null
}


const documents = {
  validations: yup.object({
    // [bdCertificate.name]: yup.string().required(bdCertificate.errorMsg),
    [bdCertificateSerial.name]: yup.string().required(bdCertificateSerial.errorMsg),
    [relation.name]: yup.string().required(relation.errorMsg),
    // [canSwim.name]: yup.string().required(canSwim.errorMsg),
  })
};

const additional = {
  validations: yup.object({
    // [isFirsTour.name]: yup.string().required(isFirsTour.errorMsg),
    // [isSawCamps.name]: yup.string().required(isSawCamps.errorMsg),
    // [canSwim.name]: yup.string().required(canSwim.errorMsg),
  })
}

const experience =
{

  validations: yup.object({
    [isFirsTour.name]: yup.string().required(isFirsTour.errorMsg),
    [isSawCamps.name]: yup.string().required(isSawCamps.errorMsg),
    [isDesMade.name]: yup.string().required(isDesMade.errorMsg),
    // [birthDay.name]: yup.date().required(birthDay.errorMsg),
    // [canSwim.name]: yup.string().required(canSwim.errorMsg),
  })
}

const general = {
  validations: yup.object({
    [firstName.name]: yup.string().required(firstName.errorMsg),
    [secondName.name]: yup.string().required(secondName.errorMsg),
    [lastName.name]: yup.string().required(lastName.errorMsg),
    [birthDate.name]: yup.date().required(birthDate.errorMsg),
    [canSwim.name]: yup.string().required(canSwim.errorMsg),
    [shortSize.name]: yup.string().required(shortSize.errorMsg),
    [gender.name]: yup.string().required(gender.errorMsg),

  })
};


export const formValues = [
  general,
  additional,
  experience,
  documents
]

