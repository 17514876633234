/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-router-dom components
// @mui material components
import {checkResponseStatus} from "@haulmont/jmix-react-core";
import {CircularProgress, FormHelperText} from "@mui/material";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import CenteredLoader from "app/common/loader/CenteredLoader";
// Images
import bgImage from "assets/app/images/panoramic/photo11.jpg";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import {APP_REST_API_URL} from "config";
import {Form, FormikProvider, useFormik} from "formik";
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import {observer} from "mobx-react";
import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import SignUpForm from "./components/SignUpForm";
import ResultAction from "../../success/ResultAction";
import {MdNotificationsActive, MdOutlineDone} from "react-icons/md";
import {AiOutlineCloseCircle} from "react-icons/all";


const SignUp = observer(() => {
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  function getDefaultSignUpFetchOptions(data) {
    return {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
  }

  const onFinish = (values) => {
    setLoading(true);
    fetch(`${APP_REST_API_URL}registration/create`, getDefaultSignUpFetchOptions(values))
      .then(checkResponseStatus)
      .then((resp) => {
        console.log("Success : ", resp)
        setLoading(false);
        setDone(true);
      })
      .catch((reason) => {
        console.log("Error : ", reason)
        setLoading(false);
        setError(true);
        setDone(true)
      });
  };


  const errorRez = (<ResultAction
    color={"error"}
    cancelAction={{
      onClick: () => {
        navigate("/sign-in")
      },
      btnName: "Закрити"
    }}
    icon={<AiOutlineCloseCircle size={"50px"} color="inherit"/>}
    title="Щось пішло не так!"
    message="За більш детальною інформацією зверніться до адміністратора."
  />)

  const loadingRez = (<ResultAction
    color={"info"}
    icon={<CircularProgress color={'inherit'} style={{fontSize: "50px"}}/>}
    title="Зачекайте будь ласка"
    message="Ми перевіряем реесткаційні данні."
  />)


  const successRez = (<ResultAction
    color={"success"}
    icon={<MdOutlineDone size={"50px"} color="inherit"/>}
    title="Дакуемо за реестрацію!"
    message="Перевірте, будь ласка, ваш ящик електронної пошти для подальших дій."
    okAction={{
      onClick: () => {
        navigate("/sign-in")
      },
      btnName: "На стрінку входу"
    }}
  />)

  return (
    <CoverLayout image={bgImage}>
      <Card>
        {loading && loadingRez}
        {done && !error && !loading && successRez}
        {done && error && errorRez}
        {!done && !error && !loading && <SignUpForm onFinish={onFinish}/>}
      </Card>
    </CoverLayout>
  );
});

export default SignUp;
