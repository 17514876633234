import {Box, Grid} from "@mui/material";
import useErrorNotification from "app/common/error/useErrorNotification";
import CenteredLoader from "app/common/loader/CenteredLoader";
import {observer} from "mobx-react";
import {ScoutvilleTour} from "jmix/entities/ScoutvilleTour";
import TourCard from "./TourCard";
import {
  Inp_ScoutvilleTourOrderBy,
  SortOrder,
  TourListQueryVariables,
  useTourListQuery
} from "app/generated/generated-types";


interface ScoutvilleTourData {
  ScoutvilleTourList: ScoutvilleTour[];
}


interface ScoutvilleTourVariables {
  orderBy: {
    startDate: string

  }
}

const TourCardGrid = observer(() => {
  const errorNotification = useErrorNotification();
  let {data, error, loading} = useTourListQuery({
    variables: {
      orderBy: {
        startDate: SortOrder.Asc
      } as Inp_ScoutvilleTourOrderBy
    } as TourListQueryVariables
  });
  //
  // const {
  //   items,
  //   listQueryResult: {loading, error, called},
  // } = useEntityListData({
  //   listQuery: OrderTourListDocument,
  //   entityName: "ScoutvilleTour",
  //   sortOrder: {
  //     "startDate": "ASC",
  //   }
  // });

  // let entityListDataHookResult = useEntityListData({
  //
  // });
  //
  // const {
  //   data,
  //   error,
  //   loading,
  // } = useQuery<ScoutvilleTourData, ScoutvilleTourVariables>(SCOUTVILLETOUR_LIST, {
  //   variables: {
  //     orderBy: {
  //       startDate: "ASC",
  //     }
  //   }
  //
  // })

  // console.log("DATA", data)
  if (loading) {
    return <CenteredLoader/>
  }

  if (error) {
    errorNotification(error)
  }

  let len = data?.ScoutvilleTourList?.length ? data?.ScoutvilleTourList?.length : 1
  const cLg = (length) => length > 4 ? 12 / 5 : Math.min(12 / length, 3.5)
  const cMd = (length) => length > 4 ? 12 / 3 : Math.min(12 / length, 4)

  return (
    <>
      <Box mt={10}>
        <Grid container rowSpacing={5} columnSpacing={1} justifyContent={'space-around'}>
          {data?.ScoutvilleTourList?.map(tour => (
            <Grid style={{display: 'flex'}} item xs={12} sm={8} md={cMd(len)} lg={cLg(len)} flex={'auto'}
                  key={"" + tour?.id} className="mb-24 gutter-row">
              <TourCard tour={tour}/>
            </Grid>
          ))
          }
        </Grid>
      </Box>
    </>
  );


})


export default TourCardGrid;